import React from 'react'

function TaskTextCompleted(props) {
  return (
    <div className="flex-1 space-y-2">
      <div className="flex justify-between">
        <div>
          <p className="text-sm font-medium text-gray-900">
            {props.subtask.plan_subtask.name}
          </p>
          <p className="text-xs text-gray-500 mt-0.5">
            {props.subtask.plan_subtask.description}
          </p>
        </div>
        {props.isAssignedToEveryone &&
          props.subtask.store_team_member && props.subtask.is_completed && (
            <div>
              <p className="text-xs italic text-emerald-600">
                {props.subtask.store_team_member?.full_name}{" "}
                @{" "}
                {
                  props.subtask?.completed_at_formatted
                }
              </p>
            </div>
          )}
      </div>
      <div className="bg-white border border-gray-200 rounded-lg p-3 min-h-[40px] text-sm text-gray-700">
        {props.subtask.value_text}
      </div>
    </div>
  )
}

export default TaskTextCompleted
