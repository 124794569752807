import React from "react";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonDelete from "../requests/shared/helpers/ButtonDelete";

function LaborDriversAttributesTable(props) {
  const default_data = {
    labor_driver_attribute: {
      store_labor_driver_attributes: [],
      uuid: null,
      value: null,
    },
    labor_driver_sales: null,
    name: "",
    uuid: "",
  };

  const [attributeLaborDrivers, setAttributeLaborDrivers] = React.useState([]);

  React.useEffect(() => {
    // Filter props.laborDrivers to include only objects with non-null labor_driver_attribute
    const filteredDrivers = props.laborDrivers.filter(
      (driver) => driver.labor_driver_attribute !== null
    );
    // Set the filtered array to attributeLaborDrivers state
    setAttributeLaborDrivers(filteredDrivers);
  }, [props.laborDrivers]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "value" || name === "uuid") {
      setModalData((prevModalData) => ({
        ...prevModalData,
        labor_driver_attribute: {
          ...prevModalData.labor_driver_attribute,
          [name]: value,
        },
      }));
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [name]: value,
      }));
    }
  }

  function handleSubmit() {
    props.post_labor_driver_company_attribute(
      modalData.labor_driver_attribute.uuid,
      modalData.name,
      modalData.labor_driver_attribute.value
    );
    handleClose();
  }

  // console.log("MODAL DATA", modalData)

  return (
    <>
      <div className="mt-10">
        <div className="w-full flex items-center justify-between px-6">
          <div>
            <h1 className="text-3xl text-slate-900">Attributes</h1>
          </div>
          <Tooltip title="Add attribute driver">
            <button
              className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
              onClick={() => {
                setModalData(default_data);
                setModalEdit(false);
                handleOpen();
              }}
            >
              <AddIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
            </button>
          </Tooltip>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="flex-1 py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Default Value
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-6">
                        {/* <span className="sr-only">Edit</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {attributeLaborDrivers?.map(
                      (attributeLaborDriver, index) => (
                        <tr
                          key={index}
                          className="hover:bg-slate-50 cursor-pointer"
                          onClick={() => {
                            setModalData(attributeLaborDriver);
                            setModalEdit(true);
                            handleOpen();
                          }}
                        >
                          <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                            <div>
                              <p className="text-slate-900">
                                {attributeLaborDriver.name}
                              </p>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            <div>
                              <p className="text-slate-900">
                                {
                                  attributeLaborDriver.labor_driver_attribute
                                    .value
                                }
                              </p>
                            </div>
                          </td>
                          <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium w-5">
                            <div className="flex items-center justify-end w-fit h-full text-slate-500">
                              <ChevronRightIcon />
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <div className="border-b border-slate-200"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={
                modalEdit ? "Edit Attribute Driver" : "Add Attribute Driver"
              }
              onClose={handleClose}
            />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-sm text-slate-500">Name</p>
                <div className="mt-1">
                  <TextField
                    placeholder="Driver name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="name"
                    value={modalData.name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Default value</p>
                <div className="mt-1">
                  <TextField
                    placeholder="1"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="value"
                    value={modalData.labor_driver_attribute?.value}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Changes" : "Save Attribute Driver"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                {modalEdit ? (
                  <ButtonDelete
                    text="Delete"
                    onClick={() => {
                      props.delete_labor_driver(modalData.uuid);
                      handleClose();
                    }}
                  />
                ) : (
                  <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
                )}
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default LaborDriversAttributesTable;
