import React from "react";
import TimelineIcon from "@mui/icons-material/Timeline";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import TopBarDisplayCreateTrainingContent from "../home/top_bar_components/TopBarDisplayCreateTrainingContent";
import TracksDisplay from "./TracksDisplay";
import ModulesDisplay from "./ModulesDisplay";
import SectionsDisplay from "./SectionsDisplay";
import QuizzesDisplay from "./QuizzesDisplay";
import {
  API_get_learning_tracks,
  API_get_learning_modules,
  API_get_learning_section_summaries,
  API_get_learning_quiz_summaries,
  API_post_learning_track,
  API_delete_learning_track,
  API_post_learning_module,
  API_delete_learning_module,
  API_post_learning_section,
  API_delete_learning_section,
  API_post_learning_quiz,
  API_delete_learning_quiz,
  API_get_role_names2,
  API_post_learning_track_enrollment_by_roles,
} from "../../../constants";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateTrainingContentDisplay(props) {
  const [display, setDisplay] = React.useState("Tracks");

  const homeCompany = props.storeNames?.filter(
    (s) => s.id === props.homeStore
  )[0]["company"];

  const default_data_track = {
    create_at: null,
    modules: [],
    name: "",
    update_at: null,
    uuid: null,
    roles_assigned_to: [],
  };
  const [modalDataTrack, setModalDataTrack] =
    React.useState(default_data_track);

  const default_data_module = {
    color: "bg-slate-400",
    created_at: null,
    name: "",
    sections: [],
    updated_at: null,
    uuid: null,
  };
  const [modalDataModule, setModalDataModule] =
    React.useState(default_data_module);

  const default_data_section = {
    created_at: null,
    name: "",
    updated_at: null,
    uuid: null,
    as_json: [],
  };
  const [modalDataSection, setModalDataSection] =
    React.useState(default_data_section);

  const default_data_quiz = {
    created_at: null,
    learning_questions: [
      // {
      //   answers: [],
      //   created_at: null,
      //   question: "",
      //   updated_at: null,
      //   uuid: null
      // }
    ],
    name: "",
    updated_at: null,
    uuid: null,
    pass_percentage: 0,
    retake_limit: null,
    show_correct_answers: true,
  };
  const [modalDataQuiz, setModalDataQuiz] = React.useState(default_data_quiz);

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const default_api_calls = {
    tracks: API_get_learning_tracks,
    modules: API_get_learning_modules,
    sections: API_get_learning_section_summaries,
    quizzes: API_get_learning_quiz_summaries,
    roleNames: API_get_role_names2,
  };

  const api_params = {
    company_id: homeCompany,
    store_id: props.homeStore,
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      // console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setData(tempData);
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
      //throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
  }, []);

  // ----------- POST APIs ----------- //

  // [POST] api/lms_info/post_learning_track (create/ update)
  // * learning_track_uuid (optional)
  // * company_id
  // * name
  // * learning_module_uuids (JSON stringified ordered list of UUIDs)

  const post_learning_track = async () => {
    const moduleUUIDs =
      modalDataTrack.modules?.map((module) => module.uuid) || [];
    const rolesAssignedToIDs =
      modalDataTrack.roles_assigned_to?.map((role) => role.id) || [];
    // console.log("ROLESID", rolesAssignedToIDs);

    // Prepare data to send
    const requestData = {
      learning_track_uuid: modalDataTrack.uuid,
      company_id: homeCompany,
      name: modalDataTrack.name,
      learning_module_uuids: JSON.stringify(moduleUUIDs),
      roles_assigned_to: JSON.stringify(rolesAssignedToIDs),
    };

    try {
      toast.loading("Saving Track...", { autoClose: false });

      const res = await postRightworkAPI(API_post_learning_track, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Track Saved Successfully", { autoClose: 3000 });

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/delete_learning_track
  // * company_id
  // * learning_track_uuid
  const delete_learning_track = async () => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      learning_track_uuid: modalDataTrack.uuid,
    };

    try {
      toast.loading("Deleting Track...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_learning_track,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Track Deleted Successfully", { autoClose: 3000 });

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  //   [POST] api/lms_info/post_learning_module (create/ update)
  // * learning_module_uuid (optional)
  // * company_id
  // * name
  // * color
  // * learning_section_uuids (JSON stringified ordered list of UUIDs)
  const post_learning_module = async () => {
    const sectionUUIDs =
      modalDataModule.sections?.map((section) => section.uuid) || [];

    // Prepare data to send
    const requestData = {
      learning_module_uuid: modalDataModule.uuid,
      company_id: homeCompany,
      name: modalDataModule.name,
      color: modalDataModule.color,
      learning_section_uuids: JSON.stringify(sectionUUIDs),
    };

    try {
      toast.loading("Saving Module...", { autoClose: false });

      const res = await postRightworkAPI(API_post_learning_module, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Module Saved Successfully", { autoClose: 3000 });

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/delete_learning_module
  // * company_id
  // * learning_module_uuid
  const delete_learning_module = async () => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      learning_module_uuid: modalDataModule.uuid,
    };

    try {
      toast.loading("Deleting Module...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_learning_module,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Module Deleted Successfully", { autoClose: 3000 });

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/post_learning_section (create/ update)
  // * learning_section_uuid (optional)
  // * company_id
  // * name
  // * as_json
  const post_learning_section = async (duplicate) => {
    const sectionUUIDs =
      modalDataModule?.sections?.map((section) => section.uuid) || [];

    // NOTE: For any newly created files, we must swap out the file URL with our
    //       internal Rightwork file UUID.
    const editedJson = modalDataSection.as_json.map((element) => {
      const validTypes = ["image", "video", "audio", "file"];
      if (!validTypes.includes(element.type)) {
        return element;
      }
      if ("file_uuid" in element.props) {
        return element;
      }

      let mapSignedUrlToFileUuid =
        localStorage.getItem("mapSignedUrlToFileUuid") || "{}";
      mapSignedUrlToFileUuid = JSON.parse(mapSignedUrlToFileUuid);

      const uuidVal = mapSignedUrlToFileUuid[element.props.url];
      if (uuidVal) {
        element.props.file_uuid = mapSignedUrlToFileUuid[element.props.url];
      }
      return element;
    });
    // console.log(editedJson);

    // Prepare data to send
    const requestData = {
      learning_section_uuid: duplicate ? null : modalDataSection.uuid,
      company_id: homeCompany,
      name: modalDataSection.name,
      as_json: modalDataSection.as_json,
    };

    try {
      toast.loading(`${duplicate ? "Duplicating" : "Saving"} Section...`, {
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_post_learning_section,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success(
          `Section ${duplicate ? "Duplicated" : "Saved"} Successfully`,
          { autoClose: 3000 }
        );

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/delete_learning_section
  // * company_id
  // * learning_section_uuid
  const delete_learning_section = async () => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      learning_section_uuid: modalDataSection.uuid,
    };

    try {
      toast.loading("Deleting Section...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_learning_section,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Section Deleted Successfully", { autoClose: 3000 });

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/post_learning_quiz (create)
  // * company_id
  // * learning_quiz_uuid
  // * name
  // * learning_questions (JSON.stringify)
  //     * uuid (either valid UUID or null)
  //     * company_id
  //     * quiz_id
  //     * order_index
  //     * question
  //     * answers
  //     * answer_indices
  const post_learning_quiz = async (duplicate) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      learning_quiz_uuid: duplicate ? null : modalDataQuiz.uuid,
      name: duplicate ? `${modalDataQuiz.name}` : modalDataQuiz.name,
      pass_percentage: modalDataQuiz.pass_percentage,
      retake_limit: modalDataQuiz.retake_limit,
      show_correct_answers: modalDataQuiz.show_correct_answers,
      learning_questions: JSON.stringify(modalDataQuiz.learning_questions),
      is_duplicate: duplicate,
    };

    try {
      toast.loading(`${duplicate ? "Duplicating" : "Saving"} Quiz...`, {
        autoClose: false,
      });

      const res = await postRightworkAPI(API_post_learning_quiz, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success(
          `Quiz ${duplicate ? "Duplicated" : "Saved"} Successfully`,
          { autoClose: 3000 }
        );

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/delete_learning_quiz
  // * company_id
  // * learning_quiz_uuid
  const delete_learning_quiz = async () => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      learning_quiz_uuid: modalDataQuiz.uuid,
    };

    try {
      toast.loading("Deleting Quiz...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_learning_quiz, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Quiz Deleted Successfully", { autoClose: 3000 });

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] lms_info/post_learning_track_enrollment_by_roles
  // * company_id
  // * learning_track_uuid
  const post_learning_track_enrollment_by_roles = async () => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      learning_track_uuid: modalDataTrack.uuid,
    };

    try {
      toast.loading("Publishing Track...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_learning_track_enrollment_by_roles,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Published Track Successfully", { autoClose: 3000 });

        fetch_data(default_api_calls, api_params, false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  return (
    <>
      {!isLoading ? (
        <div className="w-full min-h-screen flex">
          <div className="w-1/5">
            <div className="h-full flex flex-col gap-2 px-6 py-12 bg-slate-50 border-r border-slate-200">
              <button
                className={`w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
                  display === "Tracks" ? "bg-white" : ""
                }`}
                onClick={() => {
                  setModalDataSection(default_data_section);
                  setDisplay("Tracks");
                }}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Tracks" ? "text-violet-700" : "text-slate-500"
                  }`}
                >
                  <div>
                    <TimelineIcon />
                  </div>
                  <span>Tracks</span>
                </div>
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Modules" ? "bg-white" : ""
                }`}
                onClick={() => {
                  setModalDataSection(default_data_section);
                  setDisplay("Modules");
                }}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Modules" ? "text-violet-700" : "text-slate-500"
                  }`}
                >
                  <div>
                    <ViewModuleIcon />
                  </div>
                  <span>Modules</span>
                </div>
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Sections" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Sections")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Sections"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <ViewHeadlineIcon />
                  </div>
                  <span>Sections</span>
                </div>
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Quizzes" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Quizzes")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Quizzes" ? "text-violet-700" : "text-slate-500"
                  }`}
                >
                  <div>
                    <QuizOutlinedIcon />
                  </div>
                  <span>Quizzes</span>
                </div>
              </button>
            </div>
          </div>
          <div className="w-4/5 min-h-screen">
            <div>
              <TopBarDisplayCreateTrainingContent
                title={"Create Training Content"}
                storeOptions={props.storeOptions}
                homeStore={props.homeStore}
                storeNames={props.storeNames}
                change_home_store={props.change_home_store}
              />
            </div>
            <div>
              {display === "Tracks" ? (
                <TracksDisplay
                  tracksData={data.tracks?.redux?.payload?.learning_tracks}
                  modulesData={data.modules?.redux?.payload?.learning_modules}
                  sectionsData={
                    data.sections?.redux?.payload?.learning_sections
                  }
                  quizzesData={data.quizzes?.redux?.payload?.learning_quizzes}
                  roleNames={data.roleNames}
                  homeCompany={homeCompany}
                  modalDataTrack={modalDataTrack}
                  setModalDataTrack={setModalDataTrack}
                  default_data_track={default_data_track}
                  modalDataModule={modalDataModule}
                  setModalDataModule={setModalDataModule}
                  default_data_module={default_data_module}
                  modalDataSection={modalDataSection}
                  setModalDataSection={setModalDataSection}
                  default_data_section={default_data_section}
                  post_learning_track={post_learning_track}
                  delete_learning_track={delete_learning_track}
                  post_learning_module={post_learning_module}
                  post_learning_section={post_learning_section}
                  modalDataQuiz={modalDataQuiz}
                  setModalDataQuiz={setModalDataQuiz}
                  default_data_quiz={default_data_quiz}
                  post_learning_quiz={post_learning_quiz}
                  post_learning_track_enrollment_by_roles={
                    post_learning_track_enrollment_by_roles
                  }
                />
              ) : display === "Modules" ? (
                <ModulesDisplay
                  modulesData={data.modules?.redux?.payload?.learning_modules}
                  sectionsData={
                    data.sections?.redux?.payload?.learning_sections
                  }
                  quizzesData={data.quizzes?.redux?.payload?.learning_quizzes}
                  homeCompany={homeCompany}
                  modalDataModule={modalDataModule}
                  setModalDataModule={setModalDataModule}
                  default_data_module={default_data_module}
                  modalDataSection={modalDataSection}
                  setModalDataSection={setModalDataSection}
                  default_data_section={default_data_section}
                  post_learning_module={post_learning_module}
                  delete_learning_module={delete_learning_module}
                  post_learning_section={post_learning_section}
                  delete_learning_section={delete_learning_section}
                  modalDataQuiz={modalDataQuiz}
                  setModalDataQuiz={setModalDataQuiz}
                  default_data_quiz={default_data_quiz}
                  post_learning_quiz={post_learning_quiz}
                />
              ) : display === "Sections" ? (
                <SectionsDisplay
                  sectionsData={
                    data.sections?.redux?.payload?.learning_sections
                  }
                  quizzesData={data.quizzes?.redux?.payload?.learning_quizzes}
                  homeCompany={homeCompany}
                  modalDataSection={modalDataSection}
                  setModalDataSection={setModalDataSection}
                  default_data_section={default_data_section}
                  post_learning_section={post_learning_section}
                  delete_learning_section={delete_learning_section}
                  modalDataQuiz={modalDataQuiz}
                  setModalDataQuiz={setModalDataQuiz}
                  default_data_quiz={default_data_quiz}
                  post_learning_quiz={post_learning_quiz}
                />
              ) : display === "Quizzes" ? (
                <QuizzesDisplay
                  quizzesData={data.quizzes?.redux?.payload?.learning_quizzes}
                  homeCompany={homeCompany}
                  modalDataQuiz={modalDataQuiz}
                  setModalDataQuiz={setModalDataQuiz}
                  default_data_quiz={default_data_quiz}
                  post_learning_quiz={post_learning_quiz}
                  delete_learning_quiz={delete_learning_quiz}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default CreateTrainingContentDisplay;
