import React from "react";
import { getRightworkAPI } from "../../requests/utilities/requests";
import { postRightworkAPI } from "../../requests/utilities/requests";
import { formatDate } from "../../requests/utilities/helpers";
import NotificationsDrawer from "../../requests/NotificationsDrawer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  API_drawer_notifications,
  API_get_notification_settings,
  API_hide_drawer_notification,
  API_hide_all_drawer_notifications,
  API_update_notification_settings,
} from "../../../../constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Notifications(props) {
  const [storeId, setStoreId] = React.useState(props.homeStore);

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [notificationsOpen, setNotificationsOpen] = React.useState(false);

  function handleNotificationClick() {
    setNotificationsOpen((prevState) => !prevState);
  }

  const notificationsRef = React.useRef(null);

  React.useEffect(() => {
    const closeNotificationsOnOutsideClick = (event) => {
      if (
        notificationsOpen &&
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setNotificationsOpen(false);
      }
    };

    document.addEventListener("click", closeNotificationsOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeNotificationsOnOutsideClick);
    };
  }, [notificationsOpen]);

  const default_api_calls = {
    notifications: API_drawer_notifications,
    notificationSettings: API_get_notification_settings,
  };

  // Get the current date
  let currentDate = new Date();
  // Get the start date of the previous month
  let lowerBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  // Get the end date of the next month
  let upperBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    0
  );

  let lowerBoundDateStr = formatDate(lowerBoundDate);
  let upperBoundDateStr = formatDate(upperBoundDate);

  let api_params = {
    store_id: props.homeStore,
    lower_bound_date: lowerBoundDateStr,
    upper_bound_date: upperBoundDateStr,
    specific_date: formatDate(currentDate),
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      // console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setIsLoading(false);
      setData(tempData);
    } catch (error) {
      // console.log(error);
      //throw Error("Promise failed");
    }
  };

  // ----------- POST APIs ----------- //

  const archive_drawer_notification = async (drawer_notification_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      drawer_notification_uuid: drawer_notification_uuid,
    };

    try {
      toast.loading("Archiving Drawer Notification...", { autoClose: false });
      const res = await postRightworkAPI(
        API_hide_drawer_notification,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Drawer Notification Archived!", { autoClose: 3000 });
        fetch_data(
          {
            notifications: API_drawer_notifications,
          },
          api_params
        );
        // Further handle success case as per your requirement
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
  }, [props.homeStore]);

  const notifications =
    data.notifications?.redux?.payload?.drawer_notifications;

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const archive_all_drawer_notifications = async () => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
    };

    try {
      toast.loading("Archiving All Drawer Notifications...", {
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_hide_all_drawer_notifications,
        requestData
      );

      if (res.status === 200) {
        // Handle success
        toast.success("All notifications were cleared successfully", {
          autoClose: 3000,
        });
        toast.dismiss();
        fetch_data(
          {
            notifications: API_drawer_notifications,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          notifications: API_drawer_notifications,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const update_notification_settings = async (fe_settings) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      fe_settings: JSON.stringify(fe_settings),
    };

    try {
      toast.loading("Updating Notification Settings...", { autoClose: false });

      const res = await postRightworkAPI(
        API_update_notification_settings,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        fetch_data(
          {
            notificationSettings: API_get_notification_settings,
          },
          api_params
        );
        toast.success("Your notification settings were successfully updated", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          notificationSettings: API_get_notification_settings,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  React.useEffect(() => {
    setStoreId(props.homeStore);
  }, [props.homeStore]);

  // // console.log("STORE ID", storeId)

  return (
    <>
      <div>
        <button
          className="h-10 w-10 text-slate-500 flex items-center justify-center relative"
          onClick={toggleDrawer(true)}
        >
          {notificationsOpen ? (
            <NotificationsIcon style={{ fontSize: "26px" }} />
          ) : (
            <NotificationsNoneIcon style={{ fontSize: "26px" }} />
          )}
          {notifications?.length > 0 && (
            <div className="w-5 h-5 absolute top-0 right-0.5 bg-rose-600 rounded-full text-white text-xs flex items-center justify-center">
              {notifications?.length}
            </div>
          )}
        </button>
      </div>
      <NotificationsDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        notifications={notifications}
        archive_drawer_notification={archive_drawer_notification}
        archive_all_drawer_notifications={archive_all_drawer_notifications}
        notificationSettings={
          data?.notificationSettings?.redux?.payload?.notification_settings
        }
        update_notification_settings={update_notification_settings}
        permissionRole={props.permissionRole}
      />
    </>
  );
}

export default Notifications;
