import React from "react";
import EmployeesTable from "../requests/manager/employees/EmployeesTable";
import {
  API_get_list_users,
  API_get_store_team_member,
  API_get_roles,
  API_store_team_member_set_hide,
  API_store_team_member_set_order,
  API_post_store_team_member,
  API_update_team_member_info,
  API_post_certificate,
  API_delete_certificate,
} from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { formatDate } from "../requests/utilities/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../utilities/LoadSpinner";
import Account from "../home/top_bar_components/Account";
import StoreName from "../home/top_bar_components/StoreName";
import TopBarDisplayTitle from "../home/top_bar_components/TopBarDisplayTitle";
import TopBarDisplayUserProfile from "../home/top_bar_components/TopBarDisplayUserProfile";
import { getDefaultAxiosConfig } from "../requests/utilities/requests";
import axios from "axios";
import UserProfileForEmployee from "./UserProfileForEmployee";
import { getCardColor2 } from "../../../helpers/helpers";

function UserProfileEmployeeDisplay(props) {
  const [data, setData] = React.useState([]);
  const [selectedTeamMemberData, setSelectedTeamMemberData] = React.useState();
  const [selectedTeamMemberId, setSelectedTeamMemberId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  // console.log("SELECTEDTEAMMEMBERID", selectedTeamMemberId)

  const default_api_calls = {
    roleNames: API_get_roles,
  };

  // Get the current date
  let currentDate = new Date();
  // Get the start date of the previous month
  let lowerBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  // Get the end date of the next month
  let upperBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    0
  );

  let lowerBoundDateStr = formatDate(lowerBoundDate);
  let upperBoundDateStr = formatDate(upperBoundDate);

  let api_params = {
    store_id: props.homeStore,
    lower_bound_date: lowerBoundDateStr,
    upper_bound_date: upperBoundDateStr,
    specific_date: formatDate(currentDate),
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      // console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setData(tempData);
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
      //throw Error("Promise failed");
    }
  };

  const fetchStoreTeamMember = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      store_id: props.homeStore,
    };
    let api = API_get_store_team_member;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        // console.log("STORE TEAM MEMBER", res.data.redux?.payload?.employee)
        setSelectedTeamMemberData(res.data.redux?.payload?.employee);
        // setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  // ----------- POST APIs ----------- //

  const post_store_team_member = async (
    first_name,
    last_name,
    email,
    phone_number,
    permission_type,
    stm_id,
    clock_pin,
    date_of_birth
  ) => {
    // Prepare data to send
    const requestData = {
      stm_id: stm_id,
      store_id: props.homeStore,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
      permission_type: permission_type,
      clock_pin: clock_pin,
      date_of_birth: date_of_birth,
    };

    // console.log("Company ID", requestData);

    try {
      toast.loading("Updating Employee...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_store_team_member,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Employee Updated!", { autoClose: 3000 });

        fetchStoreTeamMember(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_certificate = async (
    store_team_member_id,
    certificate_uuid,
    type,
    expiration_date,
    image
  ) => {
    const formData = new FormData();

    formData.append("store_id", props.homeStore);
    formData.append("store_team_member_id", store_team_member_id);
    formData.append("certificate_uuid", certificate_uuid);
    formData.append("type", type);
    formData.append("expiration_date", expiration_date);
    formData.append("image", image);

    try {
      toast.loading("Updating Certificate...", { autoClose: false });

      const axiosConfig = await getDefaultAxiosConfig();

      const res = await axios.post(API_post_certificate, formData, {
        headers: {
          ...axiosConfig.headers, // Merge default axios headers with multipart/form-data headers
          "Content-Type": "multipart/form-data", // Set content type for file upload
        },
      });

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Certificate Updated!", { autoClose: 3000 });

        fetchStoreTeamMember(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const delete_certificate = async (store_team_member_id, certificate_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      store_team_member_id: store_team_member_id,
      certificate_uuid: certificate_uuid,
    };

    try {
      toast.loading("Deleting Certificate...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_certificate, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Certificate Deleted!", { autoClose: 3000 });

        fetchStoreTeamMember(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  React.useEffect(() => {
    fetchStoreTeamMember(true);
    fetch_data(default_api_calls, api_params, true);
  }, [props.homeStore]);

  // State for user profile

  const [showUserProfile, setShowUserProfile] = React.useState(false);

  // console.log("SELECTED EMPLOYEE", selectedTeamMemberData)
  // console.log("DATADATA", data)
  // // console.log(selectedTeamMemberData ? getCardColor2(selectedTeamMemberData?.primary_role_id, data.roleNames, false) : "bg-slate-500")

  return (
    <>
      {!isLoading ? (
        <div>
          <div>
            <TopBarDisplayTitle
              title="User Profile"
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div>
          <div>
            <UserProfileForEmployee
              selectedTeamMemberData={selectedTeamMemberData}
              roleNames={data.roleNames}
              timeEntriesEnabled={props.timeEntriesEnabled}
              homeStore={props.homeStore}
              permissionRole={props.permissionRole}
              post_store_team_member={post_store_team_member}
              post_certificate={post_certificate}
              delete_certificate={delete_certificate}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default UserProfileEmployeeDisplay;
