import React from "react";
import LoadSpinner from "../../../utilities/LoadSpinner";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import StoreIcon from "@mui/icons-material/Store";
import LaborModelInnerDisplay from "./LaborModelInnerDisplay";
import LaborDriversDisplay from "./LaborDriversDisplay";
import LocationDetailsDisplay from "./LocationDetailsDisplay";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import {
  API_get_labor_standards,
  API_post_labor_standard,
  API_delete_labor_standard,
  API_get_labor_drivers,
  API_post_labor_driver_company_attribute,
  API_post_labor_driver_sales,
  API_delete_labor_driver,
  API_get_company_time_windows,
  API_delete_company_time_windows,
  API_get_role_types,
  API_get_stores_company_admin,
  API_post_store_proficiency,
  API_get_labor_driver_store_attributes,
  API_post_labor_driver_store_attribute,
  API_get_role_names2,
  API_get_labor_program_flags,
  API_post_labor_program_flag_company,
  API_delete_labor_program_flag_company,
  API_get_labor_program_flags_with_store_overrides,
  API_post_labor_program_flag_store,
  API_publish_labor_standard,
  API_revert_labor_standard,
} from "../../../constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBarDisplayTitleNoLocationSelect from "../home/top_bar_components/TopBarDisplayTitleNoLocationSelect";

function LaborModelOuterDisplay(props) {
  const [data, setData] = React.useState([]);
  const [display, setDisplay] = React.useState("Labor Model");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSavingData, setIsSavingData] = React.useState(false);

  const homeCompany = props.storeNames?.filter(
    (s) => s.id === props.homeStore
  )[0]["company"];

  const companyStores = props.storeNames?.filter(
    (s) => s.company === homeCompany
  );

  const getBusinessWeekStartDayIndex = (storeNames, store_id) => {
    const day_of_week_names = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    try {
      return day_of_week_names.indexOf(
        storeNames.filter((v, i) => v.id === store_id)[0][
          "business_week_start_day"
        ]
      );
    } catch (error) {
      return 1;
    }
  };

  const default_api_calls = {
    laborStandards: API_get_labor_standards,
    laborDrivers: API_get_labor_drivers,
    companyTimeWindows: API_get_company_time_windows,
    roleTypes: API_get_role_types,
    storesInfo: API_get_stores_company_admin,
    laborDriverStoreAttributes: API_get_labor_driver_store_attributes,
    roleNames: API_get_role_names2,
    laborProgramFlags: API_get_labor_program_flags,
    laborProgramFlagsStoreOverrides:
      API_get_labor_program_flags_with_store_overrides,
  };

  let api_params = {
    company_id: homeCompany,
    store_id: props.homeStore,
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      // console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setData(tempData);
      setIsLoading(false);
      setIsSavingData(false);
    } catch (error) {
      // console.log(error);
      //throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
  }, []);

  // ----------- POST APIs ----------- //

  const post_labor_standard = async (
    labor_standard_uuid,
    type,
    name,
    department_id,
    company_time_window_uuid,
    company_time_window_name,
    dynamic_start_time,
    dynamic_end_time,
    fixed_start_offset_qhnum,
    fixed_end_offset_qhnum,
    week_days,
    role_ids,
    constant_time_minutes,
    scalable_time_minutes,
    labor_driver_uuid,
    labor_program_flag_company_uuid
  ) => {
    // Prepare data to send
    const requestData = {
      labor_standard_uuid: labor_standard_uuid,
      company_id: homeCompany,
      type: type,
      name: name,
      department_id: department_id,
      company_time_window_uuid: company_time_window_uuid,
      company_time_window_name: company_time_window_name,
      dynamic_start_time: dynamic_start_time,
      dynamic_end_time: dynamic_end_time,
      fixed_start_offset_qhnum: fixed_start_offset_qhnum,
      fixed_end_offset_qhnum: fixed_end_offset_qhnum,
      week_days: week_days,
      role_ids: role_ids,
      constant_time_minutes: constant_time_minutes,
      scalable_time_minutes: scalable_time_minutes,
      labor_driver_uuid: labor_driver_uuid,
      labor_program_flag_company_uuid,
      labor_program_flag_company_uuid,
    };

    // console.log("Company ID", requestData);

    try {
      toast.loading("Updating Labor Standard...", { autoClose: false });

      const res = await postRightworkAPI(API_post_labor_standard, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Labor Standard Updated!", { autoClose: 3000 });

        fetch_data(
          {
            laborStandards: API_get_labor_standards,
            companyTimeWindows: API_get_company_time_windows,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("ERRORDATA", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const delete_labor_standard = async (labor_standard_uuid) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      labor_standard_uuid: labor_standard_uuid,
    };

    try {
      toast.loading("Deleting Labor Standared...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_labor_standard,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Labor Standard Deleted!", { autoClose: 3000 });

        fetch_data(
          {
            laborStandards: API_get_labor_standards,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_labor_driver_company_attribute = async (uuid, name, value) => {
    // Prepare data to send
    const requestData = {
      labor_driver_attribute_company_uuid: uuid,
      company_id: homeCompany,
      name: name,
      value: value,
    };

    // console.log("Company ID", requestData);

    try {
      toast.loading("Updating Attribute Labor Driver...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_labor_driver_company_attribute,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Attribute Labor Driver Updated!", { autoClose: 3000 });

        fetch_data(
          {
            laborDrivers: API_get_labor_drivers,
            laborDriverStoreAttributes: API_get_labor_driver_store_attributes,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_labor_driver_sales = async (
    uuid,
    name,
    filter_column,
    filter_values_str,
    target_column,
    aggregate_type
  ) => {
    // Prepare data to send
    const requestData = {
      labor_driver_sales_uuid: uuid,
      company_id: homeCompany,
      name: name,
      filter_column: filter_column,
      filter_values_str: filter_values_str,
      target_column: target_column,
      aggregate_type: aggregate_type,
    };

    // console.log("REQUEST DATA", requestData);

    try {
      toast.loading("Updating Sales Labor Driver...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_labor_driver_sales,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Sales Labor Driver Updated!", { autoClose: 3000 });

        fetch_data(
          {
            laborDrivers: API_get_labor_drivers,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const delete_labor_driver = async (labor_driver_uuid) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      labor_driver_uuid: labor_driver_uuid,
    };

    try {
      toast.loading("Deleting Labor Driver...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_labor_driver, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Labor Driver Deleted!", { autoClose: 3000 });

        fetch_data(
          {
            laborDrivers: API_get_labor_drivers,
            laborDriverStoreAttributes: API_get_labor_driver_store_attributes,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_store_proficiency = async (store_id, proficiency) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      store_id: store_id,
      proficiency: proficiency,
    };

    // console.log("Company ID", requestData);

    try {
      toast.loading("Updating Store Proficiency...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_store_proficiency,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Store Proficiency Updated!", { autoClose: 3000 });

        fetch_data(
          {
            storesInfo: API_get_stores_company_admin,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  //   * company_id
  // * name
  // * value
  // * store_id

  const post_labor_driver_store_attribute = async (
    labor_driver_attribute_company_uuid,
    value,
    store_id
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      labor_driver_attribute_company_uuid: labor_driver_attribute_company_uuid,
      value: value,
      store_id: store_id,
    };

    try {
      toast.loading("Updating Store Attribute Value...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_labor_driver_store_attribute,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Store Attribute Value Updated!", { autoClose: 3000 });

        fetch_data(
          {
            laborDriverStoreAttributes: API_get_labor_driver_store_attributes,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const delete_time_window = async (company_time_window_uuid) => {
    // Prepare data to send
    const requestData = {
      company_time_window_uuid: company_time_window_uuid,
      company_id: homeCompany,
    };

    try {
      toast.loading("Deleting Time Window...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_company_time_windows,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Time Window Deleted!", { autoClose: 3000 });

        fetch_data(
          {
            companyTimeWindows: API_get_company_time_windows,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_labor_program_flag_company = async (
    labor_program_flag_company_uuid,
    value,
    name
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      labor_program_flag_company_uuid: labor_program_flag_company_uuid,
      default: value,
      name: name,
    };

    try {
      toast.loading("Updating Flag...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_labor_program_flag_company,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Flag Updated!", { autoClose: 3000 });

        fetch_data(
          {
            laborProgramFlags: API_get_labor_program_flags,
            laborProgramFlagsStoreOverrides:
              API_get_labor_program_flags_with_store_overrides,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const delete_labor_program_flag_company = async (
    labor_program_flag_company_uuid
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      labor_program_flag_company_uuid: labor_program_flag_company_uuid,
    };

    try {
      toast.loading("Deleting Flag...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_labor_program_flag_company,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Flag Deleted!", { autoClose: 3000 });

        fetch_data(
          {
            laborProgramFlags: API_get_labor_program_flags,
            laborProgramFlagsStoreOverrides:
              API_get_labor_program_flags_with_store_overrides,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_labor_program_flag_store = async (
    override,
    store_id,
    labor_program_flag_company_uuid
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      override: override,
      store_id: store_id,
      labor_program_flag_company_uuid: labor_program_flag_company_uuid,
    };

    try {
      toast.loading("Updating Store Flag Value...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_labor_program_flag_store,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Store Flag Value Updated!", { autoClose: 3000 });

        fetch_data(
          {
            laborProgramFlagsStoreOverrides:
              API_get_labor_program_flags_with_store_overrides,
            laborProgramFlags: API_get_labor_program_flags,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/labor_model_info/publish_labor_standard
  // * company_id

  const publish_labor_standard = async () => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
    };

    try {
      toast.loading("Publishing Labor Standards...", { autoClose: false });

      const res = await postRightworkAPI(
        API_publish_labor_standard,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Labor Standards Published!", { autoClose: 3000 });

        fetch_data(
          {
            laborStandards: API_get_labor_standards,
            companyTimeWindows: API_get_company_time_windows,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/labor_model_info/revert_labor_standard
  // * company_id

  const revert_labor_standard = async () => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
    };

    try {
      toast.loading("Resetting Sandbox...", { autoClose: false });

      const res = await postRightworkAPI(
        API_revert_labor_standard,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Sandbox Reset!", { autoClose: 3000 });

        fetch_data(
          {
            laborStandards: API_get_labor_standards,
            companyTimeWindows: API_get_company_time_windows,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // console.log("DATA", data);

  return (
    <>
      {!isLoading ? (
        <div className="w-full min-h-screen flex">
          <div className="w-1/5">
            <div className="h-full flex flex-col gap-2 px-6 py-5 bg-slate-50 border-r border-slate-200">
              <button
                className={`mt-5 w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
                  display === "Labor Model" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Labor Model")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Labor Model"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <DisplaySettingsIcon />
                  </div>
                  <span>Labor Model</span>
                </div>
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Labor Drivers" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Labor Drivers")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Labor Drivers"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <BuildOutlinedIcon />
                  </div>
                  <span>Labor Drivers</span>
                </div>
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Location Details" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Location Details")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Location Details"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <StoreIcon />
                  </div>
                  <span>Location Details</span>
                </div>
              </button>
            </div>
          </div>
          <div className="w-4/5">
            <div>
              <TopBarDisplayTitleNoLocationSelect title={display} />
            </div>
            {display === "Labor Model" ? (
              <div>
                <LaborModelInnerDisplay
                  laborStandards={
                    data?.laborStandards?.redux?.payload?.labor_standards
                  }
                  laborStandardsSandbox={
                    data?.laborStandards?.redux?.payload
                      ?.labor_standard_testings
                  }
                  post_labor_standard={post_labor_standard}
                  delete_labor_standard={delete_labor_standard}
                  laborDrivers={
                    data?.laborDrivers?.redux?.payload?.labor_drivers
                  }
                  companyTimeWindows={
                    data?.companyTimeWindows?.redux?.payload
                      ?.company_time_windows
                  }
                  delete_time_window={delete_time_window}
                  roleTypes={data?.roleTypes}
                  roleNames={data.roleNames}
                  company_id={homeCompany}
                  storeNames={companyStores}
                  homeStore={props.homeStore}
                  businessWeekStartDayIndex={getBusinessWeekStartDayIndex(
                    props.storeNames,
                    props.homeStore
                  )}
                  laborProgramFlags={
                    data?.laborProgramFlags?.redux?.payload?.labor_standards
                  }
                  publish_labor_standard={publish_labor_standard}
                  revert_labor_standard={revert_labor_standard}
                />
              </div>
            ) : display === "Labor Drivers" ? (
              <div>
                <LaborDriversDisplay
                  laborDrivers={
                    data?.laborDrivers?.redux?.payload?.labor_drivers
                  }
                  post_labor_driver_company_attribute={
                    post_labor_driver_company_attribute
                  }
                  post_labor_driver_sales={post_labor_driver_sales}
                  delete_labor_driver={delete_labor_driver}
                  laborProgramFlags={
                    data?.laborProgramFlags?.redux?.payload?.labor_standards
                  }
                  post_labor_program_flag_company={
                    post_labor_program_flag_company
                  }
                  delete_labor_program_flag_company={
                    delete_labor_program_flag_company
                  }
                />
              </div>
            ) : display === "Location Details" ? (
              <div>
                <LocationDetailsDisplay
                  storesInfo={data.storesInfo?.redux?.payload?.stores}
                  post_store_proficiency={post_store_proficiency}
                  laborDriverStoreAttributes={
                    data?.laborDriverStoreAttributes?.redux?.payload
                      ?.labor_drivers
                  }
                  post_labor_driver_store_attribute={
                    post_labor_driver_store_attribute
                  }
                  laborProgramFlagsStoreOverrides={
                    data.laborProgramFlagsStoreOverrides?.redux?.payload
                      ?.labor_standards
                  }
                  post_labor_program_flag_store={post_labor_program_flag_store}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default LaborModelOuterDisplay;
