const BACKEND = process.env.REACT_APP_DB_PATH;

export const API_get_home_store = BACKEND + "/api/get_home_store?user=";
export const API_get_forecasted_sales_daily =
  BACKEND + "/api/forecasted_sales_daily?store_id=";
export const API_get_forecasted_transactions_daily =
  BACKEND + "/api/forecasted_transactions_daily?store_id=";
export const API_get_forecasted_other_daily =
  BACKEND + "/api/store_info/forecasted_other_daily?store_id=";

export const API_get_dashboard = BACKEND + "/api/store_info/get_dashboard";

export const API_get_adjustments_chosen =
  BACKEND + "/api/adjustments_chosen?store_id=";
export const API_get_forecasted_labor =
  BACKEND + "/api/forecasted_labor?store_id=";
export const API_get_forecasted_workload =
  BACKEND + "/api/forecasted_workload?store_id=";
export const API_get_forecasted_cost_matrix =
  BACKEND + "/api/forecasted_cost_matrix?store_id=";
export const API_get_forecasted_deployment_matrix =
  BACKEND + "/api/forecasted_deployment_matrix?store_id=";
export const API_get_recommended_schedule =
  BACKEND + "/api/recommended_schedule?store_id=";
export const API_get_recommended_schedule_labor_deployment =
  BACKEND + "/api/recommended_schedule_labor_deployment?store_id=";
export const API_get_actual_schedule =
  BACKEND + "/api/actual_schedule?store_id=";
export const API_get_published_shift_count =
  BACKEND + "/api/published_shift_count?store_id=";
export const API_get_actual_schedule_labor_deployment =
  BACKEND + "/api/actual_schedule_labor_deployment?store_id=";
export const API_get_actual_sales_daily =
  BACKEND + "/api/actual_sales_daily?store_id=";
export const API_get_actual_other_daily =
  BACKEND + "/api/store_info/actual_other_daily?store_id=";
export const API_get_recommended_used_labor_vs_earned =
  BACKEND + "/api/recommended_used_labor_vs_earned?store_id=";
export const API_get_calculated_earned_labor =
  BACKEND + "/api/calculated_earned_labor?store_id=";
export const API_get_actual_used_labor_vs_earned =
  BACKEND + "/api/actual_used_labor_vs_earned?store_id=";

export const API_load_schedule_from_template =
  BACKEND + "/api/load_schedule_from_template?store_id=";
export const API_save_schedule_as_template =
  BACKEND + "/api/save_schedule_as_template?store_id=";
export const API_load_prev_week_schedule =
  BACKEND + "/api/prev_week_schedule?store_id=";
export const API_load_employee_prev_week_schedule =
  BACKEND + "/api/store_info/load_employee_prev_week_schedule?store_id=";
export const API_get_store_team_members =
  BACKEND + "/api/store_team_members?store_id=";
export const API_get_template_names = BACKEND + "/api/template_names?store_id=";
export const API_delete_schedule_template =
  BACKEND + "/api/delete_schedule_template?store_id=";

export const API_edit_shift = BACKEND + "/api/edit_shift?store_id=";

export const API_get_default_availability =
  BACKEND + "/api/schedule_info/get_default_availability?store_id=";
export const API_get_default_target_hours =
  BACKEND + "/api/default_target_hours?store_id=";
export const API_get_weekly_target_hours =
  BACKEND + "/api/weekly_target_hours?store_id=";
export const API_get_request_off = BACKEND + "/api/request_off?store_id=";
export const API_get_available_roles =
  BACKEND + "/api/available_roles?store_id=";
export const API_get_role_names = BACKEND + "/api/role_names?store_id=";
export const API_get_role_names2 = BACKEND + "/api/role_names";
export const API_get_station_info = BACKEND + "/api/station_info?store_id=";

export const API_get_departments = BACKEND + "/api/get_role_types";
export const API_get_roles = BACKEND + "/api/role_names";
export const API_get_stations = BACKEND + "/api/stations";

export const API_edit_availability =
  BACKEND + "/api/schedule_info/edit_availability?store_id=";

export const API_update_team_member_info =
  BACKEND + "/api/update_team_member_info?store_id=";

export const API_auto_schedule = BACKEND + "/api/auto_schedule?store_id=";

export const API_accept_auto_schedule =
  BACKEND + "/api/accept_auto_schedule?store_id=";

export const API_delete_all_shifts =
  BACKEND + "/api/delete_all_shifts?store_id=";
export const API_publish_schedule = BACKEND + "/api/publish_schedule?store_id=";

export const API_add_forecast_adjustment =
  BACKEND + "/api/add_forecast_adjustment?store_id=";
export const API_edit_multiple_shifts =
  BACKEND + "/api/edit_multiple_shifts?store_id=";

export const API_get_member_stores = BACKEND + "/api/member_stores?username=";
export const API_change_home_store =
  BACKEND + "/api/change_home_store?username=";

export const API_get_daily_operating_hours =
  BACKEND + "/api/get_daily_operating_hours?store_id=";
export const API_edit_operating_hours =
  BACKEND + "/api/edit_operating_hours?store_id=";

export const API_get_store_variable_flags =
  BACKEND + "/api/get_store_variable_flags?store_id=";
export const API_get_store_intraday_timings =
  BACKEND + "/api/get_store_intraday_timings?store_id=";
export const API_get_store_driver_variables =
  BACKEND + "/api/get_store_driver_variables?store_id=";
export const API_get_default_operating_hours =
  BACKEND + "/api/get_default_operating_hours?store_id=";

export const API_get_weather = BACKEND + "/api/get_weather?store_id=";
export const API_get_events = BACKEND + "/api/get_events?store_id=";
export const API_save_event = BACKEND + "/api/save_event";
export const API_delete_event = BACKEND + "/api/delete_event?event_id=";
export const API_get_forecast_exclude_dates =
  BACKEND + "/api/store_info/get_forecast_exclude_dates?store_id=";
export const API_save_forecast_exclude_date =
  BACKEND + "/api/store_info/save_forecast_exclude_date";
export const API_delete_forecast_exclude_date =
  BACKEND + "/api/store_info/delete_forecast_exclude_date?store_id=";

export const API_get_batch_guidance =
  BACKEND + "/api/get_batch_guidance?store_id=";
export const API_get_intraday_metrics =
  BACKEND + "/api/get_intraday_metrics?store_id=";
export const API_get_local_events = BACKEND + "/api/get_local_events?store_id=";

export const API_get_role_types = BACKEND + "/api/get_role_types?store_id=";
export const API_get_tasks = BACKEND + "/api/get_tasks?store_id=";
export const API_get_plays = BACKEND + "/api/get_plays?store_id=";
export const API_get_intraday_timings =
  BACKEND + "/api/get_intraday_timings?store_id=";
export const API_get_routine_role_assignments =
  BACKEND + "/api/get_routine_role_assignments?store_id=";
export const API_get_routines = BACKEND + "/api/get_routines?store_id=";
export const API_get_driver_variables =
  BACKEND + "/api/get_driver_variables?store_id=";
export const API_get_sales_group_calcs =
  BACKEND + "/api/get_sales_group_calcs?store_id=";
export const API_get_deployment_roles =
  BACKEND + "/api/get_deployment_roles?store_id=";

export const API_put_store_variable_flag =
  BACKEND + "/api/edit_store_variable_flag?store_id=";
export const API_put_store_intraday_timing =
  BACKEND + "/api/edit_store_intraday_timing?store_id=";
export const API_put_store_driver_variable =
  BACKEND + "/api/edit_store_driver_variable?store_id=";
export const API_put_default_operating_hour =
  BACKEND + "/api/edit_default_operating_hour?store_id=";

export const API_put_role_type = BACKEND + "/api/edit_role_type?store_id=";
export const API_put_role = BACKEND + "/api/edit_role?store_id=";
export const API_put_station = BACKEND + "/api/edit_station?store_id=";

export const API_put_task = BACKEND + "/api/edit_task?store_id=";
export const API_put_play = BACKEND + "/api/edit_play?store_id=";
export const API_put_intraday_timing =
  BACKEND + "/api/edit_intraday_timing?store_id=";
export const API_put_routine_role_assignment =
  BACKEND + "/api/edit_routine_role_assignment?store_id=";
export const API_put_routine = BACKEND + "/api/edit_routine?store_id=";
export const API_put_driver_variable =
  BACKEND + "/api/edit_driver_variable?store_id=";
export const API_put_sales_group_calc =
  BACKEND + "/api/edit_sales_group_calc?store_id=";
export const API_put_deployment_role =
  BACKEND + "/api/edit_deployment_role?store_id=";
export const API_get_grade_weights =
  BACKEND + "/api/get_grade_weights?store_id=";

export const API_get_contests = BACKEND + "/api/get_contests?store_id=";
export const API_get_metrics = BACKEND + "/api/get_metrics?store_id=";
export const API_get_contest_info = BACKEND + "/api/get_contest_info?store_id=";
export const API_save_contest = BACKEND + "/api/save_contest?store_id=";
export const API_delete_contest = BACKEND + "/api/delete_contest?store_id=";

export const API_no_shows = BACKEND + "/api/no-shows?";
export const API_dayparts = BACKEND + "/api/dayparts?";
export const API_role_assignments = BACKEND + "/api/role-assignments?";
export const API_save_no_show = BACKEND + "/api/save_no_show";
export const API_update_role_assignment =
  BACKEND + "/api/update_role_assignment";
export const API_delete_role_assignment =
  BACKEND + "/api/delete_role_assignment";
export const API_save_dayparts = BACKEND + "/api/save_dayparts";
export const API_delete_daypart = BACKEND + "/api/delete_daypart";
export const API_auto_assign_roles = BACKEND + "/api/auto_assign_roles";
export const API_get_spent_labor_daily_summaries =
  BACKEND + "/api/store_info/get_spent_labor_daily_summaries?store_id=";
export const API_get_recent_avg_wages =
  BACKEND + "/api/store_info/get_recent_avg_wages?store_id=";

/// Configuration APIs
export const API_get_store_proficiency =
  BACKEND + "/api/get_store_proficiency?store_id=";
export const API_save_store_proficiency =
  BACKEND + "/api/save_store_proficiency?store_id=";
export const API_get_autoschedule_settings =
  BACKEND + "/api/get_autoschedule_settings?store_id=";
export const API_save_autoschedule_settings =
  BACKEND + "/api/save_autoschedule_settings?store_id=";

/// LOGIN APIs
export const API_REGISTRATION = "none";
export const API_LOGIN = BACKEND + "/api/dj-rest-auth/login/";
export const API_PWD_RESET = BACKEND + "/api/dj-rest-auth/password/reset/";
export const API_PWD_RESET_CONFIRM =
  BACKEND + "/api/dj-rest-auth/password/reset/confirm/";
export const API_PWD_CHANGE = BACKEND + "/api/dj-rest-auth/password/change/";

export const AMP_KEY = process.env.REACT_APP_AMP_KEY;

/// Mobile App Action APIs
export const API_list_availability_for_employee =
  BACKEND + "/api/schedule_info/list_availability_for_employee";

export const API_list_availability_requests_for_manager =
  BACKEND + "/api/schedule_info/list_availability_requests_for_manager";

export const API_list_availability_request_responses_for_manager =
  BACKEND +
  "/api/schedule_info/list_availability_request_responses_for_manager";

export const API_list_preferred_availability_for_employee =
  BACKEND + "/api/schedule_info/list_preferred_availability_for_employee";

export const API_list_preferred_availability_requests_for_manager =
  BACKEND +
  "/api/schedule_info/list_preferred_availability_requests_for_manager";

export const API_list_preferred_availability_request_responses_for_manager =
  BACKEND +
  "/api/schedule_info/list_preferred_availability_request_responses_for_manager";

export const API_get_schedule = BACKEND + "/api/store_info/get_schedule";

export const API_get_stats = BACKEND + "/api/store_info/get_daily_stats";

export const API_get_list_time_off_requests_for_manager =
  BACKEND + "/api/user_info/list_time_off_requests_for_manager";

export const API_get_list_time_off_requests_for_employee =
  BACKEND + "/api/user_info/list_time_off_requests_for_employee";

export const API_get_shift_pool_for_employee =
  BACKEND + "/api/store_info/shift_pool_for_employee";

export const API_get_shift_pool_for_manager =
  BACKEND + "/api/store_info/shift_pool_for_manager";

export const API_get_manager_shift_pickup_request_responses =
  BACKEND + "/api/store_info/manager_shift_pickup_request_responses";

export const API_get_employee_shift_pickup_request_responses =
  BACKEND + "/api/store_info/employee_shift_pickup_request_responses";

export const API_post_missed_shift =
  BACKEND + "/api/store_info/post_missed_shift";

// Employees Information

export const API_get_list_users = BACKEND + "/api/user_info/list_users";

export const API_get_store_team_member =
  BACKEND + "/api/user_info/get_store_team_member";

export const API_archive_store_team_member =
  BACKEND + "/api/user_info/archive_store_team_member";

export const API_get_stores_company_manager =
  BACKEND + "/api/store_info/get_stores_company_manager";

export const API_clone_or_reactivate_store_team_member =
  BACKEND + "/api/user_info/clone_or_reactivate_store_team_member";

export const API_post_store_team_member_only_permission_type_and_store_id =
  BACKEND +
  "/api/user_info/post_store_team_member_only_permission_type_and_store_id";

// LMS APIs

export const API_get_learning_tracks =
  BACKEND + "/api/lms_info/get_learning_tracks";

export const API_post_learning_track =
  BACKEND + "/api/lms_info/post_learning_track";

export const API_delete_learning_track =
  BACKEND + "/api/lms_info/delete_learning_track";

export const API_get_learning_modules =
  BACKEND + "/api/lms_info/get_learning_modules";

export const API_delete_learning_module =
  BACKEND + "/api/lms_info/delete_learning_module";

export const API_post_learning_module =
  BACKEND + "/api/lms_info/post_learning_module";

export const API_get_learning_section_summaries =
  BACKEND + "/api/lms_info/get_learning_section_summaries";

export const API_get_learning_section_detail =
  BACKEND + "/api/lms_info/get_learning_section_detail";

export const API_post_learning_section =
  BACKEND + "/api/lms_info/post_learning_section";

export const API_delete_learning_section =
  BACKEND + "/api/lms_info/delete_learning_section";

export const API_get_learning_quiz_summaries =
  BACKEND + "/api/lms_info/get_learning_quiz_summaries";

export const API_get_learning_quiz_detail =
  BACKEND + "/api/lms_info/get_learning_quiz_detail";

export const API_post_learning_quiz =
  BACKEND + "/api/lms_info/post_learning_quiz";

export const API_delete_learning_quiz =
  BACKEND + "/api/lms_info/delete_learning_quiz";

export const API_post_learning_file =
  BACKEND + "/api/lms_info/post_learning_file";

export const API_post_learning_file_with_eagerly_generated_presigned_url =
  BACKEND +
  "/api/lms_info/post_learning_file_with_eagerly_generated_presigned_url";

export const API_get_learning_file =
  BACKEND + "/api/lms_info/get_learning_file";

export const API_get_stores_with_learning_track_enrollment =
  BACKEND + "/api/lms_info/get_stores_with_learning_track_enrollment";

export const API_get_learning_track_enrollments_by_employee_for_store =
  BACKEND +
  "/api/lms_info/get_learning_track_enrollments_by_employee_for_store";

export const API_post_learning_track_enrollment =
  BACKEND + "/api/lms_info/post_learning_track_enrollment";

export const API_post_learning_track_enrollment_by_roles =
  BACKEND + "/api/lms_info/post_learning_track_enrollment_by_roles";

export const API_get_learning_modules_by_role =
  BACKEND + "/api/lms_info/get_learning_modules_by_role";

export const API_post_learning_module_star_threshold =
  BACKEND + "/api/lms_info/post_learning_module_star_threshold";

// File upload

export const API_post_file_upload_with_eagerly_generated_presigned_url =
  BACKEND +
  "/api/file_upload_info/post_file_upload_with_eagerly_generated_presigned_url";

export const API_get_file_upload =
  BACKEND + "/api/file_upload_info/get_file_upload";

export const API_delete_file_upload =
  BACKEND + "/api/file_upload_info/delete_file_upload/";

// LMS APIs employee side

export const API_get_learning_track_enrollment_summaries_for_employee =
  BACKEND +
  "/api/lms_info/get_learning_track_enrollment_summaries_for_employee";

export const API_get_learning_track_enrollment_detail_for_employee =
  BACKEND + "/api/lms_info/get_learning_track_enrollment_detail_for_employee";

export const API_post_learning_section_completed =
  BACKEND + "/api/lms_info/post_learning_section_completed";

export const API_post_learning_block_user_passed =
  BACKEND + "/api/lms_info/post_learning_block_user_passed";

// Chat APIs

export const API_get_stream_user_token =
  BACKEND + "/api/chat_info/get_stream_user_token";

export const API_get_chat_users = BACKEND + "/api/chat_info/get_chat_users";

// Resend Welcome Email

export const API_resend_welcome_email =
  BACKEND + "/api/user_info/resend_welcome_email";

export const API_drawer_notifications =
  BACKEND + "/api/notification_info/drawer_notifications";

export const API_get_notification_settings =
  BACKEND + "/api/notification_info/get_settings";

export const API_get_all_time_off_requests =
  BACKEND + "/api/user_info/get_all_time_off_requests?store_id=";

export const API_list_frequent_schedules =
  BACKEND + "/api/user_info/list_frequent_schedules?store_id=";

export const API_get_manager_stores_rightwork_data =
  BACKEND + "/api/store_info/get_manager_stores_rightwork_data";

export const API_update_manager_store_rightwork_data =
  BACKEND + "/api/store_info/update_manager_store_rightwork_data";

export const API_get_manager_stores_integration_data =
  BACKEND + "/api/store_info/get_manager_stores_integration_data";

export const API_post_manager_store_integration_data =
  BACKEND + "/api/store_info/post_manager_store_integration_data";

// Labor model

export const API_get_labor_drivers =
  BACKEND + "/api/labor_model_info/get_labor_drivers";

export const API_post_labor_driver_company_attribute =
  BACKEND + "/api/labor_model_info/post_labor_driver_company_attribute";

export const API_post_labor_driver_sales =
  BACKEND + "/api/labor_model_info/post_labor_driver_sales";

export const API_delete_labor_driver =
  BACKEND + "/api/labor_model_info/delete_labor_driver";

export const API_get_labor_standards =
  BACKEND + "/api/labor_model_info/get_labor_standards";

export const API_post_labor_standard =
  BACKEND + "/api/labor_model_info/post_labor_standard";

export const API_delete_labor_standard =
  BACKEND + "/api/labor_model_info/delete_labor_standard";

export const API_get_company_time_windows =
  BACKEND + "/api/labor_model_info/get_company_time_windows";

export const API_delete_company_time_windows =
  BACKEND + "/api/labor_model_info/delete_company_time_windows";

export const API_get_labor_test_results =
  BACKEND + "/api/labor_model_info/get_test_results";

export const API_get_labor_program_flags =
  BACKEND + "/api/labor_model_info/get_labor_program_flags";

export const API_post_labor_program_flag_company =
  BACKEND + "/api/labor_model_info/post_labor_program_flag_company";

export const API_delete_labor_program_flag_company =
  BACKEND + "/api/labor_model_info/delete_labor_program_flag_company";

export const API_get_labor_program_flags_with_store_overrides =
  BACKEND +
  "/api/labor_model_info/get_labor_program_flags_with_store_overrides";

export const API_post_labor_program_flag_store =
  BACKEND + "/api/labor_model_info/post_labor_program_flag_store";

export const API_publish_labor_standard =
  BACKEND + "/api/labor_model_info/publish_labor_standard";

export const API_revert_labor_standard =
  BACKEND + "/api/labor_model_info/revert_labor_standard";

// Get store proficiencies
export const API_get_stores_company_admin =
  BACKEND + "/api/store_info/get_stores_company_admin";

export const API_post_store_proficiency =
  BACKEND + "/api/store_info/post_store_proficiency";

export const API_get_labor_driver_store_attributes =
  BACKEND + "/api/labor_model_info/get_labor_driver_store_attributes";

export const API_post_labor_driver_store_attribute =
  BACKEND + "/api/labor_model_info/post_labor_driver_store_attribute";

// Clock Info
export const API_get_employee_clock_data =
  BACKEND + "/api/clock_info/get_employee_clock_data";

export const API_post_timecard_clock_in =
  BACKEND + "/api/clock_info/timecard_clock_in";

export const API_post_timecard_clock_out =
  BACKEND + "/api/clock_info/timecard_clock_out";

export const API_post_timecard_break_clock_in =
  BACKEND + "/api/clock_info/timecard_break_clock_in";

export const API_post_timecard_break_clock_out =
  BACKEND + "/api/clock_info/timecard_break_clock_out";

export const API_get_timecards_for_store =
  BACKEND + "/api/clock_info/get_timecards_for_store";

export const API_get_timecards_for_employee =
  BACKEND + "/api/clock_info/get_timecards_for_employee";

export const API_post_timecard = BACKEND + "/api/clock_info/post_timecard";

export const API_get_pay_periods = BACKEND + "/api/clock_info/get_pay_periods";

export const API_get_pay_period_employees = BACKEND + "/api/clock_info/get_pay_period_employees";

export const API_delete_timecard = BACKEND + "/api/clock_info/delete_timecard";

export const API_post_timecards_status =
  BACKEND + "/api/clock_info/post_timecards_status";

export const API_get_timecard_config =
  BACKEND + "/api/clock_info/get_timecard_config";

export const API_post_timecard_config =
  BACKEND + "/api/clock_info/post_timecard_config";

export const API_post_pay_period_approval =
  BACKEND + "/api/clock_info/post_pay_period_approval";

// Certificates
export const API_get_certificates =
  BACKEND + "/api/certificate_info/get_certificates";

export const API_post_certificate =
  BACKEND + "/api/certificate_info/post_certificate";

export const API_delete_certificate =
  BACKEND + "/api/certificate_info/delete_certificate";

// Forecast historical context
export const API_get_historical_sales_context =
  BACKEND + "/api/store_info/get_historical_sales_context?store_id=";

// Employees
export const API_post_store_team_member =
  BACKEND + "/api/user_info/post_store_team_member";

// Mobile App Post Requests
export const API_drop_shift = BACKEND + "/api/store_info/drop_shift";

export const API_rescind_drop_shift =
  BACKEND + "/api/store_info/rescind_drop_shift";

export const API_pickup_shift = BACKEND + "/api/store_info/pickup_shift";

export const API_rescind_pickup_shift =
  BACKEND + "/api/store_info/rescind_pickup_shift";

export const API_hide_shift_change_request =
  BACKEND + "/api/store_info/hide_shift_change_request";

export const API_manager_reject_shift_drop =
  BACKEND + "/api/store_info/manager_reject_shift_drop";

export const API_manager_shift_pickup_request_response =
  BACKEND + "/api/store_info/manager_shift_pickup_request_response";

export const API_manager_approve_shift_pickup =
  BACKEND + "/api/store_info/manager_approve_shift_pickup";

export const API_create_availability_request =
  BACKEND + "/api/schedule_info/create_availability_request";

export const API_rescind_availability_request =
  BACKEND + "/api/schedule_info/rescind_availability_request";

export const API_delete_availability_request =
  BACKEND + "/api/schedule_info/delete_availability_request";

export const API_hide_availability_request =
  BACKEND + "/api/schedule_info/hide_availability_request";

export const API_create_preferred_availability_request =
  BACKEND + "/api/schedule_info/create_preferred_availability_request";

export const API_rescind_preferred_availability_request =
  BACKEND + "/api/schedule_info/rescind_preferred_availability_request";

export const API_delete_preferred_availability_request =
  BACKEND + "/api/schedule_info/delete_preferred_availability_request";

export const API_hide_preferred_availability_request =
  BACKEND + "/api/schedule_info/hide_preferred_availability_request";

export const API_request_time_off = BACKEND + "/api/user_info/request_time_off";

export const API_rescind_time_off_request =
  BACKEND + "/api/user_info/rescind_time_off_request";

export const API_delete_time_off_request =
  BACKEND + "/api/user_info/delete_time_off_request";

export const API_delete_temporary_availability =
  BACKEND + "/api/schedule_info/delete_temporary_availability";

export const API_hide_time_off_request =
  BACKEND + "/api/user_info/hide_time_off_request";

export const API_manager_respond_to_availability_request =
  BACKEND + "/api/schedule_info/manager_respond_to_availability_request";

export const API_manager_respond_to_preferred_availability_request =
  BACKEND +
  "/api/schedule_info/manager_respond_to_preferred_availability_request";

export const API_respond_to_time_off_request =
  BACKEND + "/api/user_info/respond_to_time_off_request";

export const API_push_notification_token =
  BACKEND + "/api/notification_info/register_expo_push_notification_token";

export const API_hide_drawer_notification =
  BACKEND + "/api/notification_info/hide_drawer_notification";

export const API_hide_all_drawer_notifications =
  BACKEND + "/api/notification_info/hide_all_drawer_notifications";

export const API_calculate_metric =
  BACKEND + "/api/store_info/calculate_metric";

export const API_update_notification_settings =
  BACKEND + "/api/notification_info/update_settings";

export const API_store_team_member_set_hide =
  BACKEND + "/api/user_info/store_team_member_set_hide";

export const API_store_team_member_set_order =
  BACKEND + "/api/user_info/store_team_member_set_order";

export const API_get_hours_for_all_stores =
  BACKEND + "/api/store_info/get_hours_for_all_stores";

export const API_update_hours_for_all_stores =
  BACKEND + "/api/store_info/update_hours_for_all_stores";

// Logbook

export const API_get_logbook_columns =
  BACKEND + "/api/logbook_info/get_logbook_columns";

export const API_post_logbook_category =
  BACKEND + "/api/logbook_info/post_logbook_category";

export const API_delete_logbook_category =
  BACKEND + "/api/logbook_info/delete_logbook_category";

export const API_post_reorder_logbook_columns_and_categories =
  BACKEND + "/api/logbook_info/post_reorder_logbook_columns_and_categories";

export const API_get_logbook_category_entries =
  BACKEND + "/api/logbook_info/get_logbook_category_entries";

export const API_post_logbook_category_entry =
  BACKEND + "/api/logbook_info/post_logbook_category_entry";

export const API_delete_logbook_category_entry =
  BACKEND + "/api/logbook_info/delete_logbook_category_entry";

// Employee Notes

export const API_get_logbook_employee_entries =
  BACKEND + "/api/logbook_info/get_logbook_employee_entries";

export const API_post_logbook_employee_entry =
  BACKEND + "/api/logbook_info/post_logbook_employee_entry";

export const API_delete_logbook_employee_entry =
  BACKEND + "/api/logbook_info/delete_logbook_employee_entry";

// Daily plan

export const API_get_plan_tasks = BACKEND + "/api/plan_info/get_plan_tasks";

export const API_post_plan_task = BACKEND + "/api/plan_info/post_plan_task";

export const API_delete_plan_task = BACKEND + "/api/plan_info/delete_plan_task";

export const API_get_plan_tasks_for_date =
  BACKEND + "/api/plan_info/get_plan_tasks_for_date";

export const API_assign_plan_task = BACKEND + "/api/plan_info/assign_plan_task";

export const API_unassign_plan_task =
  BACKEND + "/api/plan_info/unassign_plan_task";

export const API_get_plan_tasks_for_user_and_date =
  BACKEND + "/api/plan_info/get_plan_tasks_for_user_and_date";

export const API_save_plan_task_assignment =
  BACKEND + "/api/plan_info/save_plan_task_assignment";

// Copilot

export const API_get_copilot_schedule =
  BACKEND + "/api/store_info/get_copilot_schedule?store_id=";

export const API_get_copilot_status =
  BACKEND + "/api/store_info/get_copilot_status?store_id=";

// Documents

export const API_get_documents =
  BACKEND + "/api/file_upload_info/get_documents"

export const API_create_folder =
  BACKEND + "/api/file_upload_info/create_folder"

export const API_rename_folder =
  BACKEND + "/api/file_upload_info/rename_folder"

export const API_move_folder =
  BACKEND + "/api/file_upload_info/move_folder"

export const API_delete_folder =
  BACKEND + "/api/file_upload_info/delete_folder"

export const API_rename_file =
  BACKEND + "/api/file_upload_info/rename_file"

export const API_move_file =
  BACKEND + "/api/file_upload_info/move_file"
