import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import QuizInputsModal from "./QuizInputsModal";
import CompleteQuizModal from "./CompleteQuizModal";
import { formatDate } from "../requests/utilities/helpers";
import { formatDateReadable } from "../requests/utilities/helpers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getRightworkAPI } from "../requests/utilities/requests";
import { API_get_learning_quiz_detail } from "../../../constants";

function QuizzesDisplay(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalEdit, setModalEdit] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [openQuizPreviewModal, setOpenQuizPreviewModal] = React.useState(false);
  const handleOpenQuizPreviewModal = () => setOpenQuizPreviewModal(true);
  const handleCloseQuizPreviewModal = () => setOpenQuizPreviewModal(false);

  const fetchQuizDetail = async (showLoading, learning_quiz_uuid) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_quiz_uuid: learning_quiz_uuid,
    };
    try {
      const res = await getRightworkAPI(
        API_get_learning_quiz_detail,
        api_params
      );
      if (res.status === 200) {
        props.setModalDataQuiz(res.data.redux?.payload?.learning_quiz);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Promise failed", error);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.25,
      sortable: true,
      filterable: true,
      hideable: false,
    },
    {
      field: "created_at",
      headerName: "Date Created",
      flex: 0.15,
      sortable: true,
      filterable: true,
    },
    {
      field: "updated_at",
      headerName: "Date Updated",
      flex: 0.15,
      sortable: true,
      filterable: true,
    },
    {
      field: "learning_questions_count",
      headerName: "# of Questions",
      flex: 0.15,
      sortable: true,
      filterable: true,
    },
    {
      field: "show_correct_answers",
      headerName: "Show Answers",
      flex: 0.15,
      sortable: true,
      filterable: true,
    },
    {
      field: "pass_percentage",
      headerName: "Passing %",
      flex: 0.15,
      sortable: true,
      filterable: true,
    },
    {
      field: "retake_limit",
      headerName: "# of Attempts",
      flex: 0.15,
      sortable: true,
      filterable: true,
      sortComparator: (v1, v2) => {
        const normalize = (value) =>
          value === "Unlimited" ? Infinity : Number(value);
        return normalize(v1) - normalize(v2);
      },
    },
    {
      field: "Actions",
      headerName: "",
      width: 110,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <div className="flex items-center space-x-5 ml-3">
          <Tooltip title="Edit quiz">
            <div
              className="text-slate-500 hover:text-slate-900 cursor-pointer"
              onClick={() => {
                setModalEdit(true);
                fetchQuizDetail(true, params.row.uuid);
                handleOpen();
              }}
            >
              <EditIcon style={{ fontSize: "22px" }} />
            </div>
          </Tooltip>
          <Tooltip title="Preview quiz">
            <div
              className="text-slate-500 hover:text-slate-900 cursor-pointer"
              onClick={() => {
                fetchQuizDetail(true, params.row.uuid);
                handleOpenQuizPreviewModal();
              }}
            >
              <SlideshowIcon style={{ fontSize: "22px" }} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows = props.quizzesData?.map((quiz, index) => ({
    id: index,
    name: quiz.name,
    created_at: formatDate(quiz.created_at),
    updated_at: formatDate(quiz.updated_at),
    learning_questions_count: quiz.learning_questions_count,
    show_correct_answers: quiz.show_correct_answers ? "True" : "False",
    pass_percentage: quiz.pass_percentage
      ? `${quiz.pass_percentage}%`
      : "N / A",
    retake_limit: quiz.retake_limit ? quiz.retake_limit : "Unlimited",
    uuid: quiz.uuid,
  }));

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6d28d9",
      },
    },
  });

  // console.log("QUIZ DATA DATA", props.quizzesData)

  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <div className="w-full px-6 mt-10 flex items-center justify-between">
            <h2 className="text-4xl text-slate-900">Quizzes</h2>
            <Tooltip title="Create new quiz">
              <button
                className="bg-violet-700 hover:bg-violet-800 text-white h-12 w-12 rounded-full"
                onClick={() => {
                  setModalEdit(false);
                  props.setModalDataQuiz(props.default_data_quiz);
                  handleOpen();
                }}
              >
                <AddIcon style={{ fontSize: "26px" }} />
              </button>
            </Tooltip>
          </div>
          <div className="mt-8" style={{ width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              autoHeight
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    created_at: false,
                    updated_at: false,
                  },
                },
              }}
              getRowHeight={() => "auto"}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </div>
        </div>
      </ThemeProvider>
      <QuizInputsModal
        open={open}
        handleClose={handleClose}
        modalEdit={modalEdit}
        isLoading={isLoading}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        post_learning_quiz={props.post_learning_quiz}
        delete_learning_quiz={props.delete_learning_quiz}
        homeCompany={props.homeCompany}
      />
      <CompleteQuizModal
        open={openQuizPreviewModal}
        handleClose={handleCloseQuizPreviewModal}
        isLoading={isLoading}
        modalDataQuiz={props.modalDataQuiz}
        type="Preview"
      />
    </>
  );
}

export default QuizzesDisplay;
