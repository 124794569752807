import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateReadableWithDayOfWeek } from "../../requests/utilities/helpers";

function TimeOffCardDeleteModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  // console.log("UUID", props.timeOffInfo)

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <h3 className="text-2xl text-slate-900">Approved Time Off</h3>
            </div>
            <div>
              <div className="mt-4">
                <p className="text-slate-500 text-sm">Name</p>
                <p className="text-slate-900 text-base mt-0.5">
                  {props.p?.first_name} {props.p?.last_name}
                </p>
              </div>
              <div className="mt-4">
                <p className="text-slate-500 text-sm">Date</p>
                <p className="text-slate-900 text-base mt-0.5">
                  {formatDateReadableWithDayOfWeek(
                    props.timeOffInfo?.date_lower_bound
                  )}{" "}
                  {props.timeOffInfo?.date_upper_bound &&
                    `- ${formatDateReadableWithDayOfWeek(
                      props.timeOffInfo?.date_upper_bound
                    )}`}
                </p>
              </div>
              {props.timeOffInfo?.title && (
                <div className="mt-4">
                  <p className="text-slate-500 text-sm">Title</p>
                  <p className="text-slate-900 text-base mt-0.5">
                    {props.timeOffInfo?.title}
                  </p>
                </div>
              )}
              {props.timeOffInfo?.note && (
                <div className="mt-4">
                  <p className="text-slate-500 text-sm">Note</p>
                  <p className="text-slate-900 text-base mt-0.5">
                    {props.timeOffInfo?.note}
                  </p>
                </div>
              )}
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div
                  className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div
                  className="px-8 py-2 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.delete_time_off_request(props.timeOffInfo?.uuid);
                    props.handleClose();
                  }}
                >
                  Delete Time Off
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TimeOffCardDeleteModal;
