import React, { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderItem from './FolderItem';
import FileItem from './FileItem';
import CreateNewButton from './CreateNewButton';
import { getRightworkAPI } from '../requests/utilities/requests';
import { postRightworkAPI } from '../requests/utilities/requests';
import {
  API_get_documents,
  API_create_folder,
  API_rename_folder,
  API_move_folder,
  API_delete_folder,
  API_rename_file,
  API_move_file,
  API_post_file_upload_with_eagerly_generated_presigned_url,
  API_get_file_upload,
  API_delete_file_upload
} from '../../../constants';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getDefaultAxiosConfig } from '../requests/utilities/requests';
import LoadSpinner from '../../../utilities/LoadSpinner';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

function DocumentsDisplay(props) {
  const [currentFolder, setCurrentFolder] = useState(null);
  const [documents, setDocuments] = useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isFileDragging, setIsFileDragging] = useState(false);
  const [isFolderDragging, setIsFolderDragging] = useState(false);

  const fetchDocuments = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      store_id: props.homeStore,
    };

    try {
      const res = await getRightworkAPI(API_get_documents, api_params);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.documents;
        setDocuments(data);

        // Only update the current folder if it is null
        if (!currentFolder) {
          const homeFolder = Object.values(data).find(
            (item) => item.type === "folder" && item.parentUuid === null
          );

          if (homeFolder) {
            setCurrentFolder(homeFolder.uuid);
          }
        }

        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch documents");
      setIsLoading(false);
    }
  };



  React.useEffect(() => {
    fetchDocuments(true);
  }, [props.homeCompany]);

  console.log("DOCUMENTS", documents)
  console.log("CURRENT FOLDER", currentFolder)

  // ----------- POST APIs ----------- //


  const create_folder = async (name, parent_folder_uuid) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      name: name,
      parent_folder_uuid: parent_folder_uuid
    };

    try {
      toast.loading("Creating Folder...", { autoClose: false });

      const res = await postRightworkAPI(API_create_folder, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Folder Created Successfully", { autoClose: 3000 });

        fetchDocuments(false)
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };


  const rename_folder = async (folder_uuid, new_folder_name) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      folder_uuid: folder_uuid,
      new_folder_name: new_folder_name
    };

    try {
      toast.loading("Renaming Folder...", { autoClose: false });

      const res = await postRightworkAPI(API_rename_folder, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Folder Renamed Successfully", { autoClose: 3000 });

        fetchDocuments(false)
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  const move_folder = async (moving_folder_uuid, new_parent_folder_uuid) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      moving_folder_uuid: moving_folder_uuid,
      new_parent_folder_uuid
    };

    // Store the original parent UUID to revert if needed
    const originalParentUuid = documents[moving_folder_uuid].parentUuid;

    try {
      // Optimistic state update
      setDocuments(prevDocuments => {
        const updatedDocuments = { ...prevDocuments };
        if (updatedDocuments[moving_folder_uuid]) {
          updatedDocuments[moving_folder_uuid] = {
            ...updatedDocuments[moving_folder_uuid],
            parentUuid: new_parent_folder_uuid
          };
        }
        return updatedDocuments;
      });

      toast.loading("Moving Folder...", { autoClose: false });

      const res = await postRightworkAPI(API_move_folder, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Folder Moved Successfully", { autoClose: 3000 });
      }
    } catch (error) {
      // Revert state update if API call fails
      setDocuments(prevDocuments => {
        const revertedDocuments = { ...prevDocuments };
        if (revertedDocuments[moving_folder_uuid]) {
          revertedDocuments[moving_folder_uuid] = {
            ...revertedDocuments[moving_folder_uuid],
            parentUuid: originalParentUuid
          };
        }
        return revertedDocuments;
      });

      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  const delete_folder = async (folder_uuid) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      folder_uuid: folder_uuid,
    };

    try {
      toast.loading("Deleting Folder...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_folder, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Folder Deleted Successfully", { autoClose: 3000 });

        fetchDocuments(false)
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  async function upload_file(file, file_upload_folder_uuid) {
    const formData = new FormData();
    formData.append("company_id", props.homeCompany);
    formData.append("file_name", file.name);
    formData.append("type", "file");  // NOTE: Always "file" here for the filesystem
    formData.append("file_upload_folder_uuid", file_upload_folder_uuid)

    // [1] Get presigned URL
    const axiosConfig = await getDefaultAxiosConfig();
    const res = await axios.post(
      API_post_file_upload_with_eagerly_generated_presigned_url,
      formData,
      {
        headers: {
          ...axiosConfig.headers,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const fileUUID = res?.data?.redux?.payload?.uuid;
    const presignedUrl = res?.data?.redux?.payload?.presigned_url;

    // [2] Upload file
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    // [3] Get file URL
    let api_params = {
      file_upload_uuid: fileUUID,
    };
    const resFile = await getRightworkAPI(API_get_file_upload, api_params);
    const resFilePayload = resFile?.data?.redux?.payload;

    fetchDocuments(false)

    // [4] Weeee
    return resFilePayload;
  }


  const rename_file = async (file_uuid, new_file_name) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      file_uuid: file_uuid,
      new_file_name: new_file_name
    };

    try {
      toast.loading("Renaming File...", { autoClose: false });

      const res = await postRightworkAPI(API_rename_file, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("File Renamed Successfully", { autoClose: 3000 });

        fetchDocuments(false)
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  const move_file = async (moving_file_uuid, new_parent_folder_uuid) => {
    const requestData = {
      company_id: props.homeCompany,
      moving_file_uuid: moving_file_uuid,
      new_parent_folder_uuid: new_parent_folder_uuid
    };

    // Store the original parent UUID to revert if needed
    const originalParentUuid = documents[moving_file_uuid].parentUuid;

    try {
      // Optimistic state update
      setDocuments(prevDocuments => {
        const updatedDocuments = { ...prevDocuments };
        if (updatedDocuments[moving_file_uuid]) {
          updatedDocuments[moving_file_uuid] = {
            ...updatedDocuments[moving_file_uuid],
            parentUuid: new_parent_folder_uuid
          };
        }
        return updatedDocuments;
      });

      toast.loading("Moving File...", { autoClose: false });

      const res = await postRightworkAPI(API_move_file, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("File Moved Successfully", { autoClose: 3000 });

        fetchDocuments(false);
      }
    } catch (error) {
      // Revert state update if API call fails
      setDocuments(prevDocuments => {
        const revertedDocuments = { ...prevDocuments };
        if (revertedDocuments[moving_file_uuid]) {
          revertedDocuments[moving_file_uuid] = {
            ...revertedDocuments[moving_file_uuid],
            parentUuid: originalParentUuid
          };
        }
        return revertedDocuments;
      });

      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  const delete_file = async (file_upload_uuid) => {
    // Prepare data to send
    const requestData = {
      file_upload_uuid: file_upload_uuid
    };

    try {
      toast.loading("Deleting File...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_file_upload, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("File Deleted Successfully", { autoClose: 3000 });

        fetchDocuments(false)
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  const searchDocuments = (query) => {
    if (!query.trim()) {
      setIsSearching(false);
      return [];
    }

    const searchTerms = query.toLowerCase().split(' ');
    const allItems = Object.values(documents);

    return allItems.filter(item => {
      const itemName = item.name.toLowerCase();
      return searchTerms.every(term => itemName.includes(term));
    });
  };

  const getCurrentItems = () => {
    const items = Object.values(documents);
    return {
      folders: items.filter(item =>
        item?.type === 'folder' &&
        item.parentUuid === currentFolder
      ),
      files: items.filter(item =>
        item?.type === 'file' &&
        item.parentUuid === currentFolder
      )
    };
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setIsSearching(!!query);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setIsSearching(false);
  };


  const getBreadcrumbs = () => {
    const breadcrumbs = [];

    if (currentFolder) {
      let current = documents[currentFolder];
      while (current) {
        breadcrumbs.unshift(current);
        current = current.parentUuid ? documents[current.parentUuid] : null;
      }
    }

    // Add home folder if it exists and isn't already included
    const homeFolder = Object.values(documents).find(
      item => item.type === "folder" && item.parentUuid === null
    );
    if (homeFolder && !breadcrumbs.find(item => item.uuid === homeFolder.uuid)) {
      breadcrumbs.unshift(homeFolder);
    }

    return breadcrumbs;
  };

  const handleFileClick = (fileURL) => {
    window.open(fileURL, "_blank");
  };

  const handleFolderClick = (folderUuid) => {
    setSearchQuery('')
    // Update current folder
    setCurrentFolder(folderUuid);

    // If no documents are loaded yet, fetch them
    if (Object.keys(documents).length === 0) {
      fetchDocuments(true);
    }
  };

  const handleBreadcrumbClick = (index) => {
    const breadcrumbs = getBreadcrumbs();
    if (index === 0) {
      // Clicking home folder
      const homeFolder = breadcrumbs[0];
      setCurrentFolder(homeFolder.uuid);
    } else {
      // Clicking any other folder in breadcrumb
      setCurrentFolder(breadcrumbs[index].uuid);
    }
  };


  if (isLoading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LoadSpinner />
      </div>
    );

  const items = isSearching ?
    (() => {
      const searchResults = searchDocuments(searchQuery);
      return {
        folders: searchResults.filter(item => item.type === 'folder'),
        files: searchResults.filter(item => item.type === 'file')
      };
    })() :
    getCurrentItems();


  return (
      <div className="w-full bg-white">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            {!isSearching && (
              <div className="flex items-center">
                {getBreadcrumbs().map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <ChevronRightIcon className="w-4 h-4 text-gray-400" />}
                    <button
                      onDragOver={(e) => {
                        e.preventDefault(); // Allow drop
                        e.currentTarget.classList.add('bg-violet-50', 'border-2', 'border-violet-700', 'px-20');
                      }}
                      onDragLeave={(e) => {
                        e.currentTarget.classList.remove('bg-violet-50', 'border-2', 'border-violet-700', 'px-20');
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.currentTarget.classList.remove('bg-violet-50', 'border-2', 'border-violet-700', 'px-20');
                        try {
                          // Parse the dropped item from the dataTransfer
                          const droppedItem = JSON.parse(e.dataTransfer.getData('text/plain'));

                          // Check if it's a file or a folder
                          if (droppedItem.type === 'file') {
                            move_file(droppedItem.uuid, item.uuid);
                          } else if (droppedItem.type === 'folder') {
                            move_folder(droppedItem.uuid, item.uuid);
                          }
                        } catch (error) {
                          console.error("Error moving item:", error);
                        }
                      }}
                      onClick={() => handleBreadcrumbClick(index)}
                      className="text-violet-700 hover:text-violet-800 px-2 py-1 rounded hover:bg-gray-100"
                    >
                      {item.name}
                    </button>
                  </React.Fragment>
                ))}
              </div>
            )}
            <div className="flex item-center space-x-5">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="Search files and folders..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="block w-64 pl-10 pr-8 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-violet-700 text-sm"
                />
                {searchQuery && (
                  <button
                    onClick={clearSearch}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    <CloseIcon className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                  </button>
                )}
              </div>
              {!isSearching && (
                <CreateNewButton
                  currentFolder={currentFolder}
                  upload_file={upload_file}
                  create_folder={create_folder}
                />
              )}
            </div>
          </div>
          {isSearching && (
            <div className="flex items-center justify-between mt-2">
              <p className="text-sm text-gray-600">
                {items.folders.length + items.files.length} results found
              </p>
              <button
                onClick={clearSearch}
                className="text-sm text-violet-700 hover:text-violet-800"
              >
                Clear search
              </button>
            </div>
          )}
        </div>

        <div className="p-6">
          {items.folders.length > 0 && (
            <div className="mb-8">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold text-gray-700">
                  {isSearching ? 'Folders found' : 'Folders'}
                </h2>
                {isSearching && (
                  <span className="text-sm text-gray-500">
                    {items.folders.length} folder{items.folders.length !== 1 ? 's' : ''}
                  </span>
                )}
              </div>
              <div className="grid grid-cols-4 lg:grid-cols-2 md:grid-cols-1 gap-4">
                {items.folders.map((folder) => (
                  <FolderItem
                    key={folder.uuid}
                    folder={folder}
                    handleFolderClick={handleFolderClick}
                    rename_folder={rename_folder}
                    delete_folder={delete_folder}
                    move_file={move_file}
                    move_folder={move_folder}
                  />
                ))}
              </div>
            </div>
          )}

          {items.files.length > 0 && (
            <div>
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold text-gray-700">
                  {isSearching ? 'Files found' : 'Files'}
                </h2>
                {isSearching && (
                  <span className="text-sm text-gray-500">
                    {items.files.length} file{items.files.length !== 1 ? 's' : ''}
                  </span>
                )}
              </div>
              <div className="grid grid-cols-4 lg:grid-cols-2 md:grid-cols-1 gap-4">
                {items.files.map((file, index) => (
                  <FileItem
                    key={file.uuid}
                    file={file}
                    index={index}
                    handleFileClick={handleFileClick}
                    rename_file={rename_file}
                    delete_file={delete_file}
                    isFileDragging={isFileDragging}
                    setIsFileDragging={setIsFileDragging}
                  />
                ))}
              </div>
            </div>
          )}

          {isSearching && items.folders.length === 0 && items.files.length === 0 && (
            <div className="flex flex-col items-center justify-center py-12">
              <SearchIcon className="h-12 w-12 text-gray-300 mb-4" />
              <p className="text-gray-500 text-lg mb-2">No results found</p>
              <p className="text-gray-400 text-sm">
                No files or folders match "{searchQuery}"
              </p>
            </div>
          )}
        </div>
      </div>
  );
}

export default DocumentsDisplay;
