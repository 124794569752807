import React from "react";
import { formatDateReadable } from "../requests/utilities/helpers";
import { formatDateReadableWithDayOfWeekNoYear } from "../requests/utilities/helpers";

const EarningsBarChart = (props) => {
  // Generating fake data for demonstration
  const earningsData = [
    {
      date: "2024-01-01",
      hours_scheduled: "11.880",
      hours_worked: "11.72",
      wages_scheduled: "11.880",
      wages_earned: "11.72",
    },
    {
      date: "2024-01-02",
      hours_scheduled: "11.880",
      hours_worked: "12.43",
      wages_scheduled: "11.880",
      wages_earned: "12.43",
    },
    {
      date: "2024-01-03",
      hours_scheduled: "11.880",
      hours_worked: "0.00",
      wages_scheduled: "11.880",
      wages_earned: "0.00",
    },
    {
      date: "2024-01-04",
      hours_scheduled: "14.520",
      hours_worked: "15.58",
      wages_scheduled: "14.520",
      wages_earned: "15.58",
    },
  ];

  const chartWidth = props.barChartData?.length * 120;

  // Calculate the maximum earnings for scaling
  const maxData = props.timeEntriesEnabled || !props.payrollWagesEnabled
    ? Math.max(
        ...props.barChartData?.flatMap((item) => [
          Number(item.hours_scheduled),
          Number(item.hours_worked),
        ])
      )
    : Math.max(
        ...props.barChartData?.flatMap((item) => [
          Number(item.wages_scheduled),
          Number(item.wages_earned),
        ])
      );

  // console.log("MAX DATA", maxData)

  return (
    <div className="w-full p-4">
      <div
        style={{ width: props.barChartData?.length > 8 ? "100%" : chartWidth }}
      >
        <div className="flex justify-end mb-2">
          <div
            className={`flex space-x-5 ${
              props.barChartData?.length === 1 ? "-mr-12" : "mr-3"
            }`}
          >
            <div className="flex items-center space-x-1">
              <div className="w-3 h-3 rounded-full bg-blue-500"></div>
              <span className="text-xs text-slate-500">Scheduled</span>
            </div>
            <div className="flex items-center space-x-1">
              <div className="w-3 h-3 rounded-full bg-green-500"></div>
              <span className="text-xs text-slate-500">Actual</span>
            </div>
          </div>
        </div>
        <div className="flex items-end h-40 mt-16">
          {props.barChartData?.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-center ${
                props.barChartData?.length > 8 ? "flex-1" : "w-[120px]"
              }`}
            >
              <div className="flex space-x-2 h-full items-end">
                {/* Scheduled Earnings Bar */}
                {props.timeEntriesEnabled || !props.payrollWagesEnabled ? (
                  <div className="flex flex-col items-center">
                    <p className="text-[12px] text-blue-600">
                      {Number(item.hours_scheduled).toFixed(1)}
                    </p>
                    <div
                      className="w-8 bg-blue-500 mt-1"
                      style={{
                        height: `${Math.max(
                          (Number(item.hours_scheduled) / maxData) * 160,
                          10
                        )}px`,
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <p className="text-[12px] text-blue-600">
                      ${Number(item.wages_scheduled).toFixed(0)}
                    </p>
                    <div
                      className="w-8 bg-blue-500 mt-1"
                      style={{
                        height: `${Math.max(
                          (Number(item.wages_scheduled) / maxData) * 160,
                          10
                        )}px`,
                      }}
                    />
                  </div>
                )}

                {/* Actual Earnings Bar */}
                {props.timeEntriesEnabled || !props.payrollWagesEnabled ? (
                  <div className="flex flex-col items-center">
                    <p className="text-[12px] text-green-600">
                      {Number(item.hours_worked).toFixed(1)}
                    </p>
                    <div
                      className="w-8 bg-green-500 mt-1"
                      style={{
                        height: `${Math.max(
                          (Number(item.hours_worked) / maxData) * 160,
                          10
                        )}px`,
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <p className="text-[12px] text-green-600">
                      ${Number(item.wages_earned).toFixed(0)}
                    </p>
                    <div
                      className="w-8 bg-green-500 mt-1"
                      style={{
                        height: `${Math.max(
                          (Number(item.wages_earned) / maxData) * 160,
                          10
                        )}px`,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Date Labels */}
        <div className="flex border-t border-slate-200">
          {props.barChartData?.map((item, index) => (
            <div
              key={index}
              className={`text-center ${
                props.barChartData?.length > 8 ? "flex-1" : "w-[120px]"
              }`}
            >
              <span className="text-xs text-gray-500">
                {/* {new Date(item.date).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric'
                })} */}
                {formatDateReadableWithDayOfWeekNoYear(item.date)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EarningsBarChart;
