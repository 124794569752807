import React from "react";
import HomeDisplayEmployee from "../employee/home/HomeDisplayEmployee";
import AvailabilityDisplayEmployee from "../employee/availability/AvailabilityDisplayEmployee";
import TimeOffDisplayEmployee from "../employee/availability/TimeOffDisplayEmployee";
import ScheduleDisplayEmployee from "../employee/schedule/ScheduleDisplayEmployee";
import ShiftSwapDisplayEmployee from "../employee/shiftswaps/ShiftSwapDisplayEmployee";
import TopBarDisplayStoreOnly from "../../home/top_bar_components/TopBarDisplayStoreOnly";
import StoreNameSidebar from "../../home/top_bar_components/StoreNameSidebar";
import HomeIcon from "@mui/icons-material/Home";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import {
  API_get_weather,
  API_get_list_time_off_requests_for_employee,
  API_get_schedule,
  API_get_shift_pool_for_employee,
  API_drop_shift,
  API_rescind_drop_shift,
  API_get_manager_shift_pickup_request_responses,
  API_get_employee_shift_pickup_request_responses,
  API_list_availability_for_employee,
  API_list_preferred_availability_for_employee,
  API_pickup_shift,
  API_rescind_pickup_shift,
  API_hide_shift_change_request,
  API_create_availability_request,
  API_rescind_availability_request,
  API_create_preferred_availability_request,
  API_rescind_preferred_availability_request,
  API_request_time_off,
  API_rescind_time_off_request,
  API_hide_time_off_request,
  API_delete_time_off_request,
  API_drawer_notifications,
  API_hide_drawer_notification,
  API_hide_all_drawer_notifications,
  API_get_notification_settings,
  API_update_notification_settings,
  API_get_roles,
  API_get_plan_tasks_for_user_and_date,
  API_save_plan_task_assignment,
} from "../../../../constants";
import { getRightworkAPI } from "../utilities/requests";
import { postRightworkAPI } from "../utilities/requests";
import { formatDate } from "../utilities/helpers";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsCard from "../NotificationsCard";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsDrawer from "../NotificationsDrawer";
import NotificationSettingsModal from "../NotificationSettingsModal";
import Account from "../../home/top_bar_components/Account";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../../utilities/LoadSpinner";
import TopBarDisplayTitle from "../../home/top_bar_components/TopBarDisplayTitle";
import { getMaxDatePlusOne } from "../utilities/helpers";

export default function RequestsEmployeeDisplay(props) {
  const [storeId, setStoreId] = React.useState(props.homeStore);

  const [data, setData] = React.useState([]);

  const [display, setDisplay] = React.useState("Overview");

  const [notificationsOpen, setNotificationsOpen] = React.useState(false);

  function handleNotificationClick() {
    setNotificationsOpen((prevState) => !prevState);
  }

  const notificationsRef = React.useRef(null);

  React.useEffect(() => {
    const closeNotificationsOnOutsideClick = (event) => {
      if (
        notificationsOpen &&
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setNotificationsOpen(false);
      }
    };

    document.addEventListener("click", closeNotificationsOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeNotificationsOnOutsideClick);
    };
  }, [notificationsOpen]);

  const default_api_calls = {
    schedule: API_get_schedule,
    availability2: API_list_availability_for_employee,
    // preferences2: API_list_preferred_availability_for_employee,
    timeOff: API_get_list_time_off_requests_for_employee,
    shiftPool: API_get_shift_pool_for_employee,
    shiftPickupResponses: API_get_employee_shift_pickup_request_responses,
    notifications: API_drawer_notifications,
    notificationSettings: API_get_notification_settings,
    roleNames: API_get_roles,
    tasks: API_get_plan_tasks_for_user_and_date,
  };

  // ----------- POST APIs ----------- //
  const drop_shift = async (shift_drop_id, shift_pickup_id, drop_note) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
      ...(shift_pickup_id && { shift_pickup_id: shift_pickup_id }),
      drop_note: drop_note,
    };

    try {
      toast.loading("Submitting Add to Pool Request...", { autoClose: false });

      const res = await postRightworkAPI(API_drop_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Add to Pool Request Submitted!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_employee,
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const rescind_drop_shift = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Cancelling Add to Pool Request...", { autoClose: false });

      const res = await postRightworkAPI(API_rescind_drop_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Add to Pool Request Cancelled!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_employee,
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const trade_shift = async (shift_drop_id, shift_pickup_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
      ...(shift_pickup_id && { shift_pickup_id: shift_pickup_id }),
    };

    try {
      toast.loading("Submitting Trade Request...", { autoClose: false });

      const res = await postRightworkAPI(API_drop_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Trade Request Submitted!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_employee,
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const rescind_trade_shift = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Cancelling Trade Request...", { autoClose: false });

      const res = await postRightworkAPI(API_rescind_drop_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Trade Request Cancelled!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_employee,
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const respond_to_trade_request = async (
    shift_drop_id,
    shift_change_request_uuid,
    shift_change_request_response
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
      shift_change_request_uuid: shift_change_request_uuid,
      shift_change_request_response: shift_change_request_response,
    };

    try {
      toast.loading("Submitting Shift Trade Request...", { autoClose: false });

      const res = await postRightworkAPI(API_pickup_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Shift Trade Request Submitted!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_employee,
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const create_pickup_request = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Submitting Shift Pickup Request...", { autoClose: false });

      const res = await postRightworkAPI(API_pickup_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Shift Pickup Request Submitted!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_employee,
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const rescind_pickup_shift = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Cancelling Shift Pickup Request...", { autoClose: false });

      const res = await postRightworkAPI(API_rescind_pickup_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Shift Pickup Request Cancelled!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_employee,
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const archive_shift_change_request = async (shift_change_request_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_change_request_uuid: shift_change_request_uuid,
    };

    try {
      toast.loading("Archiving Shift Change Request...", { autoClose: false });

      const res = await postRightworkAPI(
        API_hide_shift_change_request,
        requestData
      );

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        fetch_data(
          {
            shiftPickupResponses:
              API_get_employee_shift_pickup_request_responses,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          shiftPickupResponses: API_get_employee_shift_pickup_request_responses,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const create_availability_request = async (data) => {
    /*
      store_id = request.data.get('store_id')
      dow = request.data.get('dow')
      available_from = request.data.get('available_from')
      available_to = request.data.get('available_to')
      preferred_from = request.data.get('preferred_from')
      preferred_to = request.data.get('preferred_to)
      note = request.data.get('note')
      user_id = request.user.id
    */
    // Prepare data to send
    let requestData = data;
    requestData["store_id"] = storeId;

    try {
      toast.loading("Submitting Availability Request...", { autoClose: false });

      const res = await postRightworkAPI(
        API_create_availability_request,
        requestData
      );

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Availability Request Submitted!", { autoClose: 3000 });
        fetch_data(
          {
            availability2: API_list_availability_for_employee,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const rescind_availability_request = async (
    default_availability_request_id
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      default_availability_request_id: default_availability_request_id,
    };

    try {
      toast.loading("Cancelling Availability Request...", { autoClose: false });

      const res = await postRightworkAPI(
        API_rescind_availability_request,
        requestData
      );

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Availability Request Cancelled!", { autoClose: 3000 });
        fetch_data(
          {
            availability2: API_list_availability_for_employee,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // const create_preferred_availability_request = async (data) => {
  //   /*
  //   store_id = request.data.get('store_id')
  //   dow = request.data.get('dow')
  //   available_from = request.data.get('available_from')
  //   available_to = request.data.get('available_to')
  //   note = request.data.get('note')
  //   user_id = request.user.id
  //   */
  //   // Prepare data to send
  //   let requestData = data;
  //   requestData["store_id"] = storeId;
  //   // console.log(requestData);
  //   try {
  //     const res = await postRightworkAPI(
  //       API_create_preferred_availability_request,
  //       requestData
  //     );
  //     // console.log(res.status);
  //     // here you can handle the response from the api call
  //     if (res.status === 200) {
  //       fetch_data(
  //         {
  //           preferences2: API_list_preferred_availability_for_employee,
  //         },
  //         api_params
  //       );
  //       alert(
  //         "Preferences Request Submitted!",
  //         "This request was sent to your manager for approval",
  //         [{ text: "OK" }]
  //       );
  //       // Further handle success case as per your requirement
  //     }
  //   } catch (error) {
  //     if (error.response.data?.system?.message) {
  //       alert("Request Failed!", error.response.data?.system?.message, [
  //         { text: "OK" },
  //       ]);
  //     } else {
  //       alert("Request Failed!", "Please try again later", [
  //         { text: "OK" },
  //       ]);
  //     }
  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  // const rescind_preferred_availability_request = async (
  //   preferred_availability_request_id
  // ) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     preferred_availability_request_id: preferred_availability_request_id,
  //   };
  //   // console.log(requestData);
  //   try {
  //     const res = await postRightworkAPI(
  //       API_rescind_preferred_availability_request,
  //       requestData
  //     );
  //     // here you can handle the response from the api call
  //     if (res.status === 200) {
  //       fetch_data(
  //         {
  //           preferences2: API_list_preferred_availability_for_employee,
  //         },
  //         api_params
  //       );
  //       alert(
  //         "Preferences Request Cancelled",
  //         "Your cancellation of this preferences request was successful",
  //         [{ text: "OK" }]
  //       );
  //       // Further handle success case as per your requirement
  //     }
  //   } catch (error) {
  //     // console.log(error.response);
  //     if (error.response.data?.system?.message) {
  //       alert("Request Failed!", error.response.data?.system?.message, [
  //         { text: "OK" },
  //       ]);
  //     } else {
  //       alert("Request Failed!", "Please try again later", [
  //         { text: "OK" },
  //       ]);
  //     }
  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  const request_time_off = async (data) => {
    // Prepare data to send
    let requestData = data;
    requestData["store_id"] = storeId;

    try {
      toast.loading("Submitting Time Off Request...", { autoClose: false });

      const res = await postRightworkAPI(API_request_time_off, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Time Off Request Submitted!", { autoClose: 3000 });
        fetch_data(
          {
            timeOff: API_get_list_time_off_requests_for_employee,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const rescind_time_off_request = async (time_off_request_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      time_off_request_uuid: time_off_request_uuid,
    };

    try {
      toast.loading("Cancelling Time Off Request...", { autoClose: false });

      const res = await postRightworkAPI(
        API_rescind_time_off_request,
        requestData
      );

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Time Off Request Cancelled", { autoClose: 3000 });
        fetch_data(
          {
            timeOff: API_get_list_time_off_requests_for_employee,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const archive_time_off_request = async (time_off_request_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      time_off_request_uuid: time_off_request_uuid,
    };

    try {
      toast.loading("Archiving Time Off Request...", { autoClose: false });

      const res = await postRightworkAPI(
        API_hide_time_off_request,
        requestData
      );

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        fetch_data(
          {
            timeOff: API_get_list_time_off_requests_for_employee,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          timeOff: API_get_list_time_off_requests_for_employee,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const archive_drawer_notification = async (drawer_notification_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      drawer_notification_uuid: drawer_notification_uuid,
    };

    try {
      toast.loading("Archiving Drawer Notification...", { autoClose: false });

      const res = await postRightworkAPI(
        API_hide_drawer_notification,
        requestData
      );

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        fetch_data(
          {
            notifications: API_drawer_notifications,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          notifications: API_drawer_notifications,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const archive_all_drawer_notifications = async () => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
    };

    try {
      toast.loading("Archiving All Drawer Notifications...", {
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_hide_all_drawer_notifications,
        requestData
      );

      if (res.status === 200) {
        // Handle success
        toast.success("All notifications were cleared successfully", {
          autoClose: 3000,
        });
        toast.dismiss();
        fetch_data(
          {
            notifications: API_drawer_notifications,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          notifications: API_drawer_notifications,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const update_notification_settings = async (fe_settings) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      fe_settings: JSON.stringify(fe_settings),
    };

    try {
      toast.loading("Updating Notification Settings...", { autoClose: false });

      const res = await postRightworkAPI(
        API_update_notification_settings,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        fetch_data(
          {
            notificationSettings: API_get_notification_settings,
          },
          api_params
        );
        toast.success("Your notification settings were successfully updated", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          notificationSettings: API_get_notification_settings,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const save_plan_task_assignment = async (
    plan_task_assignment_uuid,
    plan_subtask_assignments,
    is_submit
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      plan_task_assignment_uuid: plan_task_assignment_uuid,
      plan_subtask_assignments: JSON.stringify(plan_subtask_assignments),
      is_submit: is_submit,
    };

    try {
      toast.loading(
        `${is_submit ? "Submitting Answers" : "Saving Answers..."}`,
        { autoClose: false }
      );

      const res = await postRightworkAPI(
        API_save_plan_task_assignment,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        fetch_data(
          {
            tasks: API_get_plan_tasks_for_user_and_date,
          },
          api_params
        );
        toast.success(
          `${
            is_submit
              ? "Answers Submitted Successfully"
              : "Answers Saved Successfully"
          }`,
          {
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          tasks: API_get_plan_tasks_for_user_and_date,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  // Get the current date
  let currentDate = new Date();
  // Get the start date of the previous month
  let lowerBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  // Get the end date of the next month
  let upperBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    0
  );

  let lowerBoundDateStr = formatDate(lowerBoundDate);
  let upperBoundDateStr = formatDate(upperBoundDate);

  let api_params = {
    store_id: storeId,
    lower_bound_date: lowerBoundDateStr,
    upper_bound_date: upperBoundDateStr,
    specific_date: formatDate(currentDate),
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  };

  const fetch_data = async (api_calls, api_params) => {
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      // console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setData(tempData);
    } catch (error) {
      // console.log(error);
      //throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    if (typeof storeId !== "undefined") {
      fetch_data(default_api_calls, api_params);
    }
  }, [storeId]);

  const userData = data.users?.redux?.payload?.employees;
  const scheduleData = data.schedule?.redux?.payload?.schedule;
  const myId = data.schedule?.redux?.payload?.me?.store_team_member?.id;
  const minEffectiveDate = props.requestMinimumDaysInAdvance
    ? new Date(
        new Date().setDate(
          new Date().getDate() + props.requestMinimumDaysInAdvance
        )
      )
    : getMaxDatePlusOne(scheduleData);

  const firstName =
    data.schedule?.redux?.payload?.me?.store_team_member?.first_name;
  let myShifts = [];
  if (scheduleData) {
    myShifts = scheduleData.filter((v, i) => v.store_team_member?.id === myId);
  }
  let myUpcomingShifts = [];
  myUpcomingShifts = myShifts.filter((shift) => {
    // Include the item if its date is today or in the future
    return shift.date >= formatDate(currentDate);
  });
  // console.log(myUpcomingShifts);
  let notMyUpcomingShifts = [];
  if (scheduleData) {
    notMyUpcomingShifts = scheduleData.filter((shift) => {
      // Include the item if its date is today or in the future
      return (
        (shift.date >= formatDate(currentDate)) &
        (shift.store_team_member?.id !== myId)
      );
    });
  }

  const shiftPoolData = data.shiftPool?.redux?.payload?.schedule;
  let shiftPickupResponsesRes =
    data.shiftPickupResponses?.redux?.payload?.shift_pickup_responses;

  const myUserId = data.shiftPickupResponses?.redux?.payload?.myUserId;

  let shiftPickupResponses = shiftPickupResponsesRes?.map((item) => {
    let requestType = "";

    if (
      item.employee_pickup &&
      item.employee_pickup.id === myUserId &&
      item.shift_pickup === null
    ) {
      requestType = "Pickup Request";
    } else if (
      item.employee_drop &&
      item.employee_drop.id === myUserId &&
      item.shift_pickup === null
    ) {
      requestType = "Add to Pool Request";
    } else if (
      item.employee_pickup &&
      item.employee_pickup.id === myUserId &&
      item.shift_pickup !== null
    ) {
      requestType = "tradeReceiver";
    } else if (
      item.employee_pickup &&
      item.employee_pickup.id !== myUserId &&
      item.shift_pickup !== null
    ) {
      requestType = "tradeSender";
    }

    return {
      ...item,
      requestType: requestType,
    };
  });

  const notifications =
    data.notifications?.redux?.payload?.drawer_notifications;

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // console.log("ABCDEF", data);

  return (
    <>
      {myId ? (
        <div className="relative">
          {/* <div className="w-full px-10 my-7">
            <TopBarDisplayStoreOnly
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div> */}
          {/* <div className="absolute top-4 right-6 flex items-center space-x-4">
            <button
              className="h-10 w-10 text-slate-500 flex items-center justify-center relative"
              // onClick={(event) => {
              //   handleNotificationClick(event);
              //   event.stopPropagation();
              // }}
              onClick={toggleDrawer(true)}
            >
              {notificationsOpen ? (
                <NotificationsIcon style={{ fontSize: "26px" }} />
              ) : (
                <NotificationsNoneIcon style={{ fontSize: "26px" }} />
              )}
              {notifications.length > 0 && (
                <div className="w-5 h-5 absolute top-0 right-0.5 bg-rose-600 rounded-full text-white text-xs flex items-center justify-center">
                  {notifications.length}
                </div>
              )}
            </button>
            <div className="cursor-pointer">
              <Account />
            </div>
          </div> */}
          <div className="w-full min-h-screen flex">
            <div className="w-1/5">
              <div className="h-full flex flex-col gap-2 px-6 py-5 bg-slate-50 border-r border-slate-200">
                {/* <div>
                  <StoreNameSidebar
                    storeOptions={props.storeOptions}
                    homeStore={props.homeStore}
                    storeNames={props.storeNames}
                    change_home_store={props.change_home_store}
                  />
                </div> */}
                <button
                  className={`mt-5 w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
                    display === "Overview" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Overview")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Overview"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <SummarizeOutlinedIcon />
                    </div>
                    <span>Overview</span>
                  </div>
                </button>
                <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Availability" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Availability")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Availability"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <BackHandOutlinedIcon />
                    </div>
                    <span>Availability</span>
                  </div>
                </button>
                <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Time Off" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Time Off")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Time Off"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <ScheduleOutlinedIcon />
                    </div>
                    <span>Time Off</span>
                  </div>
                </button>
                {/* <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Schedule" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Schedule")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Schedule"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <CalendarTodayOutlinedIcon />
                    </div>
                    <span>Schedule</span>
                  </div>
                </button> */}
                <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Shift Swaps" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Shift Swaps")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Shift Swaps"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <SwapHorizIcon />
                    </div>
                    <span>Shift Swaps</span>
                  </div>
                </button>
              </div>
            </div>
            <div className="w-4/5">
              <div>
                <TopBarDisplayTitle
                  title={display}
                  storeOptions={props.storeOptions}
                  homeStore={props.homeStore}
                  storeNames={props.storeNames}
                  change_home_store={props.change_home_store}
                />
              </div>
              {display === "Overview" ? (
                <HomeDisplayEmployee
                  notifications={
                    data.notifications?.redux?.payload.drawer_notifications
                  }
                  archive_drawer_notification={archive_drawer_notification}
                  scheduleData={scheduleData}
                  numOfShiftPoolRequests={shiftPoolData.length}
                  myShifts={myShifts}
                  myUpcomingShifts={myUpcomingShifts}
                  notMyUpcomingShifts={notMyUpcomingShifts}
                  myId={myId}
                  firstName={firstName}
                  drop_shift={drop_shift}
                  trade_shift={trade_shift}
                  rescind_drop_shift={rescind_drop_shift}
                  rescind_trade_shift={rescind_trade_shift}
                  roleNames={data.roleNames}
                  tasks={data?.tasks?.redux?.payload?.plan_task_assignments}
                  save_plan_task_assignment={save_plan_task_assignment}
                  homeCompany={props.homeCompany}
                  // store_info={store_info}
                  // store_name={storeName}
                  // username={username}
                  // setStoreId={setStoreId}
                  // setStoreName={setStoreName}
                />
              ) : display === "Availability" ? (
                <AvailabilityDisplayEmployee
                  availabilityData2={data.availability2?.redux?.payload}
                  timeOffData={data.timeOff?.redux?.payload?.timeOffRequests}
                  myId={myId}
                  create_availability_request={create_availability_request}
                  rescind_availability_request={rescind_availability_request}
                  request_time_off={request_time_off}
                  rescind_time_off_request={rescind_time_off_request}
                  archive_time_off_request={archive_time_off_request}
                  permissionRole={props.permissionRole}
                  minEffectiveDate={minEffectiveDate}
                />
              ) : display === "Time Off" ? (
                <TimeOffDisplayEmployee
                  availabilityData2={data.availability2?.redux?.payload}
                  timeOffData={data.timeOff?.redux?.payload?.timeOffRequests}
                  myId={myId}
                  create_availability_request={create_availability_request}
                  rescind_availability_request={rescind_availability_request}
                  request_time_off={request_time_off}
                  rescind_time_off_request={rescind_time_off_request}
                  archive_time_off_request={archive_time_off_request}
                  permissionRole={props.permissionRole}
                  minEffectiveDate={minEffectiveDate}
                />
              ) : display === "Schedule" ? (
                <ScheduleDisplayEmployee
                  scheduleData={scheduleData}
                  notMyUpcomingShifts={notMyUpcomingShifts}
                  drop_shift={drop_shift}
                  trade_shift={trade_shift}
                  rescind_drop_shift={rescind_drop_shift}
                  rescind_trade_shift={rescind_trade_shift}
                  myId={myId}
                  roleNames={data.roleNames}
                />
              ) : display === "Shift Swaps" ? (
                <ShiftSwapDisplayEmployee
                  shiftPoolData={shiftPoolData}
                  scheduleData={scheduleData}
                  shiftPickupResponses={shiftPickupResponses}
                  myShifts={myShifts}
                  myUpcomingShifts={myUpcomingShifts}
                  notMyUpcomingShifts={notMyUpcomingShifts}
                  myId={myId}
                  myUserId={myUserId}
                  drop_shift={drop_shift}
                  trade_shift={trade_shift}
                  rescind_drop_shift={rescind_drop_shift}
                  rescind_trade_shift={rescind_trade_shift}
                  respond_to_trade_request={respond_to_trade_request}
                  create_pickup_request={create_pickup_request}
                  rescind_pickup_shift={rescind_pickup_shift}
                  archive_shift_change_request={archive_shift_change_request}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
      <NotificationsDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        notifications={notifications}
        archive_drawer_notification={archive_drawer_notification}
        archive_all_drawer_notifications={archive_all_drawer_notifications}
        notificationSettings={
          data?.notificationSettings?.redux?.payload?.notification_settings
        }
        update_notification_settings={update_notification_settings}
        permissionRole={props.permissionRole}
      />
    </>
  );
}
