import React from "react";
import RepeatIcon from "@mui/icons-material/Repeat";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Pill from "../../shared/helpers/Pill";
import { add_shift_attributes, getInitials } from "../../utilities/helpers";
import { formatDateReadable } from "../../utilities/helpers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import EmptyState from "../../shared/helpers/EmptyState";
import AvatarSmall from "../../shared/helpers/AvatarSmall";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function TradesApprovedTable(props) {
  let tradesHistory = props.requestResponsesData.filter(
    (v, i) => v["shift_pickup"] !== null
  );

  tradesHistory = tradesHistory.map((v, i) => ({
    ...v,
    shift_drop: add_shift_attributes(v.shift_drop),
    shift_pickup: add_shift_attributes(v.shift_pickup),
  }));

  // console.log("TRADE HISTROY", props.requestResponsesData);

  return (
    <div className="mt-12">
      {tradesHistory.length > 0 ? (
        <div>
          <div className="flex items-center px-6">
            <div className="flex-auto">
              <h1 className="text-3xl text-slate-900">Acted On</h1>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[40%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                        >
                          Initiator
                        </th>
                        {/* <th
                          scope="col"
                          className="w-[16%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Trading Away
                        </th> */}
                        <th
                          scope="col"
                          className="w-[14%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          <div className="w-full flex justify-center">
                            <div className="w-10 flex justify-center">
                              <SwapHorizIcon style={{ fontSize: "24px" }} />
                            </div>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="w-[40%] pr-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Responder
                        </th>
                        {/* <th
                          scope="col"
                          className="w-[16%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Trading Away
                        </th> */}
                        <th
                          scope="col"
                          className="w-[6%] relative py-3.5 pl-3 pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {tradesHistory.map((trade, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-6 text-sm">
                            <div
                              className={`flex items-center px-6 py-4 rounded-2xl ${
                                trade.acting_manager_response.toLowerCase() ===
                                "approved"
                                  ? "bg-emerald-200"
                                  : "bg-rose-200"
                              }`}
                            >
                              <div className="w-1/2">
                                <div className="font-medium text-slate-900">
                                  {trade.employee_drop.first_name}{" "}
                                  {trade.employee_drop.last_name}
                                </div>
                                <div className="text-slate-500 mt-1">
                                  is trading away
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="text-slate-900">
                                  {formatDateReadable(trade.shift_drop.date)}
                                </div>
                                <div className="text-slate-500 mt-1">
                                  {trade.shift_drop.shift}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                            <div className="text-slate-900">
                              {formatDateReadable(trade.shift_drop.date)}
                            </div>
                            <div className="text-slate-500">
                              {trade.shift_drop.shift}
                            </div>
                          </td> */}
                          <td className="whitespace-nowrap px-3 py-4 text-sm">
                            <div className="w-full flex justify-center">
                              <div
                                className={`text-white w-10 h-10 rounded-full flex items-center justify-center ${
                                  trade.acting_manager_response.toLowerCase() ===
                                  "approved"
                                    ? "bg-emerald-600"
                                    : "bg-rose-600"
                                }`}
                              >
                                {trade.acting_manager_response.toLowerCase() ===
                                "approved" ? (
                                  <CheckIcon style={{ fontSize: "24px" }} />
                                ) : (
                                  <CloseIcon style={{ fontSize: "24px" }} />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap pr-6 py-4 text-sm text-slate-500">
                            <div
                              className={`flex items-center px-6 py-4 rounded-2xl ${
                                trade.acting_manager_response.toLowerCase() ===
                                "approved"
                                  ? "bg-emerald-200"
                                  : "bg-rose-200"
                              }`}
                            >
                              <div className="w-1/2">
                                <div className="font-medium text-slate-900">
                                  {trade.employee_pickup.first_name}{" "}
                                  {trade.employee_pickup.last_name}
                                </div>
                                <div className="text-slate-500 mt-1">
                                  is trading away
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="text-slate-900">
                                  {formatDateReadable(trade.shift_pickup.date)}
                                </div>
                                <div className="text-slate-500 mt-1">
                                  {trade.shift_pickup.shift}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                            <div className="text-slate-900">
                              {formatDateReadable(trade.shift_pickup.date)}
                            </div>
                            <div className="text-slate-500">
                              {trade.shift_pickup.shift}
                            </div>
                          </td> */}
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
                            <div className="flex items-center justify-end">
                              {/* <div className="mr-3">
                                <Pill status={trade.acting_manager_response.toLowerCase()} />
                              </div> */}
                              <Tooltip title="Archive">
                                <IconButton
                                  className="text-slate-500 ml-4"
                                  sx={{ backgroundColor: "#f8fafc" }}
                                  onClick={() =>
                                    props.archive_trade_request(trade.uuid)
                                  }
                                >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          title="Acted On"
          description="No acted on trades at the moment"
        />
      )}
    </div>
  );
}
