import React, { useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

function TimeEntriesReviewCardManager(props) {
  const status = props.selectedEmployee?.pay_period_approvals?.[0]?.status // pending_approval_initial, pending_approval_again, approved, disputed, missed_approval
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [note, setNote] = React.useState("");

  // If there's no status, don't render anything
  if (!status) {
    return null;
  }


  // [POST] api/clock_info/post_pay_period_approval (company employee)
  // * store_id (auth)
  // * pay_period_uuid
  // * pay_period_approval_uuid [optional]
  // * status [optional]
  // * note [optional]


  const handleSendBackForReview = () => {
    setShowNoteInput(false);
    props.post_pay_period_approval(
      props.selectedPayPeriodUUID,
      props.selectedEmployee?.pay_period_approvals?.[0]?.uuid,
      "pending_approval_again",
      note
    )
  };

  const getCardStyle = () => {
    switch (status) {
      case 'approved':
        return 'border-emerald-300 bg-emerald-50';
      case 'disputed':
        return 'border-rose-300 bg-rose-50';
      case 'missed_approval':
        return 'border-orange-300 bg-orange-50';
      case 'pending_approval_initial':
      case 'pending_approval_again':
        return 'border-amber-300 bg-amber-50';
      default:
        return 'border-gray-300 bg-gray-50';
    }
  };

  const getTextStyle = () => {
    switch (status) {
      case 'approved':
        return 'text-emerald-800';
      case 'disputed':
        return 'text-rose-800';
      case 'missed_approval':
        return 'text-orange-800';
      case 'pending_approval_initial':
      case 'pending_approval_again':
        return 'text-amber-800';
      default:
        return 'text-gray-800';
    }
  };

  const renderIcon = () => {
    switch (status) {
      case 'approved':
        return <CheckCircleIcon className="h-5 w-5 text-emerald-500 flex-shrink-0" />;
      case 'disputed':
        return <ErrorIcon className="h-5 w-5 text-rose-500 flex-shrink-0" />;
      case 'missed_approval':
        return <WarningAmberIcon className="h-5 w-5 text-orange-500 flex-shrink-0" />;
      case 'pending_approval_initial':
      case 'pending_approval_again':
        return <WarningAmberIcon className="h-5 w-5 text-amber-500 flex-shrink-0" />;
      default:
        return null;
    }
  };

  const renderDisputedContent = () => {
    return (
      <div className={getTextStyle()}>
        {props.selectedEmployee?.pay_period_approvals?.[0]?.note && <p className="mb-3 text-sm italic">Note From Employee: {props.selectedEmployee?.pay_period_approvals?.[0]?.note}</p>}
        <div className="text-sm mb-4">
          <p>• Time Entries: {props.numOfTimeEntries}</p>
          <p>• Total Hours: {props.selectedEmployee?.timecard_summary_data?.payable_hours?.toFixed(2)}</p>
        </div>
        {!showNoteInput && (
          <button
            className="px-4 py-2 bg-rose-600 text-white rounded-md hover:bg-rose-700 transition-colors"
            onClick={() => setShowNoteInput(true)}
          >
            Send Back for Review
          </button>
        )}
        {showNoteInput && (
          <div className="space-y-3">
            <textarea
              className="w-full p-2 border border-gray-300 rounded-md focus:border-violet-700 focus:outline-none focus:ring-1 focus:ring-violet-700 text-slate-900"
              placeholder="Add a note (optional)"
              rows={3}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <div className="flex gap-3">
              <button
                className="px-4 py-2 bg-rose-600 text-white rounded-md hover:bg-rose-700 transition-colors"
                onClick={handleSendBackForReview}
              >
                Send Back for Review
              </button>
              <button
                className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100 transition-colors"
                onClick={() => setShowNoteInput(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPendingApprovalContent = (type) => {
    return (
      <div className={getTextStyle()}>
        {type === "again" && props.selectedEmployee?.pay_period_approvals?.[0]?.note && <p className="mb-3 text-sm italic">Note To Employee: {props.selectedEmployee?.pay_period_approvals?.[0]?.note}</p>}
        <div className="text-sm">
          <p>• Time Entries: {props.numOfTimeEntries}</p>
          <p>• Total Hours: {props.selectedEmployee?.timecard_summary_data?.payable_hours?.toFixed(2)}</p>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (status) {
      case 'approved':
        return (
          <div className={getTextStyle()}>
            <div className="text-sm">
              <p>• Time Entries: {props.numOfTimeEntries}</p>
              <p>• Total Hours: {props.selectedEmployee?.timecard_summary_data?.payable_hours?.toFixed(2)}</p>
            </div>
          </div>
        );
      case 'disputed':
        return renderDisputedContent();
      case 'missed_approval':
        return (
          <div className={getTextStyle()}>
            <div className="text-sm">
              <p>• Time Entries: {props.numOfTimeEntries}</p>
              <p>• Total Hours: {props.selectedEmployee?.timecard_summary_data?.payable_hours?.toFixed(2)}</p>
            </div>
          </div>
        );
      case 'pending_approval_initial':
        return renderPendingApprovalContent("initial")
      case 'pending_approval_again':
        return (renderPendingApprovalContent("again"));
      default:
        return null;
    }
  };

  return (
    <div className={`border rounded-lg p-4 w-96 ${getCardStyle()}`}>
      <div className="flex items-start space-x-3">
        {renderIcon()}
        <div className="w-full">
          <div className={`flex items-center gap-2 font-semibold mb-3 ${getTextStyle()}`}>
            <h3>
              {status === 'approved' && 'Time Entries Approved By Employee'}
              {status === 'disputed' && 'Time Entries Disputed By Employee'}
              {status === 'pending_approval_initial' && 'Awaiting Employee Response'}
              {status === 'pending_approval_again' && 'Awaiting Employee Response'}
              {status === 'missed_approval' && 'Missed Approval Period'}
            </h3>
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

export default TimeEntriesReviewCardManager;
