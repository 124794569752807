import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import { formatDate } from "../requests/utilities/helpers";
import { formatDateReadableWithDayOfWeekNoYear } from "../requests/utilities/helpers";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import TimeClockShiftCard from "./TimeClockShiftCard";
import TimeClockShiftSummaryModal from "./TimeClockShiftSummaryModal";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import Tooltip from "@mui/material/Tooltip";
import TimeClockExitAppWarningModal from "./TimeClockExitAppModal";
import { get_t_str } from "../../../helpers/helpers";
import {
  API_post_timecard_clock_in,
  API_post_timecard_clock_out,
  API_post_timecard_break_clock_in,
  API_post_timecard_break_clock_out,
} from "../../../constants";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TimeClockContents(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openExitAppWarning, setOpenExitApp] = React.useState(false);
  const handleOpenExitAppWarning = () => setOpenExitApp(true);
  const handleCloseExitAppWarning = () => setOpenExitApp(false);

  const employeeClockData = props.employeeClockData;
  const isLoading = props.isLoading;

  // console.log("DATA", employeeClockData)

  const [clockOutTimeForSummaryModal, setClockOutTimeForSummaryModal] =
    React.useState(null);
  const [showClockOutForSummaryModal, setShowClockOutForSummaryModal] =
    React.useState(false);

  const clock_pin = props.loggedInPin;
  // ----------- POST APIs ----------- //

  const post_time_card_clock_in = async (
    clocked_in_at,
    published_schedule_id
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      clock_pin: clock_pin,
      clocked_in_at: clocked_in_at,
      published_schedule_id: published_schedule_id,
    };

    try {
      toast.loading("Clocking In...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_post_timecard_clock_in,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Clocked In Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        props.get_employee_data(clock_pin);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error("Request Failed", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_time_card_clock_out = async (timecard_uuid, clocked_out_at) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      clock_pin: clock_pin,
      timecard_uuid: timecard_uuid,
      clocked_out_at: clocked_out_at,
    };

    try {
      toast.loading("Clocking Out...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_post_timecard_clock_out,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Clocked Out Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        props.get_employee_data(clock_pin);
        handleOpen();
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_time_card_break_start = async (timecard_uuid, clocked_in_at) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      clock_pin: clock_pin,
      timecard_uuid: timecard_uuid,
      clocked_in_at: clocked_in_at,
    };

    try {
      toast.loading("Starting Break...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_post_timecard_break_clock_in,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Break Started Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        props.get_employee_data(clock_pin);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_time_card_break_end = async (
    timecard_break_uuid,
    clocked_out_at
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      clock_pin: clock_pin,
      timecard_break_uuid: timecard_break_uuid,
      clocked_out_at: clocked_out_at,
    };

    try {
      toast.loading("Ending Break...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_post_timecard_break_clock_out,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Break Ended Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        props.get_employee_data(clock_pin);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  const clockInTime = employeeClockData?.active_timecard?.clocked_in_at;
  const clockOutTime = employeeClockData?.active_timecard?.clocked_out_at;

  // console.log(new Date(clockInTime).toLocaleTimeString())

  const breaksArray = [];

  employeeClockData?.active_timecard?.timecard_breaks?.forEach((breakItem) => {
    breaksArray.push({
      time: new Date(breakItem.clocked_in_at).toLocaleTimeString(),
      timeInt: breakItem.clocked_in_at,
      type: "break_start",
      text: "Break Started",
    });

    if (breakItem.clocked_out_at) {
      breaksArray.push({
        time: new Date(breakItem.clocked_out_at).toLocaleTimeString(),
        timeInt: breakItem.clocked_out_at,
        type: "break_end",
        text: "Break Ended",
      });
    }
  });

  // console.log("BREAKS ARRAY", breaksArray);

  const mostRecentBreakStartTime =
    employeeClockData?.active_timecard?.timecard_breaks?.[
      employeeClockData?.active_timecard?.timecard_breaks?.length - 1
    ]?.clocked_in_at;
  const mostRecentBreakEndTime =
    employeeClockData?.active_timecard?.timecard_breaks?.[
      employeeClockData?.active_timecard?.timecard_breaks?.length - 1
    ]?.clocked_out_at;

  const [shiftTimeline, setShiftTimeline] = useState([]);
  // const [shiftTimelineForSummaryModal, setShiftTimelineForSummaryModal] = useState([])

  const [isClockedIn, setIsClockedIn] = useState(
    clockInTime !== null && clockOutTime === null
  );
  const [isOnBreak, setIsOnBreak] = useState(
    mostRecentBreakStartTime !== null && mostRecentBreakEndTime === null
  );

  useEffect(() => {
    // Create a new array combining clockInTime and breaksArray
    const updatedTimeline = [
      ...(clockInTime
        ? [
            {
              time: new Date(clockInTime).toLocaleTimeString(),
              timeInt: clockInTime,
              type: "clock_in",
              text: "Clocked In",
            },
          ]
        : []),
      ...breaksArray,
      ...(clockOutTime
        ? [
            {
              time: new Date(clockOutTime).toLocaleTimeString(),
              timeInt: clockOutTime,
              type: "clock_out",
              text: "Clocked Out",
            },
          ]
        : []),
    ];

    // Set the state with the updated timeline
    setShiftTimeline(updatedTimeline);

    setIsClockedIn(clockInTime !== null && clockOutTime === null);
    setIsOnBreak(
      mostRecentBreakStartTime !== null && mostRecentBreakEndTime === null
    );
  }, [employeeClockData]);

  // console.log("SHIFT TIMELINE", shiftTimeline);
  // console.log("IS CLOCKED IN", isClockedIn);
  // console.log(clockInTime !== null)
  // console.log(clockOutTime === null)
  // console.log("IS ON BREAK", isOnBreak);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleClockIn = () => {
    const now = new Date().toLocaleTimeString();
    post_time_card_clock_in(
      Date.now(),
      employeeClockData?.suggested_published_schedules[0]?.id
        ? employeeClockData?.suggested_published_schedules[0]?.id
        : null
    );
    // setShiftTimeline([
    //   ...shiftTimeline,
    //   { time: now, type: "clock_in", text: "Clocked In" },
    // ]);
    // setIsClockedIn(true);
    setShowClockOutForSummaryModal(false);
    setClockOutTimeForSummaryModal(null);
  };

  const handleClockOut = () => {
    const now = new Date().toLocaleTimeString();
    post_time_card_clock_out(
      employeeClockData?.active_timecard?.uuid,
      Date.now()
    );
    // setShiftTimeline([
    //   ...shiftTimeline,
    //   { time: now, type: "clock_out", text: "Clocked Out" },
    // ]);
    // setIsClockedIn(false);
    setShowClockOutForSummaryModal(true);
    setClockOutTimeForSummaryModal(Date.now());
  };

  const handleStartBreak = () => {
    const now = new Date().toLocaleTimeString();
    post_time_card_break_start(
      employeeClockData?.active_timecard?.uuid,
      Date.now()
    );
    // setShiftTimeline([
    //   ...shiftTimeline,
    //   { time: now, type: "break_start", text: "Break Started" },
    // ]);
    // setIsOnBreak(true);
  };

  const handleEndBreak = () => {
    const now = new Date().toLocaleTimeString();
    post_time_card_break_end(
      employeeClockData?.active_timecard?.timecard_breaks?.[
        employeeClockData?.active_timecard?.timecard_breaks?.length - 1
      ]?.uuid,
      Date.now()
    );
    // setShiftTimeline([
    //   ...shiftTimeline,
    //   { time: now, type: "break_end", text: "Break Ended" },
    // ]);
    // setIsOnBreak(false);
  };

  function floatToDateObject(floatHour) {
    // Split the float into hours and minutes
    const hours = Math.floor(floatHour);
    const minutes = Math.round((floatHour - hours) * 60);

    // Create a Date object and set the hours and minutes
    const timeDateObject = new Date();
    timeDateObject.setHours(hours);
    timeDateObject.setMinutes(minutes);

    return timeDateObject;
  }

  function getMillisecondsBetweenDateObjects(date1, date2) {
    // Convert dates to milliseconds and calculate the difference
    const millisecondsDiff = date2.getTime() - date1.getTime();

    return millisecondsDiff;
  }

  const [shiftEndTimeDateObject, setShiftEndTimeDateObject] = useState(
    floatToDateObject(
      employeeClockData?.active_timecard?.published_schedule?.shift_end
    )
  );
  const [clockInTimeDateObject, setClockInTimeDateObject] = useState(
    new Date(clockInTime)
  );

  useEffect(() => {
    setShiftEndTimeDateObject(
      floatToDateObject(
        employeeClockData?.active_timecard?.published_schedule?.shift_end
      )
    );
    setClockInTimeDateObject(new Date(clockInTime));
  }, [employeeClockData]);

  const differenceBetweenClockInAndShiftEnd = getMillisecondsBetweenDateObjects(
    clockInTimeDateObject,
    shiftEndTimeDateObject
  );
  const differenceBetweenCurrentTimeAndShiftEnd =
    getMillisecondsBetweenDateObjects(new Date(), shiftEndTimeDateObject);

  const progressPercent =
    ((differenceBetweenClockInAndShiftEnd -
      differenceBetweenCurrentTimeAndShiftEnd) /
      differenceBetweenClockInAndShiftEnd) *
    100;

  return (
    <>
      <div>
        <div className="h-screen bg-white p-6 relative flex flex-col justify-center items-center">
          <div className="w-content absolute top-6 left-6">
            <img
              src="https://rightworkpublic.s3.us-west-1.amazonaws.com/logo_name.png"
              alt="Rightwork Logo"
              width={250}
            />
          </div>
          <div className="absolute top-4 right-6 flex items-center space-x-4">
            <div
              className="px-10 py-3.5 rounded-full cursor-pointer"
              // onClick={() => props.logout()}
              onClick={handleOpenExitAppWarning}
            >
              <p className="text-violet-700 hover:text-violet-900 text-lg font-semibold">
                Exit App
              </p>
            </div>
            <div
              className="px-10 py-3.5 bg-violet-700 hover:bg-violet-800 rounded-full cursor-pointer"
              onClick={() => {
                props.handleLogout();
                setClockOutTimeForSummaryModal(null);
              }}
            >
              <p className="text-white text-lg font-semibold">Logout</p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center">
            <h3 className="text-slate-900 text-4xl">
              Welcome to {props.homeStoreName},{" "}
              {employeeClockData?.employee?.first_name}
            </h3>
            <p className="mt-4 text-slate-500 text-lg text-center w-[500px]">
              {isOnBreak
                ? "You are currently on break"
                : employeeClockData?.suggested_published_schedules?.length > 0
                ? isClockedIn
                  ? "You are currently clocked in for the shift below!"
                  : "Please clock in for your shift below"
                : isClockedIn
                ? "You are currently clocked in for an unscheduled shift!"
                : "You don't have a shift scheduled right now."}
            </p>
          </div>
          <div className="w-full flex justify-center mt-8">
            <div
              className={`w-[400px] h-[400px] flex items-center justify-center relative ${
                isOnBreak &&
                employeeClockData?.active_timecard?.published_schedule === null
                  ? "bg-orange-500"
                  : isClockedIn &&
                    employeeClockData?.active_timecard?.published_schedule ===
                      null
                  ? "bg-violet-700"
                  : "bg-slate-200 shadow-inner"
              } rounded-full`}
            >
              <div className="absolute mt-[7px]">
                <CircularProgress
                  size="400px"
                  variant="determinate"
                  value={progressPercent ? progressPercent : 0}
                  style={{ color: isOnBreak ? "#f97316" : "#6d28d9" }}
                />
              </div>
              <div className="w-[334px] h-[334px] flex flex-col items-center justify-center rounded-full bg-white shadow-lg">
                {employeeClockData?.active_timecard ? (
                  employeeClockData?.active_timecard?.published_schedule ? (
                    <div>
                      <TimeClockShiftCard
                        shiftStart={get_t_str(
                          employeeClockData?.active_timecard?.published_schedule
                            ?.shift_start
                        )}
                        shiftEnd={get_t_str(
                          employeeClockData?.active_timecard?.published_schedule
                            ?.shift_end
                        )}
                        station={
                          employeeClockData?.active_timecard?.published_schedule
                            ?.station
                        }
                        role={
                          employeeClockData?.suggested_published_schedules[0]
                            ?.role_id?.role_name
                        }
                        role_type={
                          employeeClockData?.active_timecard?.published_schedule
                            ?.role_id?.role_type?.role_type
                        }
                        breakLength={
                          employeeClockData?.active_timecard?.published_schedule
                            ?.break_length
                        }
                      />
                    </div>
                  ) : (
                    <div className="text-slate-900 text-3xl">
                      Unscheduled shift
                    </div>
                  )
                ) : employeeClockData?.suggested_published_schedules.length >
                  0 ? (
                  <div>
                    <TimeClockShiftCard
                      shiftStart={get_t_str(
                        employeeClockData?.suggested_published_schedules[0]
                          ?.shift_start
                      )}
                      shiftEnd={get_t_str(
                        employeeClockData?.suggested_published_schedules[0]
                          ?.shift_end
                      )}
                      station={
                        employeeClockData?.suggested_published_schedules[0]
                          ?.station
                      }
                      role={
                        employeeClockData?.suggested_published_schedules[0]
                          ?.role_id?.role_name
                      }
                      role_type={
                        employeeClockData?.suggested_published_schedules[0]
                          ?.role_id?.role_type?.role_type
                      }
                      breakLength={
                        employeeClockData?.suggested_published_schedules[0]
                          ?.break_length
                      }
                    />
                  </div>
                ) : (
                  <div className="text-slate-900 text-3xl">
                    Unscheduled shift
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center mt-12">
            {!isClockedIn ? (
              <div
                className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-5 rounded-xl w-96 flex items-center justify-center space-x-2 shadow cursor-pointer"
                onClick={handleClockIn}
              >
                <div>
                  <PlayArrowIcon style={{ fontSize: "30px" }} />
                </div>
                <p className="text-2xl">Clock In</p>
              </div>
            ) : !isOnBreak ? (
              <div className="flex space-x-8">
                <div
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-5 rounded-xl w-72 flex items-center justify-center space-x-2 shadow cursor-pointer"
                  onClick={handleStartBreak}
                >
                  <div>
                    <PauseIcon style={{ fontSize: "30px" }} />
                  </div>
                  <p className="text-2xl">Start Break</p>
                </div>
                <div
                  className="bg-rose-500 hover:bg-rose-700 text-white font-bold py-5 rounded-xl w-72 flex items-center justify-center space-x-2 shadow cursor-pointer"
                  onClick={handleClockOut}
                >
                  <div>
                    <StopIcon style={{ fontSize: "30px" }} />
                  </div>
                  <p className="text-2xl">Clock Out</p>
                </div>
              </div>
            ) : (
              <div
                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-5 rounded-xl w-96 flex items-center justify-center space-x-2 shadow cursor-pointer"
                onClick={handleEndBreak}
              >
                <div>
                  <PlayCircleOutlineIcon style={{ fontSize: "30px" }} />
                </div>
                <p className="text-2xl">End Break</p>
              </div>
            )}
          </div>
          <div className="absolute bottom-6 left-6">
            <div>
              <p className="text-slate-900 text-3xl">
                {formatDateReadableWithDayOfWeekNoYear(formatDate(new Date()))}
              </p>
              <p className="text-slate-900 text-5xl mt-1.5">{currentTime}</p>
            </div>
          </div>
          <div className="absolute bottom-6 right-0 lg:hidden">
            {shiftTimeline?.length > 0 && (
              <div className="w-[155px] text-right pb-3 flex justify-end">
                <Tooltip title="Open summary">
                  <div
                    className="w-10 h-10 rounded-lg bg-slate-100 hover:bg-slate-200 text-slate-900 flex items-center justify-center cursor-pointer"
                    onClick={handleOpen}
                  >
                    <OpenInBrowserIcon />
                  </div>
                </Tooltip>
              </div>
            )}
            <div className="max-h-[550px] overflow-auto pr-6">
              {shiftTimeline.map((item, index) => (
                <div key={index}>
                  <div className="flex items-center space-x-2">
                    <p className="w-28 text-right text-slate-900 text-sm">
                      {item.time}
                    </p>
                    <div className="w-8 flex justify-center text-slate-500">
                      {item.type === "clock_in" ? (
                        <PlayArrowIcon style={{ marginBottom: "1px" }} />
                      ) : item.type === "clock_out" ? (
                        <StopIcon style={{ marginBottom: "1px" }} />
                      ) : item.type === "break_start" ? (
                        <PauseIcon style={{ marginBottom: "1px" }} />
                      ) : item.type === "break_end" ? (
                        <PlayCircleOutlineIcon
                          style={{ marginBottom: "1px" }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <p className="text-slate-900">{item.text}</p>
                      {/* <p className="text-xs text-slate-500">On time</p> */}
                    </div>
                  </div>
                  {index !== shiftTimeline.length - 1 && (
                    <div className="flex items-center space-x-2 pt-1.5 pb-1.5">
                      <div className="w-28"></div>
                      <div className="w-8 flex items-center">
                        <div className="w-4 h-8 border-r border-slate-200"></div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="absolute bottom-6 right-6 lg:block hidden">
            {shiftTimeline.length > 0 && (
              <div>
                <div className="pb-1 flex justify-end">
                  <Tooltip title="Open summary">
                    <div
                      className="w-10 h-10 rounded-lg bg-slate-100 hover:bg-slate-200 text-slate-900 flex items-center justify-center cursor-pointer"
                      onClick={handleOpen}
                    >
                      <OpenInBrowserIcon />
                    </div>
                  </Tooltip>
                </div>
                <p className="text-xl text-right">Last Action</p>
                <div className="flex items-center space-x-0.5 text-xl">
                  <div className="text-slate-500">
                    {shiftTimeline[shiftTimeline.length - 1]?.type ===
                    "clock_in" ? (
                      <PlayArrowIcon style={{ marginBottom: "1px" }} />
                    ) : shiftTimeline[shiftTimeline.length - 1]?.type ===
                      "clock_out" ? (
                      <StopIcon style={{ marginBottom: "1px" }} />
                    ) : shiftTimeline[shiftTimeline.length - 1]?.type ===
                      "break_start" ? (
                      <PauseIcon style={{ marginBottom: "1px" }} />
                    ) : shiftTimeline[shiftTimeline.length - 1]?.type ===
                      "break_end" ? (
                      <PlayCircleOutlineIcon style={{ marginBottom: "1px" }} />
                    ) : (
                      ""
                    )}
                  </div>
                  <p className="text-slate-500">
                    {shiftTimeline[shiftTimeline.length - 1]?.text} @{" "}
                    {shiftTimeline[shiftTimeline.length - 1]?.time}
                  </p>
                </div>
              </div>
            )}
          </div>
          <TimeClockShiftSummaryModal
            open={open}
            handleClose={handleClose}
            shiftDate={employeeClockData?.active_timecard?.clocked_in_at}
            shiftTimeline={shiftTimeline}
            clockInTimeInt={employeeClockData?.active_timecard?.clocked_in_at}
            clockInTime={clockInTime}
            clockOutTimeForSummaryModal={clockOutTimeForSummaryModal}
            setClockOutTimeForSummaryModal={setClockOutTimeForSummaryModal}
            showClockOutForSummaryModal={showClockOutForSummaryModal}
            setShowClockOutForSummaryModal={setShowClockOutForSummaryModal}
            breaksArray={employeeClockData?.active_timecard?.timecard_breaks}
          />
          <TimeClockExitAppWarningModal
            open={openExitAppWarning}
            handleClose={handleCloseExitAppWarning}
            logout={props.logout}
            handleLogout={props.handleLogout}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    username: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeClockContents);
