import React, { useState, useEffect } from "react";
import TopBarDisplayTitleNoLocationSelect from "../home/top_bar_components/TopBarDisplayTitleNoLocationSelect";
import { API_get_dashboard } from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { formatDateReadable } from "../requests/utilities/helpers";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import Tooltip from "@mui/material/Tooltip";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

function OverviewDisplay(props) {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const api_params = {
        store_id: props.homeStore,
        company_id: props.homeCompany,
        week_count: 4, // Current week + next 3 weeks
      };

      try {
        const res = await getRightworkAPI(API_get_dashboard, api_params);
        setDashboardData(res.data?.redux?.payload?.stores);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [props.homeCompany]);

  // Function to filter data based on search input
  const filteredData = dashboardData.filter((store) =>
    store.store_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  function calcLetter(score) {
    if (score >= 90) return "A";
    if (score >= 80) return "B";
    if (score >= 70) return "C";
    if (score >= 60) return "D";
    if (score === 0) return "-";
    return "F";
  }

  function getColor(score) {
    if (score >= 90) return "#059669";
    if (score >= 80) return "#65a30d";
    if (score >= 70) return "#f5be27";
    if (score >= 60) return "#ea580c";
    if (score === 0) return "gray";
    return "#dc2626";
  }

  // Smaller icon for forecast adjustment
  function adjustment_icon(value) {
    if (value.toFixed(3) === "1.000") {
      return "";
    }
    if (value < 0.9) {
      return <SouthEastIcon style={{ fontSize: "16px" }} />;
    }
    if (value < 1) {
      return <TrendingDownIcon style={{ fontSize: "16px" }} />;
    }
    if (value > 1) {
      return <TrendingUpIcon style={{ fontSize: "16px" }} />;
    }
    if (value > 9) {
      return <NorthEastIcon style={{ fontSize: "16px" }} />;
    } else {
      return "";
    }
  }

  // console.log("DASHBOARD DATA", dashboardData);

  return (
    <div className="bg-white min-h-screen">
      <div>
        <TopBarDisplayTitleNoLocationSelect title="Overview" />
      </div>
      {!isLoading ? (
        <div className="mt-10 pb-10 px-8">
          <div className="flex">
            <div className="w-[18%]">
              <div className="min-w-full h-full">
                <input
                  className={`w-[85%] h-full border border-slate-200 px-3 text-xs rounded-lg outline-none focus:border-2 focus:border-violet-700 ${
                    filteredData.length === 0 ? "text-rose-600" : ""
                  }`}
                  style={{ maxHeight: "32px" }}
                  type="text"
                  placeholder="🔎  Search by store name"
                  value={searchInput}
                  onChange={(event) => setSearchInput(event.target.value)}
                />
              </div>
            </div>
            {dashboardData?.[0]?.weekly_stats?.map((week, index) => (
              <div key={index} className="flex-1">
                <div>
                  <p
                    className={`text-slate-500 text-xxs ${
                      index === 1 ? "font-bold" : ""
                    }`}
                  >
                    {index === 0
                      ? "Last Week"
                      : index === 1
                      ? "Current Week"
                      : index === 2
                      ? "New Week"
                      : "+2 Weeks"}
                  </p>
                  <p
                    className={`text-slate-900 text-sm ${
                      index === 1 ? "font-bold" : ""
                    }`}
                  >
                    {formatDateReadable(week.start_date)} -{" "}
                    {formatDateReadable(week.end_date)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div>
            {filteredData.map((store, index) => (
              <div
                key={index}
                className="w-full border border-slate-200 rounded-xl flex my-2.5 overflow-hidden"
              >
                <div className="w-[18%] p-4">
                  <h3 className="text-2xl text-slate-900">
                    {store.store_name}
                  </h3>
                </div>
                {store.weekly_stats?.map((week, index) => {
                  const scheduledHours = week.scheduled_labor_hours || 0;
                  const forecastedHours = week.forecasted_labor_hours || 0;
                  const percentScheduleOfForecasted =
                    (scheduledHours / forecastedHours) * 100;
                  const initialVsAdjustedForecast =
                    week.forecasted_revenue_adjustment /
                    week.forecasted_revenue;
                  const actualHours = week.actual_labor_hours;
                  const earnedHours = week.earned_labor_hours;
                  const percentActualOfEarned =
                    (actualHours / earnedHours) * 100;

                  const percentChangeActualVsForecastedSales =
                    ((week.actual_revenue -
                      week.forecasted_revenue_adjustment) /
                      week.forecasted_revenue_adjustment) *
                    100;
                  const isPositive = percentChangeActualVsForecastedSales > 0;
                  return (
                    <div
                      key={index}
                      className={`flex-1 p-4 border-l ${
                        week.has_completed_week
                          ? "bg-slate-100 hover:bg-slate-50"
                          : "bg-white hover:bg-slate-50"
                      } border-slate-200 cursor-pointer`}
                      onClick={() => {
                        props.setScheduleRenderDate(week.week_start);
                        props.change_home_store(store.store_id);
                        props.setCurrentTab("home");
                      }}
                    >
                      <div className="flex justify-between">
                        <div className="flex">
                          <Tooltip
                            title={
                              initialVsAdjustedForecast === 1 ||
                              !week.forecasted_revenue ||
                              !week.forecasted_revenue_adjustment ? (
                                ""
                              ) : (
                                <div>
                                  <p>
                                    Initial Forecast:{" "}
                                    {week.forecasted_revenue.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )}
                                  </p>
                                  <p>
                                    Adjusted Forecast:{" "}
                                    {week.forecasted_revenue_adjustment.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )}
                                  </p>
                                  {week.good_adjustments > 0 && (
                                    <p>
                                      {week.good_adjustments} good adjustment
                                      {week.good_adjustments > 1 && "s"}
                                    </p>
                                  )}
                                  {week.bad_adjustments > 0 && (
                                    <p>
                                      {week.bad_adjustments} poor adjustment
                                      {week.bad_adjustments > 1 && "s"}
                                    </p>
                                  )}
                                </div>
                              )
                            }
                          >
                            <div>
                              <p className="text-slate-500 text-xxs">
                                Forecasted Sales
                              </p>
                              <div className="flex items-center">
                                <p className="text-slate-900 text-base">
                                  {week.forecasted_revenue_adjustment?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "USD",
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    }
                                  )}
                                </p>
                                <div>
                                  {adjustment_icon(initialVsAdjustedForecast)}
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                        {week.has_completed_week ? (
                          <div>
                            <p className="text-slate-500 text-xxs text-right">
                              Actual Sales
                            </p>
                            <div className="flex items-center">
                              {!isNaN(percentChangeActualVsForecastedSales) && (
                                <div
                                  className={`flex items-center ${
                                    isPositive
                                      ? "text-emerald-700"
                                      : "text-rose-700"
                                  }`}
                                >
                                  {isPositive ? (
                                    <NorthIcon style={{ fontSize: "10px" }} />
                                  ) : (
                                    <SouthIcon style={{ fontSize: "10px" }} />
                                  )}
                                  <p className="text-xxs">
                                    {Math.abs(
                                      percentChangeActualVsForecastedSales
                                    ).toFixed(1)}
                                    %
                                  </p>
                                </div>
                              )}
                              <p className="text-slate-900 text-base ml-1">
                                {week.actual_revenue?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {week.published_shifts_count === 0 && (
                              <div className="px-2 py-1 bg-orange-100 border border-orange-300 rounded-full h-fit mt-0.5">
                                <p className="text-xxs text-orange-700">
                                  Unpublished
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {week.has_completed_week ? (
                        <div className="flex justify-between mt-4">
                          <div className="w-[75%]">
                            <p className="text-slate-500 text-xxs">
                              {actualHours.toFixed(2)} /{" "}
                              {earnedHours.toFixed(2)} hrs
                            </p>
                            <div className="w-full h-1.5 bg-slate-200 rounded-full mt-0.5 overflow-hidden">
                              <div
                                style={{
                                  width:
                                    percentActualOfEarned > 100
                                      ? "100%"
                                      : `${
                                          isNaN(percentActualOfEarned)
                                            ? 0
                                            : percentActualOfEarned
                                        }%`,
                                }}
                                className="h-full bg-slate-800 rounded-full"
                              ></div>
                            </div>
                            <div className="mt-0.5">
                              <p
                                className={`${
                                  percentActualOfEarned > 100
                                    ? "text-rose-700"
                                    : "text-emerald-700"
                                } text-xs`}
                              >
                                {isNaN(percentActualOfEarned)
                                  ? "0.0"
                                  : percentActualOfEarned.toFixed(1)}
                                %{" "}
                                <span className="text-slate-500 text-xxs">
                                  of earned
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="w-8 h-8 rounded-lg text-white flex items-center justify-center"
                            style={{
                              backgroundColor: getColor(week.actual_score),
                            }}
                          >
                            <p>{calcLetter(week.actual_score)}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-between mt-4">
                          <div className="w-[75%]">
                            <p className="text-slate-500 text-xxs">
                              {scheduledHours.toFixed(2)} /{" "}
                              {forecastedHours.toFixed(2)} hrs
                            </p>
                            <div className="w-full h-1.5 bg-slate-200 rounded-full mt-0.5 overflow-hidden">
                              <div
                                style={{
                                  width:
                                    percentScheduleOfForecasted > 100
                                      ? "100%"
                                      : `${
                                          isNaN(percentScheduleOfForecasted)
                                            ? 0
                                            : percentScheduleOfForecasted
                                        }%`,
                                }}
                                className="h-full bg-slate-800 rounded-full"
                              ></div>
                            </div>
                            <div className="mt-0.5">
                              <p
                                className={`${
                                  percentScheduleOfForecasted > 100
                                    ? "text-rose-700"
                                    : "text-emerald-700"
                                } text-xs`}
                              >
                                {isNaN(percentScheduleOfForecasted)
                                  ? "0.0"
                                  : percentScheduleOfForecasted.toFixed(1)}
                                %{" "}
                                <span className="text-slate-500 text-xxs">
                                  of forecasted
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="w-8 h-8 rounded-lg text-white flex items-center justify-center"
                            style={{
                              backgroundColor: getColor(week.planned_score),
                            }}
                          >
                            <p>{calcLetter(week.planned_score)}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </div>
  );
}

export default OverviewDisplay;
