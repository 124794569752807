import React from "react";
import AvailabilityRequestsTableRow from "./AvailabilityRequestsTableRow.js";
import EmptyState from "../../shared/helpers/EmptyState.js";

export default function AvailabilityRequestsTable(props) {
  // console.log("AVAILABILITY REQUEST DATA", props.availabilityRequestsData)

  const hasAvailabilityRequests = props.availabilityRequestsData.length > 0;

  return (
    <>
      {hasAvailabilityRequests ? (
        <div className="mt-12">
          <div className="flex items-center px-6">
            <div className="flex-auto">
              <h1 className="text-3xl text-slate-900">Requests</h1>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto min-w-full">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[25%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                        >
                          Employee
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Existing Availability
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Requested Availability
                        </th>
                        <th
                          scope="col"
                          className="w-[25%] pl-3 pr-6 py-3.5 text-sm text-slate-500 font-medium text-right"
                        >
                          Effective Date
                        </th>
                        {/* <th scope="col" className="w-[18%] relative py-3.5 pl-3 pr-6">
                          <span className="sr-only">Effective Date</span>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {props.availabilityRequestsData.map((request) => (
                        <AvailabilityRequestsTableRow
                          name={request.name}
                          day={request.day}
                          currentAvailability={request.availability}
                          currentAvailability2={request.availability2}
                          currentPreferences={request.preferences}
                          requestedAvailability={request.requested.availability}
                          requestedAvailability2={
                            request.requested.availability2
                          }
                          requestedPreferences={request.requested.preferences}
                          inEffect={request.requested.inEffect}
                          id={request.requested.id}
                          note={request.requested.note}
                          manager_respond_to_availability_request={
                            props.manager_respond_to_availability_request
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          title="Requests"
          description="No availability requests at the moment"
        />
      )}
    </>
  );
}
