import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { uploadFile } from "../../requests/utilities/requests";

function TaskNameCardInput(props) {
  const [isFileLoading, setIsFileLoading] = React.useState(false);

  const handleTextChange = (event, subtaskIndex) => {
    const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
    updatedSubtasks[subtaskIndex].value_text = event.target.value;
    props.setModalData({
      ...props.modalData,
      plan_subtask_assignments: updatedSubtasks,
    });
  };

  const handleCheckChange = (subtaskIndex) => {
    const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
    updatedSubtasks[subtaskIndex].value_boolean =
      !updatedSubtasks[subtaskIndex].value_boolean;
    props.setModalData({
      ...props.modalData,
      plan_subtask_assignments: updatedSubtasks,
    });
  };

  const handleFileChange = async (event, subtaskIndex) => {
    const file = event.target.files[0];
    if (file) {
      setIsFileLoading(true);
      try {
        const fileObject = await uploadFile(
          props.homeCompany,
          file,
          "plan_subtask_assignment"
        );
        setIsFileLoading(false);

        const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
        updatedSubtasks[subtaskIndex].file_upload = fileObject;
        props.setModalData({
          ...props.modalData,
          plan_subtask_assignments: updatedSubtasks,
        });
      } catch (error) {
        console.error("Failed to upload file:", error);
        setIsFileLoading(false);
      }
    }
  };

  const handleDeleteFile = (subtaskIndex) => {
    const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
    updatedSubtasks[subtaskIndex].file_upload = null;
    props.setModalData({
      ...props.modalData,
      plan_subtask_assignments: updatedSubtasks,
    });
  };

  console.log("SUBTASK", props.subtask)

  return (
    <div className={`py-7 ${
      props.index !== props.modalData.plan_subtask_assignments.length - 1
        ? "border-b border-slate-200"
        : ""
    }`}>
      <div className="flex gap-3">
        <div className="text-sm font-medium text-slate-500 w-5">
          {props.index + 1}.
        </div>
        {props.subtask.plan_subtask.type === "text" ? (
          <div className="flex-1 space-y-2">
            <div className="flex justify-between">
              <div>
                <p className="text-sm font-medium text-slate-800">
                  {props.subtask.plan_subtask.name}
                </p>
                {props.subtask.plan_subtask.description && (
                  <p className="text-xs text-slate-500 mt-1">
                    {props.subtask.plan_subtask.description}
                  </p>
                )}
              </div>
              {props.isAssignedToEveryone && props.subtask.store_team_member && props.subtask.is_completed && (
                <div>
                  <p className="text-xs italic text-emerald-600">
                    {props.subtask.store_team_member?.full_name} @ {props.subtask.completed_at_formatted}
                  </p>
                </div>
              )}
            </div>
            <TextField
              placeholder="Enter text"
              fullWidth
              variant="outlined"
              size="small"
              name="name"
              value={props.subtask.value_text}
              onChange={(e) => handleTextChange(e, props.index)}
              InputProps={{
                sx: {
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#7C3AED",
                    borderWidth: "2px",
                  },
                },
              }}
            />
          </div>
        ) : props.subtask.plan_subtask.type === "checkbox" ? (
          <div className="flex-1">
            <div className="flex gap-3">
              <div
                className={`w-6 h-6 rounded-md flex items-center justify-center border-2 cursor-pointer ${
                  props.subtask.value_boolean
                    ? "bg-violet-600 border-violet-600"
                    : "border-slate-300 bg-white"
                }`}
                onClick={() => handleCheckChange(props.index)}
              >
                {props.subtask.value_boolean && (
                  <CheckIcon className="text-white" style={{ fontSize: "16px" }} />
                )}
              </div>
              <div className="flex-1 flex justify-between">
                <div>
                  <p className="text-sm font-medium text-slate-800">
                    {props.subtask.plan_subtask.name}
                  </p>
                  {props.subtask.plan_subtask.description && (
                    <p className="text-xs text-slate-500 mt-1">
                      {props.subtask.plan_subtask.description}
                    </p>
                  )}
                </div>
                {props.isAssignedToEveryone && props.subtask.store_team_member && props.subtask.is_completed && (
                  <div>
                    <p className="text-xs italic text-emerald-600">
                      ABC {props.subtask.store_team_member?.full_name} @ {props.subtask.completed_at_formatted}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="mt-3 ml-8">
        {!props.subtask?.file_upload?.uuid ? (
          <div>
            <label
              htmlFor={`file-upload-${props.index}`}
              className="inline-flex items-center gap-2 px-3 py-2 bg-slate-100 rounded-lg cursor-pointer hover:bg-slate-200 transition-colors"
            >
              {!isFileLoading ? (
                <>
                  <AttachFileIcon style={{ fontSize: "20px", color: "#64748B" }} />
                  <span className="text-sm text-slate-600">
                    {props.subtask.plan_subtask.is_image_required
                      ? "Required"
                      : "Attach (optional)"}
                  </span>
                </>
              ) : (
                <CircularProgress size="1.25rem" sx={{ color: "#6D28D9" }} />
              )}
            </label>
            <input
              id={`file-upload-${props.index}`}
              name={`file-upload-${props.index}`}
              type="file"
              className="hidden"
              onChange={(e) => handleFileChange(e, props.index)}
              accept=".jpg, .jpeg, .png"
            />
          </div>
        ) : (
          <div className="space-y-2">
            <div className="flex items-center gap-3">
              <button
                onClick={() => handleDeleteFile(props.index)}
                className="w-8 h-8 rounded-full hover:bg-slate-100 transition-colors flex items-center justify-center"
              >
                <CloseIcon style={{ fontSize: "16px", color: "#64748B" }} />
              </button>
              <button
                onClick={() => {
                  props.setShowLightbox(true);
                  props.setSelectedImage(props.subtask?.file_upload?.presigned_url);
                }}
                className="inline-flex items-center gap-2 text-violet-700 hover:text-violet-800 transition-colors"
              >
                <ImageIcon style={{ fontSize: "18px" }} />
                <span className="text-sm font-medium">1 Image Attached</span>
              </button>
            </div>
            {props.subtask?.file_upload?.presigned_url && (
              <div
                onClick={() => {
                  props.setShowLightbox(true);
                  props.setSelectedImage(props.subtask?.file_upload?.presigned_url);
                }}
                className="cursor-pointer hover:opacity-90 transition-opacity"
              >
                <img
                  src={props.subtask?.file_upload?.presigned_url}
                  alt="Attached"
                  className="rounded-lg max-h-[150px] max-w-[250px] shadow-sm"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskNameCardInput;
