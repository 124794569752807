import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatDateReadableWithDayOfWeek } from '../../utilities/helpers';
import { formatDate } from '../../utilities/helpers';
import dayjs from 'dayjs';

function DatePickerDropdown(props) {
  // Update value when selectedDate prop changes
  const [value, setValue] = React.useState(dayjs(props.selectedDate || props.minEffectiveDate));

  // Add effect to sync with parent's date
  React.useEffect(() => {
    setValue(dayjs(props.selectedDate || props.minEffectiveDate));
  }, [props.selectedDate, props.minEffectiveDate]);

  const handleDateChange = (newDate) => {
    setValue(newDate);
    props.setDate(formatDate(newDate));
  };

  const minDate = dayjs(props.minEffectiveDate);

  return (
    <div
      className={`flex-1 py-3 bg-white rounded-xl px-4 text-base ${props.showDailyDatePicker ? "border-2 border-violet-700" : "border border-[#bdbdbd] hover:border-slate-900"} relative`}
      ref={props.datePickerRef}
    >
      <div
        className="w-full h-full flex items-center justify-between cursor-pointer"
        onClick={() => props.toggleDailyDatePicker()}
      >
        {value ? <p>{formatDateReadableWithDayOfWeek(formatDate(value))}</p> : props.effectiveDate ? <p className="text-slate-900">Immediately</p> : <p className="text-[#9e9e9e]">Click to select</p>}
        <div className="text-slate-500">
          <CalendarTodayIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
        </div>
      </div>
      {props.showDailyDatePicker && (
        <div className="absolute bottom-10 left-0 bg-white z-10 flex items-center justify-center pl-4 pr-5 border border-[#bdbdbd] rounded-lg shadow-xl overflow-hidden">
          <div className="mt-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={value}
                onChange={handleDateChange}
                minDate={minDate}
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#6d28d9",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      )}
    </div>
  );
}

export default DatePickerDropdown;
