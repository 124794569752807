import React from "react";
import { get_t_str } from "../../../../helpers/helpers";
import TaskNameCard from "./TaskNameCard";
import { getCardColor2 } from "../../../../helpers/helpers";

function ScheduledNameCard2(props) {
  const employee = props.employee;

  const truncateName = (name, maxLength = 20) => {
    return name.length > maxLength ? name.slice(0, maxLength) + "..." : name;
  };

  const roleName =
    props.roleNames.find((role) => role.id === employee.role_id)?.role_name ||
    "";

  const getBorderColor = () => {
    if (!props.snapshot.isDraggingOver) {
      return "border-slate-200 border-dashed";
    }

    const draggingTaskId = props.snapshot.draggingOverWith;
    const draggingTask = props.unassignedTasks.find(
      (task) => task.uuid === draggingTaskId
    );

    if (draggingTask && props.isTaskEligible(draggingTask, employee)) {
      return "border-emerald-600";
    }
    return "border-red-600";
  };

  const cardColor = getCardColor2(employee.role_id, props.roleNames, false);

  // console.log("ASSIGNED TASKS", props.assignedTasks)

  return (
    <div
      key={employee.id}
      className={`border ${
        props.snapshot.isDraggingOver ? "pb-28" : ""
      } ${getBorderColor()} bg-white p-3 rounded-lg overflow-hidden relative w-[300px]`}
    >
      <div className={`${cardColor} px-3 py-2 rounded-lg`}>
        <div className="flex justify-between">
          <p className="text-sm text-white font-medium">
            {truncateName(employee.name)}
          </p>
          <div className="flex items-center space-x-1">
            <p className={`text-xs text-white`}>
              {get_t_str(employee.shift_start)}
            </p>
            <p className="text-xs text-white"> - </p>
            <p className={`text-xs text-white`}>
              {get_t_str(employee.shift_end)}
            </p>
          </div>
        </div>
        <div className="mt-1">
          <p className="text-xs text-white">{roleName}</p>
        </div>
      </div>
      <div>
        {props.assignedTasks.length > 0 &&
          props.assignedTasks.map((task) => (
            <div
              key={task.uuid}
              className="mt-2"
              // onClick={() => props.unassign_plan_task(task.plan_task_assignments?.[0]?.uuid)}
            >
              <TaskNameCard
                task={task}
                cardColor={cardColor}
                unassign_plan_task={props.unassign_plan_task}
                isIndividual={true}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ScheduledNameCard2;
