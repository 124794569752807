import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

export default function BasicRating(props) {
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      {/* <Typography component="legend">Controlled</Typography> */}
      <Rating
        name="simple-controlled"
        value={props.stars}
        onChange={(event, newValue) => {
          props.update_role_proficiency(props.roleId, newValue);
        }}
        style={{ fontSize: "22px" }}
        max={props.proficiencyStarsCount}
        readOnly={props.proficiencyFromLMSEnabled}
      />
    </Box>
  );
}
