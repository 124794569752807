import React, { useState, useEffect } from "react";
import CreateContestDisplay from "./CreateContestDisplay.js";
import ContestDisplay from "./ContestDisplay.js";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  API_get_contests,
  API_get_contest_info,
  API_get_metrics,
  API_save_contest,
  API_delete_contest,
} from "../../../constants";
import axios from "axios";
import ContestDropdown from "./ContestDropdown.js";
import TopBarDisplayStoreOnly from "../home/top_bar_components/TopBarDisplayStoreOnly.js";
import { getDefaultAxiosConfig } from "../../../utilities/requests";

const axiosConfig = getDefaultAxiosConfig();

export default function GoalsDisplay(props) {
  const [contests, setContests] = useState({});
  const [metrics, setMetrics] = useState({});

  const [currentContestId, setCurrentContestId] = useState(null);
  const [contestInfo, setContestInfo] = useState({});

  const [isCreateContest, setIsCreateContest] = React.useState(false);

  const store_id = props.homeStore;

  async function get_data(api, setFunction) {
    const store_access = { store_access: props.storeOptions };
    const res = await axios.put(
      api + store_id + "&contest_id=" + currentContestId,
      store_access,
      axiosConfig
    );
    setFunction(res.data);
    return res;
  }

  useEffect(() => {
    if (currentContestId !== null) {
      get_data(API_get_contest_info, setContestInfo);
    }
  }, [currentContestId]);

  useEffect(() => {
    if (contests.current) {
      if (contests.current.length > 0) {
        setCurrentContestId(contests.current[0]["id"]);
        setSelectedDropdown("Ongoing");
      }
    }
  }, [contests]);

  useEffect(() => {
    if (typeof store_id !== "undefined") {
      get_data(API_get_contests, setContests);
      get_data(API_get_metrics, setMetrics);
    }
  }, [store_id]);

  function saveAsDraft(contest_info, contest_metric_detail) {
    const data = {
      contest_info: contest_info,
      contest_metric_detail: contest_metric_detail,
    };
    axios
      .put(
        API_save_contest + store_id + "&username=" + props.username,
        data,
        axiosConfig
      )
      .then(() => {
        get_data(API_get_contests, setContests);
      });
    //setPushedChanges((prevState) => {
    //  return {
    //    ...prevState,
    //    pushed: false,
    //  };
    //});
  }

  function deleteContest(contest_info, contest_metric_detail) {
    const data = {
      contest_info: contest_info,
      contest_metric_detail: contest_metric_detail,
    };
    const confirm = window.confirm(
      "Are you sure you want to delete this contest?"
    );
    if (confirm) {
      axios
        .delete(
          API_delete_contest + store_id + "&contest_id=" + contest_info["id"],
          axiosConfig
        )
        .then(() => {
          get_data(API_get_contests, setContests);
          setCurrentContestId(null);
          setContestInfo({});
        });
    }
    //setPushedChanges((prevState) => {
    //  return {
    //    ...prevState,
    //    pushed: false,
    //  };
    //});
  }

  function handleCreateContestClick() {
    setCurrentContestId(null);
    setContestInfo({});
    setIsCreateContest((prevState) => !prevState);
  }

  function increment_week(start_date, add_week) {
    const new_start_date = addDays(start_date, add_week * 7);
    set_week_start_date(new_start_date);
  }

  function getMondayOfCurrentWeek() {
    const today = new Date();
    const first = today.getDate() - today.getDay() + 1;

    const monday = new Date(today.setDate(first));
    return monday;
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const [week_start_date, set_week_start_date] = React.useState(
    getMondayOfCurrentWeek()
  );

  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  const week_end_date = addDays(week_start_date, 6);

  const date_options = { month: "short", day: "numeric", year: "numeric" };

  const [selectedDropdown, setSelectedDropdown] = React.useState(null);

  const handleChangeContest = (event) => {
    setCurrentContestId(event.target.value);
    setSelectedDropdown(event.target.name);
    // console.log(event.target.name);
  };

  // console.log(contests);
  // console.log(contestInfo);

  function addUnits(value, units) {
    const value_rounded = Number(value).toFixed(2);
    if (units === "dollars") {
      return "$" + value_rounded;
    } else if (units === "percent") {
      return value_rounded + "%";
    } else {
      return value_rounded;
    }
  }

  return (
    <>
      <div className="w-full px-10 my-7">
        <TopBarDisplayStoreOnly
          storeOptions={props.storeOptions}
          homeStore={props.homeStore}
          storeNames={props.storeNames}
          change_home_store={props.change_home_store}
        />
      </div>
      <div className="w-full min-h-screen pb-44">
        <div className="w-full px-32 flex items-center gap-12">
          <div className="flex items-center gap-8">
            <h1 className="text-4xl font-bold">
              {isCreateContest ? "Create a Contest" : "Contests"}
            </h1>
            {isCreateContest && (
              <div>
                <Box sx={{ width: 250 }}>
                  <FormControl fullWidth size="small">
                    <ContestDropdown
                      type="Drafts"
                      currentContestId={currentContestId}
                      handleChange={handleChangeContest}
                      options={contests.draft}
                      selectedDropdown={selectedDropdown}
                    />
                  </FormControl>
                </Box>
              </div>
            )}
          </div>
          <div>
            <button
              className="px-6 py-2 bg-primary hover:bg-blue-900 rounded-lg text-white"
              onClick={handleCreateContestClick}
            >
              {isCreateContest ? (
                <div className="flex items-center gap-1 pr-1.5">
                  <ArrowBackIcon />
                  <p>Back to Contest View</p>
                </div>
              ) : (
                <div className="flex items-center gap-1 pr-1.5">
                  <AddIcon />
                  <p>Create New Contest</p>
                </div>
              )}
            </button>
          </div>
        </div>
        {isCreateContest ? (
          <CreateContestDisplay
            increment_week={increment_week}
            formatDate={formatDate}
            week_start_date={week_start_date}
            week_end_date={week_end_date}
            date_options={date_options}
            storeOptions={props.storeOptions}
            storeNames={props.storeNames}
            metricOptions={metrics["metrics"]}
            saveAsDraft={saveAsDraft}
            selectedDropdown={selectedDropdown}
            contestInfo={contestInfo}
            deleteContest={deleteContest}
            addUnits={addUnits}
          />
        ) : (
          <ContestDisplay
            increment_week={increment_week}
            formatDate={formatDate}
            week_start_date={week_start_date}
            week_end_date={week_end_date}
            date_options={date_options}
            contests={contests}
            contestInfo={contestInfo}
            setCurrentContestId={setCurrentContestId}
            currentContestId={currentContestId}
            handleChangeContest={handleChangeContest}
            selectedDropdown={selectedDropdown}
            setSelectedDropdown={setSelectedDropdown}
            addUnits={addUnits}
          />
        )}
      </div>
    </>
  );
}
