import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import IconButton from "@mui/material/IconButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function PublishScheduleButton(props) {

  const [scheduleNeedsReview, setScheduleNeedsReview] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.setPublishShiftsFinished(false);
    setButtonPressed(false);
  };
  const [buttonPressed, setButtonPressed] = React.useState(false);

  const handleStart = () => {
    if (props.roleType.length === props.roleTypeOptions.length) {
      props.publishShifts();
    } else {
      props.publishShifts(props.roleType.join(","));
    }
    setButtonPressed(true);
  };


  useEffect(() => {
    if (props.publishShiftsFinished) {
      handleClose();
    }
  }, [props.publishShiftsFinished]);

  const tooltipTitle = props.has7ShiftsIntegration
    ? "Send to 7Shifts"
    : "Publish Schedule";

  const modalDescription = props.has7ShiftsIntegration
    ? "Replace existing shifts in 7shifts with the current schedule."
    : "Notifies your team of their new shifts.";

  const countOfWarnings =
    props.countOfWarnings.countOfConflicts +
    props.countOfWarnings.countOfOvertime;

  function createWarningsText(numScheduleWarnings, countConflicts) {
    let scheduleWarningText = "";
    if (numScheduleWarnings > 0) {
      scheduleWarningText += `${numScheduleWarnings} ${
        numScheduleWarnings === 1 ? "person is" : "people are"
      } in overtime`;
    }

    let availabilityWarningText = "";
    if (countConflicts > 0) {
      availabilityWarningText += `${countConflicts} ${
        countConflicts === 1 ? "person has" : "shifts with"
      } an availability conflict`;
    }
    return [scheduleWarningText, availabilityWarningText];
  }

  const warningsText = createWarningsText(
    props.countOfWarnings.countOfOvertime,
    props.countOfWarnings.countOfConflicts
  );

  // This text will be displayed on the button
  let publishButtonText = "Publish Schedule";
  if (props.countOfPublished > 0) {
    publishButtonText = `Publish Changes`;
  }

  // You can also disable the button if there are no changes to publish
  const isButtonDisabled = props.countOfUnpublished === 0;

  return (
    <div>
      <Tooltip
        title={
          props.countOfUnpublished > 0
            ? "You have changes to publish"
            : "No changes to publish"
        }
      >
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleOpen}
          disabled={isButtonDisabled}
          sx={{
            backgroundColor: "#6d28d9",
            "&:hover": {
              backgroundColor: "#5b21b6",
            },
            textTransform: "none",
            borderRadius: "8px",
          }}
        >
          <span className="lg:hidden">{publishButtonText}</span>
          <span className="hidden lg:block text-sm">Publish</span>
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {scheduleNeedsReview ? (
            <div className="min-h-[280px] pt-2">
              <div className="absolute right-2 top-2">
                <IconButton
                  className="text-gray-400 hover:text-gray-600"
                  onClick={handleClose}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>

              <div className="pb-6 mb-6 border-b border-gray-200">
                <div className="flex items-center mb-2">
                  <DangerousIcon className="text-rose-600 mr-3" />
                  <h2 className="text-xl font-semibold text-gray-900">
                    Schedule Review Required
                  </h2>
                </div>
                <p className="text-gray-600 text-sm">
                  This schedule has a grade that is{" "}
                  <span className="font-medium">below an A</span> or has a forecast
                  adjustment that is{" "}
                  <span className="font-medium">greater than 10%</span>. Please
                  contact a supervisor to have them publish this schedule or make
                  the necessary changes to publish yourself.
                </p>
              </div>

              {countOfWarnings > 0 && (
                <div className="mb-6">
                  <p className="text-gray-500 text-xs mb-2">Other schedule issues</p>
                  <div className="p-4 bg-rose-50 border border-rose-200 rounded-lg">
                    <div className="flex items-start space-x-3">
                      <WarningIcon className="text-rose-600 h-5 w-5 mt-0.5" />
                      <div>
                        <p className="text-rose-700 text-sm font-medium mb-2">
                          {`This schedule has ${countOfWarnings} ${
                            countOfWarnings === 1 ? "issue" : "issues"
                          }. Are you sure you want to publish this schedule?`}
                        </p>
                        <ul className="space-y-1">
                          {warningsText.map((text, index) => (
                            text.length > 0 && (
                              <li key={index} className="text-rose-600 text-sm flex items-center space-x-2">
                                <span className="text-xs">•</span>
                                <span>{text}</span>
                              </li>
                            )
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center space-x-3">
                <button
                  onClick={handleClose}
                  className="px-6 py-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50 transition-colors duration-200 text-sm font-medium"
                >
                  Cancel
                </button>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={buttonPressed}
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                  onClick={() => handleStart()}
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    padding: "10px 16px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                  }}
                >
                  Supervisor Publish
                </LoadingButton>
              </div>
            </div>
          ) : (
            <div className="min-h-[280px] pt-2">
              <div className="absolute right-2 top-2">
                <IconButton
                  className="text-gray-400 hover:text-gray-600"
                  onClick={handleClose}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>

              <div className="pb-6 mb-6 border-b border-gray-200">
                <div className="flex items-center mb-2">
                  <SendIcon className="text-violet-600 mr-3" />
                  <h2 className="text-xl font-semibold text-gray-900">
                    {props.countOfPublished > 0
                      ? `Publish ${props.countOfUnpublished} ${
                          props.countOfUnpublished === 1 ? "Change" : "Changes"
                        }`
                      : `Publish Schedule`}
                  </h2>
                </div>
                <p className="text-gray-600 text-sm">
                  {`You will be publishing ${
                    props.countOfPublished > 0 ? "these changes" : "this schedule"
                  } to ${
                    props.roleType.length === props.roleTypeOptions.length
                      ? "all departments"
                      : props.roleType
                          .map((department, index) =>
                            index > 0
                              ? ` and your ${department} department`
                              : ` your ${department} department`
                          )
                          .join("")
                  }.`}
                </p>
              </div>

              <div className="mb-6">
                <div className="p-4 bg-emerald-50 border border-emerald-200 rounded-lg flex items-center space-x-3">
                  <CheckCircleIcon className="text-emerald-600 h-5 w-5" />
                  <p className="text-emerald-700 text-sm font-medium">
                    {modalDescription}
                  </p>
                </div>
              </div>

              {countOfWarnings > 0 && (
                <div className="mb-6">
                  <div className="p-4 bg-rose-50 border border-rose-200 rounded-lg">
                    <div className="flex items-start space-x-3">
                      <WarningIcon className="text-rose-600 h-5 w-5 mt-0.5" />
                      <div>
                        <p className="text-rose-700 text-sm font-medium mb-2">
                          {`This schedule has ${countOfWarnings} ${
                            countOfWarnings === 1 ? "issue" : "issues"
                          }. Are you sure you want to publish this schedule?`}
                        </p>
                        <ul className="space-y-1">
                          {warningsText.map((text, index) => (
                            text.length > 0 && (
                              <li key={index} className="text-rose-600 text-sm flex items-center space-x-2">
                                <span className="text-xs">•</span>
                                <span>{text}</span>
                              </li>
                            )
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center space-x-3">
                <button
                  onClick={handleClose}
                  className="px-6 py-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50 transition-colors duration-200 text-sm font-medium"
                >
                  Cancel
                </button>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={buttonPressed}
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                  onClick={() => handleStart()}
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    padding: "10px 16px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                  }}
                >
                  {props.countOfPublished > 0
                    ? countOfWarnings > 0
                      ? "Publish Anyway"
                      : "Publish Changes"
                    : countOfWarnings > 0
                    ? "Publish Anyway"
                    : "Publish Schedule"}
                </LoadingButton>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default PublishScheduleButton;
