import React from "react";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { formatDateReadable } from "../../utilities/helpers";
import { Drawer } from "@mui/material";
import DrawerHeader from "../../shared/helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../../shared/helpers/DrawerFooterContainerSmall";
import ButtonFilled from "../../shared/helpers/ButtonFilled";
import { PurpleSwitch } from "../../../../../helpers/helpers";
import DatePickerDropdown from "../../shared/helpers/DatePickerDropdown";
import { text_to_num } from "../../utilities/helpers";
import { formatDate } from "../../utilities/helpers";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextField from "@mui/material/TextField";
import { format_time_num } from "../../utilities/helpers";

export default function EmployeeProfileAvailabilityCard(props) {
  const [expandCard, setExpandCard] = React.useState(
    () =>
      props.dow?.requested &&
      props.dow?.requested?.some((request) => request.status === "approved")
  );

  function toggleExpandCard() {
    setExpandCard((prevState) => !prevState);
  }

  React.useEffect(() => {
    setExpandCard(
      props.dow?.requested &&
        props.dow?.requested?.some((request) => request.status === "approved")
    );
  }, [props.dow]);

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // const [date, setDate] = React.useState(formatDate(props.minEffectiveDate));
  const [date, setDate] = React.useState(formatDate(new Date()));

  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");

  const [startTime2, setStartTime2] = React.useState("");
  const [endTime2, setEndTime2] = React.useState("");

  const [enterSplitAvailability, setEnterSplitAvailability] =
    React.useState(false);

  function handleSplitAvailabilityChecked(e) {
    const { checked } = e.target;
    setEnterSplitAvailability(checked);
  }

  const [preferredFrom, setPreferredFrom] = React.useState("");
  const [preferredTo, setPreferredTo] = React.useState("");

  const [validationError, setValidationError] = React.useState("");

  const [selectedAvailability, setSelectedAvailability] = React.useState("");

  const [preferencesChecked, setPreferencesChecked] = React.useState(false);

  const handlePreferencesChange = (event) => {
    setPreferencesChecked(event.target.checked);
  };

  function resetState() {
    setDate(formatDate(new Date()));
    setStartTime("");
    setEndTime("");
    setStartTime2("");
    setEndTime2("");
    setEnterSplitAvailability(false);
    setPreferredFrom("");
    setPreferredTo("");
    setValidationError("");
    setSelectedAvailability("");
    setPreferencesChecked(false);
  }

  React.useEffect(() => {
    resetState();
  }, [isDrawerOpen]);

  React.useEffect(() => {
    setValidationError("");
  }, [
    props.isModalVisible,
    startTime,
    startTime2,
    endTime,
    endTime2,
    preferredFrom,
    preferredTo,
    selectedAvailability,
    enterSplitAvailability,
    preferencesChecked,
  ]);

  const handleSubmit = () => {
    let days = [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
      "Saturdays",
      "Sundays",
    ];

    if (selectedAvailability === "Custom") {
      if (!startTime || !endTime) {
        setValidationError(
          "Both start and end times are required for custom availability."
        );
        return;
      }

      const startTimeNum = text_to_num(startTime);
      const endTimeNum = text_to_num(endTime);

      if (startTimeNum >= endTimeNum) {
        setValidationError("Start time must be before end time.");
        return;
      }

      let availableRanges = [[startTimeNum, endTimeNum]];

      if (enterSplitAvailability) {
        if (!startTime2 || !endTime2) {
          setValidationError(
            "Both start and end times are required for split availability."
          );
          return;
        }

        const startTime2Num = text_to_num(startTime2);
        const endTime2Num = text_to_num(endTime2);

        if (startTime2Num >= endTime2Num) {
          setValidationError(
            "Second start time must be before second end time."
          );
          return;
        }

        if (endTimeNum >= startTime2Num) {
          setValidationError(
            "Split availability must be later than the first availability range."
          );
          return;
        }

        availableRanges.push([startTime2Num, endTime2Num]);
      }

      if (preferencesChecked) {
        if (!preferredFrom || !preferredTo) {
          setValidationError(
            "Both Preferred From and Preferred To are required when preferences are checked."
          );
          return;
        }

        const preferredFromNum = text_to_num(preferredFrom);
        const preferredToNum = text_to_num(preferredTo);

        if (preferredFromNum >= preferredToNum) {
          setValidationError("Preferred From must be before Preferred To.");
          return;
        }

        // Check if preferred times are within any of the availability ranges
        const isPreferredWithinAvailable = availableRanges.some(
          ([start, end]) => preferredFromNum >= start && preferredToNum <= end
        );

        if (!isPreferredWithinAvailable) {
          setValidationError(
            "Preferred times must be within the custom availability range."
          );
          return;
        }
      }
    }

    const data = {
      dow: days.indexOf(props.dow.day) + 1,
      available_from:
        selectedAvailability === "Custom" ? text_to_num(startTime) : 0,
      available_to:
        selectedAvailability === "Custom"
          ? text_to_num(endTime)
          : selectedAvailability === "All Day"
          ? 24
          : 0,
      available_from_2:
        selectedAvailability === "Custom" && enterSplitAvailability
          ? text_to_num(startTime2)
          : null,
      available_to_2:
        selectedAvailability === "Custom" && enterSplitAvailability
          ? text_to_num(endTime2)
          : null,
      preferred_from: preferredFrom ? text_to_num(preferredFrom) : 0,
      preferred_to: preferredTo ? text_to_num(preferredTo) : 0,
      note: "",
      activated_at: date,
      stm_id: props.selectedTeamMemberData?.id,
    };
    // console.log("DATA", data);
    props.create_availability_request(data);
    setDrawerOpen(false);
  };

  // For the effective date datepicker
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDatePicker() {
    setShowDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]); // Re-run the effect when 'showDatePicker' changes

  return (
    <>
      <div className="px-4 py-2.5 border border-slate-200 rounded-lg mt-3">
        <div className="flex justify-between">
          <p className="text-xs text-slate-500 mt-1 w-24">{props.dow.day}</p>
          <div className="w-44 cursor-pointer" onClick={toggleExpandCard}>
            <div className="mt-0.5 flex items-center space-x-1">
              <p className="text-sm">
                {props.dow.availability
                  ? props.dow.availability
                  : "No Availability Set"}
              </p>
              {expandCard ? (
                <div className="text-slate-500">
                  <ExpandLessIcon
                    style={{ fontSize: "16px", marginBottom: "1px" }}
                  />
                </div>
              ) : (
                <div className="text-slate-500">
                  <ExpandMoreIcon
                    style={{ fontSize: "16px", marginBottom: "1px" }}
                  />
                </div>
              )}
            </div>
            {props.dow.availability2 && (
              <div className="mt-0.5">
                <p className="text-sm">{props.dow.availability2}</p>
              </div>
            )}
          </div>
          <div
            className="text-slate-500 hover:text-slate-900 h-fit cursor-pointer"
            onClick={toggleDrawer(true)}
          >
            <AddIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
          </div>
        </div>
        {expandCard && (
          <div>
            <div className="mt-3">
              <div className="w-full bg-slate-100 rounded-lg px-4 py-3 relative">
                <div className="absolute right-3 top-2">
                  <p className="text-xs text-slate-500">current</p>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="text-slate-600">
                    <ScheduleIcon
                      style={{ fontSize: "16px", marginBottom: "4px" }}
                    />
                  </div>
                  <p className="text-sm">
                    {props.dow.availability
                      ? props.dow.availability
                      : "No Availability Set"}
                  </p>
                </div>
                {props.dow.availability2 && (
                  <div className="flex items-center space-x-3 mt-1">
                    <div className="text-slate-500">
                      <ScheduleIcon
                        style={{ fontSize: "16px", marginBottom: "4px" }}
                      />
                    </div>
                    <p className="text-sm mt-0.5">{props.dow.availability2}</p>
                  </div>
                )}
                <div className="flex items-center space-x-3 mt-1">
                  <div className="text-slate-500">
                    <StarBorderIcon
                      style={{ fontSize: "16px", marginBottom: "4px" }}
                    />
                  </div>
                  <p className="text-sm mt-0.5">
                    {props.dow.preferences
                      ? props.dow.preferences
                      : "No Preferences Set"}
                  </p>
                </div>
              </div>
            </div>
            {props.dow.requested &&
              props.dow.requested.map((request, index) => (
                <div key={index}>
                  {request.status === "approved" && (
                    <div className="mt-1.5">
                      <div className="w-full bg-slate-100 rounded-lg px-4 py-3 relative">
                        <div className="absolute right-3 top-2">
                          <p className="text-xs text-slate-500">upcoming</p>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="text-slate-500">
                            <ScheduleIcon
                              style={{ fontSize: "16px", marginBottom: "4px" }}
                            />
                          </div>
                          <p className="text-sm">{request.availability}</p>
                        </div>
                        {request.availability2 && (
                          <div className="flex items-center space-x-3 mt-1">
                            <div className="text-slate-500">
                              <ScheduleIcon
                                style={{
                                  fontSize: "16px",
                                  marginBottom: "4px",
                                }}
                              />
                            </div>
                            <p className="text-sm mt-0.5">
                              {request.availability2}
                            </p>
                          </div>
                        )}
                        <div className="flex items-center space-x-3 mt-1">
                          <div className="text-slate-500">
                            <StarBorderIcon
                              style={{ fontSize: "16px", marginBottom: "4px" }}
                            />
                          </div>
                          <p className="text-sm mt-0.5">
                            {request.preferences
                              ? request.preferences
                              : "No Preferences Set"}
                          </p>
                        </div>
                        <div className="flex items-center space-x-3 mt-1">
                          <div className="text-slate-500">
                            <CalendarMonthIcon
                              style={{ fontSize: "16px", marginBottom: "4px" }}
                            />
                          </div>
                          <p className="text-sm mt-0.5">
                            {formatDateReadable(request.inEffect)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-screen bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={`${props.dow.day} Availability`}
              onClose={toggleDrawer(false)}
            />
            <div className="px-6">
              <h3 className="text-slate-900 text-base">Default Availability</h3>
              <p className="text-slate-500 mt-2">
                Set {props.selectedTeamMemberData?.first_name}'s default
                availability for {props.dow.day}
              </p>
              <div className="mt-3 pb-2 border-b border-slate-200">
                <div className="bg-white border border-slate-200 rounded-2xl overflow-hidden">
                  <div
                    className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("Unavailable")}
                  >
                    <p className="text-slate-900 text-sm">Unavailable</p>
                    {selectedAvailability === "Unavailable" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                  <div
                    className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("All Day")}
                  >
                    <p className="text-slate-900 text-sm">All Day</p>
                    {selectedAvailability === "All Day" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                  <div
                    className="px-4 h-14 hover:bg-violet-50 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("Custom")}
                  >
                    <p className="text-slate-900 text-sm">Custom</p>
                    {selectedAvailability === "Custom" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  {selectedAvailability === "Custom" && (
                    <div className="w-full flex items-center justify-between mb-6">
                      <div>
                        <div>
                          <p className="text-slate-500">
                            Set custom availability for {props.dow.day}
                          </p>
                        </div>
                        <div className="mt-3 flex items-center relative bg-slate-100 rounded-2xl p-2">
                          <div className="w-[42%]">
                            <TextField
                              placeholder="Start Time"
                              size="small"
                              InputProps={{
                                sx: {
                                  backgroundColor: "white",
                                  borderRadius: "12px",
                                  paddingY: "5px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                },
                              }}
                              value={startTime}
                              onChange={(e) => setStartTime(e.target.value)}
                            />
                            {startTime && (
                              <p className="absolute -bottom-6 text-sm">
                                {format_time_num(text_to_num(startTime))}
                              </p>
                            )}
                          </div>
                          <div className="text-slate-500 w-[16%] flex items-center justify-center">
                            <ArrowForwardIcon />
                          </div>
                          <div className="w-[42%]">
                            <TextField
                              placeholder="End Time"
                              size="small"
                              InputProps={{
                                sx: {
                                  backgroundColor: "white",
                                  borderRadius: "12px",
                                  paddingY: "5px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                },
                              }}
                              value={endTime}
                              onChange={(e) => setEndTime(e.target.value)}
                            />
                            {endTime && (
                              <p className="absolute -bottom-6 text-sm">
                                {format_time_num(text_to_num(endTime))}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mt-5 flex items-center">
                          <PurpleSwitch
                            id="split-availability"
                            name="split_availability"
                            checked={enterSplitAvailability}
                            onChange={handleSplitAvailabilityChecked}
                          />
                          <p className="text-slate-500 text-sm">
                            Split availability
                          </p>
                        </div>
                        {enterSplitAvailability && (
                          <div className="mt-2 flex items-center relative bg-slate-100 rounded-2xl p-2">
                            <div className="w-[42%]">
                              <TextField
                                placeholder="Start Time"
                                size="small"
                                InputProps={{
                                  sx: {
                                    backgroundColor: "white",
                                    borderRadius: "12px",
                                    paddingY: "5px",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6d28d9",
                                      },
                                  },
                                }}
                                value={startTime2}
                                onChange={(e) => setStartTime2(e.target.value)}
                              />
                              {startTime2 && (
                                <p className="absolute -bottom-6 text-sm">
                                  {format_time_num(text_to_num(startTime2))}
                                </p>
                              )}
                            </div>
                            <div className="text-slate-500 w-[16%] flex items-center justify-center">
                              <ArrowForwardIcon />
                            </div>
                            <div className="w-[42%]">
                              <TextField
                                placeholder="End Time"
                                size="small"
                                InputProps={{
                                  sx: {
                                    backgroundColor: "white",
                                    borderRadius: "12px",
                                    paddingY: "5px",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6d28d9",
                                      },
                                  },
                                }}
                                value={endTime2}
                                onChange={(e) => setEndTime2(e.target.value)}
                              />
                              {endTime2 && (
                                <p className="absolute -bottom-6 text-sm">
                                  {format_time_num(text_to_num(endTime2))}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {selectedAvailability !== "Unavailable" && (
              <div className="px-6">
                <div className="pt-6 pb-6 border-b border-slate-200">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <h3 className="text-slate-900 text-base">Preferences</h3>
                      <p className="text-slate-500 mt-2">
                        Set {props.selectedTeamMemberData?.first_name}'s
                        preferences (optional)
                      </p>
                    </div>
                    <PurpleSwitch
                      checked={preferencesChecked}
                      onChange={handlePreferencesChange}
                    />
                  </div>
                  {preferencesChecked && (
                    <div className="mt-3 flex items-center justify-between relative mb-2 bg-slate-100 p-2 rounded-2xl">
                      <div className="w-[42%]">
                        <TextField
                          placeholder="Preferred From"
                          size="small"
                          InputProps={{
                            sx: {
                              backgroundColor: "white",
                              borderRadius: "12px",
                              paddingY: "5px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                            },
                          }}
                          value={preferredFrom}
                          onChange={(e) => setPreferredFrom(e.target.value)}
                        />
                        {preferredFrom && (
                          <p className="absolute -bottom-6 text-sm">
                            {format_time_num(text_to_num(preferredFrom))}
                          </p>
                        )}
                      </div>
                      <div className="text-slate-500 w-[16%] flex items-center justify-center">
                        <ArrowForwardIcon />
                      </div>
                      <div className="w-[42%]">
                        <TextField
                          placeholder="Preferred To"
                          size="small"
                          InputProps={{
                            sx: {
                              backgroundColor: "white",
                              borderRadius: "12px",
                              paddingY: "5px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                            },
                          }}
                          value={preferredTo}
                          onChange={(e) => setPreferredTo(e.target.value)}
                        />
                        {preferredTo && (
                          <p className="absolute -bottom-6 text-sm">
                            {format_time_num(text_to_num(preferredTo))}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {validationError && (
                  <div className="pt-5">
                    <p className="text-rose-600 text-sm">{validationError}</p>
                  </div>
                )}
              </div>
            )}
            <div className="px-6">
              <div className="py-5 border-b border-slate-200">
                <div>
                  <h3 className="text-slate-900 text-base">Effective Date</h3>
                  <p className="text-slate-500 mt-2">
                    When should this availability go into effect?
                  </p>
                </div>
                <div className="w-full mt-3">
                  <div className="p-2 bg-slate-100 rounded-2xl">
                    <DatePickerDropdown
                      showDailyDatePicker={showDatePicker}
                      toggleDailyDatePicker={toggleDatePicker}
                      datePickerRef={datePickerRef}
                      setDate={setDate}
                      effectiveDate={true}
                      minEffectiveDate={props.minEffectiveDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[150px]"></div>
          </div>
          <DrawerFooterContainerSmall>
            <ButtonFilled
              text="Add Availability"
              onClick={() => handleSubmit()}
            />
          </DrawerFooterContainerSmall>
        </div>
      </Drawer>
    </>
  );
}
