import React from "react";
import TimeClockHeader from "./TimeClockHeader";
import { PurpleSwitch } from "../../../helpers/helpers";
import { get_t_str } from "../../../helpers/helpers";
import { text_to_num } from "../../../helpers/helpers";
import { formatDateReadableWithDayOfWeek } from "../requests/utilities/helpers";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatDate } from "../requests/utilities/helpers";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  API_get_timecard_config,
  API_post_timecard_config,
} from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../utilities/LoadSpinner";

function TimeEntriesSettings(props) {
  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  function handleDateChange(newDate) {
    setDateValue(newDate);
    setShowDailyDatePicker(false); // Optionally close the date picker after selection
    // You can perform any other actions here based on the selected date
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]); // Re-run the effect when 'showDailyDatePicker' changes

  const [dateValue, setDateValue] = React.useState(dayjs(new Date()));

  const [isLoading, setIsLoading] = React.useState(false);

  const [settingsData, setSettingsData] = React.useState({});

  const fetchTimecardConfig = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = { store_id: props.homeStore };
    let api = API_get_timecard_config;
    try {
      const res = await getRightworkAPI(api, api_params);
      // console.log(res);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.timecard_config;
        setSettingsData(data);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    fetchTimecardConfig(true);
  }, []);

  // console.log("SETTINGS DATA", settingsData)

  const handleRequireShift = (event) => {
    setSettingsData((prevSettings) => ({
      ...prevSettings,
      clock_in_require_shift_on_day: event.target.checked,
      clock_in_require_shift_on_day_in_bounds: false,
    }));
  };

  const handlePreventEarlyClockIn = (event) => {
    setSettingsData((prevSettings) => ({
      ...prevSettings,
      clock_in_require_shift_on_day_in_bounds: event.target.checked,
    }));
  };

  const handleClockInEarlyPeriod = (event) => {
    setSettingsData((prevSettings) => ({
      ...prevSettings,
      clock_in_forgiveness_lower_bound: event.target.value,
    }));
  };

  const post_timecard_config = async () => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      clock_in_forgiveness_lower_bound:
        settingsData.clock_in_forgiveness_lower_bound,
      clock_in_forgiveness_upper_bound:
        settingsData.clock_in_forgiveness_upper_bound,
      clock_in_require_shift_on_day: settingsData.clock_in_require_shift_on_day,
      clock_in_require_shift_on_day_in_bounds:
        settingsData.clock_in_require_shift_on_day_in_bounds,
      clock_out_forgiveness_lower_bound:
        settingsData.clock_out_forgiveness_lower_bound,
      clock_out_forgiveness_upper_bound:
        settingsData.clock_out_forgiveness_upper_bound,
      uuid: settingsData.uuid,
    };

    try {
      toast.loading("Saving Timecard Settings...", { autoClose: false });

      const res = await postRightworkAPI(API_post_timecard_config, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Timecard Settings Successfully", { autoClose: 3000 });

        fetchTimecardConfig(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  return (
    <div>
      <div>
        <TimeClockHeader
          title={props.title}
          storeOptions={props.storeOptions}
          homeStore={props.homeStore}
          storeNames={props.storeNames}
          change_home_store={props.change_home_store}
          setCurrentTab={props.setCurrentTab}
          setShowSettings={props.setShowSettings}
          showSettings={props.showSettings}
        />
      </div>
      {!isLoading ? (
        <div className="px-6 mt-8">
          <div className="w-full flex items-center justify-between">
            <div>
              <h1 className="text-3xl text-slate-900">Settings</h1>
              <h3 className="mt-1 text-slate-500">
                Configure how you want pay periods, time entries, and time clock
                to operate
              </h3>
            </div>
            <div
              className="px-6 py-2 bg-violet-700 hover:bg-violet-800 text-white rounded-lg cursor-pointer"
              onClick={() => post_timecard_config()}
            >
              <p>Save Settings</p>
            </div>
          </div>
          <div className="my-10 py-6 border border-slate-200 rounded-xl">
            {/* <div className="flex px-6 pb-6 border-b border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Pay Period</p>
              </div>
              <div>
                <div>
                  <p className="text-slate-500 text-sm">Start date</p>
                  <div className={`${!showDailyDatePicker ? "border border-white" : ""} mt-0.5 w-[220px]`}>
                    <div
                      className={`flex-1 bg-white rounded-lg text-sm ${showDailyDatePicker ? "border-2 border-violet-700" : "border border-[#bdbdbd] hover:border-slate-900"} cursor-pointer relative`}
                      ref={datePickerRef}
                    >
                      <div
                        className="w-full h-full px-4 py-2"
                        onClick={toggleDailyDatePicker}
                      >
                        <div
                          className="w-full h-full flex items-center justify-between"
                        >
                          <p className="text-slate-900 text-[16px]">{formatDateReadableWithDayOfWeek(formatDate(dateValue))}</p>
                          <div className="text-slate-500">
                            <CalendarTodayIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                          </div>
                        </div>
                      </div>
                      {showDailyDatePicker && (
                        <div className="absolute top-10 left-0 bg-white z-10 flex items-center justify-center pl-4 pr-5 border border-[#bdbdbd] rounded-lg shadow-xl overflow-hidden">
                          <div className="mt-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                <DemoItem>
                                  <DateCalendar
                                    value={dateValue}
                                    onChange={handleDateChange}
                                    slotProps={{
                                      day: {
                                        sx: {
                                          "&.MuiPickersDay-root.Mui-selected": {
                                            backgroundColor: "#6d28d9",
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </DemoItem>
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <p className="text-slate-500 text-sm">How long is your pay period?</p>
                  <div className="mt-1 w-[220px]">
                    <Select
                      fullWidth
                      // value={modalData.department?.role_type}
                      name="pay_period_length"
                      // onChange={handleDepartmentChange}
                      size="small"
                      displayEmpty
                      sx={{
                        borderRadius: "8px",
                        paddingY: "1px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                        height: "40px",
                        backgroundColor: "white",
                      }}
                    >
                      <MenuItem>1 Week</MenuItem>
                      <MenuItem>2 Weeks</MenuItem>
                      <MenuItem>3 Weeks</MenuItem>
                      <MenuItem>4 Weeks</MenuItem>
                      <MenuItem>5 Weeks</MenuItem>
                      <MenuItem>6 Weeks</MenuItem>
                      <MenuItem>7 Weeks</MenuItem>
                      <MenuItem>8 Weeks</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="flex px-6 py-6 border-b border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Grace Period</p>
              </div>
              <div>
                <p className="text-slate-500 text-sm">How long is your grace period?</p>
                <div className="flex items-center space-x-2 mt-1">
                  <div className="w-[220px]">
                    <Select
                      fullWidth
                      // value={modalData.department?.role_type}
                      name="pay_period_length"
                      // onChange={handleDepartmentChange}
                      size="small"
                      displayEmpty
                      sx={{
                        borderRadius: "8px",
                        paddingY: "1px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                        height: "40px",
                        backgroundColor: "white",
                      }}
                    >
                      <MenuItem>1 Minute</MenuItem>
                      <MenuItem>2 Mintues</MenuItem>
                      <MenuItem>3 Minutes</MenuItem>
                      <MenuItem>4 Minutes</MenuItem>
                      <MenuItem>5 Minutes</MenuItem>
                      <MenuItem>6 Minutes</MenuItem>
                      <MenuItem>7 Minutes</MenuItem>
                      <MenuItem>8 Minutes</MenuItem>
                      <MenuItem>9 Minutes</MenuItem>
                      <MenuItem>10 Minutes</MenuItem>
                      <MenuItem>12 Minutes</MenuItem>
                      <MenuItem>15 Minutes</MenuItem>
                      <MenuItem>20 Minutes</MenuItem>
                      <MenuItem>25 Minutes</MenuItem>
                      <MenuItem>30 Minutes</MenuItem>
                      <MenuItem>35 Minutes</MenuItem>
                      <MenuItem>40 Minutes</MenuItem>
                      <MenuItem>45 Minutes</MenuItem>
                      <MenuItem>50 Minutes</MenuItem>
                      <MenuItem>55 Minutes</MenuItem>
                      <MenuItem>60 Minutes</MenuItem>
                    </Select>
                  </div>
                  <div className="text-slate-500">
                    <Tooltip title="Minutes before/after a shift starts that an employee can clock in and minutes before/after a shift ends that an employee can clock out. If the employee falls within these bounds and auto-approve is enabled, the punch is automatically approved. If the employee falls outside these bounds and auto-flag is enabled, the punch is automatically flagged.">
                      <HelpOutlineOutlinedIcon />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="flex px-6 py-6 border-b border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Rounding</p>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Rounding for early clock-in</p>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Rounding for late clock-in</p>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Rounding for late clock-out</p>
                </div>
              </div>
            </div> */}
            {/* <div className="flex px-6 py-6 border-b border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Clock-In</p>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Prevent early clock-in</p>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Allow clock-in without a shift</p>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Rounding for early clock-in</p>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Rounding for late clock-in</p>
                </div>
              </div>
            </div>
            <div className="flex px-6 py-6 border-b border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Clock-Out</p>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Auto clock-out</p>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Allow clock-in without a shift</p>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Rounding for late clock-out</p>
                </div>
              </div>
            </div> */}
            {/* <div className="flex px-6 py-6 border-b border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Breaks</p>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Allow breaks</p>
                </div>
              </div>
            </div> */}
            {/* <div className="flex px-6 pt-6">
              <div className="w-[35%]">
                <p className="font-bold">Auto-Approval and Auto-Flagging</p>
              </div>
              <div>
                <div className="flex items-center space-x-2 -ml-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p className="text-slate-500 text-sm w-36">Enable auto-approval</p>
                  <div className="text-slate-500">
                    <Tooltip title="When enabled, shifts that fall within the bounds of the grace period are automatically approved. When not enabled, all completed shifts inside the grace period will be pending.">
                      <HelpOutlineOutlinedIcon />
                    </Tooltip>
                  </div>
                </div>
                <div className="flex items-center space-x-2 mt-5 -ml-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p className="text-slate-500 text-sm w-36">Enable auto-flagging</p>
                  <div className="text-slate-500">
                    <Tooltip title="When enabled, shifts that fall outside the bounds of the grace period are automatically flagged. When not enabled, all completed shifts outside of the grace period will be pending.">
                      <HelpOutlineOutlinedIcon />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex px-6">
              <div className="w-[35%]">
                <p className="font-bold">Employee Clock-Ins</p>
              </div>
              <div>
                <div className="flex items-center space-x-2 -ml-2">
                  <div>
                    <PurpleSwitch
                      checked={
                        settingsData.clock_in_require_shift_on_day
                          ? settingsData.clock_in_require_shift_on_day
                          : false
                      }
                      onChange={handleRequireShift}
                    />
                  </div>
                  <p className="text-slate-500 text-sm w-44">
                    Require shift to clock-in
                  </p>
                  <div className="text-slate-500">
                    <Tooltip title="When enabled, employees will not be able to clock-in when they don't have a shift scheduled for that day.">
                      <HelpOutlineOutlinedIcon />
                    </Tooltip>
                  </div>
                </div>
                {settingsData.clock_in_require_shift_on_day && (
                  <div className="flex items-center space-x-2 mt-5 -ml-2">
                    <div>
                      <PurpleSwitch
                        checked={
                          settingsData.clock_in_require_shift_on_day_in_bounds
                            ? settingsData.clock_in_require_shift_on_day_in_bounds
                            : false
                        }
                        onChange={handlePreventEarlyClockIn}
                      />
                    </div>
                    <p className="text-slate-500 text-sm w-44">
                      Prevent early clock-ins
                    </p>
                    <div className="text-slate-500">
                      <Tooltip title="When enabled, employees will restricted on how early they can clock in.">
                        <HelpOutlineOutlinedIcon />
                      </Tooltip>
                    </div>
                  </div>
                )}
                {settingsData.clock_in_require_shift_on_day_in_bounds && (
                  <div className="mt-5">
                    <p className="text-slate-500 text-sm">
                      How early before an employee's shift start can they clock
                      in?
                    </p>
                    <div className="flex items-center space-x-2 mt-1">
                      <div className="w-[220px]">
                        <Select
                          fullWidth
                          value={settingsData.clock_in_forgiveness_lower_bound}
                          name="clock_in_early_period"
                          onChange={handleClockInEarlyPeriod}
                          size="small"
                          displayEmpty
                          sx={{
                            borderRadius: "8px",
                            paddingY: "1px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                            height: "40px",
                            backgroundColor: "white",
                          }}
                        >
                          <MenuItem value={0}>0 Minutes</MenuItem>
                          <MenuItem value={1}>1 Minute</MenuItem>
                          <MenuItem value={2}>2 Minutes</MenuItem>
                          <MenuItem value={3}>3 Minutes</MenuItem>
                          <MenuItem value={4}>4 Minutes</MenuItem>
                          <MenuItem value={5}>5 Minutes</MenuItem>
                          <MenuItem value={10}>10 Minutes</MenuItem>
                          <MenuItem value={15}>15 Minutes</MenuItem>
                          <MenuItem value={20}>20 Minutes</MenuItem>
                          <MenuItem value={30}>30 Minutes</MenuItem>
                          <MenuItem value={40}>40 Minutes</MenuItem>
                          <MenuItem value={50}>50 Minutes</MenuItem>
                          <MenuItem value={60}>60 Minutes</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="flex px-6 py-6 border-b border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Employee timesheet access</p>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Allow employees to view their own timesheets</p>
                </div>
              </div>
            </div> */}
            {/* <div className="flex px-6 pt-6 border-slate-200">
              <div className="w-[35%]">
                <p className="font-bold">Declare tips</p>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>
                    <PurpleSwitch />
                  </div>
                  <p>Allow employees to declare tips</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </div>
  );
}

export default TimeEntriesSettings;
