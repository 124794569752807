import React from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ImageIcon from "@mui/icons-material/Image";
import { getInitials } from "../../utilities/helpers";
import { formatTimestamp } from "../../../../../helpers/helpers";
import { formatTimestampWithDate } from "../../../../../helpers/helpers";

function EmployeeProfileNoteEntry(props) {
  // console.log("NOTE ENTRY", props.entry)

  return (
    <div className="w-full flex items-center space-x-3">
      <div className="w-full flex space-x-3">
        <div className="w-9 h-9 rounded-full bg-slate-100 text-slate-500 text-sm flex items-center justify-center mt-0.5">
          <p>{getInitials(props.entry.author_stm.full_name)}</p>
        </div>
        <div className="flex-1">
          <p className="text-slate-900">{props.entry.value_text}</p>
          <p className="text-slate-500 text-sm mt-1">
            {props.entry.author_stm.full_name} @{" "}
            {formatTimestampWithDate(props.entry.created_at)}
          </p>
          {props.entry?.file_uploads?.length > 0 && (
            <div
              className="text-violet-700 hover:text-violet-900 text-sm mt-1 w-full cursor-pointer"
              onClick={() =>
                props.get_file_upload(props.entry?.file_uploads?.[0])
              }
            >
              <span>
                <ImageIcon style={{ fontSize: "18px" }} />
              </span>{" "}
              1 Image Attached
            </div>
          )}
        </div>
      </div>
      <div
        className="text-slate-500 cursor-pointer"
        onClick={() =>
          props.delete_logbook_employee_entry(props.stm_id, props.entry.uuid)
        }
      >
        <DeleteOutlinedIcon style={{ fontSize: "24px" }} />
      </div>
    </div>
  );
}

export default EmployeeProfileNoteEntry;
