import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Select from '@mui/material/Select';
import LoadSpinner from "../../../../../utilities/LoadSpinner";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateReadable } from "../../../requests/utilities/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function LoadFromTemplateButton(props) {

  const [state, setState] = React.useState({ template_name: "" });
  const [open, setOpen] = React.useState(false);
  const [buttonPressed, setButtonPressed] = React.useState(false);
  const [secondLoadingMessage, setSecondLoadingMessage] = React.useState(false);
  const [thirdLoadingMessage, setThirdLoadingMessage] = React.useState(false);
  const [fourthLoadingMessage, setFourthLoadingMessage] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleStart = () => {
    props.loadFromTemplate(state.template_name);
    setButtonPressed(true);
    setTimeout(() => {
      setSecondLoadingMessage(true);
    }, 3000);
    setTimeout(() => {
      setThirdLoadingMessage(true);
    }, 6000);
  };

  const completeOnFinish = (loading_finished) => {
    if (loading_finished) {
      setSecondLoadingMessage(true);
      setThirdLoadingMessage(true);
      setFourthLoadingMessage(true);
      setButtonPressed(false);
      handleClose();
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }
  const handleClose = () => {
    setState({ template_name: "" });
    setOpen(false);
    setButtonPressed(false);
    setSecondLoadingMessage(false);
    setThirdLoadingMessage(false);
    setFourthLoadingMessage(false);
    props.setLoadFromTemplateFinished(false);
  };

  const handleDelete = () => {
    setState({ template_name: "" });
    setOpen(false);
    props.deleteScheduleTemplate(state.template_name);
  };

  useEffect(() => {
    completeOnFinish(props.loadFromTemplateFinished);
  }, [props.loadFromTemplateFinished]);

  return (
    <div>
      <div>
        {props.week_already_started || props.template_names.length === 0 ? (
          <Tooltip
            title={props.week_already_started
              ? "You cannot load in a template once the week has already started. Go to a future week to use this feature."
              : "You have no templates to load in"}
          >
            <div className="text-gray-300 w-10 h-10 flex items-center justify-center">
              <FolderSpecialIcon />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Load from template">
            <IconButton
              onClick={handleOpen}
              disabled={props.week_already_started || props.template_names.length === 0}
            >
              <FolderSpecialIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {buttonPressed ? (
            <div className="min-h-[280px] pt-2">
              <div className="pb-6 mb-6 border-b border-gray-200">
                <div className="flex items-center mb-2">
                  <FolderSpecialIcon className="text-violet-600 mr-3" />
                  <h2 className="text-xl font-semibold text-gray-900">
                    Loading from {state.template_name}
                  </h2>
                </div>
                <p className="text-gray-600 text-sm">
                  This can take a couple minutes. Thank you for being patient! Please don't click outside of this modal.
                </p>
              </div>

              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  {secondLoadingMessage ? (
                    <CheckCircleIcon className="text-emerald-600 h-6 w-6" />
                  ) : (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-violet-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  <p className={`text-sm ${secondLoadingMessage ? 'text-emerald-700 font-medium' : 'text-gray-700'}`}>
                    {secondLoadingMessage ? 'Loaded Schedule Successfully' : 'Loading Schedule...'}
                  </p>
                </div>

                {secondLoadingMessage && !thirdLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-violet-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                    <p className="text-sm text-gray-700">Evaluating Schedule...</p>
                  </div>
                )}

                {thirdLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <CheckCircleIcon className="text-emerald-600 h-6 w-6" />
                    <p className="text-sm text-emerald-700 font-medium">Evaluated Schedule Successfully</p>
                  </div>
                )}

                {thirdLoadingMessage && !fourthLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-violet-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                    <p className="text-sm text-gray-700">Finishing Final Touches...</p>
                  </div>
                )}

                {fourthLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <CheckCircleIcon className="text-emerald-600 h-6 w-6" />
                    <p className="text-sm text-emerald-700 font-medium">Finished Successfully</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="absolute right-2 top-2">
                <IconButton
                  className="text-gray-400 hover:text-gray-600"
                  onClick={handleClose}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>

              <div>
                <div className="mb-6">
                  <div className="flex items-center space-x-3 mb-2">
                    <FolderSpecialIcon className="text-violet-600" />
                    <h3 className="text-xl font-semibold text-gray-900">
                      Load schedule from template
                    </h3>
                  </div>
                  <p className="text-gray-600 text-sm">
                    Select a template that you have saved to load in.
                  </p>
                </div>

                <div className="mb-6">
                  <Select
                    fullWidth
                    name="template_name"
                    value={state.template_name}
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      height: "50px",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <em style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Select Template</em>
                    </MenuItem>
                    {props.template_names?.map((option) => (
                      <MenuItem key={option.template_name} value={option.template_name}>
                        {option.template_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                {state.template_name && (
                  <div className="grid grid-cols-2 gap-6 p-4 bg-gray-50 rounded-lg mb-6">
                    <div>
                      <p className="text-gray-500 text-sm font-medium mb-1">Total Hours</p>
                      <p className="text-gray-900 font-semibold">
                        {props.template_names.filter(
                          (i) => i.template_name === state.template_name
                        )[0]["hours"]}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-500 text-sm font-medium mb-1">Saved On Date</p>
                      <p className="text-gray-900 font-semibold">
                        {formatDateReadable(props.template_names.filter(
                          (i) => i.template_name === state.template_name
                        )[0]["saved_on_date"])}
                      </p>
                    </div>
                  </div>
                )}

                <div className="flex items-center space-x-3">
                  {state.template_name ? (
                    <>
                      <button
                        className="px-6 py-2.5 rounded-lg bg-rose-600 text-white hover:bg-rose-700 transition-colors duration-200 text-sm font-medium"
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleStart}
                        endIcon={<FolderSpecialIcon />}
                        sx={{
                          textTransform: "none",
                          borderRadius: "8px",
                          padding: "10px 16px",
                          backgroundColor: "#6d28d9",
                          "&:hover": {
                            backgroundColor: "#5b21b6",
                          },
                        }}
                      >
                        Load Template
                      </Button>
                    </>
                  ) : (
                    <>
                      <button className="px-6 py-2.5 rounded-lg bg-gray-100 text-gray-400 cursor-not-allowed text-sm font-medium">
                        Delete
                      </button>
                      <button className="flex-1 px-4 py-2.5 rounded-lg bg-gray-100 text-gray-400 cursor-not-allowed text-sm font-medium flex items-center justify-center space-x-2">
                        <span>Load Template</span>
                        <FolderSpecialIcon className="h-5 w-5" />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default LoadFromTemplateButton;
