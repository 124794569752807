import React from "react";
import ShiftPoolTableRow from "./ShiftPoolTableRow";
import { add_shift_attributes } from "../../utilities/helpers";
import EmptyState from "../helpers/EmptyState";

export default function ShiftPoolTable(props) {
  let shiftPoolGrouped = [];
  shiftPoolGrouped = props.shiftPoolData.reduce((grouped, shift) => {
    const date = shift.date;
    if (!grouped[date]) {
      grouped[date] = [];
    }
    grouped[date].push(shift);
    return grouped;
  }, {});

  const workDays = Object.entries(shiftPoolGrouped)
    .map(([date, shifts]) => ({
      date,
      shifts: shifts.map((v, i) => add_shift_attributes(v)),
    }))
    .sort((a, b) => a.date?.localeCompare(b.date));

  const hasShiftPoolShifts = workDays.length > 0;

  // console.log("WORK DAYS", workDays);

  return (
    <div className="mt-12">
      {hasShiftPoolShifts ? (
        <div>
          <div className="flex items-center px-6">
            <div className="flex-auto">
              <h1 className="text-3xl text-slate-900">Shift Pool</h1>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[24%] px-3 py-3.5 text-left text-sm font-medium text-slate-500 pl-6"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="w-[24%] px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                        >
                          Shift Given Up
                        </th>
                        <th
                          scope="col"
                          className="w-[16%] px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="flex-1 px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                        >
                          Note
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-6 text-right text-sm font-medium text-slate-500"
                        >
                          Badge
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {workDays.map((workDay, index) => (
                        <>
                          {workDay.shifts.map((shiftInfo, index) => (
                            <ShiftPoolTableRow
                              key={index}
                              shiftInfo={shiftInfo}
                              notMyUpcomingShifts={workDay.shifts}
                              create_pickup_request={
                                props.create_pickup_request
                              }
                              rescind_pickup_shift={props.rescind_pickup_shift}
                              shiftPool={true}
                            />
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          title="Shift Pool"
          description="No shifts available for pickup at the moment"
        />
      )}
    </div>
  );
}
