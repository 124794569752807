import React, { useEffect, useState } from "react";
import axios from "axios";
import StoreProficiencyDisplayConfigurations from "./StoreProficiencyDisplayConfigurations";
import AlgorithmSettingsDisplayConfigurations from "./AlgorithmSettingsDisplayConfigurations";
import TopBarDisplayStoreOnly from "../home/top_bar_components/TopBarDisplayStoreOnly";
import {
  API_get_store_proficiency,
  API_save_store_proficiency,
  API_get_autoschedule_settings,
  API_save_autoschedule_settings,
} from "../../../constants";

import { getDefaultAxiosConfig } from "../../../utilities/requests";
const axiosConfig = getDefaultAxiosConfig();

export default function ConfigurationsDisplay(props) {
  const [display, setDisplay] = useState("Store Proficiency");
  const [storeProficiency, setStoreProficiency] = useState(null);
  const [autoScheduleSettings, setAutoScheduleSettings] = useState(null);

  const fetchStoreProficiency = async () => {
    // console.log(props.homeStore);
    try {
      const response = await axios.get(
        API_get_store_proficiency + props.homeStore,
        axiosConfig
      );
      // console.log(response.data);
      setStoreProficiency(response.data["proficiency_index"]);
    } catch (error) {
      console.error("Error fetching store proficiency data:", error);
    }
  };

  const saveProficiency = async (proficiency_index) => {
    try {
      const response = await axios.put(
        API_save_store_proficiency + props.homeStore,
        {
          store_id: props.homeStore,
          proficiency_index: proficiency_index,
        },
        axiosConfig
      );

      if (response.status === 200) {
        // console.log("Proficiency saved successfully");
      } else {
        console.error("Error saving proficiency:", response.status);
      }
    } catch (error) {
      console.error("Error saving proficiency:", error);
    }
  };

  const fetchAutoScheduleSettings = async () => {
    try {
      const response = await axios.get(
        API_get_autoschedule_settings + props.homeStore,
        axiosConfig
      );
      // Convert the array of objects into an object with setting_name as keys and setting_weight as values
      const settingsObj = response.data.reduce((acc, setting) => {
        acc[setting.setting_name] = setting.setting_weight;
        return acc;
      }, {});
      // console.log(settingsObj);
      setAutoScheduleSettings(settingsObj);
    } catch (error) {
      console.error("Error fetching auto-schedule settings data:", error);
    }
  };

  const saveAutoScheduleSettings = async (settings) => {
    try {
      const response = await axios.put(
        API_save_autoschedule_settings + props.homeStore,
        {
          store_id: props.homeStore,
          settings: settings,
        },
        axiosConfig
      );

      if (response.status === 200) {
        // console.log("Auto-schedule settings saved successfully");
      } else {
        console.error("Error saving auto-schedule settings:", response.status);
      }
    } catch (error) {
      console.error("Error saving auto-schedule settings:", error);
    }
  };

  useEffect(() => {
    // console.log(props.homeStore);
    fetchStoreProficiency();
    fetchAutoScheduleSettings();
  }, [props.homeStore]);

  return (
    <>
      <div className="w-full px-10 my-7">
        <TopBarDisplayStoreOnly
          storeOptions={props.storeOptions}
          homeStore={props.homeStore}
          storeNames={props.storeNames}
          change_home_store={props.change_home_store}
        />
      </div>
      <div className="w-full min-h-screen flex">
        <div className="w-1/5">
          <div className="flex flex-col gap-8 px-8 border-r-2 border-gray-400">
            <button
              className={`w-full py-3 px-8 text-left rounded ${
                display === "Store Proficiency"
                  ? "bg-blue-200 border-l-4 border-primary"
                  : ""
              }`}
              onClick={() => setDisplay("Store Proficiency")}
            >
              Store Proficiency
            </button>
            {/* 
            <button
              className={`w-full py-3 px-8 text-left rounded ${
                display === "Algorithm Settings"
                  ? "bg-blue-200 border-l-4 border-primary"
                  : ""
              }`}
              onClick={() => setDisplay("Algorithm Settings")}
            >
              Algorithm Settings
            </button>
            */}
          </div>
        </div>
        <div className="w-4/5 px-20 py-6 pb-12">
          {display === "Store Proficiency" ? (
            <StoreProficiencyDisplayConfigurations
              storeProficiency={storeProficiency}
              saveProficiency={saveProficiency}
            />
          ) : display === "Algorithm Settings" ? (
            <AlgorithmSettingsDisplayConfigurations
              autoScheduleSettings={autoScheduleSettings}
              saveAutoScheduleSettings={saveAutoScheduleSettings}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}
