import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import TaskNameCardModalHeader from "./TaskNameCardModalHeader";
import TaskCheckboxCompleted from "./TaskCheckboxCompleted";
import TaskTextCompleted from "./TaskTextCompleted";

function TaskNameCardModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const assignedName =
    props.task.plan_task_assignments?.[0]?.store_team_member?.full_name;

  const handleOpenPdfInNewTab = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const isAssignedToEveryone =
    props.task?.plan_task_assignments?.[0]?.is_assigned_to_everyone;

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div className="space-y-6">
            <TaskNameCardModalHeader
              task={props.task}
              setSelectedImage={props.setSelectedImage}
              setShowLightbox={props.setShowLightbox}
              assignedName={assignedName}
              cardColor={props.cardColor}
              isAssignedToEveryone={isAssignedToEveryone}
              handleOpenPdfInNewTab={handleOpenPdfInNewTab}
            />
            {props.task?.subtasks?.length > 0 && (
              <div>
                {props.task?.plan_task_assignments?.[0]?.store_team_member ||
                isAssignedToEveryone ? (
                  <div className="bg-gray-50 rounded-xl px-6">
                    {props.task?.plan_task_assignments?.[0]?.plan_subtask_assignments?.map(
                      (subtask, index) => (
                        <div
                          key={index}
                          className={`py-7 ${index !== props.task?.plan_task_assignments?.[0]?.plan_subtask_assignments?.length - 1 ? "border-b border-slate-200" : ""}`}
                        >
                          <div className="flex gap-4">
                            <div className="text-sm font-medium text-gray-500 w-6">
                              {index + 1}.
                            </div>
                            {subtask.plan_subtask.type === "text" ? (
                              <div className="flex-1">
                                <TaskTextCompleted
                                  subtask={subtask}
                                  isAssignedToEveryone={isAssignedToEveryone}
                                />
                              </div>
                            ) : subtask.plan_subtask.type === "checkbox" ? (
                              <div className="flex-1">
                                <TaskCheckboxCompleted
                                  subtask={subtask}
                                  isAssignedToEveryone={isAssignedToEveryone}
                                />
                              </div>
                            ) : null}
                          </div>
                          {subtask?.file_upload === null &&
                          subtask.plan_subtask.is_image_required ? (
                            <div className="flex gap-4 mt-2">
                              <div className="w-6" />
                              <p className="text-xs text-red-500">
                                Image required*
                              </p>
                            </div>
                          ) : (
                            subtask?.file_upload?.presigned_url && (
                              <div className="flex gap-4 mt-4">
                                <div className="w-6" />
                                <div
                                  className="cursor-pointer hover:opacity-90 transition-opacity"
                                  onClick={() => {
                                    props.setSelectedImage(
                                      subtask?.file_upload?.presigned_url
                                    );
                                    props.setShowLightbox(true);
                                  }}
                                >
                                  <img
                                    className="rounded-lg max-h-[150px] max-w-[250px] shadow-sm"
                                    src={subtask?.file_upload?.presigned_url}
                                    alt="Subtask image"
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className="bg-gray-50 rounded-xl px-6">
                    {props.task?.subtasks?.map((subtask, index) => (
                      <div key={index} className={`py-7 ${index !== props.task?.plan_task_assignments?.[0]?.plan_subtask_assignments?.length - 1 ? "border-b border-slate-200" : ""}`}>
                        <div className="flex gap-4">
                          <div className="text-sm font-medium text-gray-500 w-6">
                            {index + 1}.
                          </div>
                          {subtask.type === "text" ? (
                            <div className="flex-1 space-y-2">
                              <div>
                                <p className="text-sm font-medium text-gray-900">
                                  {subtask.name}
                                </p>
                                <p className="text-xs text-gray-500 mt-0.5">
                                  {subtask.description}
                                </p>
                              </div>
                              <div className="bg-white border border-gray-200 rounded-lg p-3 h-[40px]" />
                            </div>
                          ) : subtask.type === "checkbox" ? (
                            <div className="flex gap-4">
                              <div className="w-6 h-6 rounded border border-gray-300 bg-white" />
                              <div>
                                <p className="text-sm font-medium text-gray-900">
                                  {subtask.name}
                                </p>
                                <p className="text-xs text-gray-500 mt-0.5">
                                  {subtask.description}
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div className="flex justify-end gap-3 pt-2">
              <button
                className="px-6 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-gray-50 rounded-full text-sm font-medium transition-colors"
                onClick={() => props.handleClose()}
              >
                Cancel
              </button>
              {props.task?.plan_task_assignments?.[0]?.store_team_member &&
                props.task?.plan_task_assignments?.[0]?.status !==
                  "completed" &&
                props.task?.plan_task_assignments?.[0]?.status !==
                  "completed_late" && (
                  <button
                    className="px-6 py-2 bg-red-100 text-red-700 hover:bg-red-200 rounded-full text-sm font-medium transition-colors"
                    onClick={() => {
                      props.unassign_plan_task(
                        props.task.plan_task_assignments?.[0]?.uuid
                      );
                      props.handleClose();
                    }}
                  >
                    Unassign
                  </button>
                )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TaskNameCardModal;
