import React from 'react'
import TaskStatusBadge from "./TaskStatusBadge";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import LinkIcon from "@mui/icons-material/Link";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { get_t_str } from '../../../../helpers/helpers';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';


function TaskNameCardModalHeader(props) {
  return (
    <div className="flex items-end justify-between">
      <div className="space-y-4 flex-1">
        <div>
          <p className="text-xl font-medium text-gray-900">
            {props.task.name}
          </p>
        </div>
        {props.task.description && (
          <div className="flex items-center space-x-2">
            <div className="text-gray-500">
              <DescriptionOutlinedIcon />
            </div>
            <p className="text-gray-700 leading-relaxed">
              {props.task.description}
            </p>
          </div>
        )}
        <div className="flex items-center space-x-2">
          <div className="text-sm font-medium text-gray-500">
            <AccessAlarmIcon />
          </div>
          {props.task.lower_bound_qhnum &&
          props.task.upper_bound_qhnum ? (
            <p className="text-gray-700">
              {get_t_str(props.task.lower_bound_qhnum)} -{" "}
              {get_t_str(props.task.upper_bound_qhnum)}
            </p>
          ) : (
            <p className="text-gray-700">Anytime</p>
          )}
        </div>
        {props.task?.file_uploads?.length > 0 && (
          <div className={`flex ${props.task?.file_uploads?.length === 1 ? "items-center" : ""} space-x-2`}>
            <div className="text-sm font-medium text-gray-500">
              <LinkIcon />
            </div>
            <div>
              {props.task?.file_uploads?.map((file, index) => {
                const fileType = file.name.split(".").pop();
                return (
                  <div key={index} className="flex items-center mb-2">
                    {fileType === "pdf" ? (
                      <div
                        onClick={() =>
                          props.handleOpenPdfInNewTab(file.presigned_url)
                        }
                        className="group cursor-pointer"
                      >
                        <p className="text-sm text-violet-700 group-hover:text-violet-800 transition-colors">
                          {file.name}
                        </p>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          props.setSelectedImage(file.presigned_url);
                          props.setShowLightbox(true);
                        }}
                        className="group cursor-pointer"
                      >
                        <p className="text-sm text-violet-700 group-hover:text-violet-800 transition-colors">
                          {file.name}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {props.permissionType === "employee" ?
          <div>
            <TaskStatusBadge
              status={props.modalData?.status}
              submitted_at={props.modalData?.submitted_at_formatted}
              size="large"
            />
          </div> :
          props.task?.plan_task_assignments?.length > 0 && (
            <div>
              <TaskStatusBadge
                status={props.task?.plan_task_assignments?.[0]?.status}
                submitted_at={
                  props.task?.plan_task_assignments?.[0]
                    ?.submitted_at_formatted
                }
                size="large"
              />
            </div>
          )
        }
      </div>
      {props.permissionType === "employee" ?
        <div className="text-slate-600">
          {props.isAssignedToEveryone ? (
            <div className="flex flex-row items-center gap-1">
              <GroupsIcon sx={{ fontSize: 20, }} />
              <span>Communal</span>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-1">
              <PersonIcon sx={{ fontSize: 20, }} />
              <span>Individual</span>
            </div>
          )}
        </div> :
        <div>
          {props.task?.plan_task_assignments?.[0]?.store_team_member ? (
            <div
              className={`${props.cardColor} text-white px-4 py-2 rounded-lg shadow-sm`}
            >
              {props.assignedName}
            </div>
          ) : props.isAssignedToEveryone ? (
            <div className="flex flex-row items-center gap-1 text-slate-600">
              <GroupsIcon sx={{ fontSize: 20, }} />
              <span>Assigned to Everyone</span>
            </div>
          ) : (
            <div className="bg-white text-gray-600 px-4 py-2 rounded-lg shadow-sm border border-gray-200">
              Unassigned
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default TaskNameCardModalHeader
