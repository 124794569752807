import React from "react";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { formatDateReadable } from "../../../requests/utilities/helpers";

function SaveAsTemplateButton(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setState({ ...state, template_name: "" });
  };

  const [state, setState] = React.useState({ template_name: "" });

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function submitAndClose() {
    const templateNamesList = props.template_names.map(
      (item) => item.template_name
    );

    if (templateNamesList.includes(state.template_name)) {
      const isConfirmed = window.confirm(
        "This template name already exists. Do you want to overwrite it?"
      );

      if (isConfirmed) {
        props.saveScheduleAsTemplate(state.template_name);
        handleClose();
      }
    } else {
      props.saveScheduleAsTemplate(state.template_name);
      handleClose();
    }
  }

  function agg_column(df, column) {
    return df.reduce(function (a, b) {
      return a + b[column];
    }, 0);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const currentDate = new Date();
  const currentDateFormatted = props.formatDate(currentDate);

  return (
    <div>
      <Tooltip title="Save As Template">
        <IconButton onClick={handleOpen}>
          <SaveIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="absolute right-2 top-2">
            <IconButton
              className="text-gray-400 hover:text-gray-600"
              onClick={handleClose}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>

          <div>
            <div className="mb-6 flex items-center space-x-3">
              <SaveIcon className="text-violet-600 h-8 w-8" />
              <h3 className="text-xl font-semibold text-gray-900">Save schedule as template</h3>
            </div>

            {props.scheduled_hours && (
              <div className="grid grid-cols-2 gap-6 p-4 bg-gray-50 rounded-lg mb-6">
                <div>
                  <p className="text-gray-500 text-sm font-medium mb-1">Total Hours</p>
                  <p className="text-gray-900 font-semibold">
                    {agg_column(props.scheduled_hours, "hours")}
                  </p>
                </div>
                <div>
                  <p className="text-gray-500 text-sm font-medium mb-1">Saved On Date</p>
                  <p className="text-gray-900 font-semibold">{formatDateReadable(currentDateFormatted)}</p>
                </div>
              </div>
            )}

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1.5">
                Template Name
              </label>
              <TextField
                fullWidth
                size="small"
                placeholder="Type a template name"
                name="template_name"
                InputProps={{
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "10px",
                    padding: "5px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  },
                }}
                value={state.template_name}
                onChange={handleChange}
              />
            </div>

            <div className="flex items-center space-x-3">
              <button
                className="flex-1 px-4 py-2.5 rounded-lg border border-gray-200 text-gray-700 hover:bg-gray-50 transition-colors duration-200 text-sm font-medium"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className={`flex-1 px-4 py-2.5 rounded-lg text-sm font-medium transition-colors duration-200
                  ${state.template_name.length > 0
                    ? 'bg-violet-600 text-white hover:bg-violet-700'
                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
                onClick={() => state.template_name.length > 0 && submitAndClose()}
              >
                Save Template
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SaveAsTemplateButton;
