import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import RepeatIcon from "@mui/icons-material/Repeat";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { formatDateReadable, getInitials } from "../../utilities/helpers";
import CloseIcon from "@mui/icons-material/Close";
import AvatarSmall from "../../shared/helpers/AvatarSmall";
import { Drawer } from "@mui/material";
import DrawerHeader from "../../shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../shared/helpers/DrawerFooterContainerLarge";
import ButtonFilled from "../../shared/helpers/ButtonFilled";
import ButtonOutlined from "../../shared/helpers/ButtonOutlined";
import ButtonFilledCancel from "../../shared/helpers/ButtonFilledCancel";

export default function TradesRequestsTableRow(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  function handleDeny() {
    props.manager_reject_shift_drop(props.senderShiftId);
    handleClose();
    setDrawerOpen(false);
  }

  function handleApprove() {
    props.manager_approve_shift_pickup(props.pickupUUID, "approved");
    handleClose();
    setDrawerOpen(false);
  }

  return (
    <>
      <tr
        className="hover:bg-slate-50 cursor-pointer"
        onClick={toggleDrawer(true)}
      >
        <td className="whitespace-nowrap py-4 pl-6 text-sm">
          <div className="flex items-center bg-slate-100 px-6 py-4 rounded-2xl">
            <div className="w-1/2">
              <div className="font-medium text-slate-900">
                {props.senderName}
              </div>
              <div className="text-slate-500 mt-1">is trading away</div>
            </div>
            <div>
              <div className="text-slate-900">
                {formatDateReadable(props.senderDate)}
              </div>
              <div className="text-slate-500 mt-1">{props.senderShift}</div>
            </div>
          </div>
        </td>
        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
          <div className="text-slate-900">{formatDateReadable(props.senderDate)}</div>
          <div className="text-slate-500">{props.senderShift}</div>
        </td> */}
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
          <div className="w-full flex justify-center">
            <div className="text-white bg-slate-900 w-10 h-10 rounded-full flex items-center justify-center">
              <SwapHorizIcon style={{ fontSize: "24px" }} />
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap pr-6 text-sm text-slate-500">
          <div className="flex items-center bg-yellow-200 px-6 py-4 rounded-2xl">
            <div className="w-1/2">
              <div className="font-medium text-slate-900">
                {props.receiverName}
              </div>
              <div className="text-slate-500 mt-1">is trading away</div>
            </div>
            <div className="w-1/2">
              <div className="text-slate-900">
                {formatDateReadable(props.receiverDate)}
              </div>
              <div className="text-slate-500 mt-1">{props.receiverShift}</div>
            </div>
          </div>
        </td>
        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
          <div className="text-slate-900">{formatDateReadable(props.receiverDate)}</div>
          <div className="text-slate-500">{props.receiverShift}</div>
        </td> */}
        <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
          {/* <button className="bg-primary hover:bg-blue-700 text-white px-5 py-2 rounded-lg" onClick={handleOpen}>Approve / Deny</button> */}
          <div className="w-10 h-10 rounded-full"></div>
        </td>
      </tr>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-2xl font-semibold">Trade Shift Request</h2>
          <div>
            <h4 className="mt-7 text-sm">{props.senderName} is trading away</h4>
            <div className="mt-1">
              <Divider />
            </div>
            <div className="flex items-center mt-3">
              <div className="h-14 w-14 flex-shrink-0">
                <div className="h-14 w-14 rounded-full bg-primary text-white flex items-center justify-center">
                  {getInitials(props.senderName)}
                </div>
              </div>
              <div className="ml-4">
                <p className="font-medium text-slate-900">{props.senderName}</p>
                <p className="text-sm text-slate-600">
                  {formatDateReadable(props.senderDate)}{" "}
                  <span className="ml-1">({props.senderShift})</span>
                </p>
                <div className="mt-1 flex items-center gap-1">
                  <CircleIcon style={{ color: "orange", fontSize: "10px" }} />
                  <p className="text-xs font-semibold text-slate-900">
                    {props.senderRole && `${props.senderRole} | `}
                    {props.senderRoleType}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-slate-600 w-full flex items-center justify-center mt-6">
              <ImportExportIcon />
            </div>
            <h4 className="mt-3 text-sm">
              {props.receiverName} is trading away
            </h4>
            <div className="mt-1">
              <Divider />
            </div>
            <div className="flex items-center mt-3">
              <div className="h-14 w-14 flex-shrink-0">
                <div className="h-14 w-14 rounded-full bg-primary text-white flex items-center justify-center">
                  {getInitials(props.receiverName)}
                </div>
              </div>
              <div className="ml-4">
                <p className="font-medium text-slate-900">
                  {props.receiverName}
                </p>
                <p className="text-sm text-slate-600">
                  {formatDateReadable(props.receiverDate)}{" "}
                  <span className="ml-1">({props.receiverShift})</span>
                </p>
                <div className="mt-1 flex items-center gap-1">
                  <CircleIcon style={{ color: "orange", fontSize: "10px" }} />
                  <p className="text-xs font-semibold text-slate-900">
                    {props.receiverRole && `${props.receiverRole} | `}
                    {props.receiverRoleType}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full mt-10 flex justify-end">
              <div className="flex items-center gap-5">
                <button
                  className="px-8 py-3 rounded-lg border border-primary text-primary hover:bg-slate-100 text-sm"
                  onClick={() => handleDeny()}
                >
                  Deny
                </button>
                <button
                  className="px-8 py-3 rounded-lg bg-primary hover:bg-blue-700 text-white text-sm"
                  onClick={() => handleApprove()}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-4 top-4 text-slate-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-full bg-slate-50 relative">
          <DrawerHeader title="Trade Request" onClose={toggleDrawer(false)} />
          <div className="px-6 mt-12">
            <div className="w-full mt-8 flex flex-row items-center justify-between">
              <h3 className="text-slate-900 text-xs font-semibold">
                Initiator
              </h3>
              <h3 className="text-slate-900 text-xs font-semibold">
                Responder
              </h3>
            </div>
            <div className="w-full flex flex-row items-center justify-between relative mt-5">
              <div className="w-1/2 pr-1">
                <div className="w-full bg-slate-100 rounded-xl p-4 relative">
                  <p className="text-slate-900">{props.senderName}</p>
                  <p className="text-slate-500 text-xs mt-3">is trading away</p>
                  <p className="text-slate-900 mt-3">
                    {formatDateReadable(props.senderDate)}
                  </p>
                  <p className="text-slate-900 mt-1">{props.senderShift}</p>
                  <p className="text-slate-500 mt-1 text-sm">
                    {props.senderStation
                      ? props.senderStation
                      : props.senderRole
                      ? props.senderRole
                      : props.senderRoleType}
                  </p>
                </div>
              </div>
              <div className="w-full h-10 absolute z-30 flex justify-center">
                <div className="w-11 h-11 bg-white flex items-center justify-center rounded-full">
                  <div className="w-8 h-8 bg-slate-900 text-white flex items-center justify-center rounded-full">
                    <SwapHorizIcon />
                  </div>
                </div>
              </div>
              <div className="w-1/2 pl-1">
                <div className="w-full bg-yellow-200 rounded-xl p-4">
                  <p className="text-slate-900 text-right">
                    {props.receiverName}
                  </p>
                  <p className="text-slate-500 text-xs mt-3 text-right">
                    is trading away
                  </p>
                  <p className="text-slate-900 mt-3 text-right">
                    {formatDateReadable(props.receiverDate)}
                  </p>
                  <p className="text-slate-900 mt-1 text-right">
                    {props.receiverShift}
                  </p>
                  <p className="text-slate-500 mt-1 text-right text-sm">
                    {props.receiverStation
                      ? props.receiverStation
                      : props.receiverRole
                      ? props.receiverRole
                      : props.receiverRoleType}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <DrawerFooterContainerLarge>
            <div className="w-full">
              <ButtonFilled text="Approve" onClick={() => handleApprove()} />
            </div>
            <div className="w-full mt-3">
              {/* <ButtonOutlined text="Deny" onClick={() => handleDeny()} /> */}
              <ButtonFilledCancel text="Deny" onClick={() => handleDeny()} />
            </div>
          </DrawerFooterContainerLarge>
        </div>
      </Drawer>
    </>
  );
}
