import React from "react";
import TaskTextCompleted from "./TaskTextCompleted";
import TaskCheckboxCompleted from "./TaskCheckboxCompleted";

function TaskNameCardCompletedTask(props) {

  console.log("SUBTASK", props.subtask)

  return (
    <div className={`py-7 ${
      props.index !== props.modalData.plan_subtask_assignments.length - 1
        ? "border-b border-slate-200"
        : ""
    }`}>
      <div className="flex gap-3">
        <div className="text-sm font-medium text-slate-500 w-5">
          {props.index + 1}.
        </div>
        {props.subtask.plan_subtask.type === "text" ? (
          <div className="flex-1">
            <TaskTextCompleted
              subtask={props.subtask}
              isAssignedToEveryone={props.isAssignedToEveryone}
            />
          </div>
        ) : props.subtask.plan_subtask.type === "checkbox" ? (
          <div className="flex-1">
            <TaskCheckboxCompleted
              subtask={props.subtask}
              isAssignedToEveryone={props.isAssignedToEveryone}
            />
          </div>
        ) : null}
      </div>

      <div className="mt-3 ml-8">
        <div className="space-y-2">
          {props.subtask?.file_upload?.presigned_url && (
            <div
              onClick={() => {
                props.setShowLightbox(true);
                props.setSelectedImage(props.subtask?.file_upload?.presigned_url);
              }}
              className="cursor-pointer hover:opacity-90 transition-opacity"
            >
              <img
                src={props.subtask?.file_upload?.presigned_url}
                alt="Attached"
                className="rounded-lg max-h-[150px] max-w-[250px] shadow-sm"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskNameCardCompletedTask;
