import React from "react";
import TopBarTrainingOverview from "../home/top_bar_components/TopBarTrainingOverview";
import TrainingStoreDisplay from "./TrainingStoreDisplay";
import RoleProficiencyThresholdsDisplay from "./RoleProficiencyThresholdsDisplay";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../utilities/LoadSpinner";
import {
  API_get_stores_with_learning_track_enrollment,
  API_get_learning_track_enrollments_by_employee_for_store,
  API_post_learning_track_enrollment,
  API_get_learning_modules_by_role,
  API_post_learning_module_star_threshold,
} from "../../../constants";
import TabsTwo from "../requests/shared/helpers/TabsTwo";
import StarIcon from '@mui/icons-material/Star';

function TrainingOverviewDisplay(props) {
  const [activeTab, setActiveTab] = React.useState("Locations");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  const [showStoreTraining, setShowStoreTraining] = React.useState(false);

  const [allStoresTrainingData, setAllStoresTrainingData] = React.useState([]);
  const [learningModulesByRole, setLearningModulesByRole] = React.useState([]);
  const [
    storeDetailTrainingDataByEmployee,
    setStoreDetailTrainingDataByEmployee,
  ] = React.useState([]);

  const [selectedStore, setSelectedStore] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const homeCompany = props.storeNames?.filter(
    (s) => s.id === props.homeStore
  )[0]["company"];

  // [GET] api/lms_info/get_stores_with_learning_track_enrollment
  // * company_id
  const fetchStoresWithLearningTrackEnrollment = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: homeCompany,
    };
    let api = API_get_stores_with_learning_track_enrollment;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        setAllStoresTrainingData(res.data.redux?.payload?.stores);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  // [GET] api/lms_info/get_learning_track_enrollments_by_employee_for_store
  // * store_id
  const fetchLearningModulesByRole = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: homeCompany,
    };
    let api = API_get_learning_modules_by_role;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        setLearningModulesByRole(res.data.redux?.payload?.roles);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  // [GET] api/lms_info/get_learning_track_enrollments_by_employee_for_store
  // * store_id
  const fetchStoreDetailTrainingDataByEmployee = async (
    showLoading,
    store_id
  ) => {
    setIsLoading(showLoading);
    let api_params = {
      store_id: store_id,
    };
    let api = API_get_learning_track_enrollments_by_employee_for_store;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        setStoreDetailTrainingDataByEmployee(
          res.data.redux?.payload?.store_team_members
        );
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  // ----------- POST APIs ----------- //

  // [POST] /lms_info/post_learning_track_enrollment
  // * learning_track_uuid
  // * company_id
  // * store_team_member_id
  const post_learning_track_enrollment = async (
    learning_track_uuid,
    store_team_member_id
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      learning_track_uuid: learning_track_uuid,
      store_team_member_id: store_team_member_id,
    };

    try {
      toast.loading("Assigning Track...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_learning_track_enrollment,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Track Assigned Successfully", { autoClose: 3000 });

        fetchStoreDetailTrainingDataByEmployee(false, selectedStore.id);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/post_learning_module_star_threshold
  // * company_id
  // * role_id
  // * stars
  // * learning_module_uuids
  const post_learning_module_star_threshold = async (
    role_id,
    star_rating,
    learning_module_uuids
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: homeCompany,
      role_id: role_id,
      star_rating: star_rating,
      learning_module_uuids: learning_module_uuids,
    };

    try {
      toast.loading("Assigning Threshold...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_learning_module_star_threshold,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Threshold Assigned Successfully", { autoClose: 3000 });

        fetchLearningModulesByRole(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // console.log("TRAINING DATA ALL STORES", allStoresTrainingData);
  // console.log("TRAINING DATA BY STORE", storeDetailTrainingDataByEmployee);

  React.useEffect(() => {
    fetchStoresWithLearningTrackEnrollment(true);
    fetchLearningModulesByRole(true);
  }, []);

  const [showTopBar, setShowTopBar] = React.useState(true);

  // console.log("MODULES BY ROLE", learningModulesByRole);

  return (
    <>
      {!isLoading ? (
        <div>
          {showTopBar && (
            <div>
              <TopBarTrainingOverview
                title={
                  showStoreTraining
                    ? "Back to Training Overview"
                    : "Training Overview"
                }
                showStoreTraining={showStoreTraining}
                setShowStoreTraining={setShowStoreTraining}
              />
            </div>
          )}
          {!showStoreTraining && (
            <div className="w-full flex items-center justify-between px-6 mt-12">
              <h2 className="text-slate-900 text-3xl">{activeTab}</h2>
              <div className="w-96">
                <TabsTwo
                  activeTab={activeTab}
                  handleTabButtonPress={handleTabButtonPress}
                  tab1="Locations"
                  tab2="Roles"
                />
              </div>
            </div>
          )}
          {activeTab === "Locations" ? (
            <div>
              {!showStoreTraining ? (
                <div className="mt-16 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-slate-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="w-[22%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                              >
                                Location
                              </th>
                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                # of Employees
                              </th> */}
                              <th
                                scope="col"
                                className="w-[22%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Avg Proficiency Rating
                              </th>
                              <th
                                scope="col"
                                className="w-[22%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Avg Track Completion
                              </th>
                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Training Status
                              </th> */}
                              <th
                                scope="col"
                                className="w-[25%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Details
                              </th>
                              <th
                                scope="col"
                                className="w- relative py-3.5 pl-3 pr-6"
                              >
                                {/* <span className="sr-only">Edit</span> */}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-slate-200 bg-white">
                            {allStoresTrainingData.map((store, index) => {
                              const totalProgress =
                                (store.learning_track_enrollment_summary
                                  .total_sections_numerator /
                                  store.learning_track_enrollment_summary
                                    .total_sections_denomenator) *
                                100;
                              return (
                                <tr
                                  key={index}
                                  className="hover:bg-slate-50 cursor-pointer"
                                  onClick={() => {
                                    fetchStoreDetailTrainingDataByEmployee(
                                      true,
                                      store.id
                                    );
                                    setSelectedStore(store);
                                    setShowStoreTraining(true);
                                  }}
                                >
                                  <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                    <div>
                                      <p>{store.store_name}</p>
                                    </div>
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>23</div>
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div className="flex items-center">
                                      <p style={{ minWidth: "35px" }}>{store.average_training_proficiency_rating.toFixed(2)}</p>
                                      <div className="text-yellow-500">
                                        <StarIcon />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>
                                      {totalProgress
                                        ? totalProgress.toFixed()
                                        : "0"}
                                      %
                                    </div>
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div className="w-fit">
                                      <div
                                        className={`rounded-lg px-2 py-1 ${
                                          totalProgress > 80
                                            ? "bg-emerald-50 border border-emerald-100"
                                            : totalProgress > 50
                                            ? "bg-yellow-50 border border-yellow-100"
                                            : "bg-rose-50 border border-rose-100"
                                        } flex items-center justify-center`}
                                      >
                                        <p
                                          className={`${
                                            totalProgress > 80
                                              ? "text-emerald-800"
                                              : totalProgress > 50
                                              ? "text-yellow-800"
                                              : "text-rose-800"
                                          }`}
                                        >
                                          {totalProgress > 80
                                            ? "Good"
                                            : totalProgress > 50
                                            ? "Fair"
                                            : "Poor"}
                                        </p>
                                      </div>
                                    </div>
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div className="mb-3.5">
                                      <div className="w-full flex items-center justify-between">
                                        <p className="text-slate-500">Total</p>
                                        <p className="text-slate-500 text-xs">
                                          {totalProgress
                                            ? totalProgress.toFixed()
                                            : "0"}
                                          % completed
                                        </p>
                                      </div>
                                      <div className="w-full h-2 rounded-full bg-slate-200 mt-1 overflow-hidden">
                                        <div
                                          className={`h-2 bg-emerald-600 rounded-full`}
                                          style={{
                                            width: `${
                                              totalProgress ? totalProgress : 0
                                            }%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    {/* {[["FOH", "bg-orange-500"],["BOH", "bg-blue-500"]].map(([role, color], index) => (
                                      <div
                                        key={index}
                                        className="mb-3.5"
                                      >
                                        <p className="text-slate-500">{role}</p>
                                        <div className="w-full h-2 rounded-full bg-slate-200 mt-0.5 overflow-hidden">
                                          <div className={`w-[50%] h-2 ${color} rounded-full`}></div>
                                        </div>
                                      </div>
                                    ))} */}
                                  </td>
                                  <td className="relative whitespace-nowrap py-5 pl-6 pr-6 text-right text-sm font-medium w-5">
                                    <div className="flex items-center justify-end w-fit h-full text-slate-500">
                                      <ChevronRightIcon />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="border-b border-slate-200"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <TrainingStoreDisplay
                    storeDetailTrainingDataByEmployee={
                      storeDetailTrainingDataByEmployee
                    }
                    selectedStore={selectedStore}
                    homeCompany={homeCompany}
                    setShowTopBar={setShowTopBar}
                    post_learning_track_enrollment={
                      post_learning_track_enrollment
                    }
                    fetchStoreDetailTrainingDataByEmployee={
                      fetchStoreDetailTrainingDataByEmployee
                    }
                    proficiencyStarsCount={props.proficiencyStarsCount}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <RoleProficiencyThresholdsDisplay
                learningModulesByRole={learningModulesByRole}
                homeCompany={homeCompany}
                post_learning_module_star_threshold={
                  post_learning_module_star_threshold
                }
                proficiencyStarsCount={props.proficiencyStarsCount}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default TrainingOverviewDisplay;
