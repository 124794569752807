import React, { useState, useEffect } from "react";
import ButtonFilled from "../requests/shared/helpers/ButtonFilled";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import {
  API_get_pay_periods,
  API_get_timecards_for_employee,
  API_post_pay_period_approval
} from "../../../constants";
import TimeEntriesHeaderEmployee from "./TimeEntriesHeaderEmployee";
import TimeEntriesHeader from "./TimeEntriesHeader";
import {
  formatDate,
  formatDateReadableWithDayOfWeekNoYear,
  formatDateReadableWithDayOfWeek,
  formatDateReadable,
} from "../requests/utilities/helpers";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import LoadSpinner from "../../../utilities/LoadSpinner";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "react-toastify/dist/ReactToastify.css";
import BlinkingDot from "./BlinkingDot";
import InfoIcon from "@mui/icons-material/Info";
import EarningsBarChart from "./EarningsBarChart";
import { getHoursAndBreaks } from "../../../helpers/helpers";
import { hoursToMinutes } from "../../../helpers/helpers";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeEntriesReviewCard from "./TimeEntriesReviewCard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TimeEntriesDisplay(props) {
  const default_data = {
    clocked_in_at: null,
    clocked_out_at: null,
    clocked_in_at_date_time: new Date(),
    clocked_out_at_date_time: new Date(),
    declared_cash_tips: null,
    published_schedule: null,
    status: null,
    store_team_member: {
      first_name: "",
      last_name: "",
      id: null,
    },
    wage_type: null,
    timecard_breaks: [],
    uuid: null,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  const [showSettings, setShowSettings] = React.useState(false);

  const [payPeriods, setPayPeriods] = useState([]);

  const [timeEntries, setTimeEntries] = useState([]);
  const [summaryList, setSummaryList] = React.useState([]);

  const [selectedPayPeriod, setSelectedPayPeriod] = React.useState(null)
  const [selectedPayPeriodDates, setSelectedPayPeriodDates] = useState([]);

  const [showTimeEntries, setShowTimeEntries] = useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  // console.log("PAY PERIODS", payPeriods)
  // console.log("TIME ENTRIES", timeEntries)

  useEffect(() => {
    setShowTimeEntries(false);
    fetchPayPeriods(true);
  }, [props.homeStore]);

  const fetchPayPeriods = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = { store_id: props.homeStore };
    let api = API_get_pay_periods;
    try {
      const res = await getRightworkAPI(api, api_params);
      // console.log(res);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.pay_periods;
        setPayPeriods(data);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  console.log("PAY PERIOD WHEN EMPLOYEE", payPeriods)

  const fetchTimeCardsForEmployee = async (showLoading, date) => {
    setIsLoading(showLoading);
    let api_params = {
      store_id: props.homeStore,
      date: date,
    };
    let api = API_get_timecards_for_employee;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        // console.log("TIMECARDS", res.data.redux?.payload?.timecards)
        setTimeEntries(res.data?.redux?.payload?.timecards);
        setSummaryList(res.data?.redux?.payload?.summary_list);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const handleSelectPayPeriod = (pay_period, beginning_date, end_date) => {
    setShowTimeEntries(true);
    setSelectedPayPeriod(pay_period)
    setSelectedPayPeriodDates([beginning_date, end_date]);
    fetchTimeCardsForEmployee(true, beginning_date);
  };

  const handleGoBack = () => {
    // Deselect the current period and go back to the pay period list
    setShowTimeEntries(false);
  };

  // Enhancing the status lookup to include both label and styling
  const statusStyles = {
    auto_approved: {
      label: "",
      classes: "bg-emerald-50 border border-emerald-300 text-emerald-800",
    },
    auto_flag: {
      label: "Flagged",
      classes: "bg-fuchsia-50 border border-fuchsia-300 text-fuchsia-800",
    },
    approved: {
      label: "Approved",
      classes: "bg-blue-50 border border-blue-300 text-blue-800",
    },
    denied: {
      label: "Denied",
      classes: "bg-rose-50 border border-rose-300 text-red-800",
    },
    pending: {
      label: "Pending",
      classes: "bg-yellow-50 border border-yellow-300 text-yellow-800",
    },
  };

  const employeeApprovalStatusStyles = {
    approved: {
      label: "approved",
      classes: "border border-emerald-300 bg-emerald-50 text-emerald-800",
    },
    disputed: {
      label: "disputed",
      classes: "border border-rose-300 bg-rose-50 text-rose-800",
    },
    missed_approval: {
      label: "missed approval",
      classes: "border border-orange-300 bg-orange-50 text-orange-800",
    },
    pending_approval_initial: {
      label: "pending review",
      classes: "border border-amber-300 bg-amber-50 text-amber-800",
    },
    pending_approval_again: {
      label: "pending 2nd review",
      classes: "border border-amber-300 bg-amber-50 text-amber-800",
    },
  };

  // Existing states and hooks...
  const [nameFilter, setNameFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // Assuming you've fetched timeEntries, now let's prepare dynamic filter options
  useEffect(() => {
    // This effect could be used to further manipulate timeEntries data if needed
  }, [timeEntries]);

  // Unique names for the Name filter dropdown
  const uniqueNames = [
    ...new Set(
      timeEntries.map(
        (entry) =>
          `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`
      )
    ),
  ];

  const uniqueStoreTeamMembers = [];
  const uniqueIds = new Set();

  timeEntries.forEach((entry) => {
    const memberId = entry.store_team_member.id;
    if (!uniqueIds.has(memberId)) {
      uniqueIds.add(memberId);
      uniqueStoreTeamMembers.push(entry.store_team_member);
    }
  });


  const filteredTimeEntries = timeEntries.filter((entry) => {
    const fullName = `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`;
    const entryDate = formatDate(
      new Date(entry.clocked_in_at).toLocaleDateString()
    );
    // Apply name filter
    const matchesName = nameFilter ? fullName === nameFilter : true;
    // Apply date filter
    const matchesDate = dateFilter ? entryDate === dateFilter : true;
    // Apply status filter
    const matchesStatus = statusFilter ? entry.status === statusFilter : true;

    return matchesName && matchesDate && matchesStatus;
  });

  // console.log("MODAL DATA", modalData);

  const filteredTimeEntriesForApproveAll = filteredTimeEntries.filter(
    (entry) => {
      return entry.status === "pending" || entry.status === "auto_flag";
    }
  );

  let filteredTimeEntriesUUIDs = [];

  filteredTimeEntriesForApproveAll.forEach((timeEntry) =>
    filteredTimeEntriesUUIDs.push(timeEntry.uuid)
  );


  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const csvRows = [];
    // Headers
    const headers = [
      "Name",
      "Date",
      "Timesheet",
      "Hours Worked",
      "Unpaid Breaks",
      "Status",
    ];
    csvRows.push(headers.join(","));

    // Values
    for (const entry of data) {
      const paidHours = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).paidHours;
      const paidMinutes = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).paidMinutes;
      const breakMinutes = props.timeEntriesEnabled
        ? getHoursAndBreaks(
            entry.clocked_in_at,
            entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
            entry.timecard_breaks
          ).breakMinutes
        : hoursToMinutes(Number(entry.unpaid_break_time));
      entry["clocked_in_at_date_time"] = new Date(entry.clocked_in_at);
      entry["clocked_out_at_date_time"] = new Date(
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime()
      );
      entry["status"] = entry.clocked_out_at
        ? entry.status
        : "currently_working";
      entry["sub_status"] =
        !entry.clocked_out_at &&
        (entry.timecard_breaks?.[entry.timecard_breaks?.length - 1]
          ?.clocked_out_at ||
          entry.timecard_breaks?.length === 0)
          ? "Working"
          : !entry.clocked_out_at &&
            !entry.timecard_breaks?.[entry.timecard_breaks?.length - 1]
              ?.clocked_out_at
          ? "On Break"
          : null;

      const values = [
        `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`,
        `${formatDate(new Date(entry.clocked_in_at).toLocaleDateString())}`,
        `${new Date(entry.clocked_in_at).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${new Date(entry.clocked_out_at).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
        `${paidHours}hrs ${paidMinutes}min`,
        `${breakMinutes}min`,
        `${entry.status}`,
      ];
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  // Function to download CSV file
  const downloadCSV = () => {
    const csvData = convertToCSV(timeEntries);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    const datePayPeriod = `${selectedPayPeriodDates[0]}_to_${selectedPayPeriodDates[1]}`;
    const filename = `time_entries_${datePayPeriod}.csv`; // Add datePayPeriod to filename
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // console.log("PAY PERIODS", payPeriods)

  const aggregatedSummary = summaryList?.reduce(
    (acc, current) => {
      return {
        hours_scheduled: acc.hours_scheduled + Number(current.hours_scheduled),
        hours_worked: acc.hours_worked + Number(current.hours_worked),
        wages_scheduled: acc.wages_scheduled + Number(current.wages_scheduled),
        wages_earned: acc.wages_earned + Number(current.wages_earned),
      };
    },
    {
      hours_scheduled: 0,
      hours_worked: 0,
      wages_scheduled: 0,
      wages_earned: 0,
    }
  );

  // [POST] api/clock_info/post_pay_period_approval (company employee)
  // * store_id (auth)
  // * pay_period_uuid
  // * pay_period_approval_uuid [optional]
  // * status [optional]
  // * note [optional]
  const post_pay_period_approval = async (
    pay_period_uuid,
    pay_period_approval_uuid,
    status,
    note
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      pay_period_uuid: pay_period_uuid,
      pay_period_approval_uuid: pay_period_approval_uuid,
      status: status,
      note: note
    };

    try {
      toast.loading("Submitting...", { autoClose: false });

      const res = await postRightworkAPI(API_post_pay_period_approval, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Submitted Successfully", { autoClose: 3000 });

        fetchPayPeriods(false);

        if (selectedPayPeriod && selectedPayPeriod.uuid === pay_period_uuid) {
          setSelectedPayPeriod(prev => ({
            ...prev,
            pay_period_approvals: [{
              ...prev.pay_period_approvals[0],
              status: status,
              note: note
            }]
          }));
        }
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  return (
    <>
      {!isLoading ? (
        <div>
          {showTimeEntries ? (
            <div>
              <div>
                <div>
                  <TimeEntriesHeaderEmployee
                    title="Back to Pay Periods"
                    storeOptions={props.storeOptions}
                    homeStore={props.homeStore}
                    storeNames={props.storeNames}
                    change_home_store={props.change_home_store}
                    setCurrentTab={props.setCurrentTab}
                    handleGoBack={handleGoBack}
                    showTimeEntries={showTimeEntries}
                    setShowTimeEntries={setShowTimeEntries}
                    setShowSettings={setShowSettings}
                    timeEntriesEnabled={props.timeEntriesEnabled}
                    payrollEnabled={props.payrollEnabled}
                  />
                </div>
                <div className="px-6 mt-8 relative">
                  <div className="flex justify-between">
                    <div>
                      <h1 className="text-3xl text-slate-900">Time Cards</h1>
                      <h3 className="mt-2 text-slate-500">
                        {`${formatDateReadable(
                          selectedPayPeriodDates[0]
                        )} to ${formatDateReadable(selectedPayPeriodDates[1])}`}
                      </h3>
                    </div>
                    {/* <div className="flex items-center space-x-3">
                      {!timeEntries.some((entry) =>
                        [
                          "pending",
                          "currently_working",
                          "auto_flag",
                        ].includes(entry.status)
                      ) ? (
                        <Tooltip title="Download all time entries to a .csv file">
                          <div
                            className="px-5 py-2 rounded-full bg-orange-600 hover:bg-orange-700 flex items-center space-x-1 text-white cursor-pointer"
                            onClick={downloadCSV}
                          >
                            <div>
                              <DownloadIcon />
                            </div>
                            <p>Download</p>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Can only download when all time entries are approved or denied">
                          <div className="px-5 py-2 rounded-full bg-slate-200 flex items-center space-x-1 text-slate-400">
                            <div>
                              <DownloadIcon />
                            </div>
                            <p>Download</p>
                          </div>
                        </Tooltip>
                      )}
                    </div> */}
                  </div>
                  {props.payrollEmployeeReviewEnabled &&
                    <div className="absolute top-0 right-6">
                      <TimeEntriesReviewCard
                        selectedPayPeriod={selectedPayPeriod}
                        post_pay_period_approval={post_pay_period_approval}
                        numOfTimeEntries={timeEntries.length}
                      />
                    </div>
                  }
                </div>
                <div className="mt-8 px-6 flex items-center space-x-6">
                  <div className="px-4 py-3 border border-slate-200 rounded-lg w-[200px]">
                    <p className="text-xs text-slate-900 font-semibold">
                      Scheduled
                    </p>
                    <div className="mt-2 flex space-x-10">
                      <div>
                        <p className="text-xxs text-slate-500">Hours</p>
                        <p className="text-lg text-slate-900">
                          {aggregatedSummary?.hours_scheduled?.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </p>
                      </div>
                      {!props.timeEntriesEnabled &&
                        props.payrollWagesEnabled && (
                          <div>
                            <p className="text-xxs text-slate-500">Earnings</p>
                            <p className="text-lg text-slate-900">
                              $
                              {aggregatedSummary?.wages_scheduled?.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="px-4 py-3 border border-slate-200 rounded-lg w-[200px]">
                    <p className="text-xs text-slate-900 font-semibold">
                      Actual
                    </p>
                    <div className="mt-2 flex space-x-10">
                      <div>
                        <p className="text-xxs text-slate-500">Hours</p>
                        <p className="text-lg text-slate-900">
                          {aggregatedSummary?.hours_worked?.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </p>
                      </div>
                      {!props.timeEntriesEnabled &&
                        props.payrollWagesEnabled && (
                          <div>
                            <p className="text-xxs text-slate-500">Earnings</p>
                            <p className="text-lg text-slate-900">
                              $
                              {aggregatedSummary?.wages_earned?.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="mt-8">
                  <EarningsBarChart
                    barChartData={summaryList}
                    timeEntriesEnabled={props.timeEntriesEnabled}
                    payrollWagesEnabled={props.payrollWagesEnabled}
                  />
                </div>
                <div className="mt-8 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-slate-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="flex-1 pl-6 pr-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Timesheet
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Hours Worked
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Unpaid Breaks
                              </th>
                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Earnings
                              </th> */}

                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Cash Tips
                              </th> */}
                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Status
                              </th> */}
                              {/* <th
                                scope="col"
                                className="w-1/12 relative py-3.5 pl-3 pr-6 text-xs text-slate-500 font-medium text-right"
                              >Status</th> */}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-slate-200 bg-white">
                            {filteredTimeEntries.map((entry, i) => {
                              const paidHours = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).paidHours;
                              const paidMinutes = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).paidMinutes;
                              const breakMinutes = props.timeEntriesEnabled
                                ? getHoursAndBreaks(
                                    entry.clocked_in_at,
                                    entry.clocked_out_at
                                      ? entry.clocked_out_at
                                      : new Date().getTime(),
                                    entry.timecard_breaks
                                  ).breakMinutes
                                : hoursToMinutes(
                                    Number(entry.unpaid_break_time)
                                  );
                              entry["clocked_in_at_date_time"] = new Date(
                                entry.clocked_in_at
                              );
                              entry["clocked_out_at_date_time"] = new Date(
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime()
                              );
                              entry["status"] = entry.clocked_out_at
                                ? entry.status
                                : "currently_working";
                              entry["sub_status"] =
                                !entry.clocked_out_at &&
                                (entry.timecard_breaks?.[
                                  entry.timecard_breaks?.length - 1
                                ]?.clocked_out_at ||
                                  entry.timecard_breaks?.length === 0)
                                  ? "Working"
                                  : !entry.clocked_out_at &&
                                    !entry.timecard_breaks?.[
                                      entry.timecard_breaks?.length - 1
                                    ]?.clocked_out_at
                                  ? "On Break"
                                  : null;
                              return (
                                <tr key={i}>
                                  <td className="whitespace-nowrap pl-6 pr-3 py-5 text-sm text-slate-900">
                                    <div>
                                      {formatDateReadableWithDayOfWeekNoYear(
                                        formatDate(
                                          new Date(
                                            entry.clocked_in_at
                                          ).toLocaleDateString()
                                        )
                                      )}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    {entry.clocked_out_at ? (
                                      <div>
                                        {new Date(
                                          entry.clocked_in_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}{" "}
                                        -{" "}
                                        {new Date(
                                          entry.clocked_out_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </div>
                                    ) : (
                                      <div>
                                        {new Date(
                                          entry.clocked_in_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}{" "}
                                        -
                                      </div>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>{`${paidHours}hrs ${paidMinutes}min`}</div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>{`${breakMinutes}min`}</div>
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>$750</div>
                                  </td> */}
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>${entry.declared_cash_tips ? entry.declared_cash_tips : "0.00"}</div>
                                  </td> */}
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    {entry.status !==
                                    "currently_working" ? (
                                      <div
                                        className={`inline-block px-3 py-1 rounded-lg text-sm font-medium whitespace-nowrap ${
                                          statusStyles[entry.status]
                                            ?.classes
                                        }`}
                                      >
                                        {statusStyles[entry.status]
                                          ?.label || ""}
                                      </div>
                                    ) : (
                                      <div
                                        className={`w-fit flex items-center space-x-1.5 px-3 py-1 rounded-lg ${
                                          entry.sub_status === "Working"
                                            ? "bg-violet-50 border border-violet-300 text-violet-800"
                                            : "bg-orange-50 border border-orange-300 text-orange-800"
                                        }`}
                                      >
                                        <div>
                                          <BlinkingDot
                                            sub_status={entry.sub_status}
                                          />
                                        </div>
                                        <div>
                                          <p>{entry.sub_status}</p>
                                        </div>
                                      </div>
                                    )}
                                  </td> */}
                                  {/* <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                                    {entry.status !==
                                      "currently_working" ? (
                                      <div
                                        className={`inline-block px-3 py-1 rounded-lg text-sm font-medium whitespace-nowrap ${
                                          statusStyles[entry.status]
                                            ?.classes
                                        }`}
                                      >
                                        {statusStyles[entry.status]
                                          ?.label || ""}
                                      </div>
                                    ) : (
                                      <div
                                        className={`w-fit flex items-center space-x-1.5 px-3 py-1 rounded-lg ${
                                          entry.sub_status === "Working"
                                            ? "bg-violet-50 border border-violet-300 text-violet-800"
                                            : "bg-orange-50 border border-orange-300 text-orange-800"
                                        }`}
                                      >
                                        <div>
                                          <BlinkingDot
                                            sub_status={entry.sub_status}
                                          />
                                        </div>
                                        <div>
                                          <p>{entry.sub_status}</p>
                                        </div>
                                      </div>
                                    )}
                                  </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="border-b border-slate-200"></div>
                        {filteredTimeEntries.length === 0 && (
                          <div className="w-full my-24 flex justify-center">
                            <div className="flex flex-col items-center">
                              <div className="text-slate-900">
                                <InfoIcon />
                              </div>
                              <p className="mt-2 text-slate-900">
                                No time entries match this filter criteria
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <TimeEntriesHeaderEmployee
                  title="Pay Periods"
                  storeOptions={props.storeOptions}
                  homeStore={props.homeStore}
                  storeNames={props.storeNames}
                  change_home_store={props.change_home_store}
                  setCurrentTab={props.setCurrentTab}
                  setShowSettings={setShowSettings}
                  timeEntriesEnabled={props.timeEntriesEnabled}
                  payrollEnabled={props.payrollEnabled}
                />
              </div>
              <div className="mt-16 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-slate-200">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="flex-1 py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                            >
                              Pay Period
                            </th>
                            {/* <th
                              scope="col"
                              className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              # Time Cards
                            </th> */}
                            <th
                              scope="col"
                              className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Hours
                            </th>
                            {!props.timeEntriesEnabled &&
                              props.payrollWagesEnabled && (
                                <th
                                  scope="col"
                                  className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                                >
                                  Earnings
                                </th>
                              )}
                            {props.payrollEmployeeReviewEnabled &&
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Approval Status
                              </th>
                            }
                            <th
                              scope="col"
                              className="w-1/12 relative py-3.5 pl-3 pr-6"
                            >
                              {/* <span className="sr-only">Edit</span> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-200 bg-white">
                          {payPeriods?.map((period, i) => (
                            <tr
                              key={i}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                handleSelectPayPeriod(
                                  period,
                                  period.beginning_date,
                                  period.end_date
                                )
                              }
                            >
                              <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                <div>
                                  {formatDateReadable(period.beginning_date)} -{" "}
                                  {formatDateReadable(period.end_date)}
                                </div>
                              </td>
                              {/* <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                <div>
                                  4
                                </div>
                              </td> */}
                              <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                {period?.timecard_summary_data?.payable_hours?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              {!props.timeEntriesEnabled &&
                                props.payrollWagesEnabled && (
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    $
                                    {period?.timecard_summary_data?.total_pay.toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )}
                                  </td>
                                )}
                              {props.payrollEmployeeReviewEnabled &&
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                  <div className={`w-fit rounded px-2 py-1 ${employeeApprovalStatusStyles?.[period?.pay_period_approvals?.[0]?.status]?.classes}`}>
                                    {employeeApprovalStatusStyles?.[period?.pay_period_approvals?.[0]?.status]?.label}
                                  </div>
                                </td>
                              }
                              <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                                <div className="text-slate-500">
                                  <ChevronRightIcon />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="border-b border-slate-200"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default TimeEntriesDisplay;
