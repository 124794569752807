import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SectionInputsModal2 from "./SectionInputsModal2";
import { API_get_learning_section_detail } from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import { formatDate } from "../requests/utilities/helpers";
import { formatDateReadable } from "../requests/utilities/helpers";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function SectionsDisplay(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalEdit, setModalEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchSectionDetail = async (showLoading, learning_section_uuid) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_section_uuid: learning_section_uuid,
    };
    let api = API_get_learning_section_detail;
    try {
      const res = await getRightworkAPI(api, api_params);

      let mapSignedUrlToFileUuid =
        localStorage.getItem("mapSignedUrlToFileUuid") || "{}";
      mapSignedUrlToFileUuid = JSON.parse(mapSignedUrlToFileUuid);
      const rawBlocksAsJson = res.data.redux?.payload?.learning_section.as_json;
      rawBlocksAsJson.forEach((block) => {
        const validTypes = ["image", "video", "audio", "file"];
        if (!validTypes.includes(block.type)) return;
        mapSignedUrlToFileUuid[block.props.url] = block.props.file_uuid;
      });
      localStorage.setItem(
        "mapSignedUrlToFileUuid",
        JSON.stringify(mapSignedUrlToFileUuid)
      );

      if (res.status === 200) {
        props.setModalDataSection(res.data.redux?.payload?.learning_section);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.4,
      sortable: true,
      filterable: true,
      hideable: false,
    },
    {
      field: "created_at",
      headerName: "Date Created",
      flex: 0.25,
      sortable: true,
      filterable: true,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: "Date Updated",
      flex: 0.25,
      sortable: true,
      filterable: true,
    },
    {
      field: "modules_transformed",
      headerName: "In Modules",
      flex: 0.4,
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line" }}>{params.value}</div>
      ),
    },
    {
      field: "tracks_transformed",
      headerName: "In Tracks",
      flex: 0.35,
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line" }}>{params.value}</div>
      ),
    },
    {
      field: "Actions",
      headerName: "",
      width: 60,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit section">
            <div
              className="text-slate-500 hover:text-slate-900 cursor-pointer"
              onClick={() => {
                setModalEdit(true);
                fetchSectionDetail(true, params.row.uuid);
                handleOpen();
              }}
            >
              <EditIcon style={{ fontSize: "22px" }} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows = props.sectionsData?.map((section, index) => ({
    id: index,
    name: section.name,
    created_at: formatDate(new Date(section.created_at)),
    updated_at: formatDate(new Date(section.updated_at)),
    // modules: section.modules,
    modules_transformed:
      section.modules.length > 0
        ? section.modules.map((module) => module.name).join("\n")
        : "Unassigned",
    tracks_transformed:
      section.tracks.length > 0
        ? section.tracks.map((track) => track.name).join("\n")
        : "Unassigned",
    uuid: section.uuid,
  }));

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6d28d9",
      },
    },
  });

  // console.log("SECTIONS DATA", props.sectionsData)

  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <div className="w-full px-6 mt-10 flex items-center justify-between">
            <h2 className="text-4xl text-slate-900">Sections</h2>
            <Tooltip title="Create new section">
              <button
                className="bg-violet-700 hover:bg-violet-800 text-white h-12 w-12 rounded-full"
                onClick={() => {
                  setModalEdit(false);
                  props.setModalDataSection(props.default_data_section);
                  handleOpen();
                }}
              >
                <AddIcon style={{ fontSize: "26px" }} />
              </button>
            </Tooltip>
          </div>
          <div className="mt-8" style={{ width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              autoHeight
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    created_at: false,
                  },
                },
              }}
              getRowHeight={() => "auto"}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </div>
        </div>
      </ThemeProvider>
      <SectionInputsModal2
        open={open}
        handleClose={handleClose}
        modalEdit={modalEdit}
        isLoading={isLoading}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        post_learning_section={props.post_learning_section}
        homeCompany={props.homeCompany}
        delete_learning_section={props.delete_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
      />
    </>
  );
}

export default SectionsDisplay;
