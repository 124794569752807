import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import DefaultAvailabilityCard from "./DefaultAvailabilityCard.js";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";
import AvailabilityRadioButtonsGroup from "./AvailabilityRadioButtonGroup.js";
import TextField from "@mui/material/TextField";
import AvailabilityMineTableRow from "./AvailabilityMineTableRow.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function AvailabilityMineTable(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log("MY AVAILABILITY DATA", props.myAvailabilityData);

  return (
    <div className="mt-12">
      <div className="flex items-center px-6">
        <div className="flex-auto">
          <h1 className="text-3xl text-slate-900">My Availability</h1>
        </div>
      </div>
      <div className="mt-12 flex flex-col">
        <div className="-my-2 overflow-x-auto min-w-full">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-[25%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                    >
                      Day
                    </th>
                    <th
                      scope="col"
                      className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                    >
                      Existing Availability
                    </th>
                    <th
                      scope="col"
                      className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                    >
                      Requested Availability
                    </th>
                    <th
                      scope="col"
                      className="w-[25%] pl-3 pr-6 py-3.5 text-sm text-slate-500 font-medium text-right"
                    >
                      Effective Date
                    </th>
                    {/* <th scope="col" className="w-[18%] relative py-3.5 pl-3 pr-6">
                        <span className="sr-only">Action</span>
                      </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200 bg-white">
                  {props.myAvailabilityData.map((item, index) => (
                    <AvailabilityMineTableRow
                      day={item.day}
                      availability={item.availability}
                      availability2={item.availability2}
                      preferences={item.preferences}
                      requested={item.requested}
                      create_availability_request={
                        props.create_availability_request
                      }
                      delete_availability_request={
                        props.delete_availability_request
                      }
                      rescind_availability_request={
                        props.rescind_availability_request
                      }
                      permissionRole={props.permissionRole}
                      minEffectiveDate={props.minEffectiveDate}
                    />
                  ))}
                </tbody>
              </table>
              <div className="border-b border-slate-200"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
