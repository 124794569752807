import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ButtonFilled from "../requests/shared/helpers/ButtonFilled";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { formatName } from "../../../helpers/helpers";

function LogbookCategoryDrawer(props) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    props.setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // console.log("MODAL DATA", props.modalData)

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader
            title={props.modalEdit ? `Edit Category` : `Add Category`}
            onClose={props.onClose}
          />
          <div className="w-full px-6">
            <p className="text-slate-500 text-sm -mt-6">
              {props.columnSide === "left" ? "Left Column" : "Right Column"}
            </p>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Name</p>
              <div className="mt-1">
                <TextField
                  placeholder="Enter category name..."
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={props.modalData.name}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-7">
              <p className="text-slate-500 text-sm">Type</p>
              <div className="mt-1">
                <Select
                  fullWidth
                  value={props.modalData.type}
                  name="type"
                  onChange={handleChange}
                  size="small"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "5px",
                    height: "45px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                >
                  {["text", "number", "star", "dollar", "percentage"].map(
                    (option, index) => (
                      <MenuItem key={index} value={option}>
                        {formatName(option)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </div>
            </div>
          </div>
          <DrawerFooterContainerLarge>
            <div className="w-full">
              <LoadingButton
                fullWidth
                // loading={props.isSavingData}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="contained"
                onClick={() => {
                  props.post_logbook_category();
                  props.onClose();
                  props.setModalData(props.default_modal_data);
                }}
                sx={{
                  textTransform: "none",
                  borderRadius: "50px",
                  paddingY: "14px",
                  backgroundColor: "#6d28d9",
                  "&:hover": {
                    backgroundColor: "#5b21b6",
                  },
                  fontSize: "16px",
                }}
              >
                Save
              </LoadingButton>
            </div>
            <div className="w-full mt-3">
              <ButtonOutlined text="Cancel" onClick={props.onClose} />
            </div>
          </DrawerFooterContainerLarge>
          <div className="h-[200px]"></div>
        </div>
      </div>
    </Drawer>
  );
}

export default LogbookCategoryDrawer;
