import React from "react";
import {
  API_get_learning_track_enrollment_summaries_for_employee,
  API_get_learning_track_enrollment_detail_for_employee,
  API_post_learning_section_completed,
  API_post_learning_block_user_passed,
} from "../../../constants";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBarTakeTraining from "../home/top_bar_components/TopBarTakeTraining";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import CompleteTrack from "./CompleteTrack";
import InfoIcon from "@mui/icons-material/Info";

function TakeTrainingEmployeeDisplay(props) {
  const [enrolledTracks, setEnrolledTracks] = React.useState([]);

  const [modalDataTrack, setModalDataTrack] = React.useState([]);

  const [currentTrack, setCurrentTrack] = React.useState(null);
  const [currentTrackUUID, setCurrentTrackUUID] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const [showCompleteTrack, setShowCompleteTrack] = React.useState(false);

  const default_data_section = {
    created_at: null,
    name: "",
    updated_at: null,
    uuid: null,
    as_json: [],
  };
  const [modalDataSection, setModalDataSection] =
    React.useState(default_data_section);

  const fetchEnrolledTracks = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = { company_id: props.homeCompany };
    let api = API_get_learning_track_enrollment_summaries_for_employee;
    try {
      const res = await getRightworkAPI(api, api_params);
      // console.log(res);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.learning_track_enrollments;
        setEnrolledTracks(data);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const fetchTrackDetails = async (showLoading, learning_track_uuid) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_track_uuid: learning_track_uuid,
    };
    let api = API_get_learning_track_enrollment_detail_for_employee;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        setModalDataTrack(res.data.redux?.payload.learning_track);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    if (!showCompleteTrack) {
      fetchEnrolledTracks(true);
    }
  }, [showCompleteTrack]);

  // ----------- POST APIs ----------- //

  // [POST] api/lms_info/post_learning_section_completed
  // * company_id
  // * learning_section_uuid

  const post_learning_section_completed = async (learning_section_uuid) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      learning_section_uuid: learning_section_uuid,
    };

    try {
      toast.loading("Completing Section...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_learning_section_completed,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Section Completed Successfully", { autoClose: 3000 });

        // Test Code
        // Refresh enrolled tracks
        fetchEnrolledTracks(false);
        fetchTrackDetails(false, currentTrackUUID);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/post_learning_block_user_passed
  // * company_id
  // * learning_quiz_uuid

  const post_learning_block_user_passed = async (
    learning_block_uuid,
    is_passed
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      learning_block_uuid: learning_block_uuid,
      is_passed: is_passed,
    };

    try {
      // toast.loading("Checking Quiz Score...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_learning_block_user_passed,
        requestData
      );

      if (res.status === 200) {
        // toast.dismiss();
        // toast.success("Quiz Passed Successfully", { autoClose: 3000 });

        fetchTrackDetails(false, currentTrackUUID);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        // toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // console.log("DATA", enrolledTracks)
  // console.log("DATA2", modalDataTrack)

  // console.log("CURRENT TRACK", currentTrack)
  // console.log("ENROLLED TRACKS", enrolledTracks)

  const userType =
    props.permissionRole === "company_employee" ? "Employee" : "Manager";

  React.useEffect(() => {
    const updatedTrack = enrolledTracks.find(
      (track) => track.track.uuid === currentTrackUUID
    );
    setCurrentTrack(updatedTrack);
  }, [enrolledTracks]);

  return (
    <>
      {!isLoading ? (
        <div>
          {!showCompleteTrack ? (
            <div className="w-full min-h-screen flex flex-col">
              <div className="w-full">
                <TopBarTakeTraining title="Training" />
              </div>
              <div className="w-full flex-1 bg-slate-50">
                <div className="mt-12 px-8">
                  <h1 className="text-3xl text-slate-900">
                    My Enrolled Tracks
                  </h1>
                </div>
                {enrolledTracks.length > 0 ? (
                  <div className="mt-10 px-8 pb-12">
                    {enrolledTracks.map((track, index) => {
                      const percentCompleted = (
                        (track.sections_completed_count /
                          track.total_sections_count) *
                        100
                      ).toFixed(0);
                      // console.log("PERCENT COMPLETED", percentCompleted)
                      return (
                        <div
                          key={index}
                          className="w-full bg-white py-6 px-8 rounded-xl flex sm:flex-col shadow mb-5"
                        >
                          <div className="flex-1 pr-8 border-r border-slate-200 sm:pr-0 sm:border-none">
                            <p className="text-slate-500">{track.track.name}</p>
                            <div className="w-full h-2 rounded-full bg-slate-200 mt-3 overflow-hidden">
                              <div
                                className="h-2 bg-emerald-600 rounded-full"
                                style={{ width: `${percentCompleted}%` }}
                              ></div>
                            </div>
                            <div className="w-full flex items-center justify-between mt-1.5">
                              <p className="text-xs text-slate-500">
                                {percentCompleted}% completed
                              </p>
                              {/* <p className="text-xs text-slate-500">{track.sections_completed_count} / {track.total_sections_count}</p> */}
                            </div>
                          </div>
                          {/* <div className="w-[22%] px-8 border-r border-slate-200 flex items-center justify-between space-x-5">
                            <div className="flex flex-col items-center">
                              <div className="flex">
                                <p className="text-slate-500">Modules</p>
                              </div>
                              <p className="text-xl mt-3">12</p>
                            </div>
                            <div className="flex flex-col items-center">
                              <p className="text-slate-500">Sections</p>
                              <p className="text-xl mt-3">140</p>
                            </div>
                            <div className="flex flex-col items-center">
                              <p className="text-slate-500">Quizzes</p>
                              <p className="text-xl mt-3">3 / 5</p>
                            </div>
                          </div> */}
                          <div className="flex-1 pl-8 flex items-center justify-between gap-x-3 sm:pl-0 sm:mt-8 sm:items-end">
                            {percentCompleted !== "100" ? (
                              <div>
                                <p className="text-slate-500">Up next</p>
                                <div className="text-sm mt-1.5 sm:text-xs">
                                  Module {track.next_module_index + 1} -{" "}
                                  {track.next_module?.name}
                                </div>
                                <div className="text-sm mt-1 flex items-center gap-x-1.5 sm:text-xs">
                                  <div className="sm:hidden">
                                    <LabelImportantIcon
                                      style={{
                                        fontSize: "16px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  </div>
                                  <p>
                                    Section {track.next_section_index + 1} -{" "}
                                    {track.next_section?.name}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p className="text-slate-500">
                                  Congratulations!
                                </p>
                                <div className="text-sm mt-1.5 sm:text-xs">
                                  All modules and sections have been completed
                                  in this track
                                </div>
                              </div>
                            )}
                            <div
                              className={`px-5 py-3 ${
                                percentCompleted !== "100"
                                  ? "bg-violet-700 hover:bg-violet-800"
                                  : "bg-emerald-600 hover:bg-emerald:700"
                              } text-white rounded-lg w-fit flex items-center justify-center gap-x-2 cursor-pointer`}
                              onClick={() => {
                                setCurrentTrack(track);
                                setCurrentTrackUUID(track.track.uuid);
                                fetchTrackDetails(true, track.track.uuid);
                                setShowCompleteTrack(true);
                              }}
                            >
                              <div className="sm:hidden">
                                <PlayArrowIcon />
                              </div>
                              {percentCompleted === "100" ? (
                                <p>Review</p>
                              ) : percentCompleted === "0" ? (
                                <p>Begin</p>
                              ) : (
                                <p>Resume</p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full flex justify-center mt-24">
                    <div className="w-full flex flex-col items-center justify-center">
                      <div className="w-10 h-10 rounded-xl bg-slate-200 flex items-center justify-center text-slate-900">
                        <InfoIcon />
                      </div>
                      <div className="w-72">
                        <p className="mt-2 text-sm text-center">
                          You are not currently enrolled in any tracks
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full min-h-screen">
              <CompleteTrack
                currentTrack={currentTrack}
                modalDataTrack={modalDataTrack}
                setModalDataTrack={setModalDataTrack}
                modalDataSection={modalDataSection}
                setModalDataSection={setModalDataSection}
                homeCompany={props.homeCompany}
                showCompleteTrack={showCompleteTrack}
                setShowCompleteTrack={setShowCompleteTrack}
                post_learning_section_completed={
                  post_learning_section_completed
                }
                post_learning_block_user_passed={
                  post_learning_block_user_passed
                }
                userType={userType}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default TakeTrainingEmployeeDisplay;
