import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import AddTaskIcon from "@mui/icons-material/AddTask";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShiftInputsSlider from "./ShiftInputsSlider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PersonAvatar from "./PersonAvatar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import ShiftCardModalDisplay from "./ShiftCardModalDisplay";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  checkIfValid,
  text_to_num,
  createDayArray,
  generateTimeOptions,
  get_t_str,
  filterOptions,
} from "../../../../helpers/helpers";
import DrawerHeader from "../../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../../requests/shared/helpers/DrawerFooterContainerSmall";
import Autocomplete from "@mui/material/Autocomplete";

// Modal style object
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

function OpenShiftInputsModal(props) {
  const initialShiftInfoString = {
    shift_start: get_t_str(props.shift_info.shift_start),
    shift_end: get_t_str(props.shift_info.shift_end),
  };
  const initialBreakInfoString = {
    break_start: get_t_str(props.shift_info.break_start),
    break_end: get_t_str(
      props.shift_info.break_start + props.shift_info.break_length
    ),
  };
  const initialBreakChecked = props.shift_info.break_length > 0;

  const [state, setState] = React.useState(props.shift_info);

  const [break_checked, set_break_checked] =
    React.useState(initialBreakChecked);
  const [isBreakManuallyControlled, setIsBreakManuallyControlled] =
    React.useState(false);

  const [savingLoading, setSavingLoading] = React.useState(false);
  const [deletingLoading, setDeletingLoading] = React.useState(false);

  const [shiftInfoString, setShiftInfoString] = React.useState(
    initialShiftInfoString
  );
  const [breakInfoString, setBreakInfoString] = React.useState(
    initialBreakInfoString
  );

  const [appliedTo, setAppliedTo] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  React.useEffect(() => {
    setState(props.shift_info);
    setShiftInfoString(initialShiftInfoString);
    setBreakInfoString(initialBreakInfoString);
    set_break_checked(initialBreakChecked);

    let appliedToCopy = [false, false, false, false, false, false, false];
    appliedToCopy[props.d] = true;
    setAppliedTo(appliedToCopy);
  }, [props.shift_info]);

  // Auto-update breakChecked based on shift length
  React.useEffect(() => {
    if (!isBreakManuallyControlled) {
      const shiftLength = state.shift_end - state.shift_start;

      const breakRequired =
        props.breakRequiredWhenShiftLengthExceeds !== null
          ? shiftLength > props.breakRequiredWhenShiftLengthExceeds
          : false;

      set_break_checked(breakRequired || initialBreakChecked);

      if (breakRequired && !initialBreakChecked) {
        let new_break_length = props.defaultBreakLengthHours;
        let new_break_start =
          Math.round(((state.shift_end + state.shift_start) / 2) * 4) / 4 -
          new_break_length / 2;

        const updatedShift = {
          ...state,
          break_start: new_break_start,
          break_end: new_break_start + new_break_length,
          break_length: new_break_length,
        };

        setState(updatedShift);
        setBreakInfoString({
          break_start: get_t_str(new_break_start),
          break_end: get_t_str(new_break_start + new_break_length),
        });
      }

      setShiftInfoString({
        shift_start: get_t_str(state.shift_start),
        shift_end: get_t_str(state.shift_end),
      });
    }
  }, [
    state.shift_start,
    state.shift_end,
    props.breakRequiredWhenShiftLengthExceeds,
  ]);

  function handleAppliedToChange(day_i) {
    let appliedToCopy = [...appliedTo];
    if (day_i !== props.d) {
      appliedToCopy[day_i] = !appliedTo[day_i];
    }
    // console.log(appliedToCopy);
    setAppliedTo(appliedToCopy);
  }

  function appliedToButtonGroup() {
    return (
      <div className="w-full mt-2 bg-slate-50 rounded-lg flex">
        <ThemeProvider theme={theme}>
          <ButtonGroup
            style={{
              flex: 1,
              border: "1px solid #f1f5f9",
              borderRadius: "7px",
            }}
          >
            {appliedTo.map((d, i) => (
              <Button
                fullWidth
                color="neutral"
                size="small"
                style={{
                  border: d ? "1px solid #6d28d9" : "1px solid #f8fafc",
                  borderRadius: "7px",
                  backgroundColor: d ? "#ede9fe" : "transparent",
                  padding: "6px",
                  margin: "0 0.5px",
                }}
                key={i}
                onClick={() => handleAppliedToChange(i)}
              >
                {day_abbrevs[i]}
              </Button>
            ))}
          </ButtonGroup>
        </ThemeProvider>
      </div>
    );
  }

  function handleChange(evt) {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
      role_type: getRoleType(value, props.role_names),
    });
  }

  function handleShiftInfo(evt) {
    // console.log(state);
    // console.log(props.shift_info);
    const value = evt.target.value;
    setShiftInfoString({
      ...shiftInfoString,
      [evt.target.name]: value,
    });
    // console.log(text_to_num(value));
    setState({
      ...state,
      [evt.target.name]: text_to_num(value),
    });
  }

  function handleBreakInfo(evt) {
    const value = evt.target.value;
    setBreakInfoString({
      ...breakInfoString,
      [evt.target.name]: value,
    });
  }

  React.useEffect(() => {
    setState({
      ...state,
      ["break_start"]: text_to_num(breakInfoString["break_start"]),
      ["break_length"]:
        text_to_num(breakInfoString["break_end"]) -
        text_to_num(breakInfoString["break_start"]),
    });
  }, [breakInfoString]);

  function handleCheckChange(e) {
    const { checked } = e.target;
    set_break_checked(checked);

    if (checked) {
      let new_break_length =
        state.shift_end - state.shift_start > 6
          ? 1
          : props.defaultBreakLengthHours;
      let new_break_start =
        Math.round(((state.shift_end + state.shift_start) / 2) * 4) / 4 -
        new_break_length / 2;

      setState({
        ...state,
        break_start: new_break_start,
        break_length: new_break_length,
      });
      setBreakInfoString({
        break_start: get_t_str(new_break_start),
        break_end: get_t_str(new_break_start + new_break_length),
      });
    } else {
      setState({
        ...state,
        break_start: 0,
        break_length: 0,
      });
      setBreakInfoString({
        break_start: get_t_str(0),
        break_end: get_t_str(0),
      });
    }
    setIsBreakManuallyControlled(true);
  }

  const initialNoteChecked = props.shift_info.note !== null;
  const [noteChecked, setNoteChecked] = React.useState(initialNoteChecked);

  function handleNoteCheckChange(e) {
    const { checked } = e.target;
    setNoteChecked(checked);
    if (!checked) {
      setState({
        ...state,
        note: null
      });
    }
  }

  function handleNoteChange(e) {
    const { value } = e.target;
    setState({
      ...state,
      note: value
    });
  }



  function submitAndClose(shift_save_type) {
    let shift_info = state;
    shift_info["hours"] =
      state.shift_end -
      state.shift_start -
      (break_checked ? state.break_length : 0);
    shift_info["shift_length"] = state.shift_end - state.shift_start;
    shift_info["break_length"] = break_checked ? state.break_length : 0;
    shift_info["break_start"] = break_checked
      ? state.break_start
      : state.shift_start;
    shift_info["status"] = "AVAILABLE";
    shift_info["store_team_member"] = null;

    props.addShift(shift_info, shift_save_type, appliedTo);
    if (shift_save_type === "delete") {
      setDeletingLoading(true);
    } else {
      setSavingLoading(true);
    }
  }

  const getRoleName = (role_id, role_lookups) => {
    const role_info = role_lookups.filter((r) => r.id === role_id);
    if (role_info.length > 0) {
      return role_info[0]["role_name"];
    } else {
      return null;
    }
  };

  const getRoleType = (role_id, role_lookups) => {
    const role_info = role_lookups.filter((r) => r.id === role_id);
    if (role_info.length > 0) {
      return role_info[0]["role_type"];
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (props.saveShiftFinished) {
      setSavingLoading(false);
      setDeletingLoading(false);
      props.handleClose();
    }
  }, [props.saveShiftFinished]);

  const day_abbrevs = createDayArray(props.businessWeekStartDayIndex);

  function generateBreakOptions(start, end) {
    const options = [];
    let currentTime = start;

    while (currentTime <= end) {
      options.push(get_t_str(currentTime));
      currentTime += 0.25;
    }

    return options;
  }

  function generateStationOptions(selectedRoleId) {
    const selectedRoleObject = props.role_names.find(
      (role) => role.id === selectedRoleId
    );
    return selectedRoleObject
      ? selectedRoleObject.station_options.map((station) => (
          <MenuItem key={station.station_id} value={station.station_id}>
            {station.station_name}
          </MenuItem>
        ))
      : [];
  }
  function generateStationDict(selectedRoleId, selectedStationId) {
    const selectedRoleObject = props.role_names.find(
      (role) => role.id === selectedRoleId
    );
    if (selectedRoleObject.station_options) {
      let station_dict = selectedRoleObject.station_options.find(
        (station) => station.station_id === selectedStationId
      );
      if (station_dict) {
        station_dict["id"] = station_dict["station_id"];
        station_dict["name"] = station_dict["station_name"];
        return station_dict;
      }
    }
    return null;
  }

  // console.log("ROLE NAMES INPUT", props.role_names);
  // console.log("STATE", state);

  const handleCloseNoSave = () => {
    setState(props.shift_info);
    setShiftInfoString(initialShiftInfoString);
    setBreakInfoString(initialBreakInfoString);
    set_break_checked(initialBreakChecked);
    setIsBreakManuallyControlled(false);
    setNoteChecked(props.shift_info.note != null);

    let appliedToCopy = [false, false, false, false, false, false, false];
    appliedToCopy[props.d] = true;
    setAppliedTo(appliedToCopy);
    props.handleClose();
  };

  return (
    <div>
      <Drawer anchor="right" open={props.open} onClose={handleCloseNoSave}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader title="Open Shift" onClose={handleCloseNoSave} />
            <div className="w-full px-6 -mt-7">
              <div className="pb-4 border-b border-slate-200">
                <p className="text-sm text-slate-500">{props.date_name}</p>
              </div>
              <div className="mt-4 pb-5 border-b border-slate-200">
                <h3 className="text-slate-900 font-bold text-sm">Shift</h3>
                <div className="bg-slate-50 py-3 px-4 rounded-lg mt-3">
                  <div>
                    <p className="text-sm text-slate-500">Role</p>
                    <div className="mt-1">
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={state.role_id}
                          name="role_id"
                          onChange={handleChange}
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "5px",
                            height: "45px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                          }}
                        >
                          {props.role_names.length > 0 ? (
                            props.role_names
                              .sort((a, b) => {
                                if (a.role_type_id === b.role_type_id) {
                                  return a.role_name.localeCompare(b.role_name);
                                }
                                return a.role_type_id - b.role_type_id;
                              })
                              .map((role) => (
                                <MenuItem value={role.id}>
                                  {role.role_name}
                                </MenuItem>
                              ))
                          ) : (
                            <MenuItem value={null}>
                              {"No roles available"}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Box sx={{ minWidth: 120 }}>
                      <p className="text-sm text-slate-500">Station</p>
                      <div className="w-full mt-1">
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                          }}
                        >
                          <Select
                            labelId="station-label"
                            placeholder="Choose a station"
                            id="station"
                            value={state.station_id}
                            name="station_id"
                            onChange={(e) => {
                              const new_station_id = e.target.value;
                              setState({
                                ...state,
                                station_id: new_station_id,
                                station: generateStationDict(
                                  state.role_id,
                                  new_station_id
                                ),
                              });
                            }}
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                            }}
                          >
                            <MenuItem value={null}>NONE</MenuItem>
                            {generateStationOptions(state.role_id)}
                          </Select>
                        </FormControl>
                      </div>
                    </Box>
                  </div>
                  <div className="w-full flex gap-2 mt-3">
                    <div className="w-[132px]">
                      <p className="text-sm text-slate-500">Shift start</p>
                      <div className="mt-1">
                        <FormControl fullWidth size="small">
                          <Autocomplete
                            id="shift-start"
                            disableClearable
                            options={generateTimeOptions(props.start_qhnum)}
                            value={shiftInfoString.shift_start}
                            onChange={(event, newValue) => {
                              handleShiftInfo({
                                target: {
                                  name: "shift_start",
                                  value: newValue,
                                },
                              });
                            }}
                            filterOptions={filterOptions}
                            size="small"
                            sx={{
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                            }}
                            onKeyDown={(event) => {
                              if (
                                event.key === "-" ||
                                event.key === "Enter" ||
                                event.key === "Tab"
                              ) {
                                event.preventDefault(); // Prevent the default dash behavior

                                // Select the first option from the autocomplete list
                                const firstOption = filterOptions(
                                  generateTimeOptions(props.start_qhnum),
                                  { inputValue: event.target.value }
                                )[0];

                                if (firstOption) {
                                  // Set the first option as the selected value
                                  handleShiftInfo({
                                    target: {
                                      name: "shift_start",
                                      value: firstOption,
                                    },
                                  });

                                  // Move focus directly to the Shift End input field by using its ID
                                  const shiftEndInput =
                                    document.getElementById("shift-end");
                                  if (shiftEndInput) {
                                    setTimeout(() => {
                                      shiftEndInput.focus(); // Move focus to the Shift End input field
                                      shiftEndInput.select();
                                    }, 0); // Adding a small timeout to ensure the DOM is ready
                                  }
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px !important",
                                    height: "45px",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6d28d9",
                                      },
                                    backgroundColor: "white",
                                  },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="flex justify-center items-center text-slate-400 mt-6">
                      <ArrowForwardIcon />
                    </div>
                    <div className="w-[132px]">
                      <p className="text-sm text-slate-500">Shift end</p>
                      <div className="mt-1">
                        <FormControl fullWidth size="small">
                          <Autocomplete
                            id="shift-end"
                            disableClearable
                            options={generateTimeOptions(props.start_qhnum)}
                            value={shiftInfoString.shift_end}
                            onChange={(event, newValue) => {
                              handleShiftInfo({
                                target: { name: "shift_end", value: newValue },
                              });
                            }}
                            filterOptions={filterOptions}
                            size="small"
                            sx={{
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                            }}
                            // Handle key down event to select the first item and move focus to next field
                            onKeyDown={(event) => {
                              if (
                                (event.key === "Enter" ||
                                  event.key === "Tab") &&
                                event.target.value
                              ) {
                                event.preventDefault(); // Prevent the default tab/enter behavior
                                const firstOption = filterOptions(
                                  generateTimeOptions(props.start_qhnum),
                                  { inputValue: event.target.value }
                                )[0];
                                if (firstOption) {
                                  handleShiftInfo({
                                    target: {
                                      name: "shift_end",
                                      value: firstOption,
                                    },
                                  });

                                  // Move focus directly to the Shift End input field by using its ID
                                  const breakChecked =
                                    document.getElementById("break-checked");
                                  if (breakChecked) {
                                    setTimeout(() => {
                                      breakChecked.focus(); // Move focus to the Shift End input field
                                    }, 0); // Adding a small timeout to ensure the DOM is ready
                                  }
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px !important",
                                    height: "45px",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6d28d9",
                                      },
                                    backgroundColor: "white",
                                  },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="w-16 flex flex-col justify-center items-center text-slate-400 text-xs mt-6">
                      <ScheduleIcon style={{ padding: "0px 5px" }} />{" "}
                      {state.shift_end - state.shift_start + " hrs"}
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="flex items-center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="break-checked"
                            name="break_checked"
                            checked={break_checked}
                            onChange={handleCheckChange}
                            size="small"
                            sx={{
                              color: "#6d28d9",
                              "&.Mui-checked": {
                                color: "#6d28d9",
                              },
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault(); // Prevent default Enter behavior
                                handleCheckChange({
                                  target: {
                                    name: "break_checked",
                                    checked: !break_checked,
                                  },
                                });
                              }
                            }}
                          />
                        }
                      />
                      <p className="text-slate-900 text-sm -ml-3">Add break</p>
                    </div>
                    {break_checked ? (
                      <div className="w-full flex mt-1 gap-2 pb-2">
                        <div className="w-[132px]">
                          {/* <TextField
                            size="small"
                            value={breakInfoString.break_start}
                            name="break_start"
                            onChange={handleBreakInfo}
                          >
                            {breakInfoString.break_start}
                          </TextField> */}
                          <p className="text-sm text-slate-500">Break start</p>
                          <div className="mt-1">
                            <FormControl fullWidth size="small">
                              <Autocomplete
                                id="break_start"
                                name="break_start"
                                disableClearable
                                options={generateBreakOptions(
                                  text_to_num(shiftInfoString.shift_start),
                                  text_to_num(shiftInfoString.shift_end)
                                )}
                                value={breakInfoString.break_start}
                                onChange={(event, newValue) => {
                                  handleBreakInfo({
                                    target: {
                                      name: "break_start",
                                      value: newValue,
                                    },
                                  });
                                }}
                                filterOptions={filterOptions}
                                size="small"
                                sx={{
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                }}
                                // Handle key down event to select the first item and move focus to next field
                                onKeyDown={(event) => {
                                  if (
                                    (event.key === "Enter" ||
                                      event.key === "-" ||
                                      event.key === "Tab") &&
                                    event.target.value
                                  ) {
                                    event.preventDefault(); // Prevent the default tab/enter behavior
                                    const firstOption = filterOptions(
                                      generateBreakOptions(
                                        text_to_num(
                                          shiftInfoString.shift_start
                                        ),
                                        text_to_num(shiftInfoString.shift_end)
                                      ),
                                      { inputValue: event.target.value }
                                    )[0];
                                    if (firstOption) {
                                      handleBreakInfo({
                                        target: {
                                          name: "break_start",
                                          value: firstOption,
                                        },
                                      });

                                      // Move focus directly to the Shift End input field by using its ID
                                      const breakEnd =
                                        document.getElementById("break-end");
                                      if (breakEnd) {
                                        setTimeout(() => {
                                          breakEnd.focus(); // Move focus to the Shift End input field
                                          breakEnd.select(); // Select the current text so it's highlighted and ready to be overwritten
                                        }, 0); // Adding a small timeout to ensure the DOM is ready
                                      }
                                    }
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px !important",
                                        height: "45px",
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "#6d28d9",
                                          },
                                        backgroundColor: "white",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div className="flex justify-center items-center text-slate-400 mt-6">
                          <ArrowForwardIcon />
                        </div>
                        <div className="w-[132px]">
                          <p className="text-sm text-slate-500">Break end</p>
                          <div className="mt-1">
                            <FormControl fullWidth size="small">
                              <Autocomplete
                                id="break-end"
                                name="break_end"
                                disableClearable
                                options={generateBreakOptions(
                                  text_to_num(shiftInfoString.shift_start),
                                  text_to_num(shiftInfoString.shift_end)
                                )}
                                value={breakInfoString.break_end}
                                onChange={(event, newValue) => {
                                  handleBreakInfo({
                                    target: {
                                      name: "break_end",
                                      value: newValue,
                                    },
                                  });
                                }}
                                filterOptions={filterOptions}
                                size="small"
                                sx={{
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                }}
                                // Handle key down event to select the first item and move focus to next field
                                onKeyDown={(event) => {
                                  if (
                                    (event.key === "Enter" ||
                                      event.key === "Tab") &&
                                    event.target.value
                                  ) {
                                    event.preventDefault(); // Prevent the default tab/enter behavior
                                    const firstOption = filterOptions(
                                      generateBreakOptions(
                                        text_to_num(
                                          shiftInfoString.shift_start
                                        ),
                                        text_to_num(shiftInfoString.shift_end)
                                      ),
                                      { inputValue: event.target.value }
                                    )[0];
                                    if (firstOption) {
                                      handleBreakInfo({
                                        target: {
                                          name: "break_end",
                                          value: firstOption,
                                        },
                                      });
                                      // Move focus directly to the Shift End input field by using its ID
                                      const saveShift =
                                        document.getElementById("save-shift");
                                      if (saveShift) {
                                        setTimeout(() => {
                                          saveShift.focus(); // Move focus to the Shift End input field
                                        }, 0); // Adding a small timeout to ensure the DOM is ready
                                      }
                                    }
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px !important",
                                        height: "45px",
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "#6d28d9",
                                          },
                                        backgroundColor: "white",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div className="w-16 flex flex-col justify-center items-center text-slate-400 text-xs mt-6">
                          <ScheduleIcon style={{ padding: "0px 5px" }} />{" "}
                          {state.break_length + " hrs"}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                  <div className="mt-2">
                    <div className="w-full flex items-center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="note-checked"
                            name="noteChecked"
                            checked={noteChecked}
                            onChange={handleNoteCheckChange}
                            size="small"
                            sx={{
                              color: "#6d28d9",
                              "&.Mui-checked": {
                                color: "#6d28d9",
                              },
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault(); // Prevent default Enter behavior
                                handleNoteCheckChange({
                                  target: { name: "noteChecked", checked: !noteChecked },
                                });
                              }
                            }}
                          />
                        }
                      />
                      <p className="text-slate-900 text-sm -ml-3">Add note</p>
                    </div>
                    {noteChecked && (
                      <div className="w-full pb-2">
                        <div className="w-full">
                          <p className="text-sm text-slate-500">Note</p>
                          <div className="mt-1">
                            <TextField
                              placeholder="Add a note to open shift card"
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="note"
                              value={state.note || ''} // Add fallback empty string
                              onChange={handleNoteChange}
                              InputProps={{
                                sx: {
                                  backgroundColor: "white",
                                  borderRadius: "8px",
                                  padding: "5px",
                                  height: "45px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#6d28d9",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4 pb-4 border-slate-200">
                <h3 className="text-sm text-slate-900 font-bold">Apply To</h3>
                <div className="mt-4">{appliedToButtonGroup()}</div>
              </div>

            </div>
            <div className="w-full h-[120px]"></div>
            {props.new_shift ? (
              <DrawerFooterContainerSmall>
                <LoadingButton
                  id="save-shift"
                  widthFull
                  variant="contained"
                  loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  disabled={!checkIfValid(state, break_checked)}
                  onClick={() => submitAndClose("add")}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Create
                </LoadingButton>
              </DrawerFooterContainerSmall>
            ) : (
              <DrawerFooterContainerSmall>
                <div className="w-full h-full flex items-center">
                  <div className="w-1/2 pr-2">
                    <LoadingButton
                      widthFull
                      variant="contained"
                      loading={deletingLoading}
                      loadingPosition="end"
                      endIcon={<DeleteIcon />}
                      onClick={() => submitAndClose("delete")}
                      sx={{
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "50px",
                        paddingY: "14px",
                        backgroundColor: "#e11d48",
                        "&:hover": {
                          backgroundColor: "#be123c",
                        },
                        fontSize: "16px",
                      }}
                    >
                      Delete
                    </LoadingButton>
                  </div>
                  <div className="w-1/2 pl-2">
                    <LoadingButton
                      id="save-shift"
                      widthFull
                      variant="contained"
                      loading={savingLoading}
                      loadingPosition="end"
                      endIcon={<SaveIcon />}
                      disabled={!checkIfValid(state, break_checked)}
                      onClick={() => submitAndClose("edit")}
                      sx={{
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "50px",
                        paddingY: "14px",
                        backgroundColor: "#6d28d9",
                        "&:hover": {
                          backgroundColor: "#5b21b6",
                        },
                        fontSize: "16px",
                      }}
                    >
                      Save
                    </LoadingButton>
                  </div>
                </div>
              </DrawerFooterContainerSmall>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default OpenShiftInputsModal;
