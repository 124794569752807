import React, { useEffect, useState } from "react";
import WeekScheduleTypeGrouping from "./WeekScheduleTypeGrouping";
import StoreName from "./StoreName";
import Account from "./Account";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import { createWarningsTooltip } from "../../../../helpers/helpers";
import TabsTwo from "../../requests/shared/helpers/TabsTwo";
import ScheduleTypeFilter from "./ScheduleTypeFilter";
import Notifications from "./Notifications";
import ScheduleWarningButton from "./ScheduleWarningButton";


function TopBarDisplay(props) {

  function toggleShowWarnings() {
    props.setShowWarnings((prevState) => !prevState);
  }

  const [activeTab, setActiveTab] = React.useState('Week')

  function handleTabButtonPress(target) {
    setActiveTab(target)
    props.toggleDailyWeekly(0)
  }

  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className={`${props.windowWidth > 1100 ? "w-72" : "w-60"}`}>
            <WeekScheduleTypeGrouping
              start_date={props.start_date}
              end_date={props.end_date}
              increment_week={props.increment_week}
              roleType={props.roleType}
              setRoleType={props.setRoleType}
              roleTypeOptions={props.roleTypeOptions}
              set_week_start_date={props.set_week_start_date}
              businessWeekStartDayIndex={props.businessWeekStartDayIndex}
            />
          </div>
          {props.windowWidth > 1300 &&
            <div className="w-40">
              <TabsTwo
                activeTab={activeTab}
                handleTabButtonPress={handleTabButtonPress}
                tab1="Week"
                tab2="Day"
                type="TopBar"
              />
            </div>
          }
        </div>
        {/* {(props.countOfWarnings.countOfOvertime > 0 ||
          props.countOfWarnings.countOfConflicts > 0) && (
          <div className="flex-1 flex items-center justify-center">
            <Tooltip
              title={`${createWarningsTooltip(
                props.countOfWarnings.countOfOvertime,
                props.countOfWarnings.countOfConflicts
              )}. Click to bring all employees with conflicts to the top of the schedule.`}
            >
              <button
                className={`px-5 h-9 ${
                  props.showWarnings
                    ? "bg-rose-600 text-white"
                    : "border border-rose-600 text-rose-600 hover:bg-rose-50"
                } rounded-full text-xs flex items-center gap-2`}
                onClick={toggleShowWarnings}
              >
                <WarningIcon style={{ fontSize: "14px" }} /> Schedule Warning
              </button>
            </Tooltip>
          </div>
        )} */}
        <div className="ml-3">
          <ScheduleWarningButton
            countOfWarnings={{
              countOfOvertime: props.countOfWarnings.countOfOvertime,
              countOfConflicts: props.countOfWarnings.countOfConflicts
            }}
            showWarnings={props.showWarnings}
            toggleShowWarnings={toggleShowWarnings}
          />
        </div>
        <div className="flex items-center">
          <div className="mr-4">
            <ScheduleTypeFilter
              roleType={props.roleType}
              setRoleType={props.setRoleType}
              roleTypeOptions={props.roleTypeOptions}
            />
          </div>
          <div className="mr-6">
            <StoreName
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div>
          <div className="mr-8">
            <Notifications
              homeStore={props.homeStore}
            />
          </div>
          <div className="h-10 flex items-center justify-center cursor-pointer">
            <Account />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBarDisplay;
