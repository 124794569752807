import React from "react";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import Tooltip from "@mui/material/Tooltip";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

function DeleteShiftsButton(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function submitAndClose() {
    // console.log("Clear Unlocked Shifts");
    props.deleteAllShifts();
    handleClose();
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Tooltip title="Clear Schedule">
        <IconButton onClick={handleOpen}>
          <DeleteSweepIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div
              className="absolute right-2 top-2"
            >
              <IconButton
              className="text-gray-400 hover:text-gray-600"
              onClick={handleClose}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>

            </div>
            <div>
              <div className="mb-6">
                <div className="flex items-center space-x-3 mb-2">
                  <DeleteSweepIcon className="text-rose-600 h-8 w-8" />
                  <h3 className="text-xl font-semibold text-gray-900">Delete all unlocked shifts</h3>
                </div>
                <p className="mt-2 text-gray-600 text-sm">
                  This will remove all unlocked shifts from the schedule. This action cannot be undone.
                </p>
              </div>

              <div className="flex items-center space-x-3">
                <button
                  className="flex-1 px-4 py-2.5 rounded-lg border border-gray-200 text-gray-700 hover:bg-gray-50 transition-colors duration-200 text-sm font-medium"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="flex-1 px-4 py-2.5 rounded-lg bg-rose-600 text-white hover:bg-rose-700 transition-colors duration-200 text-sm font-medium"
                  onClick={submitAndClose}
                >
                  Delete Shifts
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DeleteShiftsButton;
