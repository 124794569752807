import React from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HomeStoreInfoCard from "./HomeStoreInfoCard.js";
import EastIcon from "@mui/icons-material/East";

export default function ContestLeaderboard(props) {
  // console.log(props.participating_stores);
  return (
    <div className="w-[30%] mt-8">
      <h3 className="font-bold text-xl">{props.metricName}</h3>
      {props.homeStoreInfo.length > 0 ? (
        <div className="w-full mt-3">
          <h5 className="font-bold text-sm">Your Store</h5>
          <HomeStoreInfoCard
            index={props.index}
            metricName={props.metricName}
            metric_detail={props.metric_detail}
            homeStoreInfo={props.homeStoreInfo}
            contest_type={props.contest_type}
            participating_stores={props.participating_stores}
            addUnits={props.addUnits}
          />
        </div>
      ) : (
        <div className="w-full mt-3">
          <h5 className="font-bold text-sm">No information available.</h5>
        </div>
      )}
      {props.contest_type === "competition" && (
        <div className="mt-3">
          <h5 className="font-bold text-sm">Leaderboard</h5>
          <div className="bg-white shadow rounded-lg max-h-[425px] overflow-auto">
            <ul role="list" className="divide-y divide-gray-200">
              {props.otherStoresInfo
                .sort((a, b) => a.rank - b.rank)
                .map((store, i) => (
                  <li key={i}>
                    <div className="block hover:bg-gray-50">
                      <div className="py-4 px-6">
                        <div className="flex items-center justify-between">
                          <p className="truncate text-base font-medium text-gray-800">
                            {
                              props.participating_stores.filter(
                                (v, i) => v.id === store.store_id
                              )[0]["store_name"]
                            }
                          </p>
                          <div className="ml-2 flex flex-shrink-0">
                            <p
                              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                                store.rank === 1
                                  ? "bg-amber-200 text-amber-600"
                                  : store.rank === 2
                                  ? "bg-gray-200 text-gray-800"
                                  : store.rank === 3
                                  ? "bg-orange-200 text-orange-800"
                                  : "border border-gray-500 text-gray-500"
                              }`}
                            >
                              {store.rank === 1
                                ? "1st Place"
                                : store.rank === 2
                                ? "2nd Place"
                                : store.rank === 3
                                ? "3rd Place"
                                : `${store.rank}th Place`}
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 flex justify-between">
                          {props.metric_detail.relative_value_ind === 0 ? (
                            <div className="text-sm font-bold flex items-center gap-4">
                              <p>
                                {props.addUnits(
                                  store.value,
                                  props.metric_detail.units
                                )}
                              </p>
                            </div>
                          ) : (
                            <div className="text-sm font-bold flex items-center gap-4">
                              <p>
                                {props.addUnits(
                                  store.value,
                                  props.metric_detail.units
                                )}{" "}
                                /{" "}
                                {props.addUnits(
                                  store.relative_value,
                                  props.metric_detail.units
                                )}
                              </p>
                              <EastIcon style={{ fontSize: "14px" }} />
                              <p>
                                {props.addUnits(store.final_value, "percent")}
                              </p>
                            </div>
                          )}
                          <div className="mt-2 flex items-center gap-1 text-sm text-gray-500 mt-0">
                            {store.rank < 4 && (
                              <EmojiEventsIcon fontSize="small" />
                            )}
                            <p>{store.payout > 0 && `$${store.payout}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
