import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import LoadSpinner from "../../../utilities/LoadSpinner";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { API_get_learning_section_detail } from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import { BlockNoteSchema, defaultBlockSpecs } from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote, createReactBlockSpec } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { Quiz } from "../create_training_content/Quiz";
import { Alert } from "../create_training_content/Alert";
import { Checkpoint } from "../create_training_content/Checkpoint";
import TrackSidebarModuleDropdown from "../create_training_content/TrackSidebarModuleDropdown";

function CompleteTrack(props) {
  const [showMenu, setShowMenu] = React.useState(true);

  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedModuleIndex, setSelectedModuleIndex] = React.useState(
    props.currentTrack ? props.currentTrack.next_module_index : 0
  );

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(
    props.currentTrack ? props.currentTrack.next_section_index : null
  );

  const [selectedSectionUUID, setSelectedSectionUUID] = React.useState(
    props.modalDataTrack?.modules?.[selectedModuleIndex]?.sections?.[
      selectedSectionIndex
    ]?.uuid
  );

  const [refreshSection, setRefreshSection] = React.useState(false);

  React.useEffect(() => {
    if (refreshSection) {
      fetchSectionDetail(
        true,
        selectedSectionUUID,
        props.selectedEmployee ? props.selectedEmployee?.id : null
      );
    }
    setRefreshSection(false);
  }, [refreshSection]);

  // console.log("SELECTED SECTION UUID", selectedSectionUUID)

  // console.log("MODULEINDEX", selectedModuleIndex)
  // console.log("SECTIONINDEX", selectedSectionIndex)

  // Checking small screen to allow closing menu when clicking / pressing outside of the menu
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  React.useEffect(() => {
    // Function to check if the screen is small
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 640); // Adjust the breakpoint as needed
    };

    // Check screen size on initial render
    checkScreenSize();

    // Add event listener to handle window resize
    window.addEventListener("resize", checkScreenSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const modules = props.modalDataTrack?.modules;

  const [isCurrentSectionCompleted, setIsCurrentSectionCompleted] =
    React.useState(
      modules?.[selectedModuleIndex]?.sections?.[selectedSectionIndex]
        ?.annotated_enrollment?.is_completed
    );

  React.useEffect(() => {
    if (selectedModuleIndex !== null && selectedSectionIndex !== null) {
      setIsCurrentSectionCompleted(
        modules?.[selectedModuleIndex]?.sections?.[selectedSectionIndex]
          ?.annotated_enrollment?.is_completed
      );
    }
    // setSelectedSectionUUID(props.modalDataTrack?.modules?.[selectedModuleIndex]?.sections?.[selectedSectionIndex]?.uuid)
  }, [selectedModuleIndex, selectedSectionIndex, modules]);

  const lastSectionInModule =
    modules?.[selectedModuleIndex]?.sections?.length ===
    selectedSectionIndex + 1;
  const lastSectionInTrack =
    modules?.length === selectedModuleIndex + 1 &&
    props.modalDataTrack?.modules?.[selectedModuleIndex]?.sections?.length ===
      selectedSectionIndex + 1;

  const firstSectionInTrack =
    selectedModuleIndex === 0 &&
    (selectedSectionIndex === null || selectedSectionIndex === 0);
  const firstSectionInModule =
    selectedModuleIndex !== 0 &&
    (selectedSectionIndex === null || selectedSectionIndex === 0);

  const percentCompleted = (
    (props.modalDataTrack?.annotated_enrollment?.sections_completed_count /
      props.modalDataTrack?.annotated_enrollment?.total_sections_count) *
    100
  ).toFixed();

  // // console.log("First Section In Track", firstSectionInTrack)
  // // console.log("First Section In Module", firstSectionInModule)
  // // console.log("Selected Section Index", selectedSectionIndex)
  // // console.log("Selected Module Index", selectedModuleIndex)

  // [GET] api/lms_info/get_learning_section_detail
  // * company_id
  // * learning_section_uuid
  const fetchSectionDetail = async (
    showLoading,
    learning_section_uuid,
    store_team_member_id
  ) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_section_uuid: learning_section_uuid,
      store_team_member_id: store_team_member_id,
    };
    let api = API_get_learning_section_detail;
    try {
      const res = await getRightworkAPI(api, api_params);

      // WORKAROUND: Blocknote and signed URLs
      let mapSignedUrlToFileUuid =
        localStorage.getItem("mapSignedUrlToFileUuid") || "{}";
      mapSignedUrlToFileUuid = JSON.parse(mapSignedUrlToFileUuid);
      const rawBlocksAsJson = res.data.redux?.payload?.learning_section.as_json;
      rawBlocksAsJson.forEach((block, index) => {
        const validTypes = ["image", "video", "audio", "file"];
        if (!validTypes.includes(block.type)) {
          return;
        }
        mapSignedUrlToFileUuid[block.props.url] = block.props.file_uuid;
      });
      localStorage.setItem(
        "mapSignedUrlToFileUuid",
        JSON.stringify(mapSignedUrlToFileUuid)
      );

      if (res.status === 200) {
        props.setModalDataSection(res.data.redux?.payload?.learning_section);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    if (props.showCompleteTrack) {
      fetchSectionDetail(
        true,
        props.currentTrack?.next_section?.uuid,
        props.selectedEmployee ? props.selectedEmployee?.id : null
      );
      setSelectedSectionUUID(
        modules?.[selectedModuleIndex]?.sections?.[selectedSectionIndex]?.uuid
      );
    }
  }, [props.showCompleteTrack]);

  const quizzesData = props.quizzesData;
  const homeCompany = props.homeCompany;
  const post_learning_block_user_passed = props.post_learning_block_user_passed;
  const employeeID = props.selectedEmployee?.id;
  const userType = props.userType;

  const schema = BlockNoteSchema.create({
    blockSpecs: {
      ...defaultBlockSpecs,
      quiz: createReactBlockSpec(
        {
          type: "quiz",
          propSchema: {
            quizSelected: { type: "object", default: null },
            quizzesData: { type: "array", required: true },
            homeCompany: { type: "string", required: true },
          },
          content: "inline",
        },
        {
          render: (props) => (
            <Quiz
              {...props}
              quizzesData={quizzesData}
              homeCompany={homeCompany}
              post_learning_block_user_passed={post_learning_block_user_passed}
              employeeID={employeeID}
              setRefreshSection={setRefreshSection}
              userType={userType}
            />
          ),
        }
      ),
      checkpoint: createReactBlockSpec(
        {
          type: "checkpoint",
          propSchema: {
            checkpointSelected: { type: "object", default: null },
            homeCompany: { type: "string", required: true },
          },
          content: "inline",
        },
        {
          render: (props) => (
            <Checkpoint
              {...props}
              post_learning_block_user_passed={post_learning_block_user_passed}
              employeeID={employeeID}
              setRefreshSection={setRefreshSection}
              userType={userType}
            />
          ),
        }
      ),
      alert: Alert,
    },
  });

  const editor = useCreateBlockNote({
    schema,
  });

  const removeAllBlocks = () => {
    if (editor) {
      const allBlockIDsToRemove = editor.document.map((block) => block.id);
      editor.removeBlocks(allBlockIDsToRemove);
    }
  };

  const loadAllBlocks = () => {
    if (editor) {
      const allBlocksToLoad = props.modalDataSection?.as_json;
      editor.insertBlocks(
        allBlocksToLoad,
        editor.document[0]?.id || null,
        "before"
      );
    }
  };

  React.useEffect(() => {
    if (!isLoading && props.modalDataSection?.as_json.length > 0) {
      removeAllBlocks();
      loadAllBlocks();
    }
  }, [isLoading]);

  // console.log("EDITOR DOCUMENT", editor.document)

  // Need to do
  return (
    <div className="w-full h-screen sm:h-dvh flex">
      {showMenu && (
        <div className="w-[25%] min-w-[225px] bg-white h-full overflow-auto sm:fixed sm:z-40 sm:shadow-lg sm:w-[70%]">
          <div className="px-4 pt-4 pb-6 border-b border-slate-200">
            <div
              className="flex items-center space-x-1.5 text-slate-500 hover:text-slate-600 cursor-pointer"
              onClick={() => setShowMenu(false)}
            >
              <div>
                <MenuIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
              </div>
              <p className="text-slate-500 text-xs">Hide</p>
            </div>
            <h2 className="mt-8 text-2xl">{props.modalDataTrack?.name}</h2>
            <div className="mt-3 flex items-center space-x-2">
              <div className={`w-3 h-3 rounded bg-violet-700`}></div>
              <p className="text-slate-900 text-xs">
                {modules?.length} {modules?.length === 1 ? "module" : "modules"}
              </p>
            </div>
            <div className="mt-6 flex items-center justify-between">
              <p className="text-slate-500 text-xxs">
                {percentCompleted}% completed
              </p>
              {/* <p className="text-slate-500 text-xxs">0 / {props.modalDataTrack?.modules?.length}</p> */}
            </div>
            <div className="w-full h-1.5 rounded-full bg-slate-200 mt-1.5 overflow-hidden">
              <div
                className="h-1.5 bg-emerald-600 rounded-full"
                style={{ width: `${percentCompleted}%` }}
              ></div>
            </div>
          </div>
          <div>
            {modules?.map((module, index) => {
              return (
                <div key={index}>
                  <TrackSidebarModuleDropdown
                    module={module}
                    index={index}
                    selectedModuleIndex={selectedModuleIndex}
                    setSelectedModuleIndex={setSelectedModuleIndex}
                    fetchSectionDetail={fetchSectionDetail}
                    removeAllBlocks={removeAllBlocks}
                    selectedSectionIndex={selectedSectionIndex}
                    setSelectedSectionIndex={setSelectedSectionIndex}
                    setSelectedSectionUUID={setSelectedSectionUUID}
                    modalDataSection={props.modalDataSection}
                    isLoading={isLoading}
                    userType={props.userType}
                    nextModuleIndex={props.currentTrack?.next_module_index}
                    nextSectionIndex={props.currentTrack?.next_section_index}
                    setIsCurrentSectionCompleted={setIsCurrentSectionCompleted}
                    selectedEmployee={props.selectedEmployee}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {showMenu && <div className="w-2 bg-slate-100 shadow-inner"></div>}
      <div
        className={`flex-1 sm:w-full h-screen sm:fixed sm:h-full`}
        onClick={() => {
          if (isSmallScreen && showMenu) {
            setShowMenu(false);
          }
        }}
      >
        <div className="w-full h-full flex flex-col">
          <div>
            <div className="w-full py-5 bg-white flex justify-center shadow relative z-50">
              <div>
                {props.employeeName && (
                  <p className="text-slate-900 text-sm font-bold text-center">
                    {props.employeeName}
                  </p>
                )}
                <p className="text-slate-500 text-sm text-center">
                  Module {selectedModuleIndex + 1}{" "}
                  {selectedSectionIndex !== null &&
                    `- Section ${selectedSectionIndex + 1}`}
                </p>
              </div>
              {!showMenu && (
                <div className="absolute left-2 top-2">
                  <div
                    className="flex items-center space-x-1 text-slate-500 hover:text-slate-600 cursor-pointer"
                    onClick={() => setShowMenu(true)}
                  >
                    <div>
                      <MenuIcon
                        style={{ fontSize: "22px", marginBottom: "2px" }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className="absolute right-2 top-2 text-gray-500 cursor-pointer z-40"
                onClick={() => {
                  if (props.setShowTopBar) {
                    props.setShowTopBar(true);
                  }
                  props.setShowCompleteTrack(false);
                  removeAllBlocks();
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="h-2 bg-slate-100 shadow-inner"></div>
          </div>
          <div className="w-full flex-1 relative pt-8 overflow-auto bg-white shadow">
            {!isLoading ? (
              <div>
                <BlockNoteView editor={editor} editable={false} theme="light" />
              </div>
            ) : (
              <div className="w-full flex-1">
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <LoadSpinner />
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex flex-col">
            <div className="w-full h-2 bg-slate-100 shadow-inner"></div>
            <div className="w-full bg-white flex items-center justify-between px-6 py-4 flex-1 shadow">
              {selectedSectionUUID ? (
                isCurrentSectionCompleted ? (
                  <div className="py-2 px-4 bg-slate-200 rounded-lg text-sm text-slate-400 h-fit flex items-center justify-center gap-x-3">
                    <p className="md:hidden">Mark As Completed</p>
                    <p className="hidden md:block">Complete</p>
                    <div>
                      <TaskAltIcon />
                    </div>
                  </div>
                ) : (
                  <div
                    className="py-2 px-4 bg-emerald-600 hover:bg-emerald-700 rounded-lg text-sm text-white cursor-pointer h-fit flex items-center justify-center gap-x-3"
                    onClick={() =>
                      props.post_learning_section_completed(
                        selectedSectionUUID,
                        props.selectedEmployee
                          ? props.selectedEmployee.id
                          : null
                      )
                    }
                  >
                    <p className="md:hidden">Mark As Completed</p>
                    <p className="hidden md:block">Complete</p>
                    <div>
                      <TaskAltIcon />
                    </div>
                  </div>
                )
              ) : (
                <div></div>
              )}
              <div className="flex items-center space-x-4">
                {firstSectionInTrack ? (
                  <div></div>
                ) : firstSectionInModule ? (
                  <div
                    className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                    onClick={() => {
                      removeAllBlocks();
                      fetchSectionDetail(
                        true,
                        modules?.[selectedModuleIndex - 1]?.sections?.[
                          modules?.[selectedModuleIndex - 1].sections?.length -
                            1
                        ]?.uuid,
                        props.selectedEmployee
                          ? props.selectedEmployee?.id
                          : null
                      );
                      setSelectedSectionUUID(
                        modules?.[selectedModuleIndex - 1]?.sections?.[
                          modules?.[selectedModuleIndex - 1].sections?.length -
                            1
                        ]?.uuid
                      );
                      setSelectedSectionIndex(
                        modules?.[selectedModuleIndex - 1].sections?.length - 1
                      );
                      setSelectedModuleIndex(selectedModuleIndex - 1);
                    }}
                  >
                    <div>
                      <ChevronLeftIcon />
                    </div>
                    <p className="md:hidden">Last section</p>
                  </div>
                ) : (
                  <div
                    className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                    onClick={() => {
                      removeAllBlocks();
                      fetchSectionDetail(
                        true,
                        modules?.[selectedModuleIndex]?.sections?.[
                          selectedSectionIndex - 1
                        ]?.uuid,
                        props.selectedEmployee
                          ? props.selectedEmployee?.id
                          : null
                      );
                      setSelectedSectionUUID(
                        modules?.[selectedModuleIndex]?.sections?.[
                          selectedSectionIndex - 1
                        ]?.uuid
                      );
                      setSelectedSectionIndex(selectedSectionIndex - 1);
                    }}
                  >
                    <div>
                      <ChevronLeftIcon />
                    </div>
                    <p className="md:hidden">Last section</p>
                  </div>
                )}
                {lastSectionInTrack ? (
                  props.currentTrack?.next_module_index ===
                    selectedModuleIndex &&
                  props.currentTrack?.next_section_index ===
                    selectedSectionIndex &&
                  !isCurrentSectionCompleted &&
                  props.userType === "Employee" ? (
                    <div className="py-2 px-4 bg-slate-200 rounded-lg text-sm text-slate-400 flex items-center justify-center gap-x-1.5">
                      <p className="md:hidden">Finish Track</p>
                      <div>
                        <ChevronRightIcon />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                      onClick={() => {
                        props.setShowCompleteTrack(false);
                        removeAllBlocks();
                      }}
                    >
                      <p className="md:hidden">Finish Track</p>
                      <div>
                        <ChevronRightIcon />
                      </div>
                    </div>
                  )
                ) : lastSectionInModule ? (
                  props.currentTrack?.next_module_index ===
                    selectedModuleIndex &&
                  props.currentTrack?.next_section_index ===
                    selectedSectionIndex &&
                  !isCurrentSectionCompleted &&
                  props.userType === "Employee" ? (
                    <div className="py-2 px-4 bg-slate-200 rounded-lg text-sm text-slate-400 flex items-center justify-center gap-x-1.5">
                      <p className="md:hidden">Next section</p>
                      <div>
                        <ChevronRightIcon />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                      onClick={() => {
                        removeAllBlocks();
                        fetchSectionDetail(
                          true,
                          modules?.[selectedModuleIndex + 1]?.sections?.[0]
                            ?.uuid,
                          props.selectedEmployee
                            ? props.selectedEmployee?.id
                            : null
                        );
                        setSelectedSectionUUID(
                          modules?.[selectedModuleIndex + 1]?.sections?.[0]
                            ?.uuid
                        );
                        setSelectedModuleIndex(selectedModuleIndex + 1);
                        setSelectedSectionIndex(0);
                      }}
                    >
                      <p className="md:hidden">Next section</p>
                      <div>
                        <ChevronRightIcon />
                      </div>
                    </div>
                  )
                ) : props.currentTrack?.next_module_index ===
                    selectedModuleIndex &&
                  props.currentTrack?.next_section_index ===
                    selectedSectionIndex &&
                  !isCurrentSectionCompleted &&
                  props.userType === "Employee" ? (
                  <div className="py-2 px-4 bg-slate-200 rounded-lg text-sm text-slate-400 flex items-center justify-center gap-x-1.5">
                    <p className="md:hidden">Next section</p>
                    <div>
                      <ChevronRightIcon />
                    </div>
                  </div>
                ) : (
                  <div
                    className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                    onClick={() => {
                      removeAllBlocks();
                      fetchSectionDetail(
                        true,
                        modules?.[selectedModuleIndex]?.sections?.[
                          selectedSectionIndex + 1
                        ]?.uuid,
                        props.selectedEmployee
                          ? props.selectedEmployee?.id
                          : null
                      );
                      setSelectedSectionUUID(
                        modules?.[selectedModuleIndex]?.sections?.[
                          selectedSectionIndex + 1
                        ]?.uuid
                      );
                      setSelectedSectionIndex(selectedSectionIndex + 1);
                    }}
                  >
                    <p className="md:hidden">Next section</p>
                    <div>
                      <ChevronRightIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteTrack;
