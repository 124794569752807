import React, { useState, useRef, useEffect } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';

function FolderItem(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [renameMode, setRenameMode] = useState(false);
  const [newFolderName, setNewFolderName] = useState(props.folder.name);
  const [isDropTarget, setIsDropTarget] = useState(false);

  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current?.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Focus input when entering rename mode
  useEffect(() => {
    if (renameMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [renameMode]);

  const handleContainerClick = (e) => {
    // Only trigger folder click if we're not in rename mode and didn't click menu items
    if (!renameMode && !isMenuOpen) {
      props.handleFolderClick(props.folder.uuid);
    }
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleRenameClick = (e) => {
    e.stopPropagation();
    setRenameMode(true);
    setIsMenuOpen(false);
  };

  const handleRenameConfirm = (e) => {
    e.stopPropagation();
    if (newFolderName.trim()) {
      props.rename_folder(props.folder.uuid, newFolderName);
      setRenameMode(false);
    }
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleRenameConfirm(e);
    } else if (e.key === 'Escape') {
      setRenameMode(false);
      setNewFolderName(props.folder.name);
    }
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    props.delete_folder(props.folder.uuid);
    setIsMenuOpen(false);
  };

  // Drag and Drop Event Handlers
  const handleDragOver = (e) => {
    e.preventDefault(); // Allow drop
    setIsDropTarget(true);
  };

  const handleDragLeave = (e) => {
    setIsDropTarget(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDropTarget(false);

    try {
      // Parse the dropped item from the dataTransfer
      const droppedItem = JSON.parse(e.dataTransfer.getData('text/plain'));

      if (droppedItem.uuid === props.folder.uuid) {
        return;
      }

      // Check if it's a file and not dropping into the same folder
      if (droppedItem.type === 'file' && props.move_file) {
        props.move_file(droppedItem.uuid, props.folder.uuid);
      }

      // Check if it's a folder and not dropping into the same folder
      if (droppedItem.type === 'folder' && props.move_folder) {
        props.move_folder(droppedItem.uuid, props.folder.uuid);
      }

    } catch (error) {
      console.error("Error moving item:", error);
    }
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(props.folder));
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      draggable={!renameMode}
      onDragStart={handleDragStart}
      className={`
        p-4 border rounded-lg cursor-pointer relative
        ${isDropTarget
          ? 'border-violet-700 bg-violet-50 shadow-md'
          : 'border-gray-200 hover:bg-gray-50'
        }
      `}
      onClick={handleContainerClick}
    >
      <div className="flex items-center space-x-3">
        <FolderIcon className="w-6 h-6 text-violet-700" />
        <div className="flex-1 min-w-0">
          {renameMode ? (
            <div className="flex items-center space-x-2" onClick={handleInputClick}>
              <input
                ref={inputRef}
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                onKeyDown={handleInputKeyDown}
                className="border border-gray-300 rounded px-2 py-1 text-sm flex-1 focus:outline-none focus:border-violet-700"
              />
              <button
                onClick={handleRenameConfirm}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <CheckIcon className="w-5 h-5 text-emerald-600" />
              </button>
            </div>
          ) : (
            <p className="text-sm font-medium text-gray-900 truncate">
              {props.folder.name}
            </p>
          )}
        </div>
        {!renameMode && (
          <button
            ref={buttonRef}
            onClick={handleMenuClick}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <MoreVertIcon className="w-5 h-5 text-gray-500" />
          </button>
        )}
      </div>

      {isMenuOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 py-1"
          style={{ top: '100%' }}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              props.handleFolderClick(props.folder.uuid);
              setIsMenuOpen(false);
            }}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Open
          </button>
          <button
            onClick={handleRenameClick}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Rename
          </button>
          <button
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={handleDeleteClick}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
}

export default FolderItem;
