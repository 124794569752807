import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";

function LocationDetailsProficiencyTable(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (store) => {
    setOpen(true);
    setSelectedProficiency(store.proficiency);
    setModalData(store);
  };

  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState();

  const [selectedProficiency, setSelectedProficiency] = React.useState();

  const [storesInfo, setStoresInfo] = React.useState(props.storesInfo);

  React.useEffect(() => {
    setStoresInfo(props.storesInfo);
  }, [props.storesInfo]);

  // console.log(modalData)

  function handleSelectProficiency(proficiency) {
    setSelectedProficiency(proficiency);
    setModalData((prevModalData) => ({
      ...prevModalData,
      proficiency: proficiency,
    }));
  }

  function handleSubmit() {
    props.post_store_proficiency(modalData.id, modalData.proficiency);
    handleClose();
  }

  const capitalizeProficiencyLookup = {
    beginner: "Beginner",
    competent: "Competent",
    intermediate: "Intermediate",
    advanced: "Advanced",
    expert: "Expert",
  };

  return (
    <>
      <div className="mt-10">
        <div className="w-full px-6">
          <div>
            <h1 className="text-3xl text-slate-900">Proficiency</h1>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-[30%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Value
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-6">
                        {/* <span className="sr-only">Edit</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {storesInfo?.map((store, index) => (
                      <tr
                        key={index}
                        className="hover:bg-slate-50 cursor-pointer"
                        onClick={() => handleOpen(store)}
                      >
                        <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                          <div>
                            <p className="text-slate-900">{store.store_name}</p>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div className="w-fit">
                            <div
                              className={`rounded-md px-2.5 py-0.5 ${
                                store.proficiency === "beginner"
                                  ? "bg-emerald-200 border border-emerald-300"
                                  : store.proficiency === "competent"
                                  ? "bg-blue-200 border border-blue-300"
                                  : store.proficiency === "intermediate"
                                  ? "bg-orange-200 border border-orange-300"
                                  : store.proficiency === "advanced"
                                  ? "bg-purple-200 border border-purple-300"
                                  : store.proficiency === "expert"
                                  ? "bg-rose-200 border border-rose-300"
                                  : ""
                              } `}
                            >
                              <p
                                className={`${
                                  store.proficiency === "beginner"
                                    ? "text-emerald-800"
                                    : store.proficiency === "competent"
                                    ? "text-blue-800"
                                    : store.proficiency === "intermediate"
                                    ? "text-orange-800"
                                    : store.proficiency === "advanced"
                                    ? "text-purple-800"
                                    : store.proficiency === "expert"
                                    ? "text-rose-800"
                                    : "text-slate-500"
                                }`}
                              >
                                {store.proficiency
                                  ? capitalizeProficiencyLookup[
                                      store.proficiency
                                    ]
                                  : "None"}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                          <div className="flex items-center justify-end w-2 space-x-3 text-slate-500">
                            <ChevronRightIcon />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="border-b border-slate-200"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader title={"Edit Proficiency"} onClose={handleClose} />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-sm text-slate-500">Location</p>
                <div className="mt-1">
                  <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                    <p>{modalData?.store_name}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-base text-slate-900">Proficiency value</p>
                <p className="text-xs text-slate-500 mt-1">Select one</p>
                <div>
                  <div
                    className={`w-full rounded-xl border ${
                      selectedProficiency === "beginner"
                        ? "border-violet-700"
                        : "border-slate-300"
                    } flex items-center justify-between px-6 py-3 mt-4 cursor-pointer`}
                    onClick={() => handleSelectProficiency("beginner")}
                  >
                    <div className="flex items-center space-x-4">
                      {selectedProficiency === "beginner" ? (
                        <div className="w-5 h-5 border border-violet-700 bg-white flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-violet-700 bg-violet-700 rounded-full"></div>
                        </div>
                      ) : (
                        <div className="w-5 h-5 border border-slate-300 bg-slate-200 flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-slate-300 bg-white rounded-full"></div>
                        </div>
                      )}
                      <p className="text-lg text-slate-900">Beginner</p>
                    </div>
                    <Tooltip title="Hello World">
                      <div className="text-slate-300">
                        <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className={`w-full rounded-xl border ${
                      selectedProficiency === "competent"
                        ? "border-violet-700"
                        : "border-slate-300"
                    } flex items-center justify-between px-6 py-3 mt-4 cursor-pointer`}
                    onClick={() => handleSelectProficiency("competent")}
                  >
                    <div className="flex items-center space-x-4">
                      {selectedProficiency === "competent" ? (
                        <div className="w-5 h-5 border border-violet-700 bg-white flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-violet-700 bg-violet-700 rounded-full"></div>
                        </div>
                      ) : (
                        <div className="w-5 h-5 border border-slate-300 bg-slate-200 flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-slate-300 bg-white rounded-full"></div>
                        </div>
                      )}
                      <p className="text-lg text-slate-900">Competent</p>
                    </div>
                    <Tooltip title="Hello World">
                      <div className="text-slate-300">
                        <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className={`w-full rounded-xl border ${
                      selectedProficiency === "intermediate"
                        ? "border-violet-700"
                        : "border-slate-300"
                    } flex items-center justify-between px-6 py-3 mt-4 cursor-pointer`}
                    onClick={() => handleSelectProficiency("intermediate")}
                  >
                    <div className="flex items-center space-x-4">
                      {selectedProficiency === "intermediate" ? (
                        <div className="w-5 h-5 border border-violet-700 bg-white flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-violet-700 bg-violet-700 rounded-full"></div>
                        </div>
                      ) : (
                        <div className="w-5 h-5 border border-slate-300 bg-slate-200 flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-slate-300 bg-white rounded-full"></div>
                        </div>
                      )}
                      <p className="text-lg text-slate-900">Intermediate</p>
                    </div>
                    <Tooltip title="Hello World">
                      <div className="text-slate-300">
                        <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className={`w-full rounded-xl border ${
                      selectedProficiency === "advanced"
                        ? "border-violet-700"
                        : "border-slate-300"
                    } flex items-center justify-between px-6 py-3 mt-4 cursor-pointer`}
                    onClick={() => handleSelectProficiency("advanced")}
                  >
                    <div className="flex items-center space-x-4">
                      {selectedProficiency === "advanced" ? (
                        <div className="w-5 h-5 border border-violet-700 bg-white flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-violet-700 bg-violet-700 rounded-full"></div>
                        </div>
                      ) : (
                        <div className="w-5 h-5 border border-slate-300 bg-slate-200 flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-slate-300 bg-white rounded-full"></div>
                        </div>
                      )}
                      <p className="text-lg text-slate-900">Advanced</p>
                    </div>
                    <Tooltip title="Hello World">
                      <div className="text-slate-300">
                        <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className={`w-full rounded-xl border ${
                      selectedProficiency === "expert"
                        ? "border-violet-700"
                        : "border-slate-300"
                    } flex items-center justify-between px-6 py-3 mt-4 cursor-pointer`}
                    onClick={() => handleSelectProficiency("expert")}
                  >
                    <div className="flex items-center space-x-4">
                      {selectedProficiency === "expert" ? (
                        <div className="w-5 h-5 border border-violet-700 bg-white flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-violet-700 bg-violet-700 rounded-full"></div>
                        </div>
                      ) : (
                        <div className="w-5 h-5 border border-slate-300 bg-slate-200 flex items-center justify-center rounded-full">
                          <div className="w-3 h-3 border border-slate-300 bg-white rounded-full"></div>
                        </div>
                      )}
                      <p className="text-lg text-slate-900">Expert</p>
                    </div>
                    <Tooltip title="Hello World">
                      <div className="text-slate-300">
                        <InfoOutlinedIcon style={{ fontSize: "20px" }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Save Proficiency
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default LocationDetailsProficiencyTable;
