import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { get_t_str } from "../../../../helpers/helpers";
import TaskNameCardInput from "./TaskNameCardInput";
import TaskStatusBadge from "./TaskStatusBadge";
import TaskNameCardModalHeader from "./TaskNameCardModalHeader";
import TaskNameCardCompletedTask from "./TaskNameCardCompletedTask";

function TaskNameCardEmployeeModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [modalData, setModalData] = React.useState(props.task);

  React.useEffect(() => {
    setModalData(props.task);
  }, [props.task]);

  const handleSave = (is_submit) => {
    props.save_plan_task_assignment(
      modalData.uuid,
      modalData.plan_subtask_assignments,
      is_submit
    );
    props.handleClose();
  };

  const handleOpenPdfInNewTab = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div className="space-y-6">
            <TaskNameCardModalHeader
              task={modalData?.plan_task}
              handleOpenPdfInNewTab={handleOpenPdfInNewTab}
              setSelectedImage={props.setSelectedImage}
              setShowLightbox={props.setShowLightbox}
              isAssignedToEveryone={props.isAssignedToEveryone}
              permissionType="employee"
              modalData={modalData}
            />
            {modalData?.plan_subtask_assignments?.length > 0 && (
              <div>
                <div className="bg-gray-50 rounded-xl px-6">
                  {modalData?.plan_subtask_assignments?.map((subtask, index) => (
                    <div key={index}>
                      {(subtask.is_completed && props.isAssignedToEveryone) ?
                        <div>
                          <TaskNameCardCompletedTask
                            isAssignedToEveryone={props.isAssignedToEveryone}
                            subtask={subtask}
                            index={index}
                            modalData={modalData}
                            setModalData={setModalData}
                            homeCompany={props.homeCompany}
                            setShowLightbox={props.setShowLightbox}
                            setSelectedImage={props.setSelectedImage}
                          />
                        </div> :
                        <div>
                          <TaskNameCardInput
                            isAssignedToEveryone={props.isAssignedToEveryone}
                            subtask={subtask}
                            index={index}
                            modalData={modalData}
                            setModalData={setModalData}
                            homeCompany={props.homeCompany}
                            setShowLightbox={props.setShowLightbox}
                            setSelectedImage={props.setSelectedImage}
                          />
                        </div>
                      }
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex justify-end gap-3 pt-2">
              <button
                className="px-6 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-gray-50 rounded-full text-sm font-medium transition-colors"
                onClick={() => props.handleClose()}
              >
                Cancel
              </button>
              {props.isAssignedToEveryone ? (
                <div>
                  <button
                    className={`px-8 py-2 rounded-full text-sm font-medium transition-colors ${
                      modalData.status === "completed" || modalData.status === "completed_late"
                        ? "bg-slate-200 text-slate-400 cursor-not-allowed"
                        : "bg-violet-700 hover:bg-violet-800 text-white"
                    }`}
                    onClick={() => handleSave(false)}
                    disabled={modalData.status === "completed" || modalData.status === "completed_late"}
                  >
                    Save Answers
                  </button>
                </div>
              ) : (
                <div className="flex gap-3">
                  <button
                    className={`px-6 py-2 rounded-full text-sm font-medium transition-colors ${
                      modalData.status === "completed" || modalData.status === "completed_late"
                        ? "bg-slate-200 text-slate-400 cursor-not-allowed"
                        : "bg-violet-700 hover:bg-violet-800 text-white"
                    }`}
                    onClick={() => handleSave(false)}
                    disabled={modalData.status === "completed" || modalData.status === "completed_late"}
                  >
                    Save
                  </button>
                  <button
                    className={`px-6 py-2 rounded-full text-sm font-medium transition-colors ${
                      modalData.status === "completed" || modalData.status === "completed_late"
                        ? "bg-slate-200 text-slate-400 cursor-not-allowed"
                        : "bg-emerald-600 hover:bg-emerald-700 text-white"
                    }`}
                    onClick={() => handleSave(true)}
                    disabled={modalData.status === "completed" || modalData.status === "completed_late"}
                  >
                    Submit Answers
                  </button>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TaskNameCardEmployeeModal;
