import React from "react";
import TopBarDisplayTitle from "../home/top_bar_components/TopBarDisplayTitle";
import ScheduleDisplayEmployee from "../requests/employee/schedule/ScheduleDisplayEmployee";
import { formatDate } from "../requests/utilities/helpers";
import {
  API_get_schedule,
  API_drop_shift,
  API_rescind_drop_shift,
  API_get_roles,
} from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../utilities/LoadSpinner";

function PublishedScheduleDisplay(props) {
  const [storeId, setStoreId] = React.useState(props.homeStore);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const default_api_calls = {
    schedule: API_get_schedule,
    roleNames: API_get_roles,
  };

  // Get the current date
  let currentDate = new Date();
  // Get the start date of the previous month
  let lowerBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  // Get the end date of the next month
  let upperBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    0
  );

  let lowerBoundDateStr = formatDate(lowerBoundDate);
  let upperBoundDateStr = formatDate(upperBoundDate);

  let api_params = {
    store_id: props.homeStore,
    lower_bound_date: lowerBoundDateStr,
    upper_bound_date: upperBoundDateStr,
    specific_date: formatDate(currentDate),
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      // console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setIsLoading(false);
      setData(tempData);
    } catch (error) {
      // console.log(error);
      //throw Error("Promise failed");
    }
  };

  // ----------- POST APIs ----------- //
  const drop_shift = async (shift_drop_id, shift_pickup_id, drop_note) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
      ...(shift_pickup_id && { shift_pickup_id: shift_pickup_id }),
      drop_note: drop_note,
    };

    try {
      toast.loading("Submitting Add to Pool Request...", { autoClose: false });

      const res = await postRightworkAPI(API_drop_shift, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Add to Pool Request Submitted!", { autoClose: 3000 });

        fetch_data(
          {
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const rescind_drop_shift = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Cancelling Add to Pool Request...", { autoClose: false });

      const res = await postRightworkAPI(API_rescind_drop_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Add to Pool Request Cancelled!", { autoClose: 3000 });
        fetch_data(
          {
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const trade_shift = async (shift_drop_id, shift_pickup_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
      ...(shift_pickup_id && { shift_pickup_id: shift_pickup_id }),
    };

    try {
      toast.loading("Submitting Trade Request...", { autoClose: false });

      const res = await postRightworkAPI(API_drop_shift, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Trade Request Submitted!", { autoClose: 3000 });

        fetch_data(
          {
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const rescind_trade_shift = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Cancelling Trade Request...", { autoClose: false });

      const res = await postRightworkAPI(API_rescind_drop_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Trade Request Cancelled!", { autoClose: 3000 });
        fetch_data(
          {
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }
      console.error("Error:", error); // Handle error as needed
    }
  };

  const myId = data.schedule?.redux?.payload?.me?.store_team_member?.id;
  const scheduleData = data.schedule?.redux?.payload?.schedule;

  let notMyUpcomingShifts = [];
  if (scheduleData) {
    notMyUpcomingShifts = scheduleData.filter((shift) => {
      // Include the item if its date is today or in the future
      return (
        (shift.date >= formatDate(currentDate)) &
        (shift.store_team_member?.id !== myId)
      );
    });
  }

  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
  }, [props.homeStore]);

  return (
    <>
      {!isLoading ? (
        <div>
          <div>
            <TopBarDisplayTitle
              title="Published Schedule"
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div>
          <div className="mt-12">
            <ScheduleDisplayEmployee
              scheduleData={scheduleData}
              notMyUpcomingShifts={notMyUpcomingShifts}
              drop_shift={drop_shift}
              trade_shift={trade_shift}
              rescind_drop_shift={rescind_drop_shift}
              rescind_trade_shift={rescind_trade_shift}
              myId={myId}
              roleNames={data.roleNames}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default PublishedScheduleDisplay;
