import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ForgotPasswordReset from "./password_reset/ForgotPasswordReset";
import ForgotPasswordResend from "./password_reset/ForgotPasswordResend";
import * as actions from "./store/actions/auth";

import { connect } from "react-redux";
import ForgotPasswordChange from "./password_reset/ForgotPasswordChange";
import ForgotPasswordSuccess from "./password_reset/ForgotPasswordSuccess";

// {"Copyright © "}
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="#64748b" href="https://getrightwork.com/">
        RightWork {new Date().getFullYear()}
      </Link>
    </Typography>
  );
}

const HandleSubmit = (onAuth, username, pwd) => {
  onAuth(username, pwd);
  //history.push("/");
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://rightworkpublic.s3.us-west-1.amazonaws.com/login_photo_upscaled.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login(props) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  const classes = useStyles();

  const [passwordResetFlow, setPasswordResetFlow] = React.useState(0);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  useEffect(() => {
    // Assuming the URL is of the format: http://yourdomain.com/reset-password/:uid/:token
    if (props.token && props.uid) {
      setPasswordResetFlow(3);
    }
  }, [props.token, props.uid]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      {passwordResetFlow === 0 ? (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className="w-full h-full flex flex-col items-center justify-center relative pb-10">
              <div className="w-full flex justify-center">
                <div className="w-content">
                  <img
                    src="https://rightworkpublic.s3.us-west-1.amazonaws.com/full_logo_with_text.png"
                    alt="Rightwork Logo"
                    width={340}
                  />
                </div>
              </div>
              <div className="w-full flex justify-center mt-12">
                <div className="w-[85%]">
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      HandleSubmit(props.onAuth, username.toLowerCase(), pwd);
                    }}
                  >
                    <div>
                      <p className="text-slate-500 text-sm">Email Address</p>
                      <div className="mt-1.5">
                        <TextField
                          variant="outlined"
                          // margin="normal"
                          required
                          fullWidth
                          id="username"
                          // label="Email Address"
                          placeholder="email@email.com"
                          name="username"
                          autoComplete="email"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          autoFocus
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "12px !important",
                              height: "55px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                              backgroundColor: "white",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="text-slate-500 text-sm">Password</p>
                      <div className="mt-1.5">
                        <TextField
                          variant="outlined"
                          // margin="normal"
                          required
                          fullWidth
                          name="password"
                          // label="Password"
                          placeholder="Enter password"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          value={pwd}
                          onChange={(e) => setPwd(e.target.value)}
                          autoComplete="current-password"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "12px !important",
                              height: "55px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                              backgroundColor: "white",
                            },
                          }}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "22px" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "22px" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <Button
                        type="submit"
                        onClick={(e) =>
                          HandleSubmit(
                            props.onAuth,
                            username.toLowerCase(),
                            pwd
                          )
                        }
                        fullWidth
                        variant="contained"
                        //color="primary"
                        className={classes.submit}
                        sx={{
                          textTransform: "none",
                          borderRadius: "50px",
                          paddingY: "14px",
                          backgroundColor: "#6d28d9",
                          "&:hover": {
                            backgroundColor: "#5b21b6",
                          },
                          fontSize: "16px",
                        }}
                      >
                        <span style={{ color: "white" }}>Sign In</span>
                      </Button>
                    </div>
                    <div className="w-full mt-8 flex justify-center">
                      <button
                        className="text-slate-500 hover:text-slate-900 text-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPasswordResetFlow(1);
                        }}
                      >
                        Forgot Password?
                      </button>
                    </div>
                    <Grid container>
                      <Grid item xs></Grid>
                    </Grid>
                  </form>
                </div>
              </div>
              <div className="w-full absolute bottom-10">
                <Copyright />
              </div>
            </div>
            {/* <div className={classes.paper}>
              <Avatar
                className={classes.avatar}
                style={{ backgroundColor: "#EB5E55" }}
              >
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <form
                className={classes.form}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  HandleSubmit(props.onAuth, username.toLowerCase(), pwd);
                }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Email Address"
                  name="username"
                  autoComplete="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  autoComplete="current-password"
                />
                <div className="mt-2">
                  <Button
                    type="submit"
                    onClick={(e) =>
                      HandleSubmit(props.onAuth, username.toLowerCase(), pwd)
                    }
                    fullWidth
                    variant="contained"
                    //color="primary"
                    className={classes.submit}
                    style={{ backgroundColor: "#1C2541", padding: "10px 0px" }}
                  >
                    <span style={{ color: "white" }}>Sign In</span>
                  </Button>
                </div>
                <div className="w-full flex justify-end">
                  <button
                    className="text-slate-500 hover:text-slate-900 text-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      // console.log("RESET PRESSED");
                      setPasswordResetFlow(1);
                    }}
                  >
                    Forgot Password?
                  </button>
                </div>
                <Grid container>
                  <Grid item xs></Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </div> */}
          </Grid>
        </Grid>
      ) : passwordResetFlow === 1 ? (
        <ForgotPasswordReset
          email={email}
          setEmail={setEmail}
          setPasswordResetFlow={setPasswordResetFlow}
        />
      ) : passwordResetFlow === 2 ? (
        <ForgotPasswordResend
          email={email}
          setPasswordResetFlow={setPasswordResetFlow}
        />
      ) : passwordResetFlow === 3 ? (
        <ForgotPasswordChange
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          setPasswordResetFlow={setPasswordResetFlow}
          token={props.token}
          uid={props.uid}
        />
      ) : (
        <ForgotPasswordSuccess setPasswordResetFlow={setPasswordResetFlow} />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) =>
      dispatch(actions.authLogin(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
