import React from 'react';
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";

const ScheduleWarningButton = ({
  countOfWarnings,
  showWarnings,
  toggleShowWarnings
}) => {
  const totalWarnings = countOfWarnings.countOfOvertime + countOfWarnings.countOfConflicts;

  const createWarningText = () => {
    const parts = [];

    if (countOfWarnings.countOfOvertime > 0) {
      parts.push(`${countOfWarnings.countOfOvertime} ${
        countOfWarnings.countOfOvertime === 1 ? 'person' : 'people'
      } in overtime`);
    }

    if (countOfWarnings.countOfConflicts > 0) {
      parts.push(`${countOfWarnings.countOfConflicts} availability ${
        countOfWarnings.countOfConflicts === 1 ? 'conflict' : 'conflicts'
      }`);
    }

    return parts.join(' • ');
  };

  if (totalWarnings === 0) return null;

  return (
    <div className="flex-1 flex items-center justify-center">
      <Tooltip
        title="Click to bring affected employees to the top"
        placement="bottom"
      >
        <button
          onClick={toggleShowWarnings}
          className={`
            inline-flex items-center gap-2 px-3 py-1.5
            rounded-lg border transition-all duration-200
            ${showWarnings
              ? 'bg-rose-600 text-white border-rose-600 hover:bg-rose-700'
              : 'bg-rose-50 border-rose-200 text-rose-700 hover:bg-rose-100'
            }
          `}
        >
          <WarningIcon style={{ fontSize: "16px" }} />
          <span className="text-xs">
            {createWarningText()}
          </span>
        </button>
      </Tooltip>
    </div>
  );
};

export default ScheduleWarningButton;
