import React, { useState, useEffect } from "react";
import DaypartSelector from "./DaypartSelector";
import ScheduledNameCard from "./ScheduledNameCard";
import IconButton from "@mui/material/IconButton";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip from "@mui/material/Tooltip";

function DailyPlanInfo(props) {
  const [assignedRoles, setAssignedRoles] = useState({});
  const [noShows, setNoShows] = useState([]);
  const [dayparts, setDayparts] = useState([]);
  const [selectedDaypart, setSelectedDaypart] = useState(null);

  useEffect(() => {
    const sortedDayparts = [...props.dayparts].sort(
      (a, b) => a.start_time - b.start_time
    );

    setDayparts(sortedDayparts);
    setSelectedDaypart(sortedDayparts[0]);
  }, [props.dayparts]);

  useEffect(() => {
    const convertedRoleAssignments = props.roleAssignments.reduce(
      (acc, assignment) => {
        const daypart = props.dayparts.find(
          (daypart) => daypart.id === assignment.daypart
        );
        const daypartName = daypart ? daypart.name : null;

        const employee = props.combinedData.find(
          (employee) =>
            employee.store_team_member_id === assignment.store_team_member
        );
        const employeeName = employee ? employee.name : null;

        if (daypartName && employeeName) {
          if (!acc[daypartName]) {
            acc[daypartName] = {};
          }

          if (!acc[daypartName][assignment.deployment_role]) {
            acc[daypartName][assignment.deployment_role] = [];
          }

          acc[daypartName][assignment.deployment_role].push(employeeName);
        }
        return acc;
      },
      {}
    );

    setAssignedRoles(convertedRoleAssignments);
  }, [props.roleAssignments]);

  useEffect(() => {
    const noShowEmployeeNames = props.noShows.map((noShow) => {
      const employee = props.combinedData.find(
        (teamMember) =>
          teamMember.store_team_member_id === noShow.store_team_member
      );

      return employee ? employee.name : "Employee not found";
    });
    setNoShows(noShowEmployeeNames);
  }, [props.noShows]);

  const toggleNoShow = (name) => {
    setNoShows((prevNoShows) => {
      if (prevNoShows.includes(name)) {
        const newNoShows = prevNoShows.filter(
          (noShowName) => noShowName !== name
        );
        const employee = props.combinedData.find(
          (teamMember) => teamMember.name === name
        );
        props.saveNoShow(employee.store_team_member_id, false);
        return newNoShows;
      } else {
        const newNoShows = [...prevNoShows, name];
        const employee = props.combinedData.find(
          (teamMember) => teamMember.name === name
        );
        props.saveNoShow(employee.store_team_member_id, true);
        return newNoShows;
      }
    });
  };

  const handleDaypartSelection = (daypart) => {
    setSelectedDaypart(daypart);
  };

  const handleNewDaypart = (name, start_time) => {
    const newDaypart = { name, start_time };
    const sortedDayparts = [...dayparts, newDaypart].sort(
      (a, b) => a.start_time - b.start_time
    );

    const updatedDayparts = sortedDayparts.map((daypart, index) => {
      if (index < sortedDayparts.length - 1) {
        return { ...daypart, end_time: sortedDayparts[index + 1].start_time };
      } else {
        return { ...daypart, end_time: 24 };
      }
    });
    props.saveDayparts(updatedDayparts);
    setDayparts(updatedDayparts);

    // Initialize assignedRoles for the new daypart
    setAssignedRoles((prevAssignedRoles) => ({
      ...prevAssignedRoles,
      [name]: {},
    }));

    // Set selectedDaypart to the new daypart
    setSelectedDaypart(updatedDayparts[0]);
  };

  const handleDeleteDaypart = () => {
    if (dayparts.length === 1) {
      alert("You must have at least one daypart.");
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to delete this daypart for this location?"
    );

    if (!confirmation) {
      return;
    }

    const updatedDayparts = dayparts.filter(
      (daypart) => daypart.name !== selectedDaypart.name
    );

    props.deleteDaypart(selectedDaypart.id);

    // Recalculate the end times for the remaining dayparts
    const daypartsToUpdate = updatedDayparts.map((daypart, index) => {
      if (index < updatedDayparts.length - 1) {
        return { ...daypart, end_time: updatedDayparts[index + 1].start_time };
      } else {
        return { ...daypart, end_time: 24 };
      }
    });

    setDayparts(daypartsToUpdate);
    props.saveDayparts(daypartsToUpdate);

    // Remove the assignedRoles for the deleted daypart
    setAssignedRoles((prevAssignedRoles) => {
      const newAssignedRoles = { ...prevAssignedRoles };
      delete newAssignedRoles[selectedDaypart.name];
      return newAssignedRoles;
    });

    // Set the selectedDaypart to the first daypart in the updated list
    setSelectedDaypart(daypartsToUpdate[0]);
  };

  const autoAssign = () => {
    const unassigned = getFilteredSchedule(props.combinedData).filter(
      (employee) =>
        !isAssigned(employee.name) && !noShows.includes(employee.name)
    );

    const roleIds = [].concat(
      ...props.roleTypes.map((role_type) =>
        props.getRolesByType(role_type.id).map((role) => role.id)
      )
    );

    if (roleIds.length === 0) {
      return;
    }

    unassigned.forEach((employee) => {
      const randomRoleId = roleIds[Math.floor(Math.random() * roleIds.length)];

      setAssignedRoles((prevAssignedRoles) => {
        const newRoles = {
          ...prevAssignedRoles,
          [selectedDaypart?.name]: {
            ...prevAssignedRoles[selectedDaypart?.name],
          },
        };

        if (!newRoles[selectedDaypart?.name][randomRoleId]) {
          newRoles[selectedDaypart?.name][randomRoleId] = [];
        }

        newRoles[selectedDaypart?.name][randomRoleId].push(employee.name);
        return newRoles;
      });
    });
  };

  const getFilteredSchedule = (schedule) => {
    if (!selectedDaypart) {
      return schedule;
    }

    return schedule.filter((shift) => {
      const shiftStart = shift.shift_start;
      const shiftEnd = shift.shift_end;

      return (
        (shiftStart >= selectedDaypart.start_time &&
          shiftStart < selectedDaypart.end_time) ||
        (shiftEnd > selectedDaypart.start_time &&
          shiftEnd <= selectedDaypart.end_time) ||
        (shiftStart <= selectedDaypart.start_time &&
          shiftEnd >= selectedDaypart.end_time)
      );
    });
  };

  // Drag and drop functionality
  const onDragStart = (event, name, roleId) => {
    event.dataTransfer.setData("name", name);
    event.dataTransfer.setData("roleId", roleId);
  };

  const onDrop = (event, roleId) => {
    event.preventDefault();

    const name = event.dataTransfer.getData("name");
    const fromRoleId = parseInt(event.dataTransfer.getData("roleId"), 10);

    setAssignedRoles((prevAssignedRoles) => {
      const newRoles = {
        ...prevAssignedRoles,
        [selectedDaypart?.name]: {
          ...prevAssignedRoles[selectedDaypart?.name],
        },
      };

      if (!isNaN(fromRoleId)) {
        newRoles[selectedDaypart?.name][fromRoleId] = newRoles[
          selectedDaypart?.name
        ][fromRoleId].filter((assignedName) => assignedName !== name);
      }

      if (!newRoles[selectedDaypart?.name][roleId]) {
        newRoles[selectedDaypart?.name][roleId] = [];
      }

      newRoles[selectedDaypart?.name][roleId].push(name);
      return newRoles;
    });

    // Call the API to update the role assignment

    // Retrieve daypartId
    const daypartId = selectedDaypart?.id;

    // Retrieve storeTeamMemberId
    const employee = props.combinedData.find(
      (teamMember) => teamMember.name === name
    );
    const storeTeamMemberId = employee?.store_team_member_id;

    // Retrieve deploymentRoleId
    const deploymentRoleId = roleId;

    // Retrieve assignedDate
    // You can either pass the assignedDate as a prop from the parent component or use the current date
    props.updateRoleAssignment(daypartId, storeTeamMemberId, deploymentRoleId);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const isAssigned = (name) => {
    if (!selectedDaypart || !assignedRoles[selectedDaypart.name]) {
      return false;
    }

    const rolesInDaypart = assignedRoles[selectedDaypart.name];

    return Object.values(rolesInDaypart).some((namesArray) => {
      if (!Array.isArray(namesArray)) {
        return false;
      }
      return namesArray.includes(name);
    });
  };

  const getRoleAssignmentId = (roleId, name) => {
    const employee = props.combinedData.find(
      (employee) => employee.name === name
    );

    if (!employee) return null;

    const assignment = props.roleAssignments.find(
      (assignment) =>
        assignment.daypart === selectedDaypart.id &&
        assignment.store_team_member === employee.store_team_member_id &&
        assignment.deployment_role === roleId
    );

    return assignment ? assignment.id : null;
  };

  const unassignRole = (roleIndex, name) => {
    const newAssignedRoles = { ...assignedRoles[selectedDaypart.name] };
    if (newAssignedRoles[roleIndex]) {
      const assignmentId = getRoleAssignmentId(roleIndex, name);
      props.deleteRoleAssignment(assignmentId);
      newAssignedRoles[roleIndex] = newAssignedRoles[roleIndex].filter(
        (assignedName) => assignedName !== name
      );

      if (newAssignedRoles[roleIndex].length === 0) {
        delete newAssignedRoles[roleIndex];
      }

      setAssignedRoles({
        ...assignedRoles,
        [selectedDaypart.name]: {
          ...newAssignedRoles,
        },
      });
    }
  };

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  const unassignedPeople = getFilteredSchedule(props.combinedData).filter(
    (employee) => !isAssigned(employee.name) && !noShows.includes(employee.name)
  );

  const assignedPeople = getFilteredSchedule(props.combinedData).filter(
    (employee) => isAssigned(employee.name)
  );

  // console.log(unassignedPeople);

  return (
    <div>
      <DaypartSelector
        dayparts={dayparts}
        selectedDaypart={selectedDaypart}
        onSelect={handleDaypartSelection}
        onAdd={handleNewDaypart}
        onDelete={handleDeleteDaypart}
      />

      <div className="flex">
        <div>
          <div className="mb-10">
            <div className="flex justify-between items-center">
              <h3 className="mb-1 text-gray-500">Unassigned</h3>
              <Tooltip title="Auto-assign to roles">
                <IconButton
                  onClick={() =>
                    props.autoAssignRoles(selectedDaypart.id, unassignedPeople)
                  }
                >
                  <AutoFixHighIcon />{" "}
                </IconButton>
              </Tooltip>
            </div>
            <hr className="mb-4"></hr>
            {unassignedPeople.length > 0 ? (
              unassignedPeople.map((employee) => (
                <ScheduledNameCard
                  key={employee.id}
                  employee={employee}
                  onDragStart={onDragStart}
                  formatTime={formatTime}
                  selectedDaypart={selectedDaypart}
                  isAssigned={false}
                  onNoShowClick={() => toggleNoShow(employee.name)}
                />
              ))
            ) : (
              <p className="text-sm text-gray-300">No people found.</p>
            )}
          </div>
          <div className="mb-10">
            <h3 className="mb-1 text-gray-500">Assigned</h3>
            <hr className="mb-4"></hr>
            {assignedPeople.length > 0 ? (
              assignedPeople.map((employee) => (
                <ScheduledNameCard
                  key={employee.id}
                  employee={employee}
                  onDragStart={onDragStart}
                  formatTime={formatTime}
                  selectedDaypart={selectedDaypart}
                  isAssigned={true}
                />
              ))
            ) : (
              <p className="text-sm text-gray-300">No people found.</p>
            )}
          </div>
          <div>
            <h3 className="mb-1 text-gray-500">No Shows</h3>
            <hr className="mb-4"></hr>
            {noShows.length > 0 ? (
              noShows.map((noShowName) => {
                const noShowEmployee = props.combinedData.find(
                  (employee) => employee.name === noShowName
                );
                return (
                  <ScheduledNameCard
                    key={noShowEmployee.id}
                    employee={noShowEmployee}
                    onDragStart={onDragStart}
                    formatTime={formatTime}
                    selectedDaypart={selectedDaypart}
                    isAssigned={false}
                    onNoShowClick={() => toggleNoShow(noShowName)}
                    isNoShow={true}
                  />
                );
              })
            ) : (
              <p className="text-sm text-gray-300">No people found.</p>
            )}
          </div>
        </div>
        <div className="ml-20 text-gray-500">
          {props.roleTypes.map((typeId) => (
            <div key={typeId.id}>
              <h3 className="mb-5 mt-6">{typeId.role_type}</h3>
              <div className="grid grid-cols-3 gap-5 mb-5">
                {props.getRolesByType(typeId.id).map((role) => (
                  <div
                    key={role.id}
                    className="border border-gray-300 bg-gray-100 p-2 rounded relative min-w-[200px]"
                    onDrop={(event) => onDrop(event, role.id)}
                    onDragOver={onDragOver}
                  >
                    <div className="bg-sky-800 text-white font-bold p-2 rounded">
                      {role.deployment_role_name}
                    </div>
                    {assignedRoles[selectedDaypart?.name] &&
                      assignedRoles[selectedDaypart?.name][role.id] &&
                      assignedRoles[selectedDaypart?.name][role.id].map(
                        (assignedName, index) => {
                          const assignedEmployee = props.combinedData.find(
                            (employee) => employee.name === assignedName
                          );

                          return (
                            <div
                              key={index}
                              className="mt-2"
                              onClick={() =>
                                unassignRole(role.id, assignedName)
                              }
                            >
                              {assignedEmployee && (
                                <ScheduledNameCard
                                  employee={assignedEmployee}
                                  onDragStart={(event, name) =>
                                    onDragStart(event, assignedName, role.id)
                                  }
                                  formatTime={formatTime}
                                  selectedDaypart={selectedDaypart}
                                  isAssigned={false}
                                  isNoShow={noShows.includes(assignedName)}
                                />
                              )}
                            </div>
                          );
                        }
                      )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default DailyPlanInfo;
