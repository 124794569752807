import React, { useState, useEffect } from "react";
import { defaultProps } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { Menu } from "@mantine/core";
import { QuizOutlined as QuizOutlinedIcon } from "@mui/icons-material";
import { BackHand as BackHandIcon } from "@mui/icons-material";
import { Check as CheckIcon } from "@mui/icons-material";
import { ThumbUp as ThumbUpIcon } from "@mui/icons-material";

export const Checkpoint = (props) => {
  const { block, editor } = props;
  const { checkpointSelected } = block.props;

  const [isChecked, setIsChecked] = React.useState(
    checkpointSelected?.user_has_passed
  );

  // console.log("CHECKPOINT SELECTED", checkpointSelected)

  // console.log("CHECKPOINT SELECTED UUID", props.selectedSectionUUID)

  // console.log("USER TYPE", props.userType)
  // console.log("EMPLOYEEID", props.employeeID)

  return (
    <>
      {!isChecked ? (
        <div
          className="w-full bg-rose-200 py-4 px-5 rounded-lg flex items-center space-x-5"
          contentEditable={false}
        >
          <div className="text-rose-700">
            <BackHandIcon style={{ fontSize: "48px" }} />
          </div>
          <div className="flex-1">
            <p className="text-rose-700 font-bold" style={{ fontSize: "24px" }}>
              STOP
            </p>
            <p className="text-rose-700" style={{ fontSize: "16px" }}>
              This is a checkpoint. Your manager must sign off by checking this
              box before you can continue.
            </p>
          </div>
          <div
            className={`w-10 h-10 border border-slate-200 rounded bg-white ${
              props.userType !== "Employee"
                ? "hover:bg-slate-50 cursor-pointer"
                : ""
            }`}
            onClick={() => {
              if (props.userType !== "Employee") {
                props.post_learning_block_user_passed(
                  checkpointSelected.uuid,
                  true,
                  props.employeeID
                );
                setIsChecked(true);
              }
            }}
          ></div>
        </div>
      ) : (
        <div
          className="w-full bg-emerald-200 py-4 px-5 rounded-lg flex items-center space-x-5"
          contentEditable={false}
        >
          <div className="text-emerald-700">
            <ThumbUpIcon style={{ fontSize: "48px" }} />
          </div>
          <div className="flex-1">
            <p
              className="text-emerald-700 font-bold"
              style={{ fontSize: "24px" }}
            >
              GO
            </p>
            <p className="text-emerald-700" style={{ fontSize: "16px" }}>
              Congratulations, your manager has marked this checkpoint complete.
              You may continue!
            </p>
          </div>
          <div className="w-10 h-10 border border-white rounded flex items-center justify-center bg-emerald-600 text-white">
            <CheckIcon style={{ fontSize: "26px" }} />
          </div>
        </div>
      )}
    </>
  );
};
