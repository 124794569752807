import React from "react";
import SettingsHeader from "../department_role_station_settings/SettingsHeader";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import { PurpleSwitch } from "../../../helpers/helpers";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

function ManageLocationsDisplay(props) {
  const [locationData, setLocationData] = React.useState(props.locationsData);

  const default_data = {
    store_name: null,
    latitude: null,
    longitude: null,
    onboarding_status: "setting_up",
    mobile_app_enabled: false,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  function toggleMobileAppEnabled() {
    setModalData((prevModalData) => ({
      ...prevModalData,
      mobile_app_enabled: !prevModalData.mobile_app_enabled,
    }));
  }

  const getFormattedOnboardingStatus = {
    active: "active",
    inactive: "inactive",
    setting_up: "setting up",
  };

  // console.log("MODAL DATA", modalData)

  const handleOnboardingStatusChange = (event) => {
    const { value } = event.target;
    setModalData((prevState) => ({
      ...prevState,
      onboarding_status: value,
    }));
  };

  function handleSubmit() {
    props.update_store_info(modalData);
    handleClose();
  }

  React.useEffect(() => {
    setLocationData(props.locationsData);
  }, [props.locationsData]);

  return (
    <>
      <div>
        {/* <SettingsHeader title="Manage Locations" /> */}
        <div className="w-full p-6 flex justify-end">
          <Tooltip title="Add location">
            <button
              className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
              onClick={() => {
                setModalData(default_data);
                setModalEdit(false);
                handleOpen();
              }}
            >
              <AddIcon style={{ fontSize: "22px" }} />
            </button>
          </Tooltip>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-[22%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Latitude
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Longitude
                      </th>
                      <th
                        scope="col"
                        className="w-[20%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Mobile App Enabled
                      </th>
                      <th scope="col" className="w- relative py-3.5 pl-3 pr-6">
                        {/* <span className="sr-only">Edit</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {locationData?.map((location, index) => (
                      <tr
                        key={index}
                        className="hover:bg-slate-50 cursor-pointer"
                        onClick={() => {
                          setModalData(location);
                          setModalEdit(true);
                          handleOpen();
                        }}
                      >
                        <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                          <div>{location.store_name}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div>{location.latitude}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div>{location.longitude}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div className="w-fit">
                            <div
                              className={`rounded-lg px-2 py-1 ${
                                location.onboarding_status === "setting_up"
                                  ? "bg-yellow-50 border border-yellow-200"
                                  : location.onboarding_status === "active"
                                  ? "bg-emerald-50 border border-emerald-100"
                                  : "bg-rose-50 border border-rose-100"
                              } flex items-center justify-center`}
                            >
                              <p
                                className={`${
                                  location.onboarding_status === "setting_up"
                                    ? "text-yellow-800"
                                    : location.onboarding_status === "active"
                                    ? "text-emerald-800"
                                    : "text-rose-800"
                                }`}
                              >
                                {
                                  getFormattedOnboardingStatus[
                                    location.onboarding_status
                                  ]
                                }
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div>
                            {location.mobile_app_enabled ? (
                              <div className="w-7 h-7 rounded-full flex items-center justify-center bg-emerald-600">
                                <CheckIcon
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="w-7 h-7 rounded-full flex items-center justify-center bg-slate-200">
                                <CloseIcon
                                  style={{
                                    color: "#94a3b8",
                                    fontSize: "18px",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-5 pl-6 pr-6 text-right text-sm font-medium w-5">
                          <div className="flex items-center justify-end w-fit h-full text-slate-500">
                            <ChevronRightIcon />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="border-b border-slate-200"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={
                modalEdit ? `Edit ${modalData.store_name}` : "Add Location"
              }
              onClose={handleClose}
            />
            <div className="w-full px-6">
              {!modalEdit && (
                <div className="mt-5">
                  <p className="text-slate-500 text-base mt-1">
                    Your Rightwork representative will be reaching out to finish
                    configuring this location
                  </p>
                </div>
              )}
              <div className="mt-5">
                <p className="text-sm text-slate-500">Name</p>
                <div className="mt-1">
                  <TextField
                    // placeholder="Location name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="role_type"
                    value={modalData.store_name}
                    // onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Latitude</p>
                <div className="mt-1">
                  <TextField
                    // placeholder="Latitude"
                    fullWidth
                    variant="outlined"
                    size="small"
                    // name="latitude"
                    value={modalData.latitude}
                    // onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Longitude</p>
                <div className="mt-1">
                  <TextField
                    // placeholder="Longitude"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="longitude"
                    value={modalData.longitude}
                    // onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              {/* <div className="mt-5">
                <p className="text-sm text-slate-500">Status</p>
                <div className="mt-1">
                  <Select
                    fullWidth
                    value={modalData.onboarding_status}
                    // name="status"
                    onChange={handleOnboardingStatusChange}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "1px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      height: "45px",
                      backgroundColor: "white"
                    }}
                  >
                    <MenuItem value="active">
                      Active
                    </MenuItem>
                    <MenuItem value="setting_up">
                      Setting Up
                    </MenuItem>
                    <MenuItem value="inactive">
                      Inactive
                    </MenuItem>
                  </Select>
                </div>
              </div> */}
              <div className="mt-5 flex items-center justify-between">
                <p className="text-slate-900 text-base">Mobile app enabled</p>
                <div>
                  <PurpleSwitch
                    checked={modalData.mobile_app_enabled}
                    onClick={toggleMobileAppEnabled}
                  />
                </div>
              </div>
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Changes" : "Save New Location"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ManageLocationsDisplay;
