import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, storesSelected, theme) {
  return {
    fontWeight:
      storesSelected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectDropdown(props) {
  const theme = useTheme();
  const [storesSelected, setStoresSelected] = React.useState(
    props.contest_info.participating_stores
  );

  useEffect(() => {
    setStoresSelected(props.contest_info.participating_stores);
  }, [props.contest_info.participating_stores]);

  // console.log(storesSelected);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const new_value = typeof value === "string" ? value.split(",") : value;
    setStoresSelected(
      // On autofill we get a stringified value.
      new_value
    );
    props.set_contest_info({
      ...props.contest_info,
      ["participating_stores"]: new_value,
    });
  };

  const get_store_name = (storeId) => {
    const store_info = props.storeNames.filter((s) => s.id === storeId);
    if (store_info.length > 0) {
      return store_info[0]["store_name"];
    } else {
      return null;
    }
  };

  return (
    <div>
      <FormControl sx={{ width: 250 }} size="small">
        <InputLabel id="demo-multiple-name-label">Add Participants</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={storesSelected}
          onChange={handleChange}
          input={<OutlinedInput label="Participating Stores" />}
          MenuProps={MenuProps}
        >
          {props.storeOptions.map((value) => (
            <MenuItem
              key={value.store}
              value={value.store}
              style={getStyles(
                get_store_name(value.store),
                storesSelected,
                theme
              )}
            >
              {get_store_name(value.store)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
