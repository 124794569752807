import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { formatDateReadable } from "../../utilities/helpers";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../../shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../shared/helpers/DrawerFooterContainerLarge";
import { formatDateReadableWithDayOfWeek } from "../../utilities/helpers";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatDate } from "../../utilities/helpers";
import ImageIcon from "@mui/icons-material/Image";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ButtonOutlined from "../../shared/helpers/ButtonOutlined";

function EmployeeProfileCertificationsTab(props) {
  const default_data = {
    expiration_date: "",
    file: "",
    type: "",
    uuid: null,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setModalData(default_data);
    setOpen(false);
    setSelectedFile(null);
  };
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  // Code to show date picker
  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]);

  const handleTypeChange = (event) => {
    setModalData((prevData) => ({
      ...prevData,
      type: event.target.value,
    }));
  };

  const handleDateChange = (newValue) => {
    const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : "";
    setModalData((prevData) => ({
      ...prevData,
      expiration_date: formattedDate,
    }));
  };

  const [selectedFile, setSelectedFile] = React.useState(modalData.file);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Update modalData with the selected file
    setModalData((prevData) => ({
      ...prevData,
      file: file,
    }));
  };

  const truncateFilename = (filename, maxLength) => {
    if (filename?.length <= maxLength) {
      return filename;
    } else {
      return filename?.substring(0, maxLength) + "...";
    }
  };

  // console.log("SELECTED FILE", selectedFile)

  const certificateTypeLookup = {
    other: "Other",
    food_permit: "Food Permit",
    liquor_permit: "Liquor Permit",
    break_waiver: "Break Waiver",
  };

  function handleSubmit() {
    props.post_certificate(
      props.stm_id,
      modalData.uuid ? modalData.uuid : null,
      modalData.type,
      modalData.expiration_date ? modalData.expiration_date : null,
      modalData.file ? modalData.file : null
    );
    handleClose();
  }
  // console.log("MODAL DATA", modalData)

  return (
    <>
      <div className="w-full flex flex-col items-center">
        <div>
          <div className="flex items-center space-x-5">
            <h3 className="text-slate-900 text-2xl font-bold">Documents</h3>
            <div
              className="w-8 h-8 rounded-full bg-slate-100 flex items-center justify-center text-slate-500"
              onClick={() => {
                setModalEdit(false);
                handleOpen();
              }}
            >
              <Tooltip title="Add employee certification">
                <IconButton>
                  <AddIcon style={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-fit">
          {props.certificateData?.length > 0 ? (
            <div className="mt-5 flex space-x-5">
              {props.certificateData?.map((certificate, index) => (
                <div className="bg-white p-5 rounded-xl h-fit relative">
                  <div className="absolute top-3 right-3">
                    <div
                      className="w-8 h-8 rounded-full bg-slate-50 flex items-center justify-center text-slate-500"
                      onClick={() => {
                        setModalData(certificate);
                        setSelectedFile({ name: certificate.file });
                        setModalEdit(true);
                        handleOpen();
                      }}
                    >
                      <Tooltip title="Edit employee certification">
                        <IconButton>
                          <EditIcon style={{ fontSize: "16px" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="absolute top-3 right-12">
                    <div
                      className="w-8 h-8 rounded-full bg-slate-50 flex items-center justify-center text-slate-500"
                      onClick={() => {
                        props.delete_certificate(
                          props.stm_id,
                          certificate.uuid
                        );
                      }}
                    >
                      <Tooltip title="Delete employee certification">
                        <IconButton>
                          <DeleteIcon style={{ fontSize: "16px" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className="text-slate-500 text-sm">Type</p>
                      <p className="text-slate-900 mt-0.5">
                        {certificate.type
                          ? certificateTypeLookup[certificate.type]
                          : "None"}
                      </p>
                    </div>
                    <div className="mt-3">
                      <p className="text-slate-500 text-sm">Expires</p>
                      <p className="text-slate-900 mt-0.5">
                        {certificate.expiration_date
                          ? formatDateReadable(certificate?.expiration_date)
                          : "No Expiration"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="text-slate-500 text-sm">Image</p>
                    {certificate.file ? (
                      <div className="w-[350px] overflow-hidden rounded-xl mt-2">
                        <img
                          src={certificate.file}
                          alt="Certificate Image"
                          style={{ width: "350px" }}
                        />
                      </div>
                    ) : (
                      <div className="w-[350px] h-[200px] bg-slate-200 rounded-xl flex items-center justify-center mt-2">
                        <div className="flex flex-col items-center">
                          <div className="text-slate-900">
                            <InfoIcon />
                          </div>
                          <p className="mt-2 text-slate-900">No Image</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full mt-14 flex justify-center">
              <div className="flex flex-col items-center p-4 bg-white rounded-xl">
                <div className="text-slate-900">
                  <InfoIcon />
                </div>
                <p className="mt-2 text-slate-900">No documents to show</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={modalEdit ? "Edit Document" : "Add Document"}
              onClose={handleClose}
            />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-sm text-slate-500">Type</p>
                <div className="mt-1">
                  <Select
                    fullWidth
                    displayEmpty
                    value={modalData?.type}
                    onChange={handleTypeChange}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "1px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      height: "45px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem disabled value="">
                      Select document type
                    </MenuItem>
                    <MenuItem value="food_permit">Food Permit</MenuItem>
                    <MenuItem value="liquor_permit">Liquor Permit</MenuItem>
                    <MenuItem value="break_waiver">Break Waiver</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Expiration Date</p>
                <div
                  className={`${
                    !showDailyDatePicker ? "border border-white" : ""
                  } flex-1 mt-1`}
                >
                  <div
                    className={`flex-1 bg-white rounded-lg text-sm ${
                      showDailyDatePicker
                        ? "border-2 border-violet-700"
                        : "border border-[#bdbdbd] hover:border-slate-900"
                    } cursor-pointer relative`}
                    ref={datePickerRef}
                  >
                    <div
                      className="w-full h-full px-3.5 py-2.5"
                      onClick={toggleDailyDatePicker}
                    >
                      <div className="w-full h-full flex items-center justify-between">
                        <p className="text-[16px]">
                          {modalData.expiration_date
                            ? formatDateReadable(modalData?.expiration_date)
                            : "Click to select"}
                        </p>
                        <div className="text-slate-500">
                          <CalendarTodayIcon
                            style={{ fontSize: "18px", marginBottom: "2px" }}
                          />
                        </div>
                      </div>
                    </div>
                    {showDailyDatePicker && (
                      <div className="absolute top-10 left-0 bg-white z-10 flex items-center justify-center pl-4 pr-5 border border-[#bdbdbd] rounded-lg shadow-xl overflow-hidden">
                        <div className="mt-3">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DateCalendar", "DateCalendar"]}
                            >
                              <DemoItem>
                                <DateCalendar
                                  value={
                                    modalData.expiration_date
                                      ? dayjs(modalData.expiration_date)
                                      : null
                                  }
                                  onChange={handleDateChange}
                                  slotProps={{
                                    day: {
                                      sx: {
                                        "&.MuiPickersDay-root.Mui-selected": {
                                          backgroundColor: "#6d28d9",
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoItem>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">
                  Upload Image of Certification Card
                </p>
                <div className="mt-10">
                  {/* Hidden file input styled as a button */}
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer border border-dashed border-slate-400 text-slate-900 hover:text-violet-700 py-8 px-16 rounded-lg hover:border hover:border-solid hover:border-violet-700 transition duration-100 ease-in-out"
                  >
                    <span>
                      <FileUploadOutlinedIcon
                        style={{ marginBottom: "2px", marginRight: "4px" }}
                      />
                    </span>
                    <span>{selectedFile ? "Change File" : "Browse Files"}</span>
                  </label>
                  {/* Actual file input (hidden) */}
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png"
                  />
                  {modalData.file && (
                    <div className="mt-16">
                      <p className="text-slate-900 text-sm font-bold">
                        Uploaded
                      </p>
                      <div className="flex items-center space-x-1.5 mt-2">
                        <div className="text-violet-700">
                          <ImageIcon style={{ marginBottom: "1px" }} />
                        </div>
                        <p className="text-sm text-slate-500">
                          {truncateFilename(selectedFile.name, 50)}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[400px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Changes" : "Create Certificate"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default EmployeeProfileCertificationsTab;
