import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ModuleInputsModal from "./ModuleInputsModal";
import ModulePreviewModal from "./ModulePreviewModal";
import { formatDate } from "../requests/utilities/helpers";
import { formatDateReadable } from "../requests/utilities/helpers";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function ModulesDisplay(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalEdit, setModalEdit] = React.useState(false);

  const [selectedModule, setSelectedModule] = React.useState(null);
  const [openModulePreviewModal, setOpenModulePreviewModal] =
    React.useState(false);
  const handleOpenModulePreviewModal = () => setOpenModulePreviewModal(true);
  const handleCloseModulePreviewModal = () => setOpenModulePreviewModal(false);

  const columns = [
    {
      field: "color",
      headerName: "Color",
      flex: 0.06,
      sortable: true,
      filterable: false,
      renderCell: (params) => (
        <div className={`w-5 h-5 ${params.value} rounded-full`}></div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.2,
      sortable: true,
      filterable: true,
      hideable: false,
    },
    {
      field: "created_at",
      headerName: "Date Created",
      flex: 0.2,
      sortable: true,
      filterable: true,
    },
    {
      field: "updated_at",
      headerName: "Date Updated",
      flex: 0.2,
      sortable: true,
      filterable: true,
    },
    {
      field: "sections_transformed",
      headerName: "Sections",
      flex: 0.3,
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line" }}>{params.value}</div>
      ),
    },
    {
      field: "tracks_transformed",
      headerName: "In Tracks",
      flex: 0.3,
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line" }}>{params.value}</div>
      ),
    },
    {
      field: "Actions",
      headerName: "",
      width: 110,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <div className="flex items-center space-x-5 ml-3">
          <Tooltip title="Edit module">
            <div
              className="text-slate-500 hover:text-slate-900 cursor-pointer"
              onClick={() => {
                setModalEdit(true);
                props.setModalDataModule(params.row);
                handleOpen();
              }}
            >
              <EditIcon style={{ fontSize: "22px" }} />
            </div>
          </Tooltip>
          <Tooltip title="Preview module">
            <div
              className="text-slate-500 hover:text-slate-900 cursor-pointer"
              onClick={() => {
                props.setModalDataModule(params.row);
                setSelectedModule(params.row);
                handleOpenModulePreviewModal();
              }}
            >
              <SlideshowIcon style={{ fontSize: "22px" }} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows = props.modulesData?.map((module, index) => ({
    id: index,
    name: module.name,
    created_at: formatDate(new Date(module.created_at)),
    updated_at: formatDate(new Date(module.updated_at)),
    sections: module.sections,
    sections_transformed:
      module.sections.length > 0
        ? module.sections.map((section) => section.name).join("\n")
        : "No sections",
    tracks: module.tracks,
    tracks_transformed:
      module.tracks.length > 0
        ? module.tracks.map((track) => track.name).join("\n")
        : "Unassigned",
    color: module.color,
    uuid: module.uuid,
  }));

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6d28d9",
      },
    },
  });

  // console.log("MODULES DATA", props.modulesData)

  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <div className="w-full px-6 mt-10 flex items-center justify-between">
            <h2 className="text-4xl text-slate-900">Modules</h2>
            <Tooltip title="Create new module">
              <button
                className="bg-violet-700 hover:bg-violet-800 text-white h-12 w-12 rounded-full"
                onClick={() => {
                  setModalEdit(false);
                  props.setModalDataModule(props.default_data_module);
                  handleOpen();
                }}
              >
                <AddIcon style={{ fontSize: "26px" }} />
              </button>
            </Tooltip>
          </div>
          <div className="mt-8" style={{ width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              autoHeight
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    created_at: false,
                    updated_at: false,
                  },
                },
              }}
              getRowHeight={() => "auto"}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </div>
        </div>
      </ThemeProvider>
      <ModuleInputsModal
        open={open}
        handleClose={handleClose}
        modalEdit={modalEdit}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        homeCompany={props.homeCompany}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        sectionsData={props.sectionsData}
        post_learning_module={props.post_learning_module}
        delete_learning_module={props.delete_learning_module}
        post_learning_section={props.post_learning_section}
        delete_learning_section={props.delete_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
      />
      <ModulePreviewModal
        open={openModulePreviewModal}
        handleClose={handleCloseModulePreviewModal}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        isLoading={false}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        homeCompany={props.homeCompany}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        sectionsData={props.sectionsData}
        post_learning_module={props.post_learning_module}
        delete_learning_module={props.delete_learning_module}
        post_learning_section={props.post_learning_section}
        delete_learning_section={props.delete_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
        selectedModule={selectedModule}
      />
    </>
  );
}

export default ModulesDisplay;
