import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import CloseIcon from "@mui/icons-material/Close";

import AutoScheduleDiffs from "./AutoScheduleDiffs";
import { getColorFromGrade } from "../../../../../helpers/helpers";
import LoadSpinner from "../../../../../utilities/LoadSpinner";
import CircularProgress from "@mui/material/CircularProgress";

function AutoScheduleComparison(props) {
  const [showSummary, setShowSummary] = React.useState(false);
  const modalRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowSummary(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleShowSummary = () => {
    setShowSummary((prevState) => !prevState);
  };

  const oldGrade = props.calcLetter(
    props.schedule_differences.metrics.old_sched.score
  );
  const newGrade = props.calcLetter(
    props.schedule_differences.metrics.new_sched.score
  );
  const percentGradeDifference = (
    props.schedule_differences.metrics.new_sched.score -
    props.schedule_differences.metrics.old_sched.score
  ).toFixed(2);

  const oldHours = props.schedule_differences.metrics.old_sched.hours;
  const newHours = props.schedule_differences.metrics.new_sched.hours;
  const amountHoursDifference =
    props.schedule_differences.metrics.new_sched.hours -
    props.schedule_differences.metrics.old_sched.hours;

  const oldShifts = props.schedule_differences.metrics.old_sched.shifts;
  const newShifts = props.schedule_differences.metrics.new_sched.shifts;
  const amountShiftsDifference =
    props.schedule_differences.metrics.new_sched.shifts -
    props.schedule_differences.metrics.old_sched.shifts;

  // console.log("SCHED DIFF", props.schedule_differences.sched_dif);
  return (
    <div className="w-full h-full px-10">
      <div className="w-full flex items-center justify-between relative">
        <h2 className="text-2xl">Schedule Comparison</h2>
        <div
          className="pl-8 pr-7 py-2 border border-slate-200 rounded-full flex items-center space-x-2 relative cursor-pointer"
          onClick={toggleShowSummary}
        >
          <div className="text-slate-500">Weekly Summary</div>
          <div className="text-slate-500">
            {showSummary ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>
        {showSummary && (
          <div
            ref={modalRef}
            className="absolute w-72 bg-white top-16 right-0 shadow-lg rounded-xl border border-slate-200 p-4"
          >
            <h3 className="text-xl">Weekly Summary</h3>
            <div className="w-full mt-6 bg-slate-50 rounded-xl p-4">
              <h5 className="text-slate-500">New Grade</h5>
              <div
                style={{ backgroundColor: getColorFromGrade(newGrade) }}
                className={`mt-3 w-9 h-9 rounded-lg flex items-center justify-center text-white text-lg`}
              >
                <p>{newGrade}</p>
              </div>
              <p
                className={`mt-3 font-bold ${
                  percentGradeDifference < 0
                    ? "text-rose-800"
                    : "text-emerald-800"
                }`}
              >
                {percentGradeDifference > 0 ? (
                  <NorthIcon style={{ fontSize: "18px" }} />
                ) : (
                  <SouthIcon style={{ fontSize: "18px" }} />
                )}{" "}
                {percentGradeDifference}%{" "}
                <span className="text-slate-500 font-normal">
                  from Grade {oldGrade}
                </span>
              </p>
            </div>
            <div className="w-full mt-2 bg-slate-50 rounded-xl p-4">
              <h5 className="text-slate-500">New Total Hours</h5>
              <p className="text-2xl mt-3">{newHours} hrs</p>
              <p className="mt-3 text-slate-500 font-normal">
                {amountHoursDifference > 0 ? "+" : "-"}{" "}
                {Math.abs(amountHoursDifference)}{" "}
                <span className="text-slate-500 font-normal">
                  from {oldHours} hrs
                </span>
              </p>
            </div>
            <div className="w-full mt-2 bg-slate-50 rounded-xl p-4">
              <h5 className="text-slate-500">New Total Shifts</h5>
              <p className="text-2xl mt-3">{newShifts}</p>
              <p className="mt-3 text-slate-500 font-normal">
                {amountShiftsDifference > 0 ? "+" : "-"}{" "}
                {Math.abs(amountShiftsDifference)}{" "}
                <span className="text-slate-500 font-normal">
                  from {oldShifts} shifts
                </span>
              </p>
            </div>
            <div className="w-full flex mt-3">
              <div className="w-1/2 pr-1">
                <div
                  className="w-full bg-rose-600 hover:bg-rose-700 text-white rounded-full h-[40px] flex items-center justify-center space-x-2 cursor-pointer shadow text-[14px]"
                  onClick={() => props.cancelAndClose()}
                >
                  <div>
                    <CloseIcon
                      style={{ fontSize: "18px", marginBottom: "2px" }}
                    />
                  </div>
                  <p className="pr-1.5">Reject All</p>
                </div>
              </div>
              <div className="w-1/2 pl-1">
                {/* <div
                  className="w-full bg-emerald-600 hover:bg-emerald-700 text-white rounded-full py-2 flex items-center justify-center space-x-1 cursor-pointer"
                  onClick={() => props.saveAndClose()}
                >
                  <div><CheckIcon style={{ fontSize: "18px", marginBottom: "2px" }}/></div>
                  <p className="pr-1.5">Accept All</p>
                </div> */}
                <Button
                  variant="contained"
                  // loading={props.acceptedLoading}
                  // loadingPosition="end"
                  startIcon={<CheckIcon />}
                  onClick={() =>
                    props.saveAndClose(
                      props.schedule_differences.sched_dif.map((day) => ({
                        ...day,
                        status: "accepted",
                      }))
                    )
                  }
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    borderRadius: "20px",
                    fontSize: "14px",
                    height: "40px",
                    backgroundColor: "#059669",
                    ":hover": {
                      backgroundColor: "#047857",
                    },
                  }}
                >
                  {props.acceptedLoading ? (
                    <CircularProgress size={14} sx={{ color: "white" }} />
                  ) : (
                    "Accept All"
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          className="absolute -right-16 -top-4 text-gray-500 cursor-pointer"
          onClick={() => props.handleClose()}
        >
          <CloseIcon />
        </div>
      </div>
      <div>
        {props.schedule_differences.sched_dif.length > 0 ? (
          <AutoScheduleDiffs
            sched_dif={props.schedule_differences.sched_dif}
            calcLetter={props.calcLetter}
            schedule_differences={props.schedule_differences}
            cancelAndClose={props.cancelAndClose}
            acceptedLoading={props.acceptedLoading}
            saveAndClose={props.saveAndClose}
            store_team_member_weekly_info={props.store_team_member_weekly_info}
            get_t_str={props.get_t_str}
            actual_schedule={props.actual_schedule}
            role_types={props.role_types}
            role_names={props.role_names}
          />
        ) : (
          <div class="flex justify-center items-center mt-72">
            <div class="text-xl text-violet-700">
              No opportunities identified by autoscheduler.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AutoScheduleComparison;
