import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import AddTaskIcon from "@mui/icons-material/AddTask";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteIcon from "@mui/icons-material/Delete";
import RolesCapabilitySelector from "./RolesCapabilitySelector";
import PersonAvatar from "./PersonAvatar";
import CloseIcon from "@mui/icons-material/Close";
import { PurpleSwitch } from "../../../../helpers/helpers";
import DrawerHeader from "../../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonOutlined from "../../requests/shared/helpers/ButtonOutlined";

function PersonCardModal(props) {
  const [state, setState] = React.useState(props.p);
  // Role state to be able to change to right format and then load
  const [roleIds, setRoleIds] = React.useState(get_available_roles());
  const [roleRatings, setRoleRatings] = React.useState(get_role_ratings());
  const [targetHoursChecked, setTargetHoursChecked] = React.useState(
    props.p?.target_weekly_hours > 0
  );

  React.useEffect(() => {
    if (props.open) {
      // console.log("UPDATING STATE");
      setState(props.p);
      setRoleIds(get_available_roles());
      setRoleRatings(get_role_ratings());
      setTargetHoursChecked(props.p?.target_weekly_hours > 0);
    }
  }, [props.open]);

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const name = props.p.first_name + " " + props.p.last_name;

  function get_available_roles() {
    return props.p.available_role_info.map((obj) => {
      return obj.scheduled_role;
    });
  }

  function get_role_ratings() {
    return props.role_names.map((obj) => {
      const current_rating = props.p.available_role_info.filter(
        (x) => x.scheduled_role === obj.id
      );
      if (current_rating.length > 0) {
        obj.proficiency_rating = current_rating[0].proficiency_rating;
      } else {
        obj.proficiency_rating = 3;
      }
      return obj;
    });
  }

  const handleToggleChange = (event) => {
    setTargetHoursChecked(event.target.checked);
  };

  function submitAndClose() {
    //('id', 'store_team_member', 'scheduled_role',
    //'main_role_ind', 'proficiency_rating')
    let available_role_info = [];
    roleIds.forEach((id) => {
      let dict = { store_team_member: props.p.id, scheduled_role: id };
      if (id === props.mainRoleId) {
        dict["main_role_ind"] = 1;
      } else {
        dict["main_role_ind"] = 0;
      }
      dict["proficiency_rating"] = getStars(id);
      available_role_info.push(dict);
    });
    // console.log("SAVE!");
    let new_data = { ...state, available_role_info: available_role_info };
    if (!targetHoursChecked) {
      new_data = { ...new_data, target_weekly_hours: 0 };
    }
    props.updatePersonInfo(new_data);
    props.handleClose();
  }

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoleIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getStars = (role_id) => {
    const stars = roleRatings.filter((r) => r.id === role_id);
    if (props.proficiencyFromLMSEnabled) {
      if (stars.length > 0) {
        return stars[0]["training_proficiency_rating"];
      } else {
        return 0;
      }
    } else {
      if (stars.length > 0) {
        return stars[0]["proficiency_rating"];
      } else {
        return 3;
      }
    }
  };

  const getRoleName = (role_id, role_lookups) => {
    const role_info = role_lookups.filter((r) => r.id === role_id);
    if (role_info.length > 0) {
      return role_info[0]["role_name"];
    } else {
      return null;
    }
  };

  function update_role_proficiency(roleId, value) {
    const data = [...roleRatings];
    const new_data = data.map((obj) =>
      obj.id === roleId ? { ...obj, proficiency_rating: value } : obj
    );
    setRoleRatings(new_data);
  }

  return (
    <div>
      <Drawer open={props.open} anchor="right" onClose={props.handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <div className="flex items-center justify-between px-6 py-8">
              <div className="flex items-center space-x-2.5">
                {props.returnAvatar()}
                <h2 className="text-slate-900 text-2xl">{name}</h2>
              </div>
              <div
                className="text-slate-500 cursor-pointer"
                onClick={() => {
                  props.handleClose();
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="w-full px-6">
              <div>
                <h3 className="text-slate-900 text-sm font-bold">
                  Employee Attributes
                </h3>
                <p className="text-slate-500 mt-1.5 text-sm">
                  Select {name}'s capable roles, main role, proficiency, and
                  target hours.
                </p>
              </div>
              <div className="mt-5">
                <RolesCapabilitySelector
                  roleIds={roleIds}
                  mainRoleId={props.mainRoleId}
                  setMainRoleId={props.setMainRoleId}
                  handleRoleChange={handleRoleChange}
                  handleMainRoleSelection={props.handleMainRoleSelection}
                  update_role_proficiency={update_role_proficiency}
                  getStars={getStars}
                  role_names={props.role_names}
                  proficiencyStarsCount={props.proficiencyStarsCount}
                  proficiencyFromLMSEnabled={props.proficiencyFromLMSEnabled}
                />
              </div>
              {/* <div className="mt-5 pb-5 border-b border-slate-200">
                <p className="text-slate-500 text-sm">Wage</p>
                <div className="mt-1">
                  <TextField
                    fullWidth
                    name="hourly_wage"
                    type="number"
                    size="small"
                    value={state.hourly_wage}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        paddingY: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div> */}
              <div className="mt-5">
                <div className="flex items-center justify-between">
                  <h3 className="text-slate-900 text-sm font-bold">
                    Target Hours
                  </h3>
                  <div>
                    <PurpleSwitch
                      checked={targetHoursChecked}
                      onChange={handleToggleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </div>
                {targetHoursChecked && (
                  <div className="mt-1">
                    <TextField
                      required
                      fullWidth
                      id="outlined-required"
                      name="target_weekly_hours"
                      type="number"
                      size="small"
                      value={state.target_weekly_hours}
                      onChange={handleChange}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "8px",
                          paddingY: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="h-[200px]"></div>
            </div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <Button
                  variant="contained"
                  endIcon={<AddTaskIcon />}
                  onClick={() => submitAndClose()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {"Save"}
                </Button>
              </div>
              <div className="mt-3 w-full">
                <ButtonOutlined text="Cancel" onClick={props.handleClose} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default PersonCardModal;

/*
                <TextField
                  required
                  style={{ width: "250px" }}
                  id="outlined-required"
                  label="Minimum Hours"
                  name="min_weekly_hours"
                  type="number"
                  size="small"
                  value={state.min_weekly_hours}
                  onChange={handleChange}
                />
                <TextField
                  required
                  style={{ width: "250px" }}
                  id="outlined-required"
                  label="Maximum Hours"
                  name="max_weekly_hours"
                  type="number"
                  size="small"
                  value={state.max_weekly_hours}
                  onChange={handleChange}
                />
*/
