import React from "react";
import TimeOffMineTable from "../../shared/availability/TimeOffMineTable";


export default function TimeOffDisplayEmployee(props) {

  const myTimeOff = props.timeOffData.filter(
    (v, i) => v.store_team_member.id === props.myId
  );

  const myTimeOffData = myTimeOff;

  return (
    <>
      <div className="w-full mt-2">
        <TimeOffMineTable
          myTimeOffData={myTimeOffData}
          request_time_off={props.request_time_off}
          rescind_time_off_request={props.rescind_time_off_request}
          archive_time_off_request={props.archive_time_off_request}
          minEffectiveDate={props.minEffectiveDate}
        />
      </div>
    </>
  );
}
