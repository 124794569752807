import React, { useState, useRef, useEffect } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';


function FileItem(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [renameMode, setRenameMode] = useState(false);
  const [newFileName, setNewFileName] = useState(props.file.name);

  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current?.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Focus input when entering rename mode
  useEffect(() => {
    if (renameMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [renameMode]);

  const handleContainerClick = (e) => {
    // Only trigger file click if we're not in rename mode and didn't click menu items
    if (!renameMode && !isMenuOpen) {
      props.handleFileClick(props.file.presigned_url);
    }
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    props.delete_file(props.file.uuid);
    setIsMenuOpen(false);
  };

  const handleRenameClick = (e) => {
    e.stopPropagation();
    setRenameMode(true);
    setIsMenuOpen(false);
  };

  const handleRenameConfirm = (e) => {
    e.stopPropagation();
    if (newFileName.trim()) {
      props.rename_file(props.file.uuid, newFileName);
      setRenameMode(false);
    }
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleRenameConfirm(e);
    } else if (e.key === 'Escape') {
      setRenameMode(false);
      setNewFileName(props.file.name);
    }
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const fileType = props.file?.extension;

  const FileIcon = ({ type }) => {
    switch (type) {
      case 'folder':
        return <FolderIcon className="w-6 h-6 text-violet-700" />;
      case 'jpeg':
      case 'jpg' :
      case 'png':
      case 'svg':
        return <ImageIcon className="w-6 h-6 text-green-500" />;
      case 'pdf':
        return <PictureAsPdfIcon className="w-6 h-6 text-red-500" />;
      case 'doc':
      case 'docx':
      case 'txt':
      default:
        return <DescriptionIcon className="w-6 h-6 text-gray-500" />;
    }
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(props.file));
    props.setIsFileDragging(true)
  };

  const handleDragEnd = (e) => {
    props.setIsFileDragging(false)
  };

  console.log("FILE INFO", props.file)

  return (
    <div
      className="p-4 border rounded-lg bg-white hover:bg-gray-50 cursor-pointer relative"
      onClick={handleContainerClick}
      draggable={!renameMode}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="flex items-start space-x-3">
        <FileIcon type={fileType} />
        <div className="flex-1 min-w-0">
          {renameMode ? (
            <div className="flex items-center space-x-2" onClick={handleInputClick}>
              <input
                ref={inputRef}
                type="text"
                value={newFileName}
                onChange={(e) => setNewFileName(e.target.value)}
                onKeyDown={handleInputKeyDown}
                className="border border-gray-300 rounded px-2 py-1 text-sm flex-1 focus:outline-none focus:border-violet-700"
              />
              <button
                onClick={handleRenameConfirm}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <CheckIcon className="w-5 h-5 text-emerald-600" />
              </button>
            </div>
          ) : (
            <p className="text-sm font-medium text-gray-900 truncate">
              {props.file.name}
            </p>
          )}
          <p className="text-xs text-gray-500">
            {props.file.fileSize} • {props.file.created_at_date}
          </p>
        </div>
        {!renameMode && (
          <button
            ref={buttonRef}
            onClick={handleMenuClick}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <MoreVertIcon className="w-5 h-5 text-gray-500" />
          </button>
        )}
      </div>

      {isMenuOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 py-1"
          style={{ top: '100%' }}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              props.handleFileClick(props.file.presigned_url);
              setIsMenuOpen(false);
            }}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Open
          </button>
          <button
            onClick={handleRenameClick}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Rename
          </button>
          <button
            onClick={handleDeleteClick}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
}

export default FileItem;
