import React, { useEffect } from "react";
import PersonCard from "./PersonCard";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import DateCard from "./DateCard";
import EditTeamButton from "./EditTeamButton";
import Switch from "@mui/material/Switch";

import DailyMetricsCard from "./DailyMetricsCard";
import DailyChartCard from "./DailyChartCard";
import VisualButtonOptions from "./VisualButtonOptions";

import PersonDayShiftDisplay from "./PersonDayShiftDisplay";
import PersonDayAvailabilityDisplay from "./PersonDayAvailabilityDisplay";

import SkeletonDisplay from "./SkeletonDisplay";
import { sum } from "d3";
import DailySchedule from "./daily_view/DailySchedule";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Battery3BarIcon from "@mui/icons-material/Battery3Bar";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import NewOpenShiftCard from "./NewOpenShiftCard";
import OpenShiftCard from "./OpenShiftCard";
import { PurpleSwitch, get_t_str } from "../../../../helpers/helpers";
import VizualizeWeeklyAvailOrSched from "./VizualizeWeeklyAvailOrSched";
import VizualizeWeeklySched from "./VizualizeWeeklySched";

import NoStoreTeamMemberDisplay from "./NoStoreTeamMembersDisplay";

const screen_size = window.document.body.clientWidth;
const schedule_size = screen_size - 60 - 40 * 2;
const day_nums = [0, 1, 2, 3, 4, 5, 6];
const date_options = { weekday: "short", month: "short", day: "numeric" };
const today = new Date(new Date().toDateString());
const day_of_week_str = today.toString().substring(0, 3);

function create_new_date(date_str) {
  var myDateArray = date_str.split("-");
  var theDate = new Date(myDateArray[0], myDateArray[1] - 1, myDateArray[2]);
  return theDate;
}

function ScheduleDisplay(props) {
  // EditTeam represents availability
  // Shows the availability display when true, shows the schedule display when false
  const [editTeam, setEditTeam] = React.useState(false);

  // Shows the daily metrics cards at the bottom when true
  const [showDailyMetrics, setShowDailyMetrics] = React.useState(
    !props.minimizedViewDefaultEnabled
  );

  // Toggles between showing daily metrics cards and hiding daily metric cards
  function toggleShowDailyMetrics() {
    setShowDailyMetrics((prevState) => !prevState);
  }

  // Toggles between the schedule display and the availability display.
  function toggleEditTeam() {
    setEditTeam((prevState) => !prevState);
  }

  // Shows the actual metrics on the daily metrics cards when true, shows the planned metrics on the daily metric cards when false
  const [checkedMetricsToggle, setCheckedMetricsToggle] = React.useState(
    props.week_in_past
  );

  // Max width of each box
  const box_max_width = schedule_size / 8 - 10;

  // Max height of each box
  const box_max_height = props.minimizeMode ? "28px" : "50px";

  // Styling object for the inner boxes
  const box_size_style = {
    height: box_max_height,
    maxWidth: box_max_width,
    minWidth: "100%",
  };

  // Styling object for the person boxes
  const person_box_size_style = {
    height: box_max_height,
    width: props.windowWidth > 1100 ? 170 : 140,
    minWidth: "100%",
  };

  // State showing metric cards vs chart cards
  const [bottomCardType, setBottomCardType] = React.useState(
    props.defaultDailyCardChartEnabled ? "chart" : "metric"
  );

  const [isFixed, setIsFixed] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 140;

      setIsFixed(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Styling object for the header box with the date, forecasted sales, weather, and events
  const header_box_size_style = {
    position: isFixed ? "fixed" : "relative",
    top: isFixed ? 0 : "auto",
    zIndex: isFixed ? 30 : 5,
    height: isFixed
      ? props.minimizeMode
        ? "50px"
        : "110px"
      : props.minimizeMode
      ? "32px"
      : "95px",
    width: "100%",
    maxWidth: box_max_width,
    boxShadow: isFixed
      ? "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)"
      : "",
  };

  // When start date changes, sets metrics toggle to true if week is in the past
  useEffect(() => {
    setCheckedMetricsToggle(props.week_in_past);
  }, [props.start_date]);

  // Event handler function for when metrics toggle is clicked in the daily metrics cards
  const handleChangeMetricsToggle = (event) => {
    setCheckedMetricsToggle(event.target.checked);
  };

  function sum_daily_sales(df) {
    let sales = [];
    day_nums.map((d, i) => {
      const date = props.addDays(start_date, d);
      const date_str = props.formatDate(date);
      const daily_sales = props.agg_column(
        props.filter_df_to_day(df, "date", date_str),
        "value"
      );
      const adjustment = props.filter_df_to_day(
        props.all_data.adjustments_chosen,
        "date",
        date_str
      );
      const adjustment_value =
        adjustment.length === 0 ? 0 : adjustment[0]["adjustment_percent_value"];
      sales.push(daily_sales * adjustment_value + daily_sales);
    });
    return sum(sales);
  }

  function sum_daily_sales_up_to_current_day() {
    let sales = [];

    // Get the current date
    const currentDate = new Date();

    // Get the end date of the week
    const weekEndDate = props.addDays(
      start_date,
      day_nums[day_nums.length - 1]
    );

    // Check if the current date is after the end date of the week
    if (currentDate > weekEndDate) {
      // Use the original function to sum all daily forecasts for the week
      day_nums.map((d, i) => {
        const date = props.addDays(start_date, d);
        const date_str = props.formatDate(date);
        const daily_sales = props.agg_column(
          props.filter_df_to_day(
            props.all_data.forecasted_sales_daily,
            "date",
            date_str
          ),
          "value"
        );
        const adjustment = props.filter_df_to_day(
          props.all_data.adjustments_chosen,
          "date",
          date_str
        );
        const adjustment_value =
          adjustment.length === 0
            ? 0
            : adjustment[0]["adjustment_percent_value"];
        sales.push(daily_sales * adjustment_value + daily_sales);
      });
    } else {
      // Use the function to sum forecasts up to yesterday
      const currentDayOfWeek = new Date().getDay();

      day_nums.slice(0, currentDayOfWeek - 1).map((d, i) => {
        const date = props.addDays(start_date, d);
        const date_str = props.formatDate(date);
        const daily_sales = props.agg_column(
          props.filter_df_to_day(
            props.all_data.forecasted_sales_daily,
            "date",
            date_str
          ),
          "value"
        );
        const adjustment = props.filter_df_to_day(
          props.all_data.adjustments_chosen,
          "date",
          date_str
        );
        const adjustment_value =
          adjustment.length === 0
            ? 0
            : adjustment[0]["adjustment_percent_value"];
        sales.push(daily_sales * adjustment_value + daily_sales);
      });
    }

    return sum(sales);
  }

  function get_date_info(d) {
    const locked = props.lockedDays[d];
    const date = new Date(props.addDays(start_date, d).toDateString());
    const date_str = props.formatDate(date);
    const date_dow = date.getDay() === 0 ? 7 : date.getDay();
    const date_name = date.toLocaleDateString("en-us", date_options);
    const todays_date_yes = props.formatDate(today) === date_str;
    const dow_name = date.toLocaleDateString("en-us", {
      weekday: "long",
    });
    const day_shifts = props.filter_df_to_day(
      props.isCopilotOn
        ? props.all_data.copilot_schedule
        : props.all_data.actual_schedule,
      "date",
      date_str
    );
    const day_other_store_shifts = props
      .filter_df_to_day(props.all_data.actual_schedule, "date", date_str)
      .filter((s, i) => s.other_store == true);

    const day_time_off_requests = props.all_data.all_time_off_requests.filter(
      (request) => {
        // If date_upper_bound is null, then check if date_lower_bound matches the specific date
        if (!request.date_upper_bound) {
          return request.date_lower_bound === date_str;
        }

        // If date_upper_bound is not null, then check if specific date is within the range of date_lower_bound to date_upper_bound
        return (
          request.date_lower_bound <= date_str &&
          request.date_upper_bound >= date_str
        );
      }
    );
    const day_request_off = props.filter_df_to_day(
      props.all_data.request_off,
      "date",
      date_str
    );
    const dow_availability = props.filter_df_to_day(
      props.all_data.default_availability,
      "dow",
      date_dow
    );
    let dow_default_operating_hours = props.filter_df_to_day(
      props.all_data.default_operating_hours,
      "dow",
      date_dow
    );
    dow_default_operating_hours.forEach((v, i) => (v.date = date_str));

    let day_operating_hours = props.filter_df_to_day(
      props.all_data.daily_operating_hours,
      "date",
      date_str
    );
    day_operating_hours.forEach((v, i) => (v.dow = date_dow));

    const edit_mode = locked ? false : date >= today ? true : false;
    const day_in_past = date >= today ? false : true;

    const forecasted_labor_day = props.filter_df_to_day(
      props.all_data.forecasted_labor,
      "date",
      date_str
    );
    const forecasted_workload_day = props.filter_df_to_day(
      props.all_data.forecasted_workload,
      "date",
      date_str
    );
    const recommended_schedule_labor_day = props.filter_df_to_day(
      props.all_data.recommended_schedule_labor_deployment,
      "date",
      date_str
    );
    const actual_schedule_labor_day = props.filter_df_to_day(
      props.all_data.actual_schedule_labor_deployment,
      "date",
      date_str
    );

    const calculated_earned_labor_day = props.filter_df_to_day(
      props.all_data.calculated_earned_labor,
      "date",
      date_str
    );
    const recommended_used_labor_vs_earned_day = props.filter_df_to_day(
      props.all_data.recommended_used_labor_vs_earned,
      "date",
      date_str
    );
    const actual_used_labor_vs_earned_day = props.filter_df_to_day(
      props.all_data.actual_used_labor_vs_earned,
      "date",
      date_str
    );
    const adjustment_chosen = props.filter_df_to_day(
      props.all_data.adjustments_chosen,
      "date",
      date_str
    );

    const forecast_sales = props.agg_column(
      props.filter_df_to_day(
        props.all_data.forecasted_sales_daily,
        "date",
        date_str
      ),
      "value"
    );
    const actual_sales = props.agg_column(
      props.filter_df_to_day(
        props.all_data.actual_sales_daily,
        "date",
        date_str
      ),
      "value"
    );

    const historical_sales_context = props.filter_df_to_day(
      props.historicalSalesContext,
      "date",
      date_str
    );

    return [
      locked,
      date,
      date_str,
      date_dow,
      date_name,
      todays_date_yes,
      dow_name,
      day_shifts,
      day_request_off,
      dow_availability,
      edit_mode,
      day_in_past,
      forecasted_labor_day,
      forecasted_workload_day,
      recommended_schedule_labor_day,
      actual_schedule_labor_day,
      calculated_earned_labor_day,
      recommended_used_labor_vs_earned_day,
      actual_used_labor_vs_earned_day,
      adjustment_chosen,
      forecast_sales,
      actual_sales,
      dow_default_operating_hours,
      day_operating_hours,
      day_time_off_requests,
      historical_sales_context,
      day_other_store_shifts,
    ];
  }

  function get_person_shift_info(
    p,
    date_str,
    date_dow,
    day_shifts,
    day_request_off,
    dow_availability,
    day_time_off_requests,
    day_other_store_shifts
  ) {
    const locked_person = props.lockedPeople[p.id];
    const person_day_shift = day_shifts.filter(
      (s) => s.store_team_member === p.id
    );
    const person_day_request_off = day_request_off.filter(
      (s) => s.store_team_member === p.id
    );

    let person_day_time_off_requests = day_time_off_requests.filter(
      (s) => s.store_team_member === p.id
    );

    let person_day_availability = dow_availability.filter(
      (s) => s.store_team_member === p.id
    );
    person_day_availability.forEach(function (element) {
      element.date = date_str;
      element.default = true;
    });
    let person_day_other_store_shift = day_other_store_shifts.filter(
      (s) => s.user_id === p.user?.id
    );

    return [
      locked_person,
      person_day_shift,
      person_day_request_off,
      person_day_availability,
      person_day_time_off_requests,
      person_day_other_store_shift,
    ];
  }

  const average = (array) => array.reduce((a, b) => a + b) / array.length;

  const grade_weights = props.all_data.grade_weights;
  let intraday_weight =
    grade_weights?.length > 0
      ? grade_weights[0]["grade_intraday_weight"]
      : 0.25;
  let budget_weight =
    grade_weights?.length > 0 ? grade_weights[0]["grade_budget_weight"] : 0.75;
  let budget_under_multiplier =
    grade_weights?.length > 0
      ? grade_weights[0]["grade_budget_under_multiplier"]
      : 0.25;
  let budget_over_multiplier =
    grade_weights?.length > 0
      ? grade_weights[0]["grade_budget_over_multiplier"]
      : 1;
  let budget_difficulty_multiplier =
    grade_weights?.length > 0
      ? grade_weights[0]["grade_budget_difficulty_multiplier"]
      : 1;
  let intraday_difficulty_multiplier =
    grade_weights?.length > 0
      ? grade_weights[0]["grade_intraday_difficulty_multiplier"]
      : 1;

  function calculate_score(value, best) {
    const days = [...new Set(best.map((item) => item.date))];
    let scores = [];

    days.forEach((d) => {
      const best_df = best.filter((r) => r.date === d);
      const value_df = value.filter((r) => r.date === d);

      const best_hours = props.agg_column(best_df, "hours");
      const value_hours = props.agg_column(value_df, "hours");

      let labor_diff = calculateLaborDifference(value_df, best_df);

      let intraday_cost = 0;
      if (labor_diff["sumLabor"] > 0) {
        intraday_cost =
          (labor_diff["sumDifference"] * intraday_difficulty_multiplier +
            labor_diff["sumLabor"]) /
          labor_diff["sumLabor"];
      }

      const labor_sum = props.agg_column(best_df, "labor");
      if (labor_sum === 0) {
        intraday_weight = 0;
        budget_weight = 1;
      }

      const score =
        best_hours === 0 || value_hours === 0
          ? 0
          : (intraday_cost * intraday_weight +
              (1 -
                (budget_difficulty_multiplier *
                  ((best_hours > value_hours
                    ? budget_under_multiplier
                    : budget_over_multiplier) *
                    Math.abs(best_hours - value_hours))) /
                  best_hours) *
                budget_weight) *
            100;
      if (best_hours > 0 && value_hours > 0) {
        scores.push(score);
      }
    });

    if (scores.length > 0) {
      return average(scores);
    } else {
      return 0;
    }
  }

  function calculateLaborDifference(value, best) {
    // Aggregate value array by date and qh_num
    const valueAggregated = aggregateArray(value);

    // Aggregate best array by date and qh_num
    const bestAggregated = aggregateArray(best);

    // Join the aggregated arrays by date and qh_num
    const joined = joinData(valueAggregated, bestAggregated);

    // Calculate the sum of the difference between value and best where value is less than best
    let sumDifference = 0;
    for (const item of joined) {
      const difference = item.valueLabor - item.bestLabor;
      if (difference < 0) {
        sumDifference += difference;
      }
    }

    const best_hours = props.agg_column(bestAggregated, "labor");

    return { sumDifference: sumDifference, sumLabor: best_hours };
  }

  function aggregateArray(arr) {
    const aggregated = {};

    for (const item of arr) {
      const key = `${item.date}_${item.qh_num}`;

      if (!aggregated[key]) {
        aggregated[key] = {
          date: item.date,
          qh_num: item.qh_num,
          labor: 0,
        };
      }

      aggregated[key].labor += item.labor;
    }

    return Object.values(aggregated);
  }

  // Helper function to join aggregated data sets with a left join (flipped for value)
  function joinData(value, best) {
    const joinedData = [];
    for (const bestObj of best) {
      const key = bestObj.date + "-" + bestObj.qh_num;
      const valueObj = value.find(
        (obj) => obj.date === bestObj.date && obj.qh_num === bestObj.qh_num
      );
      if (valueObj) {
        joinedData.push({
          date: bestObj.date,
          qh_num: bestObj.qh_num,
          valueLabor: valueObj.labor,
          bestLabor: bestObj.labor,
        });
      } else {
        joinedData.push({
          date: bestObj.date,
          qh_num: bestObj.qh_num,
          valueLabor: 0, // Use 0 as the default value for valueLabor in the flipped left join
          bestLabor: bestObj.labor,
        });
      }
    }
    return joinedData;
  }

  function calculate_daily_budget_score(value, best) {
    const days = [...new Set(best.map((item) => item.date))];
    let scores = [];

    days.forEach((d) => {
      const best_df = best.filter((r) => r.date === d);
      const value_df = value.filter((r) => r.date === d);
      const best_hours = props.agg_column(best_df, "hours");
      const value_hours = props.agg_column(value_df, "hours");
      const score =
        best_hours === 0 || value_hours === 0
          ? 0
          : (1 -
              ((best_hours > value_hours
                ? budget_under_multiplier
                : budget_over_multiplier) *
                Math.abs(best_hours - value_hours)) /
                best_hours) *
            100;
      if (best_hours > 0 && value_hours > 0) {
        scores.push(score);
      }
    });

    let avg_score = 0;
    if (scores.length > 0) {
      avg_score = average(scores);
    }
    return Math.min(Math.max(Math.ceil((avg_score - 60) / (40 / 5)), 0), 5);
  }

  function calculate_intraday_coverage_score(value, best) {
    const days = [...new Set(best.map((item) => item.date))];
    let scores = [];

    days.forEach((d) => {
      const best_df = best.filter((r) => r.date === d);
      const value_df = value.filter((r) => r.date === d);

      const best_hours = props.agg_column(best_df, "hours");
      const value_hours = props.agg_column(value_df, "hours");
      let labor_diff = calculateLaborDifference(value_df, best_df);
      let intraday_cost = 0;
      if (labor_diff["sumLabor"] > 0) {
        intraday_cost =
          (labor_diff["sumDifference"] + labor_diff["sumLabor"]) /
          labor_diff["sumLabor"];
      }
      const score =
        best_hours === 0 || value_hours === 0 ? 0 : intraday_cost * 100;
      if (best_hours > 0 && value_hours > 0) {
        scores.push(score);
      }
    });

    let avg_score = 0;
    if (scores.length > 0) {
      avg_score = average(scores);
    }
    return Math.min(Math.max(Math.ceil((avg_score - 60) / (40 / 5)), 0), 5);
  }

  // Function that gives the letter grade based on the score
  function calcLetter(score) {
    if (score >= 90) {
      return "A";
    } else if (score >= 80) {
      return "B";
    } else if (score >= 70) {
      return "C";
    } else if (score >= 60) {
      return "D";
    } else if (score === 0) {
      return "-";
    } else {
      return "F";
    }
  }

  // Function that gives the color of the letter grade based on the score
  function getColor(score) {
    if (score >= 90) {
      return "#059669";
    } else if (score >= 80) {
      return "#65a30d";
    } else if (score >= 70) {
      return "#f5be27";
    } else if (score >= 60) {
      return "#ea580c";
    } else if (score === 0) {
      return "gray";
    } else {
      return "#dc2626";
    }
  }

  function replace_above_max(df, col, max_val) {
    df = df.map((x) => {
      x[col] = Math.min(x[col], max_val);
      return x;
    });
    return df;
  }

  function calculate_letter(value, best) {
    return calcLetter(calculate_score(value, best));
  }

  function calculate_color(value, best) {
    return getColor(calculate_score(value, best));
  }

  function concatenate_column(df, column) {
    let arr = [];
    df.forEach((element) => {
      const new_arr = JSON.parse(element[column].replace(/'/g, '"'));
      let role_type_arr = [];
      if (new_arr.length > 0) {
        new_arr.forEach((i) => {
          role_type_arr.push(element.role_type + " - " + i);
        });
        arr = arr.concat(role_type_arr);
      }
    });
    return arr;
  }

  function combine_data(
    forecasted_labor_day,
    forecasted_workload_day,
    recommended_schedule_labor_day,
    actual_schedule_labor_day
  ) {
    // add info to qh
    const qh_labor = [];
    forecasted_labor_day.reduce(function (res, value) {
      if (!res[value.qh_num]) {
        res[value.qh_num] = { qh_num: value.qh_num, labor: 0 };
        qh_labor.push(res[value.qh_num]);
      }
      res[value.qh_num].labor += value.labor;
      return res;
    }, {});

    // Sort qh_labor by qh_num
    qh_labor.sort((a, b) => a.qh_num - b.qh_num);

    const roles = [];
    const data = qh_labor.map((x) => {
      x.workload = props.agg_column(
        forecasted_workload_day.filter((q) => q.qh_num === x.qh_num),
        "workload"
      );
      x.recommended_labor = props.agg_column(
        recommended_schedule_labor_day.filter((q) => q.qh_num === x.qh_num),
        "labor"
      );
      x.actual_labor = props.agg_column(
        actual_schedule_labor_day.filter((q) => q.qh_num === x.qh_num),
        "labor"
      );
      x.role_list_arr = concatenate_column(
        actual_schedule_labor_day.filter((q) => q.qh_num === x.qh_num),
        "role_list"
      );
      x.routine_list_arr = concatenate_column(
        actual_schedule_labor_day.filter((q) => q.qh_num === x.qh_num),
        "routine_list"
      );
      for (const role of x.role_list_arr) {
        x[role] = x[role] ? x[role] + 1 : 1;
        if (!roles.includes(role)) {
          roles.push(role);
        }
      }
      x.time = props.get_t_str(x.qh_num);
      return x;
    });
    return { data: data, roles: roles };
  }

  const open_shifts = props.all_data.actual_schedule
    ? props.all_data.actual_schedule.filter((s) => s.status === "AVAILABLE")
    : [];

  const open_shift_counts = open_shifts.reduce((acc, { date }) => {
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  // Get the maximum count
  const maxOpenShiftCount = Math.max(...Object.values(open_shift_counts));

  // Shows the drop down for the open shifts if true
  const [isOpenShiftsExtended, setIsOpenShiftsExtended] = React.useState(false);

  // Toggles the drop down for the open shifts
  function toggleOpenShiftsExtended() {
    setIsOpenShiftsExtended((prevState) => !prevState);
  }

  // Styling object for the open shifts box
  const subheader_box_size_style = {
    height: isOpenShiftsExtended
      ? open_shifts.length === 0
        ? 72
        : 38 * (maxOpenShiftCount + 2) + 0
      : 32,
    maxWidth: box_max_width,
    minWidth: "100%",
  };

  // When the start date changes, hides the open shifts
  useEffect(() => {
    setIsOpenShiftsExtended(false);
  }, [props.start_date]);

  // Team members associated with store
  if (props.store_team_member_weekly_info_view.length > 0 && !props.loading) {
    var start_date = new Date(props.start_date.toDateString());
    {
      return props.weeklyView ? (
        <div>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            divider={<Divider orientation="vertical" flexItem />}
            style={{ width: "100%", paddingTop: isFixed ? "50px" : "0px" }}
          >
            <Stack
              direction="column"
              spacing={props.minimizeMode ? 0.5 : 1}
              divider={<Divider variant="inset" />}
              style={{ width: "100%" }}
            >
              <div style={header_box_size_style}>
                <div
                  className={`bg-white h-full ${
                    props.windowWidth > 1100 ? "min-w-[178px]" : "min-w-[146px]"
                  } ${isFixed ? "pt-3" : ""}`}
                >
                  <EditTeamButton
                    toggleEditTeam={toggleEditTeam}
                    editTeam={editTeam}
                    minimizeMode={props.minimizeMode}
                    windowWidth={props.windowWidth}
                  />
                </div>
                {!props.minimizeMode && (
                  <div
                    className={`relative ${isFixed ? "hidden" : "bottom-8"}`}
                  >
                    {editTeam ? (
                      <VizualizeWeeklyAvailOrSched
                        store_team_member_weekly_info_view={
                          props.store_team_member_weekly_info_view
                        }
                        forecasted_labor={props.all_data.forecasted_labor}
                        hours_of_operation={
                          props.all_data.default_operating_hours
                        }
                        roleType={props.roleType}
                        setRoleType={props.setRoleType}
                        roleTypeOptions={props.roleTypeOptions}
                        windowWidth={props.windowWidth}
                      />
                    ) : (
                      <VizualizeWeeklySched
                        forecasted_labor={props.all_data.forecasted_labor}
                        scheduled_labor={
                          props.all_data.actual_schedule_labor_deployment
                        }
                        hours_of_operation={
                          props.all_data.default_operating_hours
                        }
                        roleType={props.roleType}
                        setRoleType={props.setRoleType}
                        roleTypeOptions={props.roleTypeOptions}
                        windowWidth={props.windowWidth}
                      />
                    )}
                  </div>
                )}
              </div>
              <div style={subheader_box_size_style}>
                {/* <button
                  className="w-full flex items-center justify-between mt-1"
                  onClick={toggleOpenShiftsExtended}
                >
                  <h2 className="font-bold text-sm">
                    {open_shifts.length +
                      ` Open Shift${open_shifts.length === 1 ? "" : "s"}`}{" "}
                  </h2>
                  {isOpenShiftsExtended ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </button> */}
                <div className="min-w-full h-full">
                  <input
                    className={`w-full h-full border border-gray-300 px-3 text-xs rounded-lg outline-none focus:border-2 focus:border-violet-700 ${
                      props.noMatchingSearch ? "text-rose-600" : ""
                    }`}
                    style={{ maxHeight: "32px" }}
                    type="text"
                    placeholder="🔎  Search by name"
                    onChange={(event) =>
                      props.setSearchInput(event.target.value)
                    }
                  />
                </div>
              </div>
              {props.store_team_member_weekly_info_view?.map((p, i) => {
                const locked_person = props.lockedPeople[p.id];
                return (
                  <div
                    style={person_box_size_style}
                    className="flex justify-center items-center min-w-full"
                  >
                    <PersonCard
                      key={p.id}
                      p={p}
                      role_names={props.all_data.role_names}
                      updatePersonInfo={props.updatePersonInfo}
                      lock_person={props.lock_person}
                      locked_person={locked_person}
                      id={p.id}
                      week_in_past={props.week_in_past}
                      week_already_started={props.week_already_started}
                      minimizeMode={props.minimizeMode}
                      role_types={props.all_data.role_types}
                      loadEmployeePreviousSchedule={
                        props.loadEmployeePreviousSchedule
                      }
                      proficiencyStarsCount={props.proficiencyStarsCount}
                      proficiencyFromLMSEnabled={props.proficiencyFromLMSEnabled}
                    />
                  </div>
                );
              })}
              {showDailyMetrics ? (
                <div>
                  <div className="h-8"></div>
                  <div
                    style={{ boxShadow: "0px -3px 3px rgb(20 20 20 / 10%" }}
                    className="fixed bottom-0 left-5 z-50 pl-20 w-full h-44 bg-slate-100 total-metrics-padding"
                  >
                    <button
                      className="fixed bottom-36 left-16 z-72 text-slate-500"
                      onClick={toggleShowDailyMetrics}
                    >
                      <ExpandMoreIcon style={{ fontSize: "28px" }} />
                    </button>
                    <div
                      className={`absolute bottom-[64px] left-[55px] w-6 z-[150]`}
                    >
                      <div
                        className={`w-full h-7 flex items-center justify-center ${
                          bottomCardType === "metric"
                            ? "border border-violet-700 text-violet-700"
                            : "border-t border-x border-slate-300 text-slate-500 hover:bg-slate-200"
                        } rounded-t cursor-pointer`}
                        onClick={() => setBottomCardType("metric")}
                      >
                        <Battery3BarIcon style={{ fontSize: "16px" }} />
                      </div>
                      <div
                        className={`w-full h-7 flex items-center justify-center ${
                          bottomCardType === "chart"
                            ? "border border-violet-700 text-violet-700"
                            : "border-b border-x border-slate-300 text-slate-500 hover:bg-slate-200"
                        } rounded-b cursor-pointer`}
                        onClick={() => setBottomCardType("chart")}
                      >
                        <LineAxisIcon style={{ fontSize: "16px" }} />
                      </div>
                    </div>
                    <div className="fixed bottom-1.5 left-28 z-50 mt-10">
                      <DailyMetricsCard
                        weekly_or_daily="weekly"
                        forecasted_labor={props.all_data.forecasted_labor}
                        recommended_schedule_labor={
                          props.all_data.recommended_schedule_labor_deployment
                        }
                        actual_schedule_labor={
                          props.all_data.actual_schedule_labor_deployment
                        }
                        calculated_earned_labor={
                          props.all_data.calculated_earned_labor
                        }
                        recommended_used_labor_vs_earned={
                          props.all_data.recommended_used_labor_vs_earned
                        }
                        actual_used_labor_vs_earned={
                          props.all_data.actual_used_labor_vs_earned
                        }
                        agg_column={props.agg_column}
                        day_in_past={start_date >= today ? false : true}
                        adjustments_chosen={props.adjustments_chosen}
                        calculate_letter={calculate_letter}
                        calculate_color={calculate_color}
                        checkedMetricsToggle={checkedMetricsToggle}
                        forecast_sales={sum_daily_sales(
                          props.all_data.forecasted_sales_daily
                        )}
                        forecast_transactions={props.agg_column(
                          props.filter_df_to_day(
                            props.all_data.forecasted_other_daily,
                            "metric",
                            "Transaction"
                          ),
                          "value"
                        )}
                        actual_transactions={props.agg_column(
                          props.filter_df_to_day(
                            props.all_data.actual_other_daily,
                            "metric",
                            "Transaction"
                          ),
                          "value"
                        )}
                        forecast_sales_up_to_current_day={sum_daily_sales_up_to_current_day()}
                        actual_sales={props.agg_column(
                          props.all_data.actual_sales_daily,
                          "value"
                        )}
                        calculate_daily_budget_score={
                          calculate_daily_budget_score
                        }
                        calculate_intraday_coverage_score={
                          calculate_intraday_coverage_score
                        }
                        showLaborPercent={props.showLaborPercent}
                        averageWageFromPreviousWeek={
                          props.averageWageFromPreviousWeek
                        }
                        dailyLaborPercentData={props.laborPercentData}
                        windowWidth={props.windowWidth}
                        tplhEnabled={props.tplhEnabled}
                      />
                      <hr className="mt-3 w-[150px]" />
                      <div className="flex items-center text-xxs">
                        <h4>Planned</h4>
                        <PurpleSwitch
                          checked={checkedMetricsToggle}
                          onChange={handleChangeMetricsToggle}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <h4>Actual</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="h-8"></div>
                  <div
                    style={{ boxShadow: "0px -3px 3px rgb(20 20 20 / 10%" }}
                    className="fixed bottom-0 left-5 z-50 pl-20 w-full h-12 bg-gray-100 total-metrics-padding"
                  >
                    <button
                      className="fixed bottom-5 left-16 z-72 text-slate-500"
                      onClick={toggleShowDailyMetrics}
                    >
                      <ExpandLessIcon style={{ fontSize: "28px" }} />
                    </button>
                  </div>
                </div>
              )}
            </Stack>
            {day_nums.map((d, i) => {
              const [
                locked,
                date,
                date_str,
                date_dow,
                date_name,
                todays_date_yes,
                dow_name,
                day_shifts,
                day_request_off,
                dow_availability,
                edit_mode,
                day_in_past,
                forecasted_labor_day,
                forecasted_workload_day,
                recommended_schedule_labor_day,
                actual_schedule_labor_day,
                calculated_earned_labor_day,
                recommended_used_labor_vs_earned_day,
                actual_used_labor_vs_earned_day,
                adjustment_chosen,
                forecast_sales,
                actual_sales,
                dow_default_operating_hours,
                day_operating_hours,
                day_time_off_requests,
                historical_sales_context,
                day_other_store_shifts,
              ] = get_date_info(d);

              const daily_weather = props.filter_df_to_day(
                props.all_data.weather,
                "date",
                date_str
              );
              const daily_events = props.filter_df_to_day(
                props.all_data.events,
                "date",
                date_str
              );

              const daily_local_events = props.filter_df_to_day(
                props.all_data.local_events,
                "date",
                date_str
              );

              const daily_forecast_exclude_date = props.filter_df_to_day(
                props.all_data.forecast_exclude_dates,
                "date",
                date_str
              );

              const daily_forecast_other = props.filter_df_to_day(
                props.all_data.forecasted_other_daily,
                "date",
                date_str
              );

              const daily_actual_other = props.filter_df_to_day(
                props.all_data.actual_other_daily,
                "date",
                date_str
              );

              const daily_labor_percent_data = props.filter_df_to_day(
                props.laborPercentData,
                "date",
                date_str
              );

              const dayOfWeekStr = date.toLocaleDateString("en-us", {
                weekday: "short",
              });

              const daily_batch_guidance =
                date_str in props.all_data.batch_guidance
                  ? props.all_data.batch_guidance[date_str]
                  : {};

              const daily_intraday_metrics =
                date_str in props.all_data.intraday_metrics
                  ? props.all_data.intraday_metrics[date_str]
                  : {};

              const daily_adjustment =
                (adjustment_chosen.length === 0
                  ? 0
                  : adjustment_chosen[0]["adjustment_percent_value"]) + 1;

              const daily_forecast_transactions =
                props.agg_column(
                  props.filter_df_to_day(
                    props.all_data.forecasted_transactions_daily,
                    "date",
                    date_str
                  ),
                  "value"
                ) * daily_adjustment;

              const daily_forecast_sales = daily_adjustment * forecast_sales;

              const daily_open_shifts = props.filter_df_to_day(
                open_shifts,
                "date",
                date_str
              );

              return (
                <Stack
                  direction="column"
                  spacing={props.minimizeMode ? 0.5 : 1}
                  divider={<Divider variant="inset" />}
                  style={{ width: "100%" }}
                >
                  <div
                    style={header_box_size_style}
                    className={`relative bg-white ${
                      isFixed ? "pr-2 w-full min-w-max" : "min-w-full"
                    }`}
                  >
                    <DateCard
                      date_name={date.toLocaleDateString("en-us", date_options)}
                      day_of_week_str={day_of_week_str}
                      forecast_sales={forecast_sales}
                      actual_sales={actual_sales}
                      lock_day={props.lock_day}
                      locked={locked}
                      d={d}
                      i={i}
                      edit_mode={edit_mode}
                      day_in_past={day_in_past}
                      todays_date_yes={todays_date_yes}
                      adjustment_chosen={adjustment_chosen}
                      addForecastAdjustment={props.addForecastAdjustment}
                      addAdjustmentFinished={props.addAdjustmentFinished}
                      date_str={date_str}
                      toggleDailyWeekly={props.toggleDailyWeekly}
                      daily_weather={daily_weather}
                      daily_events={daily_events}
                      daily_local_events={daily_local_events}
                      saveEvent={props.saveEvent}
                      deleteEvent={props.deleteEvent}
                      saveEventFinished={props.saveEventFinished}
                      setSaveEventFinished={props.setSaveEventFinished}
                      saveForecastExcludeDate={props.saveForecastExcludeDate}
                      deleteForecastExcludeDate={
                        props.deleteForecastExcludeDate
                      }
                      daily_forecast_exclude_date={daily_forecast_exclude_date}
                      isFixed={isFixed}
                      historical_sales_context={historical_sales_context}
                      get_date_info={get_date_info}
                      edit_hoo={props.edit_hoo}
                      get_t_str={get_t_str}
                      start_qhnum={props.start_qhnum}
                      minimizeMode={props.minimizeMode}
                      windowWidth={props.windowWidth}
                    />
                  </div>
                  <div style={subheader_box_size_style}>
                    {isOpenShiftsExtended ? (
                      <div className="w-full min-w-full">
                        {edit_mode ? (
                          <div
                            className="w-full min-w-full flex items-center justify-between mb-2 py-2 pl-3 pr-2 rounded-lg relative text-slate-500 text-xxs font-bold bg-slate-100 hover:bg-slate-200 cursor-pointer"
                            onClick={toggleOpenShiftsExtended}
                          >
                            <p className="xl:hidden">
                              {daily_open_shifts.length +
                                ` Open Shift${
                                  daily_open_shifts.length === 1 ? "" : "s"
                                }`}
                            </p>
                            <p className="hidden xl:block">
                              {daily_open_shifts.length + ` Open`}
                            </p>
                            <p className="absolute right-1">
                              <ExpandLessIcon style={{ fontSize: "18px" }} />
                            </p>
                          </div>
                        ) : (
                          <div className="w-full min-w-full flex items-center justify-between mb-2 py-2 pl-3 pr-2 rounded-lg relative text-slate-400 text-xxs font-bold bg-slate-200">
                            <p className="xl:hidden">
                              {daily_open_shifts.length +
                                ` Open Shift${
                                  daily_open_shifts.length === 1 ? "" : "s"
                                }`}
                            </p>
                            <p className="hidden xl:block">
                              {daily_open_shifts.length + ` Open`}
                            </p>
                          </div>
                        )}
                        <div
                          className="mb-2 min-w-full"
                          style={{ height: "30px" }}
                        >
                          {edit_mode ? (
                            <div className="w-full min-w-full h-full relative">
                              <NewOpenShiftCard
                                role_names={props.all_data.role_names}
                                week_in_past={props.week_in_past}
                                weekly_or_daily="weekly"
                                date_str={date_str}
                                addShift={props.addShift}
                                store_id={props.store_id}
                                get_t_str={props.get_t_str}
                                date_name={date_name}
                                saveShiftFinished={props.saveShiftFinished}
                                setSaveShiftFinished={
                                  props.setSaveShiftFinished
                                }
                                d={d}
                                copiedShift={props.copiedShift}
                                setCopiedShift={props.setCopiedShift}
                                addMultipleShifts={props.addMultipleShifts}
                                minimizeMode={props.minimizeMode}
                                role_types={props.all_data.role_types}
                                businessWeekStartDayIndex={
                                  props.businessWeekStartDayIndex
                                }
                                start_qhnum={props.start_qhnum}
                                defaultBreakLengthHours={
                                  props.defaultBreakLengthHours
                                }
                                breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
                              />
                              {/* <button
                                className="absolute -right-5 top-0.5"
                                onClick={toggleOpenShiftsExtended}
                              >
                                <ArrowDropUpIcon style={{ fontSize: "24px" }} />
                              </button> */}
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {daily_open_shifts.map((v, i) => (
                          <div
                            className="mb-2 min-w-full"
                            style={{ height: "30px" }}
                          >
                            <OpenShiftCard
                              edit_mode={edit_mode}
                              weekly_or_daily="weekly"
                              shift_info={v}
                              lockAllShifts={props.lockAllShifts}
                              p={props.p}
                              locked_mode={props.lockAllShifts}
                              addShift={props.addShift}
                              get_t_str={props.get_t_str}
                              date_name={date_name}
                              role_names={props.all_data.role_names}
                              saveShiftFinished={props.saveShiftFinished}
                              setSaveShiftFinished={props.setSaveShiftFinished}
                              d={d}
                              setCopiedShift={props.setCopiedShift}
                              copiedShift={props.copiedShift}
                              minimizeMode={props.minimizeMode}
                              role_types={props.all_data.role_types}
                              businessWeekStartDayIndex={
                                props.businessWeekStartDayIndex
                              }
                              start_qhnum={props.start_qhnum}
                              defaultBreakLengthHours={
                                props.defaultBreakLengthHours
                              }
                              breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
                            />
                          </div>
                        ))}
                      </div>
                    ) : edit_mode ? (
                      <div
                        className={`w-full min-w-full flex items-center justify-between mb-2 py-2 pl-3 pr-2 rounded-lg relative cursor-pointer ${
                          daily_open_shifts.length > 0
                            ? "text-violet-700"
                            : "text-slate-500"
                        } text-xxs font-bold bg-slate-100 hover:bg-slate-200`}
                        onClick={toggleOpenShiftsExtended}
                      >
                        <p className="xl:hidden">
                          {daily_open_shifts.length +
                            ` Open Shift${
                              daily_open_shifts.length === 1 ? "" : "s"
                            }`}
                        </p>
                        <p className="hidden xl:block">
                          {daily_open_shifts.length + ` Open`}
                        </p>
                        <p className="absolute right-1">
                          <ExpandMoreIcon style={{ fontSize: "18px" }} />
                        </p>
                      </div>
                    ) : (
                      <div
                        className={`w-full min-w-full flex items-center justify-between mb-2 py-2 pl-3 pr-2 rounded-lg text-xxs font-bold bg-slate-200 text-slate-400`}
                      >
                        <p className="xl:hidden">
                          {daily_open_shifts.length +
                            ` Open Shift${
                              daily_open_shifts.length === 1 ? "" : "s"
                            }`}
                        </p>
                        <p className="hidden xl:block">
                          {daily_open_shifts.length + ` Open`}
                        </p>
                        {/* <p className="absolute right-1">
                            <ExpandMoreIcon style={{ fontSize: "18px" }} />
                          </p> */}
                      </div>
                    )}
                  </div>
                  {props.store_team_member_weekly_info_view.map((p, i) => {
                    const [
                      locked_person,
                      person_day_shift,
                      person_day_request_off,
                      person_day_availability,
                      person_day_time_off_requests,
                      person_day_other_store_shift,
                    ] = get_person_shift_info(
                      p,
                      date_str,
                      date_dow,
                      day_shifts,
                      day_request_off,
                      dow_availability,
                      day_time_off_requests,
                      day_other_store_shifts
                    );
                    return (
                      // Depending on information
                      <div
                        style={box_size_style}
                        className="flex justify-center items-center"
                      >
                        {editTeam ? (
                          <PersonDayAvailabilityDisplay
                            edit_mode={edit_mode}
                            displayMode={props.displayMode}
                            minimizeMode={props.minimizeMode}
                            date_name={date_name}
                            get_t_str={props.get_t_str}
                            dow_name={dow_name}
                            date_dow={date_dow}
                            date_str={date_str}
                            p={p}
                            editAvailability={props.editAvailability}
                            person_day_availability={person_day_availability}
                            person_day_request_off={person_day_request_off}
                            saveAvailabilityFinished={
                              props.saveAvailabilityFinished
                            }
                            setSaveAvailabilityFinished={
                              props.setSaveAvailabilityFinished
                            }
                            d={d}
                            person_day_time_off_requests={
                              person_day_time_off_requests
                            }
                            businessWeekStartDayIndex={
                              props.businessWeekStartDayIndex
                            }
                            start_qhnum={props.start_qhnum}
                            request_time_off={props.request_time_off}
                            delete_time_off_request={
                              props.delete_time_off_request
                            }
                            delete_temporary_availability={
                              props.delete_temporary_availability
                            }
                          />
                        ) : (
                          <PersonDayShiftDisplay
                            edit_mode={edit_mode}
                            lockAllShifts={props.lockAllShifts}
                            locked_person={locked_person}
                            displayMode={props.displayMode}
                            minimizeMode={props.minimizeMode}
                            date_name={date_name}
                            get_t_str={props.get_t_str}
                            person_day_shift={person_day_shift}
                            p={p}
                            date_str={date_str}
                            addShift={props.addShift}
                            store_id={props.store_id}
                            person_day_availability={person_day_availability}
                            role_names={props.all_data.role_names}
                            saveShiftFinished={props.saveShiftFinished}
                            setSaveShiftFinished={props.setSaveShiftFinished}
                            d={d}
                            setCopiedShift={props.setCopiedShift}
                            copiedShift={props.copiedShift}
                            addMultipleShifts={props.addMultipleShifts}
                            person_day_time_off_requests={
                              person_day_time_off_requests
                            }
                            person_day_other_store_shift={person_day_other_store_shift}
                            role_types={props.all_data.role_types}
                            businessWeekStartDayIndex={
                              props.businessWeekStartDayIndex
                            }
                            start_qhnum={props.start_qhnum}
                            frequentShifts={props.frequentShifts}
                            day_in_past={day_in_past}
                            updatePersonInfo={props.updatePersonInfo}
                            post_missed_shift={props.post_missed_shift}
                            breakWaiverEnabled={props.breakWaiverEnabled}
                            showUnpublishedShifts={props.showUnpublishedShifts}
                            defaultBreakLengthHours={
                              props.defaultBreakLengthHours
                            }
                            editShiftsInPastEnabled={
                              props.editShiftsInPastEnabled
                            }
                            isCopilotOn={props.isCopilotOn}
                            breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
                          />
                        )}
                      </div>
                    );
                  })}
                  {showDailyMetrics ? (
                    <div>
                      <div className="h-28"></div>
                      <div className="fixed bottom-1 z-50">
                        {bottomCardType === "metric" ? (
                          <DailyMetricsCard
                            forecasted_labor={forecasted_labor_day}
                            recommended_schedule_labor={
                              recommended_schedule_labor_day
                            }
                            actual_schedule_labor={actual_schedule_labor_day}
                            calculated_earned_labor={
                              calculated_earned_labor_day
                            }
                            recommended_used_labor_vs_earned={
                              recommended_used_labor_vs_earned_day
                            }
                            actual_used_labor_vs_earned={
                              actual_used_labor_vs_earned_day
                            }
                            agg_column={props.agg_column}
                            edit_mode={edit_mode}
                            day_in_past={day_in_past}
                            calculate_letter={calculate_letter}
                            calculate_color={calculate_color}
                            checkedMetricsToggle={checkedMetricsToggle}
                            forecast_sales={daily_forecast_sales}
                            forecast_transactions={props.agg_column(
                              props.filter_df_to_day(
                                daily_forecast_other,
                                "metric",
                                "Transaction"
                              ),
                              "value"
                            )}
                            actual_transactions={props.agg_column(
                              props.filter_df_to_day(
                                daily_actual_other,
                                "metric",
                                "Transaction"
                              ),
                              "value"
                            )}
                            actual_sales={actual_sales}
                            isLastCard={i >= day_nums.length - 2}
                            calculate_daily_budget_score={
                              calculate_daily_budget_score
                            }
                            calculate_intraday_coverage_score={
                              calculate_intraday_coverage_score
                            }
                            day_of_week_str={dayOfWeekStr}
                            daily_actual_other={daily_actual_other}
                            daily_forecast_other={daily_forecast_other}
                            daily_adjustment={daily_adjustment}
                            showLaborPercent={props.showLaborPercent}
                            averageWageFromPreviousWeek={
                              props.averageWageFromPreviousWeek
                            }
                            dailyLaborPercentData={daily_labor_percent_data}
                            tplhEnabled={props.tplhEnabled}
                          />
                        ) : (
                          <DailyChartCard
                            forecasted_labor_day={forecasted_labor_day}
                            forecasted_workload_day={forecasted_workload_day}
                            recommended_schedule_labor_day={
                              recommended_schedule_labor_day
                            }
                            actual_schedule_labor_day={
                              actual_schedule_labor_day
                            }
                            calculated_earned_labor_day={
                              calculated_earned_labor_day
                            }
                            actual_used_labor_vs_earned_day={
                              actual_used_labor_vs_earned_day
                            }
                            actual_schedule={day_shifts}
                            agg_column={props.agg_column}
                            get_t_str={props.get_t_str}
                            combine_data={combine_data}
                            date_name={date_name}
                            earned_toggle={checkedMetricsToggle}
                            index={i}
                            windowWidth={props.windowWidth}
                          />
                        )}
                        <hr className="mt-3" />
                        <div>
                          <VisualButtonOptions
                            date_name={date_name}
                            date_str={date_str}
                            forecasted_labor_day={forecasted_labor_day}
                            forecasted_workload_day={forecasted_workload_day}
                            recommended_schedule_labor_day={
                              recommended_schedule_labor_day
                            }
                            store_team_member_weekly_info={
                              props.store_team_member_weekly_info
                            }
                            actual_schedule_labor_day={
                              actual_schedule_labor_day
                            }
                            calculated_earned_labor_day={
                              calculated_earned_labor_day
                            }
                            actual_used_labor_vs_earned_day={
                              actual_used_labor_vs_earned_day
                            }
                            actual_schedule={day_shifts}
                            agg_column={props.agg_column}
                            get_t_str={props.get_t_str}
                            combine_data={combine_data}
                            daily_batch_guidance={daily_batch_guidance}
                            daily_intraday_metrics={daily_intraday_metrics}
                            forecast_sales={daily_forecast_sales}
                            calculate_daily_budget_score={
                              calculate_daily_budget_score
                            }
                            calculate_intraday_coverage_score={
                              calculate_intraday_coverage_score
                            }
                            calculate_letter={calculate_letter}
                            calculate_color={calculate_color}
                            daily_weather={daily_weather}
                            checkedMetricsToggle={checkedMetricsToggle}
                            role_types={props.all_data.role_types}
                            role_names={props.all_data.role_names}
                            start_qhnum={props.start_qhnum}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="h-28"></div>
                      <div className="fixed bottom-1 z-50">
                        <div>
                          <VisualButtonOptions
                            date_name={date_name}
                            date_str={date_str}
                            forecasted_labor_day={forecasted_labor_day}
                            forecasted_workload_day={forecasted_workload_day}
                            recommended_schedule_labor_day={
                              recommended_schedule_labor_day
                            }
                            store_team_member_weekly_info={
                              props.store_team_member_weekly_info
                            }
                            actual_schedule_labor_day={
                              actual_schedule_labor_day
                            }
                            calculated_earned_labor_day={
                              calculated_earned_labor_day
                            }
                            actual_used_labor_vs_earned_day={
                              actual_used_labor_vs_earned_day
                            }
                            actual_schedule={day_shifts}
                            agg_column={props.agg_column}
                            get_t_str={props.get_t_str}
                            combine_data={combine_data}
                            daily_batch_guidance={daily_batch_guidance}
                            daily_intraday_metrics={daily_intraday_metrics}
                            forecast_sales={daily_forecast_sales}
                            calculate_daily_budget_score={
                              calculate_daily_budget_score
                            }
                            calculate_intraday_coverage_score={
                              calculate_intraday_coverage_score
                            }
                            calculate_letter={calculate_letter}
                            calculate_color={calculate_color}
                            daily_weather={daily_weather}
                            checkedMetricsToggle={checkedMetricsToggle}
                            role_types={props.all_data.role_types}
                            role_names={props.all_data.role_names}
                            start_qhnum={props.start_qhnum}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </div>
      ) : (
        <DailySchedule
          toggleDailyWeekly={props.toggleDailyWeekly}
          dowSelected={props.dowSelected}
          get_date_info={get_date_info}
          get_person_shift_info={get_person_shift_info}
          store_team_member_weekly_info_view={
            props.store_team_member_weekly_info_view
          }
          store_team_member_weekly_info={
            props.store_team_member_weekly_info
          }
          lock_person={props.lock_person}
          role_names={props.all_data.role_names}
          addForecastAdjustment={props.addForecastAdjustment}
          addAdjustmentFinished={props.addAdjustmentFinished}
          lock_day={props.lock_day}
          day_of_week_str={day_of_week_str}
          week_in_past={props.week_in_past}
          updatePersonInfo={props.updatePersonInfo}
          get_t_str={props.get_t_str}
          lockAllShifts={props.lockAllShifts}
          displayMode={props.displayMode}
          saveShiftFinished={props.saveShiftFinished}
          setSaveShiftFinished={props.setSaveShiftFinished}
          setCopiedShift={props.setCopiedShift}
          addMultipleShifts={props.addMultipleShifts}
          addShift={props.addShift}
          store_id={props.store_id}
          agg_column={props.agg_column}
          calculate_letter={calculate_letter}
          calculate_color={calculate_color}
          combine_data={combine_data}
          copiedShift={props.copiedShift}
          edit_hoo={props.edit_hoo}
          saveEvent={props.saveEvent}
          deleteEvent={props.deleteEvent}
          saveEventFinished={props.saveEventFinished}
          setSaveEventFinished={props.setSaveEventFinished}
          saveForecastExcludeDate={props.saveForecastExcludeDate}
          deleteForecastExcludeDate={props.deleteForecastExcludeDate}
          filter_df_to_day={props.filter_df_to_day}
          weather={props.all_data.weather}
          events={props.all_data.events}
          local_events={props.all_data.local_events}
          forecast_exclude_dates={props.all_data.forecast_exclude_dates}
          role_types={props.all_data.role_types}
          businessWeekStartDayIndex={props.businessWeekStartDayIndex}
          start_qhnum={props.start_qhnum}
          frequentShifts={props.frequentShifts}
          post_missed_shift={props.post_missed_shift}
          showUnpublishedShifts={props.showUnpublishedShifts}
          defaultBreakLengthHours={props.defaultBreakLengthHours}
          week_already_started={props.week_already_started}
          editShiftsInPastEnabled={props.editShiftsInPastEnabled}
          highlightedEmployees={props.highlightedEmployees}
          isCopilotOn={props.isCopilotOn}
          proficiencyStarsCount={props.proficiencyStarsCount}
          proficiencyFromLMSEnabled={props.proficiencyFromLMSEnabled}
          calculate_daily_budget_score={
            calculate_daily_budget_score
          }
          calculate_intraday_coverage_score={
            calculate_intraday_coverage_score
          }
          batch_guidance={props.all_data.batch_guidance}
          intraday_metrics={props.all_data.intraday_metrics}
          breakRequiredWhenShiftLengthExceeds={props.breakRequiredWhenShiftLengthExceeds}
        />
      );
    }
  } else if (
    props.store_team_member_weekly_info_view.length === 0 &&
    !props.loading
  ) {
    return <NoStoreTeamMemberDisplay roleType={props.roleType} />;
  } else {
    return <SkeletonDisplay />;
  }
}

export default ScheduleDisplay;
