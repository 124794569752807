import React from 'react'
import CheckIcon from "@mui/icons-material/Check";

function TaskCheckboxCompleted(props) {
  return (
    <div className="flex-1">
      <div className="flex gap-4">
        <div
          className={`${
            props.subtask.value_boolean
              ? "bg-violet-700 border-violet-700"
              : "bg-white border-gray-300"
          } w-6 h-6 rounded border flex items-center justify-center transition-colors`}
        >
          {props.subtask.value_boolean && (
            <CheckIcon
              className="text-white"
              style={{ fontSize: "18px" }}
            />
          )}
        </div>
        <div className="flex-1 flex justify-between">
          <div>
            <p className="text-sm font-medium text-gray-900">
              {props.subtask.plan_subtask.name}
            </p>
            <p className="text-xs text-gray-500 mt-0.5">
              {props.subtask.plan_subtask.description}
            </p>
          </div>
          {props.isAssignedToEveryone &&
            props.subtask.store_team_member && props.subtask.is_completed && (
              <div>
                <p className="text-xs italic text-emerald-600">
                  {
                    props.subtask.store_team_member
                      ?.full_name
                  }{" "}
                  @{" "}
                  {
                    props.subtask?.completed_at_formatted
                  }
                </p>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default TaskCheckboxCompleted
