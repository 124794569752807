import React from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CloseIcon from "@mui/icons-material/Close";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ChecklistIcon from "@mui/icons-material/Checklist";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InfoIcon from "@mui/icons-material/Info";

const RenderQuestionDragAndDrop = ({
  question,
  selectedAnswers,
  setSelectedAnswers,
  hasSubmitted,
  feedback,
  handleNextQuestion,
  handleProceed,
  currentQuestionIndex,
  totalQuestions,
  quizDetails,
  score,
  setScore,
  setFeedback,
  setHasSubmitted,
  postLearningBlockUserPassed,
  employeeID,
  type,
}) => {
  const [draggableAnswers, setDraggableAnswers] = React.useState(
    question.answers
  );
  const [targetAreaHeight, setTargetAreaHeight] = React.useState(0);
  const [imagesLoaded, setImagesLoaded] = React.useState(false);
  const targetAreaRef = React.useRef(null);

  React.useEffect(() => {
    setDraggableAnswers(question.answers);
    setSelectedAnswers([]);
    setImagesLoaded(false);
  }, [question, setSelectedAnswers]);

  React.useEffect(() => {
    const imageUrls = question.answers
      .map(([, , , image_url]) => image_url)
      .filter(Boolean);

    if (imageUrls.length === 0) {
      setImagesLoaded(true);
      return;
    }

    let loadedCount = 0;
    const imagePromises = imageUrls.map((url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          loadedCount++;
          if (loadedCount === imageUrls.length) {
            setImagesLoaded(true);
          }
          resolve();
        };
        img.onerror = reject;
        img.src = url;
      });
    });

    Promise.all(imagePromises).catch((error) => {
      console.error("Error loading images:", error);
      setImagesLoaded(true);
    });
  }, [question]);

  React.useEffect(() => {
    if (imagesLoaded && targetAreaRef.current) {
      setTargetAreaHeight(targetAreaRef.current.offsetHeight);
    }
  }, [imagesLoaded]);

  const correctAnswers = question.answers
    .filter(([_, is_correct]) => is_correct)
    .map(([answer, , image_uuid]) => image_uuid || answer);

  const onDragEnd = (result) => {
    if (hasSubmitted) return;

    if (!result.destination) return;

    const { source, destination } = result;
    const sourceDroppableId = source.droppableId;
    const destinationDroppableId = destination.droppableId;

    if (
      sourceDroppableId === "answers-drop-area" &&
      destinationDroppableId === "target-drop-area"
    ) {
      const answer = draggableAnswers[source.index];
      const answerId = answer[2] || answer[0];

      setSelectedAnswers((prevSelected) => [...prevSelected, answerId]);

      setDraggableAnswers((prevAnswers) =>
        prevAnswers.filter((_, index) => index !== source.index)
      );
    }
  };

  const handleAnswerClick = (answerId) => {
    if (hasSubmitted) return;

    // Find the original answer
    const originalAnswer = question.answers.find(
      ([a, , uuid]) => uuid === answerId || a === answerId
    );

    // Remove the answer from selectedAnswers
    setSelectedAnswers((prevSelected) =>
      prevSelected.filter((id) => id !== answerId)
    );

    // Add the original answer back to draggableAnswers
    setDraggableAnswers((prevAnswers) => [...prevAnswers, originalAnswer]);
  };

  const handleNextQuestionDragAndDrop = () => {
    const isCorrect =
      correctAnswers.every((id) => selectedAnswers.includes(id)) &&
      selectedAnswers.every((id) => correctAnswers.includes(id));

    if (isCorrect) {
      setScore(score + 1);
    }

    setFeedback(isCorrect ? "Correct!" : "Incorrect!");
    setHasSubmitted(true);
  };

  // console.log("TARGET AREA HEIGHT", targetAreaHeight)

  return (
    <div className="w-[65%] lg:w-[85%] md:w-[90%] sm:w-[95%]">
      <div className="px-3 py-0.5 border border-slate-200 text-slate-500 text-sm rounded-lg w-fit">
        Drag and drop
      </div>
      <div className="pt-6 pb-10 border-b border-slate-200">
        <h3 className="text-slate-900 text-2xl">{question.question_v2?.[0]}</h3>
      </div>
      <div className="mt-10" ref={targetAreaRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="flex gap-x-8 md:gap-x-4">
            <Droppable
              droppableId="answers-drop-area"
              isDropDisabled={hasSubmitted}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="w-1/2 flex flex-wrap justify-between gap-y-3"
                >
                  {draggableAnswers.map(
                    ([answer, isCorrect, image_uuid, image_url], index) => {
                      if (!answer && !image_url) return null;

                      const answerClass = hasSubmitted
                        ? isCorrect
                          ? "bg-emerald-500 text-white"
                          : "bg-slate-50 text-slate-600"
                        : "bg-slate-50 text-slate-600";
                      const draggableId = `draggable-${image_uuid || answer}`;

                      return (
                        <Draggable
                          key={draggableId}
                          draggableId={draggableId}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                              className={`p-2.5 rounded-lg flex w-[48%] space-x-5 h-fit ${answerClass}`}
                            >
                              {answer ? <p>{answer}</p> : ""}
                              {image_url && (
                                <div>
                                  <img
                                    src={image_url}
                                    alt="Answer Image"
                                    style={{
                                      borderRadius: "10px",
                                      maxHeight: "250px",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable
              droppableId="target-drop-area"
              isDropDisabled={hasSubmitted}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    backgroundImage: question.question_v2?.[2]
                      ? `url(${question.question_v2?.[2]})`
                      : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: `${targetAreaHeight}px`,
                  }}
                  className={`w-1/2 border-dashed border-2 rounded-lg flex flex-wrap justify-between gap-y-3 ${
                    snapshot.isDraggingOver
                      ? "border-emerald-500"
                      : "border-slate-300"
                  }`}
                >
                  {selectedAnswers.map((answerId) => {
                    const answerData = question.answers.find(
                      ([a, , uuid]) => uuid === answerId || a === answerId
                    );
                    const [text, isCorrect, , image_url] = answerData || [];
                    const answerClass = hasSubmitted
                      ? isCorrect
                        ? "bg-emerald-500 text-white"
                        : "bg-rose-500 text-white"
                      : "bg-slate-50 text-slate-600";

                    return (
                      <div
                        key={`selected-answer-${answerId}`}
                        className={`p-2.5 h-fit rounded-lg flex w-[48%] space-x-5 ${answerClass} ${
                          !hasSubmitted ? "cursor-pointer" : ""
                        }`}
                        onClick={() =>
                          !hasSubmitted && handleAnswerClick(answerId)
                        }
                      >
                        {text ? <p>{text}</p> : ""}
                        {image_url && (
                          <div>
                            <img
                              src={image_url}
                              alt="Answer Image"
                              style={{
                                borderRadius: "10px",
                                maxHeight: "250px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      <div className="w-full flex items-center justify-between mt-8 gap-x-2">
        <div className="flex items-center space-x-3">
          <div
            className={`w-fit px-6 py-3 rounded-lg ${
              !hasSubmitted
                ? "bg-violet-700 hover:bg-violet-800 text-white cursor-pointer"
                : "bg-slate-200 text-slate-400"
            } text-sm`}
            onClick={handleNextQuestionDragAndDrop}
          >
            Submit
          </div>
          {hasSubmitted && (
            <div
              className="w-fit px-6 py-3 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm cursor-pointer flex items-center justify-center gap-x-1.5"
              onClick={() => {
                if (
                  currentQuestionIndex === totalQuestions - 1 &&
                  type !== "Preview"
                ) {
                  const passScore = (score / totalQuestions) * 100;
                  const passed = passScore >= quizDetails.pass_percentage;
                  postLearningBlockUserPassed(
                    quizDetails.uuid,
                    passed,
                    employeeID
                  );
                }
                handleProceed();
              }}
            >
              <p>
                {currentQuestionIndex === totalQuestions - 1
                  ? "Results"
                  : "Next"}
              </p>
              <div>
                {currentQuestionIndex === totalQuestions - 1 ? (
                  <ChecklistIcon style={{ fontSize: "20px" }} />
                ) : (
                  <ChevronRightIcon style={{ fontSize: "20px" }} />
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          {feedback && quizDetails.show_correct_answers && (
            <div className="flex items-center space-x-2">
              <div
                className={`w-6 h-6 rounded-full flex items-center justify-center text-white ${
                  feedback === "Correct!"
                    ? "bg-emerald-500 animate-bounce"
                    : "bg-rose-500 animate-pulse"
                }`}
              >
                {feedback === "Correct!" ? <CheckIcon /> : <CloseIcon />}
              </div>
              <p
                className={`text-lg ${
                  feedback === "Correct!"
                    ? "text-emerald-600 animate-bounce"
                    : "text-rose-600 animate-pulse"
                }`}
              >
                {feedback}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderQuestionDragAndDrop;
