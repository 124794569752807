import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

const CreateNewButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const open = Boolean(anchorEl);
  const fileInputRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateFolderClick = () => {
    handleClose();
    setIsModalOpen(true);
  };

  const handleCreateFolder = () => {
    props.create_folder(newFolderName, props.currentFolder);
    setNewFolderName("");
    setIsModalOpen(false);
  };

  const handleUploadClick = () => {
    handleClose();
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        await props.upload_file(file, props.currentFolder);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setIsUploading(false);
      }
    }
    // Reset the file input so the same file can be selected again
    event.target.value = '';
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        endIcon={<AddIcon />}
        disabled={isUploading}
        sx={{
          textTransform: "none",
          bgcolor: "#6d28d9",
          "&:hover": {
            bgcolor: "#5b21b6",
          },
        }}
      >
        {isUploading ? (
          <>
            <CircularProgress size={20} color="inherit" className="mr-2" />
            Uploading...
          </>
        ) : (
          'Create New'
        )}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleCreateFolderClick} sx={{ gap: 1 }}>
          <CreateNewFolderOutlinedIcon fontSize="small" />
          <p className="ml-1.5">Create new folder</p>
        </MenuItem>
        <MenuItem
          onClick={handleUploadClick}
          sx={{ gap: 1 }}
          disabled={isUploading}
        >
          <UploadFileIcon fontSize="small" />
          <p className="ml-1.5">Upload new file</p>
        </MenuItem>
      </Menu>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="bg-white rounded-lg p-6 w-96 relative">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <CloseIcon className="w-5 h-5" />
            </button>

            <h3
              className="text-lg font-medium text-gray-900 mb-4"
              id="modal-title"
            >
              Create New Folder
            </h3>

            <div className="mb-4">
              <label
                htmlFor="folderName"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Folder Name
              </label>
              <input
                type="text"
                id="folderName"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-violet-500 focus:border-violet-500"
                placeholder="Enter folder name"
                autoFocus
              />
            </div>

            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateFolder}
                disabled={!newFolderName.trim()}
                className="px-4 py-2 text-sm font-medium text-white bg-violet-700 rounded-md hover:bg-violet-800 disabled:bg-violet-300 disabled:cursor-not-allowed"
              >
                Create
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreateNewButton;
