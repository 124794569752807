import React from "react";
import TopBarDisplayCreateTrainingContent from "../home/top_bar_components/TopBarDisplayCreateTrainingContent";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LogbookCategoryDrawer from "./LogbookCategoryDrawer";
import {
  API_get_logbook_columns,
  API_post_logbook_category,
  API_delete_logbook_category,
  API_post_reorder_logbook_columns_and_categories,
} from "../../../constants";
import {
  getRightworkAPI,
  postRightworkAPI,
} from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { formatName } from "../../../helpers/helpers";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

function ConfigureLogbookDisplay(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const default_modal_data = {
    name: "",
    type: "text",
    logbook_category_uuid: null,
  };

  const [modalData, setModalData] = React.useState(default_modal_data);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [columnSide, setColumnSide] = React.useState("");
  const [selectedColumnUUID, setSelectedColumnUUID] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState(null);

  // New state to track if columns have been reordered
  const [isReordered, setIsReordered] = React.useState(false);

  const fetchLogbookColumns = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = { company_id: props.homeCompany };
    let api = API_get_logbook_columns;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.logbook_columns;
        setIsReordered(false);
        setColumnsData(data);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    fetchLogbookColumns(true);
  }, []);

  React.useEffect(() => {
    if (isReordered) {
      post_reorder_logbook_columns_and_categories();
    }
  }, [columnsData]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    return {
      source: sourceClone,
      destination: destClone,
    };
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const updatedCategories = reorder(
        columnsData.find((col) => col.uuid === source.droppableId).categories,
        source.index,
        destination.index
      );

      const updatedColumnsData = columnsData.map((column) =>
        column.uuid === source.droppableId
          ? { ...column, categories: updatedCategories }
          : column
      );

      setColumnsData(updatedColumnsData);
    } else {
      const { source: updatedSource, destination: updatedDest } = move(
        columnsData.find((col) => col.uuid === source.droppableId).categories,
        columnsData.find((col) => col.uuid === destination.droppableId)
          .categories,
        source,
        destination
      );

      const updatedColumnsData = columnsData.map((column) => {
        if (column.uuid === source.droppableId) {
          return { ...column, categories: updatedSource };
        }
        if (column.uuid === destination.droppableId) {
          return { ...column, categories: updatedDest };
        }
        return column;
      });

      setColumnsData(updatedColumnsData);
    }
    setIsReordered(true);
  };

  const post_logbook_category = async () => {
    const requestData = {
      company_id: props.homeCompany,
      logbook_category_uuid: modalData.uuid,
      logbook_column_uuid: selectedColumnUUID,
      name: modalData.name,
      type: modalData.type,
    };

    try {
      toast.loading("Saving Logbook Category...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_logbook_category,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Logbook Category Saved Successfully", {
          autoClose: 3000,
        });
        fetchLogbookColumns(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  const delete_logbook_category = async (logbook_category_uuid) => {
    const requestData = {
      company_id: props.homeCompany,
      logbook_category_uuid: logbook_category_uuid,
    };

    try {
      toast.loading("Deleting Logbook Category...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_logbook_category,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Logbook Category Deleted Successfully", {
          autoClose: 3000,
        });
        fetchLogbookColumns(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  // [POST] api/logbook_info/post_reorder_logbook_columns_and_categories
  // * company_id (auth)
  // * columns_json
  const post_reorder_logbook_columns_and_categories = async () => {
    const requestData = {
      company_id: props.homeCompany,
      columns_json: JSON.stringify(columnsData),
    };

    try {
      toast.loading("Saving Reorder...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_reorder_logbook_columns_and_categories,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Reorder Saved Successfully", { autoClose: 3000 });
        fetchLogbookColumns(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  // console.log("COLUMNS DATA", columnsData)
  // console.log("MODAL DATA", modalData)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="w-full min-h-screen flex flex-col">
        <div>
          <TopBarDisplayCreateTrainingContent
            title={"Configure Logbook"}
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
          />
        </div>
        {!isLoading ? (
          <div className="mt-12 px-12">
            <h2 className="text-slate-900 text-2xl">Categories</h2>
            <p className="text-slate-500 mt-2">
              Drag and organize your logbook categories into columns for easy
              viewing
            </p>
            {/* <p className="text-slate-500 mt-2 text-xs">WARNING: Reordering or deleting logbook categories may cause issues with present and future entries (past entries will not be affected)</p> */}
            <div className="w-full flex mt-8 space-x-12 mb-10">
              {columnsData?.map((column, indexColumn) => (
                <Droppable key={column.uuid} droppableId={column.uuid}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="w-1/2"
                    >
                      <div className="flex items-center justify-between">
                        <h4 className="text-lg text-slate-900">
                          {indexColumn === 0 ? "Left Column" : "Right Column"}
                        </h4>
                        <div
                          className="w-7 h-7 rounded-full bg-violet-700 hover:bg-violet-800 text-white flex items-center justify-center cursor-pointer"
                          onClick={() => {
                            setModalData(default_modal_data);
                            setModalEdit(false);
                            setColumnSide(
                              column.index === 0 ? "left" : "right"
                            );
                            setSelectedColumnUUID(column.uuid);
                            handleOpen();
                          }}
                        >
                          <AddIcon style={{ fontSize: "20px" }} />
                        </div>
                      </div>
                      <div className="mt-4 w-full border border-slate-300 rounded-xl overflow-hidden">
                        {column.categories.length > 0 ? (
                          column.categories.map((entry, index) => (
                            <Draggable
                              key={entry.uuid}
                              draggableId={entry.uuid}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`py-4 px-4 ${
                                    index !== column.categories.length - 1
                                      ? `border-b border-slate-300`
                                      : ""
                                  } flex items-center justify-between ${
                                    snapshot.isDragging
                                      ? "bg-slate-200 rounded"
                                      : "bg-white"
                                  }`}
                                >
                                  <div className="flex items-center gap-x-2">
                                    <div className="text-slate-500">
                                      <DragIndicatorIcon />
                                    </div>
                                    <div>
                                      <p className="text-slate-900">
                                        {entry.name}
                                      </p>
                                      <p className="text-slate-500 text-sm">
                                        {formatName(entry.type)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-x-2 text-slate-500">
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setModalEdit(true);
                                        setColumnSide(
                                          indexColumn === 0 ? "left" : "right"
                                        );
                                        setSelectedColumnUUID(column.uuid);
                                        setModalData(entry);
                                        handleOpen();
                                      }}
                                    >
                                      <EditOutlinedIcon />
                                    </div>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() =>
                                        delete_logbook_category(entry.uuid)
                                      }
                                    >
                                      <DeleteOutlinedIcon />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <div className="w-full h-36 text-slate-500 flex flex-col items-center justify-center">
                            <div>
                              <InfoOutlinedIcon />
                            </div>
                            <p className="mt-1 w-[65%] text-center text-sm">
                              No categories have been added
                            </p>
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
            {/* {isReordered && (
              <div className="mt-2 mb-6">
                <button
                  className="bg-violet-100 hover:bg-violet-200 text-violet-700 rounded-lg px-4 py-2 cursor-pointer"
                  onClick={() => post_reorder_logbook_columns_and_categories()}
                >
                    Save Reorder
                </button>
              </div>
            )} */}
          </div>
        ) : (
          <div className="w-full flex-1 relative">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <LoadSpinner />
            </div>
          </div>
        )}
        <LogbookCategoryDrawer
          open={open}
          onClose={handleClose}
          modalData={modalData}
          setModalData={setModalData}
          modalEdit={modalEdit}
          columnSide={columnSide}
          post_logbook_category={post_logbook_category}
          default_modal_data={default_modal_data}
        />
      </div>
    </DragDropContext>
  );
}

export default ConfigureLogbookDisplay;
