import React from "react";
import ShiftPoolRequestsTableRow from "./ShiftPoolRequestsTableRow";
import ShiftPoolRequestsOpenShiftsTableRow from "../../ShiftPoolRequestsOpenShiftsTableRow";
import { add_shift_attributes } from "../../utilities/helpers";
import EmptyState from "../../shared/helpers/EmptyState";

export default function ShiftPoolRequestsTable(props) {
  let shiftPoolRequests = props.shiftPoolData?.filter(
    (v, i) => v.status === "PENDING_DROP" || v.status === "AVAILABLE"
  );

  shiftPoolRequests = shiftPoolRequests?.map((v, i) => add_shift_attributes(v));

  // let shiftPoolOpenShifts = props.shiftPoolData?.filter(
  //   (v, i) => v.status === "AVAILABLE"
  // );

  // shiftPoolOpenShifts = shiftPoolOpenShifts?.map((v, i) =>
  //   add_shift_attributes(v)
  // );

  const hasShiftPoolRequests = shiftPoolRequests?.length > 0;
  // const hasOpenShiftsRequests = shiftPoolOpenShifts?.length > 0

  // console.log("SHIFT POOL REQUESTS", shiftPoolRequests)

  return (
    <div className="mt-12">
      {hasShiftPoolRequests ? (
        <div>
          <div className="flex items-center px-6">
            <div className="flex-auto">
              <h1 className="text-3xl text-slate-900">Requests</h1>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[20%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Shift Given Up
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Note
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] pl-3 pr-6 py-3.5 text-right text-sm text-slate-500 font-medium"
                        >
                          Requested By
                        </th>
                        {/* <th scope="col" className="w-1/12 relative py-3.5 pl-3 pr-6">
                          <span className="sr-only">Edit</span>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {shiftPoolRequests?.map((request, index) => (
                        <ShiftPoolRequestsTableRow
                          key={index}
                          id={request.id}
                          date={request.date}
                          name={request.name}
                          shift={request.shift}
                          break={request.break}
                          breakFormatted={request.break_formatted}
                          hours={request.hours}
                          location={request.location}
                          role={request.role}
                          station={request.station?.name}
                          roleType={request.roleType}
                          note={request.status !== "PENDING_DROP" ? request.note : request.employee_drop_note}
                          status={request.status}
                          bids={request.pending_pickups}
                          manager_reject_shift_drop={
                            props.manager_reject_shift_drop
                          }
                          manager_approve_shift_pickup={
                            props.manager_approve_shift_pickup
                          }
                          manager_shift_pickup_request_response={
                            props.manager_shift_pickup_request_response
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          title="Requests"
          description="No shift pool requests at the moment"
        />
      )}
    </div>
  );
}
