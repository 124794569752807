import React from "react";
import TabsThree from "../../shared/helpers/TabsThree";
import TabsTwo from "../../shared/helpers/TabsTwo";
import TimeOffMineTable from "../../shared/availability/TimeOffMineTable";
import PreferencesMineTable from "../../shared/availability/PreferencesMineTable";
import AvailabilityMineTable from "../../shared/availability/AvailabilityMineTable";
import { mergeAvailability2 } from "../../utilities/helpers";
import { mergePreferences2 } from "../../utilities/helpers";

export default function AvailabilityDisplayEmployee(props) {

  const myAvailabilityData2 = mergeAvailability2(
    props.availabilityData2.current,
    props.availabilityData2.approved,
    props.availabilityData2.pending
  );

  return (
    <>
      <div className="w-full mt-2">
        <AvailabilityMineTable
          myAvailabilityData={myAvailabilityData2}
          create_availability_request={props.create_availability_request}
          rescind_availability_request={props.rescind_availability_request}
          permissionRole={props.permissionRole}
          minEffectiveDate={props.minEffectiveDate}
        />
      </div>
    </>
  );
}
