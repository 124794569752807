import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import {
  API_post_file_upload_with_eagerly_generated_presigned_url,
  API_get_file_upload,
} from "../../../constants";
import { getDefaultAxiosConfig } from "../requests/utilities/requests";
import ImageIcon from "@mui/icons-material/Image";
import { Lightbox } from "react-modal-image";
import { getRightworkAPI } from "../requests/utilities/requests";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

function QuestionInputsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 700,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [showLighbox, setShowLightbox] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const uploadFile = async (media, type) => {
    const formData = new FormData();
    formData.append("company_id", props.homeCompany);
    formData.append("file_name", media.name);
    formData.append("type", type);

    // [1] Get presigned URL
    const axiosConfig = await getDefaultAxiosConfig();
    const res = await axios.post(
      API_post_file_upload_with_eagerly_generated_presigned_url,
      formData,
      {
        headers: {
          ...axiosConfig.headers,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const fileUUID = res?.data?.redux?.payload?.uuid;
    const presignedUrl = res?.data?.redux?.payload?.presigned_url;

    // [2] Upload file
    await axios.put(presignedUrl, media, {
      headers: {
        "Content-Type": media.type,
      },
    });

    // [3] Get file URL
    return fileUUID;
  };

  // [GET] api/file_upload_info/get_file_upload
  // * file_upload_uuid
  const get_file_upload = async (file_upload_uuid) => {
    let api_params = {
      file_upload_uuid: file_upload_uuid,
    };
    try {
      const res = await getRightworkAPI(API_get_file_upload, api_params);
      if (res.status === 200) {
        setSelectedImage(res.data.redux?.payload?.presigned_url);
        setShowLightbox(true);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const handleSaveQuestion = () => {
    props.setModalDataQuiz((prevModalData) => {
      const updatedQuestions = [...(prevModalData.learning_questions || [])];
      const filteredAnswers = props.modalDataQuizQuestion.answers.filter(
        ([answerText, , fileUUID]) => answerText || fileUUID
      );

      const updatedQuestion = {
        ...props.modalDataQuizQuestion,
        answers: filteredAnswers,
      };

      if (props.modalEdit) {
        updatedQuestions[props.selectedQuestionIndexToEdit] = updatedQuestion;
      } else {
        updatedQuestions.push(updatedQuestion);
      }

      return {
        ...prevModalData,
        learning_questions: updatedQuestions,
      };
    });
  };

  const handleDuplicateQuestion = () => {
    props.setModalDataQuiz((prevModalData) => {
      const updatedQuestions = [...(prevModalData.learning_questions || [])];
      const currentQuestion = {
        ...props.modalDataQuizQuestion,
        question_v2: [
          `${props.modalDataQuizQuestion.question_v2[0]}`,
          ...props.modalDataQuizQuestion.question_v2.slice(1),
        ],
      };
      const filteredAnswers = currentQuestion.answers.filter(
        ([answerText, , fileUUID]) => answerText || fileUUID
      );

      const newQuestion = {
        ...currentQuestion,
        answers: filteredAnswers,
      };

      // Insert the duplicated question right below the current question
      const indexToInsert = props.selectedQuestionIndexToEdit + 1;
      updatedQuestions.splice(indexToInsert, 0, newQuestion);

      return {
        ...prevModalData,
        learning_questions: updatedQuestions,
      };
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.setModalDataQuizQuestion((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    props.setModalDataQuizQuestion((prevModalData) => {
      const updatedQuestionV2 = [
        ...(prevModalData.question_v2 || ["", "", ""]),
      ];
      updatedQuestionV2[0] = value;
      return {
        ...prevModalData,
        question_v2: updatedQuestionV2,
      };
    });
  };

  const handleAnswersChange = (event, index) => {
    const { value } = event.target;
    props.setModalDataQuizQuestion((prevModalData) => {
      const updatedAnswers = [...(prevModalData.answers || [])];
      const [currentAnswerText, isCorrect, currentFileUUID] = updatedAnswers[
        index
      ] || ["", false, null];
      updatedAnswers[index] = [value, isCorrect, currentFileUUID];
      return {
        ...prevModalData,
        answers: updatedAnswers,
      };
    });
  };

  const handleFileChangeForQuestion = async (e) => {
    const file = e.target.files[0];
    const fileUUID = await uploadFile(file, "lms_quiz_question_main");
    props.setModalDataQuizQuestion((prevModalData) => ({
      ...prevModalData,
      question_v2: [prevModalData.question_v2?.[0] || "", fileUUID],
    }));
  };

  const handleFileChangeForAnswer = async (e, index) => {
    const file = e.target.files[0];
    const fileUUID = await uploadFile(file, "lms_quiz_question_answer");
    props.setModalDataQuizQuestion((prevModalData) => {
      const updatedAnswers = [...(prevModalData.answers || [])];
      const [currentAnswerText, isCorrect] = updatedAnswers[index] || [
        "",
        false,
      ];
      updatedAnswers[index] = [currentAnswerText, isCorrect, fileUUID];
      return {
        ...prevModalData,
        answers: updatedAnswers,
      };
    });
  };

  const handleToggleCorrect = (index) => {
    props.setModalDataQuizQuestion((prevModalData) => {
      const updatedAnswers = [...(prevModalData.answers || [])];
      const [currentAnswerText, isCorrect, fileUUID] = updatedAnswers[
        index
      ] || ["", false, null];
      updatedAnswers[index] = [currentAnswerText, !isCorrect, fileUUID];
      return {
        ...prevModalData,
        answers: updatedAnswers,
      };
    });
  };

  const handleRemoveFileForQuestion = () => {
    props.setModalDataQuizQuestion((prevModalData) => {
      const updatedQuestionV2 = [
        ...(prevModalData.question_v2 || ["", "", ""]),
      ];
      updatedQuestionV2[1] = null;
      updatedQuestionV2[2] = null;
      return {
        ...prevModalData,
        question_v2: updatedQuestionV2,
      };
    });
  };

  const handleRemoveFileForAnswer = (index) => {
    props.setModalDataQuizQuestion((prevModalData) => {
      const updatedAnswers = [...(prevModalData.answers || [])];
      const [currentAnswerText, isCorrect, fileUUID, fileURL] = updatedAnswers[
        index
      ] || ["", false, null, null];
      updatedAnswers[index] = [currentAnswerText, isCorrect, null, null];
      return {
        ...prevModalData,
        answers: updatedAnswers,
      };
    });
  };

  const handleDragAndDropChange = (event) => {
    const { checked } = event.target;
    props.setModalDataQuizQuestion((prevModalData) => ({
      ...prevModalData,
      is_drag_and_drop: checked,
    }));
  };

  // console.log("MODAL DATA QUESTION", props.modalDataQuizQuestion)

  return (
    <>
      <Modal
        open={props.open}
        // onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div>
          {showLighbox && (
            <div className="absolute w-screen h-screen z-40">
              <Lightbox
                small={selectedImage}
                medium={selectedImage}
                // large={selectedImage}
                hideDownload={true}
                hideZoom={true}
                onClose={() => setShowLightbox(false)}
              />
            </div>
          )}
          <Box sx={style}>
            <div
              className="absolute right-2 top-2 text-gray-500 cursor-pointer"
              onClick={() => props.handleClose()}
            >
              <CloseIcon />
            </div>
            <div>
              <div>
                <h3 className="text-2xl text-slate-900">
                  {props.modalEdit ? "Edit Question" : "Add Question"}
                </h3>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Question</p>
                <div className="mt-1.5">
                  <div className="flex items-center space-x-3">
                    <div className="flex-1">
                      <TextField
                        placeholder="How are you supposed to approach a table?"
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="question_v2"
                        value={
                          props.modalDataQuizQuestion.question_v2?.[0] || ""
                        }
                        onChange={handleNameChange}
                        InputProps={{
                          sx: {
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "5px",
                            height: "45px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                          },
                        }}
                      />
                      {props.modalDataQuizQuestion.question_v2?.[1] && (
                        <div className="w-full flex justify-end">
                          <div className="flex items-center space-x-2 mt-0.5">
                            <div
                              className="text-slate-500 hover:text-slate-500 cursor-pointer"
                              onClick={handleRemoveFileForQuestion}
                            >
                              <CloseIcon style={{ fontSize: "18px" }} />
                            </div>
                            <div
                              className="text-violet-700 hover:text-violet-900 text-sm cursor-pointer"
                              onClick={() => {
                                if (
                                  props.modalDataQuizQuestion.question_v2?.[2]
                                ) {
                                  setSelectedImage(
                                    props.modalDataQuizQuestion.question_v2?.[2]
                                  );
                                  setShowLightbox(true);
                                } else {
                                  get_file_upload(
                                    props.modalDataQuizQuestion.question_v2?.[1]
                                  );
                                }
                              }}
                            >
                              <span>
                                <ImageIcon style={{ fontSize: "18px" }} />
                              </span>
                              1 Image Attached
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="file-upload-question"
                        className="text-slate-500 cursor-pointer"
                      >
                        <span>
                          <AttachFileIcon />
                        </span>
                      </label>
                      <input
                        id="file-upload-question"
                        name="file-upload-question"
                        type="file"
                        className="hidden"
                        onChange={handleFileChangeForQuestion}
                        accept=".jpg, .jpeg, .png"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <Checkbox
                      name="showCorrectAnswersChecked"
                      checked={props.modalDataQuizQuestion.is_drag_and_drop}
                      onChange={handleDragAndDropChange}
                      size="small"
                      sx={{
                        color: "#6d28d9",
                        "&.Mui-checked": {
                          color: "#6d28d9",
                        },
                      }}
                    />
                    <p className="text-sm text-slate-500 ml-1">
                      Do you want this question to be drag and drop
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <p className="text-sm text-slate-500">Answers</p>
                {["A", "B", "C", "D", "E"].map((label, index) => (
                  <div className="mt-5 flex items-center space-x-3" key={index}>
                    <p className="text-sm text-slate-500">{label}.</p>
                    <div className="flex-1">
                      <TextField
                        placeholder={`Answer ${label}`}
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={
                          props.modalDataQuizQuestion.answers?.[index]?.[0] ||
                          ""
                        }
                        onChange={(event) => handleAnswersChange(event, index)}
                        InputProps={{
                          sx: {
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "5px",
                            height: "45px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                          },
                        }}
                      />
                      {props.modalDataQuizQuestion.answers?.[index]?.[2] && (
                        <div className="w-full flex justify-end">
                          <div className="flex items-center space-x-2 mt-0.5">
                            <div
                              className="text-slate-500 hover:text-slate-500 cursor-pointer"
                              onClick={() => handleRemoveFileForAnswer(index)}
                            >
                              <CloseIcon style={{ fontSize: "18px" }} />
                            </div>
                            <div
                              className="text-violet-700 hover:text-violet-900 text-sm cursor-pointer"
                              onClick={() => {
                                if (
                                  props.modalDataQuizQuestion.answers?.[
                                    index
                                  ]?.[3]
                                ) {
                                  setSelectedImage(
                                    props.modalDataQuizQuestion.answers?.[
                                      index
                                    ]?.[3]
                                  );
                                  setShowLightbox(true);
                                } else {
                                  get_file_upload(
                                    props.modalDataQuizQuestion.answers?.[
                                      index
                                    ]?.[2]
                                  );
                                }
                              }}
                            >
                              <span>
                                <ImageIcon style={{ fontSize: "18px" }} />
                              </span>
                              1 Image Attached
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor={`file-upload-answer-${index}`}
                        className="text-slate-500 cursor-pointer"
                      >
                        <span>
                          <AttachFileIcon />
                        </span>
                      </label>
                      <input
                        id={`file-upload-answer-${index}`}
                        name={`file-upload-answer-${index}`}
                        type="file"
                        className="hidden"
                        onChange={(e) => handleFileChangeForAnswer(e, index)}
                        accept=".jpg, .jpeg, .png"
                      />
                    </div>
                    <div
                      className={`w-8 h-8 rounded-full ${
                        props.modalDataQuizQuestion.answers?.[index]?.[1]
                          ? "bg-emerald-600"
                          : "bg-white border border-[#bdbdbd]"
                      } flex items-center justify-center text-white cursor-pointer`}
                      onClick={() => handleToggleCorrect(index)}
                    >
                      {props.modalDataQuizQuestion.answers?.[index]?.[1] && (
                        <CheckIcon />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full mt-16 flex justify-end">
                <div className="flex items-center space-x-2">
                  {props.modalEdit && (
                    <Tooltip title="Duplicate question">
                      <div
                        className="px-6 py-2.5 bg-amber-200 hover:bg-amber-300 text-amber-800 rounded-full text-base w-fit cursor-pointer"
                        onClick={() => {
                          handleDuplicateQuestion();
                          props.handleClose();
                        }}
                      >
                        <ContentCopyIcon />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    className="px-10 py-2.5 border border-violet-700 bg-white hover:bg-slate-50 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                    onClick={() => props.handleClose()}
                  >
                    Cancel
                  </div>
                  <div
                    className="px-10 py-2.5 bg-violet-700 hover:bg-violet-800 text-white rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      handleSaveQuestion();
                      props.handleClose();
                    }}
                  >
                    Save Question
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default QuestionInputsModal;
