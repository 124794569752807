import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddIcon from "@mui/icons-material/Add";
import {
  generateTimeOptionsDF,
  PurpleSwitch,
} from "../../../../helpers/helpers";
import { ButtonGroup, Button, createTheme, ThemeProvider } from "@mui/material";
import { createDayArray } from "../../../../helpers/helpers";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SubtaskDrawer from "./SubtaskDrawer";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import InfoIcon from "@mui/icons-material/Info";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { uploadFile } from "../../requests/utilities/requests";
import CloseIcon from "@mui/icons-material/Close";
import { Lightbox } from "react-modal-image";
import CircularProgress from "@mui/material/CircularProgress";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatDate } from "../../requests/utilities/helpers";
import dayjs from 'dayjs';


const TASK_TYPES = {
  ONE_OFF: 'one_off',
  REPEATING: 'repeating'
};

const REPEATING_PERIODS = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly'
};

function DailyPlanFormBuilder(props) {
  const [open, setOpen] = useState(false);
  const [showLighbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [showCreateCategory, setShowCreateCategory] = useState(props.distinctCategories?.length === 0);
  const [timeSelectionChecked, setTimeSelectionChecked] = useState(false);

  const default_subtask_data = {
    uuid: null,
    name: "",
    description: "",
    type: "text",
    is_image_required: false,
  };

  const [subtaskData, setSubtaskData] = useState(default_subtask_data);
  const [subtaskEdit, setSubtaskEdit] = useState(false);
  const [editingSubtaskIndex, setEditingSubtaskIndex] = useState(-1);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveSubtask = (newSubtaskData) => {
    if (subtaskEdit) {
      // Edit existing subtask
      const updatedSubtasks = [...props.modalData?.subtasks];
      updatedSubtasks[editingSubtaskIndex] = newSubtaskData;
      props.setModalData((prevData) => ({
        ...prevData,
        subtasks: updatedSubtasks,
      }));
    } else {
      // Add new subtask
      props.setModalData((prevData) => ({
        ...prevData,
        subtasks: [...prevData?.subtasks, newSubtaskData],
      }));
    }
    handleClose();
  };

  const handleEditSubtask = (index) => {
    setSubtaskEdit(true);
    setSubtaskData(props.modalData?.subtasks[index]);
    setEditingSubtaskIndex(index);
    handleOpen();
  };

  const handleDeleteSubtask = (index) => {
    props.setModalData((prevData) => ({
      ...prevData,
      subtasks: prevData?.subtasks?.filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    const dateString = date ? formatDate(date) : null;

    props.setModalData((prevData) => ({
      ...prevData,
      one_off_date: dateString,
    }));
  };

  const getDatePickerValue = () => {
    return props.modalData?.one_off_date ? dayjs(props.modalData.one_off_date) : null;
  };

  const handleTaskTypeChange = (event) => {
    const { value } = event.target;
    props.setModalData((prevData) => ({
      ...prevData,
      type: value,
      // Reset related fields when switching types
      one_off_date: value === TASK_TYPES.ONE_OFF ? null : undefined,
      repeating_period: value === TASK_TYPES.REPEATING ? REPEATING_PERIODS.DAILY : undefined,
      repeating_period_count: value === TASK_TYPES.REPEATING ? 1 : undefined,
    }));
  };

  const handleRepeatingPeriodChange = (event) => {
    const { name, value } = event.target;
    props.setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    props.setModalData((prevData) => ({
      ...prevData,
      [name === "start_time" ? "lower_bound_qhnum" : "upper_bound_qhnum"]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    props.setModalData((prevData) => ({
      ...prevData,
      category: value,
    }));
  };

  const handleDeleteFile = (fileIndex) => {
    props.setModalData((prevData) => ({
      ...prevData,
      file_uploads: prevData.file_uploads.filter((_, index) => index !== fileIndex),
    }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsFileLoading(true);
      try {
        const fileObject = await uploadFile(
          props.homeCompany,
          file,
          "plan_task"
        );

        setIsFileLoading(false);

        props.setModalData((prevData) => ({
          ...prevData,
          file_uploads: [...prevData?.file_uploads, fileObject],
        }));
      } catch (error) {

        console.error("Failed to upload file:", error);
        setIsFileLoading(false);
      }
    }
  };

  const handleOpenPdfInNewTab = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedSubtasks = Array.from(props.modalData.subtasks);
    const [reorderedItem] = reorderedSubtasks.splice(result.source.index, 1);
    reorderedSubtasks.splice(result.destination.index, 0, reorderedItem);

    props.setModalData((prevData) => ({
      ...prevData,
      subtasks: reorderedSubtasks,
    }));
  };

  console.log("MODAL DATA", props.modalData)

  return (
    <>
      {showLighbox && (
        <div className="absolute w-screen h-screen z-40">
          <Lightbox
            small={selectedImage}
            medium={selectedImage}
            hideDownload={true}
            hideZoom={true}
            onClose={() => setShowLightbox(false)}
          />
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="pb-10">
          <div className="w-full flex flex-col items-center">
            <div className="w-[65%] flex items-center justify-between">
              <div
                className="text-slate-500 hover:text-slate-600 text-sm flex items-center cursor-pointer"
                onClick={() => props.handleCloseFormBuilder()}
              >
                <div>
                  <ChevronLeftIcon style={{ fontSize: "18px", marginBottom: "1px" }} />
                </div>
                <p>Back to Task List</p>
              </div>
              <div className="flex items-center space-x-2">
                {props.modalEdit ? (
                  <div
                    className="px-4 py-1.5 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded cursor-pointer"
                    onClick={() => {
                      props.delete_plan_task(props.modalData?.uuid);
                      props.handleCloseFormBuilder();
                    }}
                  >
                    Delete
                  </div>
                ) : (
                  <div
                    className="px-4 py-1.5 bg-white hover:bg-slate-50 border border-slate-300 text-violet-700 rounded cursor-pointer"
                    onClick={() => props.handleCloseFormBuilder()}
                  >
                    Cancel
                  </div>
                )}
                <div
                  className="px-4 py-1.5 bg-violet-700 hover:bg-violet-800 text-white rounded cursor-pointer"
                  onClick={() => {
                    props.post_plan_task(
                      props.modalData?.uuid || null,
                      props.modalData?.name,
                      props.modalData?.description,
                      props.modalData?.category,
                      props.modalData?.type,
                      props.modalData?.lower_bound_qhnum,
                      props.modalData?.upper_bound_qhnum,
                      props.modalData?.repeating_period,
                      props.modalData?.repeating_period_count,
                      props.modalData?.one_off_date,
                      props.modalData?.subtasks,
                      props.modalData?.file_uploads?.length > 0
                        ? props.modalData?.file_uploads?.map((v) => v.uuid)
                        : ""
                    );
                    props.handleCloseFormBuilder();
                  }}
                >
                  Save
                </div>
              </div>
            </div>

            <div className="p-6 border border-slate-200 rounded-xl w-[65%] mt-4">
              <div>
                <p className="text-sm text-slate-500">Name</p>
                <div className="mt-1">
                  <TextField
                    placeholder="FOH Opening Subtask List"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="name"
                    value={props.modalData?.name}
                    onChange={handleInputChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div className="mt-5">
                <p className="text-sm text-slate-500">Description</p>
                <div className="mt-1">
                  <TextField
                    placeholder="Please complete this subtask list before the store opens"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="description"
                    value={props.modalData?.description}
                    onChange={handleInputChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div className="mt-5">
                <div className="flex items-center space-x-4">
                  <p className="text-sm text-slate-500">Category</p>
                  {showCreateCategory ? (
                    <p
                      className="text-xs text-violet-700 hover:text-violet-800 cursor-pointer"
                      onClick={() =>
                        setShowCreateCategory((prevState) => !prevState)
                      }
                    >
                      Back to Options
                    </p>
                  ) : (
                    <p
                      className="text-xs text-violet-700 hover:text-violet-800 cursor-pointer"
                      onClick={() => {
                        setShowCreateCategory((prevState) => !prevState);
                        if (!showCreateCategory) {
                          props.setModalData((prevData) => ({
                            ...prevData,
                            category: "",
                          }));
                        }
                      }}
                    >
                      Create New
                    </p>
                  )}
                </div>
                {showCreateCategory ? (
                  <div className="mt-1">
                    <TextField
                      placeholder="Create a new category"
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="category"
                      value={props.modalData?.category}
                      onChange={handleInputChange}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <div className="mt-1">
                    <Select
                      fullWidth
                      value={props.modalData?.category}
                      name="category"
                      onChange={handleCategoryChange}
                      size="small"
                      placeholder="General"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      }}
                    >
                      {props.distinctCategories?.map((category, index) => (
                        <MenuItem key={index} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>

              <div className="mt-5">
                <div>
                  <p className="text-sm text-slate-500">Resources</p>
                  {props.modalData?.file_uploads?.map((file, index) => {
                    const fileType = file.name.split(".").pop();
                    return (
                      <div
                        key={index}
                        className="py-1 flex items-center space-x-2"
                      >
                        <div
                          className="text-slate-500 hover:text-slate-900 cursor-pointer"
                          onClick={() => handleDeleteFile(index)}
                        >
                          <CloseIcon
                            style={{ fontSize: "18px", marginBottom: "2px" }}
                          />
                        </div>
                        {fileType === "pdf" ? (
                          <div
                            onClick={() => {
                              handleOpenPdfInNewTab(file.presigned_url);
                            }}
                          >
                            <p className="text-sm text-violet-700 hover:text-violet-800 cursor-pointer">
                              {file.name}
                            </p>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setSelectedImage(file.presigned_url);
                              setShowLightbox(true);
                            }}
                          >
                            <p className="text-sm text-violet-700 hover:text-violet-800 cursor-pointer">
                              {file.name}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div className="mt-2">
                    <label
                      htmlFor={`file-upload-plan`}
                      className="text-slate-500 hover:text-slate-900 flex items-center space-x-1 cursor-pointer"
                    >
                      <div>
                        {!isFileLoading ? (
                          <AttachFileIcon
                            style={{ fontSize: "20px", marginBottom: "2px" }}
                          />
                        ) : (
                          <CircularProgress
                            size="1rem"
                            sx={{
                              color: "#6d28d9",
                              marginTop: "4px",
                              marginRight: "4px",
                            }}
                          />
                        )}
                      </div>
                      <p className="text-sm">Attach resource</p>
                    </label>
                    <input
                      id={`file-upload-plan`}
                      name={`file-upload-plan`}
                      type="file"
                      className="hidden"
                      onChange={(e) => handleFileChange(e)}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-7">
                <div className="flex items-center space-x-4">
                  <p className="text-sm text-slate-500">Subtasks</p>
                  <div
                    className="w-6 h-6 rounded-full bg-violet-700 hover:bg-violet-800 text-white flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setSubtaskEdit(false);
                      setSubtaskData(default_subtask_data);
                      handleOpen();
                    }}
                  >
                    <AddIcon style={{ fontSize: "18px" }} />
                  </div>
                </div>
                {props.modalData?.subtasks?.length > 0 ? (
                  <div className="mt-3">
                    <Droppable droppableId="subtasks">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="w-full border border-[#bdbdbd] rounded-xl overflow-hidden"
                        >
                          {props.modalData?.subtasks?.map((subtask, index) => (
                            <Draggable
                              key={index}
                              draggableId={`subtask-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className={`py-4 px-4 flex items-center justify-between bg-white ${
                                    index !==
                                      props.modalData?.subtasks?.length - 1 &&
                                    !snapshot.isDragging
                                      ? "border-b border-[#bdbdbd]"
                                      : ""
                                  } ${
                                    snapshot.isDragging
                                      ? "bg-slate-100 rounded"
                                      : ""
                                  }`}
                                >
                                  <div className="flex items-center gap-x-4 w-[45%]">
                                    <div
                                      className="text-slate-500"
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorIcon />
                                    </div>
                                    <div>
                                      <p className="text-slate-900 text-sm">
                                        {subtask?.name}
                                      </p>
                                      <p className="text-slate-500 text-xs">
                                        {subtask?.description}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="w-32">
                                    <div
                                      className={`w-fit rounded-md px-2.5 py-1 text-sm ${
                                        subtask?.type === "text"
                                          ? "bg-orange-50 border border-orange-200 text-orange-600"
                                          : "bg-green-50 border border-green-200 text-green-600"
                                      }`}
                                    >
                                      <p>
                                        {subtask?.type === "text"
                                          ? "Text"
                                          : "Checkbox"}
                                      </p>
                                    </div>
                                  </div>
                                  {subtask?.is_image_required ? (
                                    <div className="text-slate-700 text-xs flex items-center gap-x-1 w-40">
                                      <CameraAltIcon
                                        style={{ fontSize: "20px" }}
                                      />
                                      <p>Image required</p>
                                    </div>
                                  ) : (
                                    <div className="w-40"></div>
                                  )}
                                  <div className="flex items-center gap-x-2 text-slate-500">
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => handleEditSubtask(index)}
                                    >
                                      <EditOutlinedIcon />
                                    </div>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => handleDeleteSubtask(index)}
                                    >
                                      <DeleteOutlinedIcon />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ) : (
                  <div className="mt-4 w-full flex flex-col items-center justify-center">
                    <div className="w-8 h-8 rounded-lg bg-slate-100 flex items-center justify-center text-slate-900">
                      <InfoIcon style={{ fontSize: "18px" }} />
                    </div>
                    <p className="mt-2 text-xs w-72 text-center">
                      No subtasks created yet
                    </p>
                  </div>
                )}
              </div>

              <div className="mt-8">
                <p className="text-sm text-slate-500">Task Type</p>
                <div className="mt-1">
                  {props.modalEdit ?
                    <div className="rounded-lg bg-slate-100 border border-[#bdbdbd] text-slate-400 w-full h-[45px] flex items-center px-4">
                      {props.modalData?.type === "repeating" ? "Repeating Task" : "One-Off Task"}
                    </div> :
                    <Select
                      fullWidth
                      value={props.modalData?.type || TASK_TYPES.REPEATING}
                      onChange={handleTaskTypeChange}
                      size="small"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      }}
                    >
                      <MenuItem value={TASK_TYPES.REPEATING}>Repeating Task</MenuItem>
                      <MenuItem value={TASK_TYPES.ONE_OFF}>One-Off Task</MenuItem>
                    </Select>
                  }
                </div>

                {props.modalData?.type === TASK_TYPES.ONE_OFF ? (
                  <div className="mt-3">
                    <p className="text-sm text-slate-500">Select Date</p>
                    <div className="mt-1">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          value={getDatePickerValue()}
                          onChange={handleDateChange}
                          slotProps={{
                            day: {
                              sx: {
                                "&.MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#6d28d9",
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "45px",
                              backgroundColor: "white",
                              borderRadius: "8px",
                              fontSize: "14px",
                              "& fieldset": {
                                borderRadius: "8px",
                                // height: "45px",
                                paddingX: "25px",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 space-y-3">
                    <div>
                      <p className="text-sm text-slate-500">Frequency</p>
                      {/* <div className="flex items-center space-x-3 mt-1">
                        <TextField
                          type="number"
                          size="small"
                          value={props.modalData?.repeating_period_count}
                          onChange={(e) => handleRepeatingPeriodChange({
                            target: {
                              name: 'repeating_period_count',
                              value: parseInt(e.target.value)
                            }
                          })}
                          InputProps={{
                            inputProps: { min: 1 },
                            sx: {
                              backgroundColor: "white",
                              borderRadius: "8px",
                              padding: "5px",
                              width: "100px",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                        />
                        <p className="text-sm text-slate-500">times</p>
                        <Select
                          value={props.modalData?.repeating_period || REPEATING_PERIODS.DAILY}
                          name="repeating_period"
                          onChange={handleRepeatingPeriodChange}
                          size="small"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "5px",
                            minWidth: "150px",
                            height: "45px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                          }}
                        >
                          <MenuItem value={REPEATING_PERIODS.DAILY}>per day</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.WEEKLY}>per week</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.MONTHLY}>per month</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.QUARTERLY}>per quarter</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.YEARLY}>per year</MenuItem>
                        </Select>
                      </div> */}
                      <div className="mt-1">
                        <Select
                          value={props.modalData?.repeating_period || REPEATING_PERIODS.DAILY}
                          name="repeating_period"
                          onChange={handleRepeatingPeriodChange}
                          size="small"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "5px",
                            minWidth: "150px",
                            height: "45px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                          }}
                        >
                          <MenuItem value={REPEATING_PERIODS.DAILY}>Daily</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.WEEKLY}>Weekly</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.MONTHLY}>Monthly</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.QUARTERLY}>Quarterly</MenuItem>
                          <MenuItem value={REPEATING_PERIODS.YEARLY}>Yearly</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-8">
                <div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-slate-900">Time selection</p>
                      <p className="text-xs text-slate-500 mt-1">
                        Do you want to restrict this task to a certain time of day?
                      </p>
                    </div>
                    <div>
                      <PurpleSwitch
                        checked={timeSelectionChecked}
                        onChange={(event) => {
                          setTimeSelectionChecked(prevState => !prevState)
                        }}
                      />
                    </div>
                  </div>
                  {timeSelectionChecked && (
                    <div>
                      <div className="mt-3 flex items-center space-x-3">
                        <div>
                          <p className="text-sm text-slate-500">Start time</p>
                          <div className="mt-1">
                            <Select
                              fullWidth
                              value={props.modalData?.lower_bound_qhnum}
                              name="start_time"
                              onChange={handleTimeChange}
                              size="small"
                              sx={{
                                borderRadius: "8px",
                                paddingY: "1px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6d28d9",
                                },
                                height: "45px",
                                backgroundColor: "white",
                                minWidth: "150px",
                              }}
                            >
                              {generateTimeOptionsDF(0)?.map((v) => (
                                <MenuItem key={v.value} value={v.value}>
                                  {v.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="text-slate-500 mt-5">
                          <ArrowForwardIcon />
                        </div>
                        <div>
                          <p className="text-sm text-slate-500">End time</p>
                          <div className="mt-1">
                            <Select
                              fullWidth
                              value={props.modalData?.upper_bound_qhnum}
                              name="end_time"
                              onChange={handleTimeChange}
                              size="small"
                              sx={{
                                borderRadius: "8px",
                                paddingY: "1px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6d28d9",
                                },
                                height: "45px",
                                backgroundColor: "white",
                                minWidth: "150px",
                              }}
                            >
                              {generateTimeOptionsDF(0)?.map((v) => (
                                <MenuItem key={v.value} value={v.value}>
                                  {v.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubtaskDrawer
          open={open}
          onClose={handleClose}
          subtaskData={subtaskData}
          setSubtaskData={setSubtaskData}
          subtaskEdit={subtaskEdit}
          default_modal_data={default_subtask_data}
          onSave={handleSaveSubtask}
        />
      </DragDropContext>
    </>
  );
}

export default DailyPlanFormBuilder;
