import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ButtonFilled from "../requests/shared/helpers/ButtonFilled";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

function StationSettingsDrawer(props) {
  const [state, setState] = React.useState(props.modalData);
  const [selectedRoles, setSelectedRoles] = React.useState(getRoleIds());

  function getRoleIds() {
    return props.modalData?.roles.map((v, i) => {
      const match = props.roles.find((r) => r.role_name === v.role_name);
      return match ? match.id : null;
    });
  }

  useEffect(() => {
    setState(props.modalData);
    setSelectedRoles(getRoleIds());
  }, [props.modalData]);

  useEffect(() => {
    if (!props.isSavingData) {
      props.onClose();
    }
  }, [props.isSavingData]);

  function handleChange(evt) {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  const handleRoleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(value);
  };

  function handleSubmit() {
    // console.log("SUBMITTING: ", state);
    let edit_type = "edit";
    if (!props.modalEdit) {
      edit_type = "create";
    }
    let data = state;
    data["roles"] = selectedRoles;
    props.edit_station(data, edit_type);
  }

  // console.log(state);
  // console.log(selectedRoles);
  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader
            title={`${props.modalEdit ? "Edit" : "Add"} station`}
            onClose={props.onClose}
          />
          <div className="w-full px-6">
            <div className="mt-7">
              <p className="text-sm text-slate-500">Location</p>
              <div className="mt-1">
                <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                  <p>{props.store_name}</p>
                </div>
              </div>
            </div>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Name</p>
              <div className="mt-1">
                <TextField
                  placeholder="Server 1"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Roles</p>
              <div className="mt-1">
                <FormControl fullWidth size="small">
                  <Select
                    labelId="station-role"
                    id="station-role"
                    value={selectedRoles}
                    name="selectedRoles"
                    onChange={handleRoleSelect}
                    multiple
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    }}
                    renderValue={(selected) => {
                      const selectedRoleNames = props.roles
                        .filter((role) => selected.includes(role.id))
                        .map((role) => role.role_name);

                      if (selectedRoleNames.length <= 2) {
                        return selectedRoleNames.join(", ");
                      } else {
                        return `${selectedRoleNames[0]}, ${selectedRoleNames[1]}, ...`;
                      }
                    }}
                  >
                    {Array.isArray(props.roles) &&
                      props.roles.map((value, i) => (
                        <MenuItem key={value.id} value={value.id}>
                          <Checkbox
                            checked={selectedRoles.indexOf(value.id) > -1}
                            sx={{
                              color: "#6d28d9",
                              "&.Mui-checked": {
                                color: "#6d28d9",
                              },
                            }}
                          />
                          <ListItemText primary={value.role_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <DrawerFooterContainerLarge>
            <div className="w-full">
              <LoadingButton
                fullWidth
                loading={props.isSavingData}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="contained"
                onClick={() => handleSubmit()}
                sx={{
                  textTransform: "none",
                  borderRadius: "50px",
                  paddingY: "14px",
                  backgroundColor: "#6d28d9",
                  "&:hover": {
                    backgroundColor: "#5b21b6",
                  },
                  fontSize: "16px",
                }}
              >
                Save
              </LoadingButton>
            </div>
            <div className="w-full mt-3">
              <ButtonOutlined text="Cancel" onClick={props.onClose} />
            </div>
          </DrawerFooterContainerLarge>
          <div className="h-[200px]"></div>
        </div>
      </div>
    </Drawer>
  );
}

export default StationSettingsDrawer;
