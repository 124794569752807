import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function LoadFromScheduleButton(props) {

  const [open, setOpen] = React.useState(false);
  const [buttonPressed, setButtonPressed] = React.useState(false);
  const [secondLoadingMessage, setSecondLoadingMessage] = React.useState(false);
  const [thirdLoadingMessage, setThirdLoadingMessage] = React.useState(false);
  const [fourthLoadingMessage, setFourthLoadingMessage] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleStart = () => {
    props.loadFrom7Shift(weeksBack);
    setButtonPressed(true);
    setTimeout(() => {
      setSecondLoadingMessage(true);
    }, 3000);
    setTimeout(() => {
      setThirdLoadingMessage(true);
    }, 6000);
  };

  const completeOnFinish = (loading_finished) => {
    if (loading_finished) {
      setSecondLoadingMessage(true);
      setThirdLoadingMessage(true);
      setFourthLoadingMessage(true);
      setButtonPressed(false);
      handleClose()
    }
  };

  const handleClose = () => {
    setOpen(false);
    setButtonPressed(false);
    setSecondLoadingMessage(false);
    setThirdLoadingMessage(false);
    setFourthLoadingMessage(false);
    props.setLoad7ShiftsScheduleFinished(false);
    setWeeksBack(0);
    set_load_week_start_date(props.week_start_date);
  };

  // const [state, setState] = React.useState({ weeks_back: 0 });

  // function handleChange(evt) {
  //   const value = evt.target.value;
  //   setState({
  //     ...state,
  //     [evt.target.name]: value,
  //   });
  // }

  const [weeksBack, setWeeksBack] = React.useState(0);

  function incrementWeek(start_date) {
    const new_start_date = addDays(start_date, 1 * 7);
    set_load_week_start_date(new_start_date);
    setWeeksBack((prevState) => prevState - 1);
  }

  function decrementWeek(start_date) {
    const new_start_date = addDays(start_date, -1 * 7);
    set_load_week_start_date(new_start_date);
    setWeeksBack((prevState) => prevState + 1);
  }

  useEffect(() => {
    completeOnFinish(props.load7ShiftsScheduleFinished);
  }, [props.load7ShiftsScheduleFinished]);

  const [load_week_start_date, set_load_week_start_date] = useState(
    props.week_start_date
  );

  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  const start_date = load_week_start_date;
  const end_date = addDays(load_week_start_date, 6);

  const date_options = { month: "short", day: "numeric" };

  useEffect(() => {
    set_load_week_start_date(props.week_start_date);
  }, [props.week_start_date]);

  const tooltipTitle = props.has7ShiftsIntegration
    ? "Load from 7Shifts"
    : "Copy from previous week";
  const modalTitle = props.has7ShiftsIntegration
    ? "Load schedule from 7Shifts"
    : "Copy from previous week";

  return (
    <div>
      {props.week_already_started && !props.has7ShiftsIntegration ? (
        <Tooltip title="You cannot copy a schedule from a previous week once the week has already started. Go to a future week to use this feature.">
          <div className="text-gray-300 w-10 h-10 flex items-center justify-center">
            <EventRepeatIcon />
          </div>
        </Tooltip>
      ) : (
        <div>
          <Tooltip title={tooltipTitle}>
            <IconButton
              onClick={handleOpen}
              disabled={props.week_already_started && !props.has7ShiftsIntegration}
            >
              <EventRepeatIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {buttonPressed ? (
            <div className="min-h-[280px] pt-2">
              <div className="pb-6 mb-6 border-b border-gray-200">
                <div className="flex items-center mb-2">
                  <EventRepeatIcon className="text-violet-600 mr-3" />
                  <h2 className="text-xl font-semibold text-gray-900">
                    Copying From {start_date.toLocaleDateString("en-us", date_options) +
                      " - " +
                      end_date.toLocaleDateString("en-us", date_options)}
                  </h2>
                </div>
                <p className="text-gray-600 text-sm">
                  This can take a couple minutes. Thank you for being patient! Please don't click outside of this modal.
                </p>
              </div>

              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  {secondLoadingMessage ? (
                    <CheckCircleIcon className="text-emerald-600 h-6 w-6" />
                  ) : (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-violet-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  <p className={`text-sm ${secondLoadingMessage ? 'text-emerald-700 font-medium' : 'text-gray-700'}`}>
                    {secondLoadingMessage ? 'Copied Schedule Successfully' : 'Copying Schedule...'}
                  </p>
                </div>

                {secondLoadingMessage && !thirdLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-violet-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                    <p className="text-sm text-gray-700">Evaluating Schedule...</p>
                  </div>
                )}

                {thirdLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <CheckCircleIcon className="text-emerald-600 h-6 w-6" />
                    <p className="text-sm text-emerald-700 font-medium">Evaluated Schedule Successfully</p>
                  </div>
                )}

                {thirdLoadingMessage && !fourthLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-violet-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                    <p className="text-sm text-gray-700">Finishing Final Touches...</p>
                  </div>
                )}

                {fourthLoadingMessage && (
                  <div className="flex items-center space-x-3">
                    <CheckCircleIcon className="text-emerald-600 h-6 w-6" />
                    <p className="text-sm text-emerald-700 font-medium">Finished Successfully</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="absolute right-2 top-2">
                <IconButton
                  className="text-gray-400 hover:text-gray-600"
                  onClick={handleClose}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>

              <div>
                <div className="mb-6">
                  <div className="flex items-center space-x-3 mb-2">
                    <EventRepeatIcon className="text-violet-600" />
                    <h3 className="text-xl font-semibold text-gray-900">
                      {modalTitle}
                    </h3>
                  </div>
                  <p className="text-gray-600 text-sm">
                    Select a schedule week to load in.
                  </p>
                </div>

                <div className="flex items-center mb-6 text-gray-600">
                  <IconButton
                    disabled={props.week_already_started}
                    onClick={() => decrementWeek(start_date)}
                    className="w-10 h-10 bg-gray-50 rounded-full"
                  >
                    <ChevronLeftIcon />
                  </IconButton>

                  <div className="flex-1 px-4 flex justify-center items-center space-x-3">
                    <CalendarMonthIcon className="text-gray-900" />
                    <span className="text-gray-900">
                      {start_date.toLocaleDateString("en-us", date_options) +
                        " - " +
                        end_date.toLocaleDateString("en-us", date_options)}
                    </span>
                  </div>

                  <IconButton
                    disabled={props.week_already_started}
                    onClick={() => incrementWeek(start_date)}
                    className="w-10 h-10 bg-gray-50 rounded-full"
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </div>

                <div className="flex items-center space-x-3">
                  <button
                    onClick={handleClose}
                    className="px-6 py-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50 transition-colors duration-200 text-sm font-medium"
                  >
                    Cancel
                  </button>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleStart}
                    endIcon={<EventRepeatIcon />}
                    sx={{
                      textTransform: "none",
                      borderRadius: "8px",
                      padding: "10px 16px",
                      backgroundColor: "#6d28d9",
                      "&:hover": {
                        backgroundColor: "#5b21b6",
                      },
                    }}
                  >
                    Load Schedule
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default LoadFromScheduleButton;
