import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { Drawer } from "@mui/material";
import { formatDateReadable } from "../../utilities/helpers";
import DrawerHeader from "../helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../helpers/DrawerFooterContainerSmall";
import ButtonFilledCancel from "../helpers/ButtonFilledCancel";
import StatusWithText from "../helpers/StatusWithText";
import PillAvailability from "../helpers/PillAvailability";

export default function DefaultAvailabilityCardUpdated(props) {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <div
        className="flex flex-col"
        onClick={props.status !== "current" && toggleDrawer(true)}
      >
        <div
          className={`w-full h-16 rounded-xl flex flex-col justify-center px-4 shadow relative ${
            props.status === "current"
              ? "bg-slate-100"
              : props.status === "pending"
              ? "bg-slate-100 hover:bg-slate-200 cursor-pointer"
              : props.status === "approved"
              ? "bg-slate-100 hover:bg-slate-200 cursor-pointer"
              : props.status === "rejected"
              ? "bg-slate-100 hover:bg-slate-200 cursor-pointer"
              : ""
          }`}
        >
          <h3 className="text-[12px] text-slate-900">
            {props.availability ? props.availability : "No Availability Set"}
          </h3>
          {props.availability2 && (
            <h3 className="text-[12px] text-slate-900">
              {props.availability2}
            </h3>
          )}
          {
            <h4 className="text-xxs text-slate-500">
              {props.preferences
                ? "Preferred: " + props.preferences
                : "No Preferences Set"}
            </h4>
          }
          <div className="absolute top-2 right-2">
            {/* {props.status === "current" ? (
              <div></div>
            ) : props.status === "pending" ? (
              <div className="text-yellow-600 text-xxs">pending</div>
            ) : props.status === "approved" ? (
              <Tooltip title="Approved">
                <div className="w-5 h-5 rounded-full flex items-center justify-center bg-emerald-600 text-white">
                  <CheckIcon style={{ fontSize: "15px" }} />
                </div>
              </Tooltip>
            ) : props.status === "rejected" ? (
              <Tooltip title="Rejected">
                <div className="w-5 h-5 rounded-full flex items-center justify-center bg-rose-600 text-white">
                  <ClearIcon style={{ fontSize: "15px" }} />
                </div>
              </Tooltip>
            ) : (
              <div></div>
            )} */}
            <PillAvailability status={props.status} />
          </div>
        </div>
      </div>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-screen bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={`${props.day} Availability Request`}
              onClose={toggleDrawer(false)}
            />
            <div className="pt-3">
              <div className="px-6">
                <h3 className="text-slate-900 text-base">Availability</h3>
                <p className="text-slate-500 mt-2">
                  This is your requested availability for {props.day}
                </p>
              </div>
              <div className="px-6">
                <div className="py-5 border-b border-slate-200">
                  <div className="bg-white border border-slate-200 rounded-2xl px-6 h-14 flex flex-row items-center space-x-3">
                    <div className="text-slate-500">
                      <ScheduleIcon />
                    </div>
                    <p className="text-slate-900">{props.availability}</p>
                  </div>
                  {props.availability2 && (
                    <div className="bg-white border border-slate-200 rounded-2xl px-6 h-14 flex flex-row items-center space-x-3 mt-2">
                      <div className="text-slate-500">
                        <ScheduleIcon />
                      </div>
                      <p className="text-slate-900">{props.availability2}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="px-6 mt-5">
                <h3 className="text-slate-900 text-base">Preferences</h3>
                <p className="text-slate-500 mt-2">
                  These are your requested preferences for {props.day}
                </p>
              </div>
              <div className="px-6">
                <div className="py-5 border-b border-slate-200">
                  <div className="bg-white border border-slate-200 rounded-2xl px-6 h-14 flex flex-row items-center space-x-3">
                    <div className="text-slate-500">
                      <StarBorderIcon />
                    </div>
                    <p className="text-slate-900">
                      {props.preferences
                        ? props.preferences
                        : "No Preference Set"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-6 mt-5">
                <h3 className="text-slate-900 text-base">Effective Date</h3>
                <p className="text-slate-500 mt-2">
                  {props.status === "approved"
                    ? "This availability will go into effect on"
                    : "If approved, this availability will go into effect on"}
                </p>
              </div>
              <div className="px-6">
                <div className="py-5 border-b border-slate-200">
                  <div className="bg-white border border-slate-200 rounded-2xl px-6 h-14 flex flex-row items-center space-x-3">
                    <div className="text-slate-500">
                      <CalendarMonthIcon />
                    </div>
                    <p className="text-slate-900">
                      {props.inEffect
                        ? formatDateReadable(props.inEffect)
                        : "None"}
                    </p>
                  </div>
                </div>
              </div>
              {props.note && (
                <div>
                  <div className="px-6 mt-5">
                    <h3 className="text-slate-900 text-base">Note</h3>
                    <p className="text-slate-500 mt-2">
                      The note that you sent to your manager
                    </p>
                  </div>
                  <div className="px-6">
                    <div className="py-5 border-b border-slate-200">
                      <div className="bg-white border border-slate-200 rounded-2xl px-6 h-14 flex flex-row items-center space-x-3">
                        <div className="text-slate-500">
                          <CreateOutlinedIcon />
                        </div>
                        <p className="text-slate-900">{props.note}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="px-6">
                <div className="py-5 border-b border-slate-200">
                  <h3 className="text-slate-900 text-base">Status</h3>
                  <p className="text-slate-500 mt-2">
                    {props.status === "pending"
                      ? "Still waiting on approval from your manager"
                      : "Your manager has approved this availability"}
                  </p>
                  <div className="mt-5">
                    <StatusWithText status={props.status} />
                  </div>
                </div>
              </div>
              <div className="h-[120px]"></div>
            </div>
            <DrawerFooterContainerSmall>
              <ButtonFilledCancel
                text="Cancel"
                onClick={() => {
                  props.rescind_availability_request(props.id);
                  setDrawerOpen(false);
                }}
              />
            </DrawerFooterContainerSmall>
          </div>
        </div>
      </Drawer>
    </>
  );
}
