import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import ReactToPrint from "react-to-print";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { formatName, getRoleName } from "../../../../helpers/helpers";
import { formatDateReadableWithDayOfWeekNoYear } from "../../requests/utilities/helpers";
import { get_t_str } from "../../../../helpers/helpers";
import { formatDate } from "../../requests/utilities/helpers";

const date_options = { weekday: "short", month: "short", day: "numeric" };

export default function PrintMode(props) {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showUnavailability, setShowUnavailability] = useState(true);
  const [showColors, setShowColors] = useState(true);

  const handleUnavailabilityToggle = (event) => {
    setShowUnavailability(event.target.checked);
  };

  const handleColorsToggle = (event) => {
    setShowColors(event.target.checked);
  };

  let componentRef = React.useRef();

  const dow_nums = [0, 1, 2, 3, 4, 5, 6];
  let day_names = [];
  dow_nums.map((dow) =>
    day_names.push(
      props
        .addDays(props.week_start_date, dow)
        .toLocaleDateString("en-us", date_options)
    )
  );

  const open_shifts = props.actual_schedule?.filter(
    (s) => s.status === "AVAILABLE"
  );

  let daily_open_shifts = [];
  dow_nums.map((dow) =>
    daily_open_shifts?.push(
      props.filter_df_to_day(
        open_shifts,
        "date",
        formatDate(props.addDays(props.week_start_date, dow))
      )
    )
  );

  const groupByRole = () => {
    const groupedData = {};
    props.store_team_member_weekly_info_view
      // .filter((p) => p.hours > 0)
      .forEach((employee) => {
        const { role } = employee;
        if (!groupedData[role]) {
          groupedData[role] = [];
        }
        groupedData[role].push(employee);
      });
    return groupedData;
  };

  const groupedData = groupByRole();

  const roles = Object.keys(groupedData);

  const handleRoleChange = (event) => {
    const { value } = event.target;
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const filteredData = selectedRoles.length
    ? Object.entries(groupedData).filter(([role]) =>
        selectedRoles.includes(role)
      )
    : Object.entries(groupedData);

  const getAvailabilityText = (avail_list, timeoff_list) => {
    if (timeoff_list.length > 0) {
      return "Time Off";
    } else if (avail_list.length === 0) {
      return "";
    } else if (
      (avail_list[0]["available_from"] === 0) &
      (avail_list[0]["available_to"] === 0)
    ) {
      return "Unavailable";
    } else {
      return "";
    }
  };

  const calculateTotalHours = () => {
    const scheduledHours = filteredData?.reduce((total, [, employees]) => {
      return (
        total +
        employees.reduce(
          (subTotal, employee) =>
            subTotal + employee.hours - employee.other_store_hours,
          0
        )
      );
    }, 0);

    const openShiftHours = props.actual_schedule
      ?.filter((s) => s.status === "AVAILABLE")
      ?.reduce((total, shift) => total + shift.hours, 0);

    return scheduledHours + openShiftHours;
  };

  const calculateTotalHoursForDay = (dow) => {
    const scheduledHours = filteredData?.reduce((total, [, employees]) => {
      return (
        total +
        employees.reduce((subTotal, employee) => {
          if (employee[dow].length > 0) {
            let hours = 0;
            employee[dow].forEach((s) => {
              hours += s.hours;
            });
            return subTotal + hours;
          }
          return subTotal;
        }, 0)
      );
    }, 0);

    const openShiftHours = daily_open_shifts?.[dow]?.reduce(
      (total, shift) => total + shift.hours,
      0
    );

    return scheduledHours + openShiftHours;
  };

  const totalHoursPerDay = dow_nums.map((dow) =>
    calculateTotalHoursForDay(dow)
  );

  const totalOpenShiftHours = props.actual_schedule
    ?.filter((s) => s.status === "AVAILABLE")
    ?.reduce((total, shift) => total + shift.hours, 0);

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div className="w-full px-5">
          <div className="w-full flex items-center justify-between pt-4">
            <div>
              <h3 className="text-xs font-bold">
                {day_names[0]} - {day_names[6]}
              </h3>
            </div>
            {showColors && (
              <div className="flex items-center space-x-8">
                <div className="flex items-center space-x-2">
                  <div className="w-3 h-3 rounded-full bg-yellow-200"></div>
                  <p className="text-xxs">Morning</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-3 h-3 rounded-full bg-red-200"></div>
                  <p className="text-xxs">Afternoon</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-3 h-3 rounded-full bg-blue-200"></div>
                  <p className="text-xxs">Evening</p>
                </div>
              </div>
            )}
          </div>
          <div>
            <table className="w-full text-sm font-bold text-left text-gray-900 mt-2">
              <thead className="text-sm text-gray-900 uppercase bg-slate-100 border-x border-y border-slate-600">
                <tr>
                  <th scope="col" className="py-0.5 px-2 w-[170px]">
                    <p>Employee</p>
                  </th>
                  <th
                    scope="col"
                    className="py-0.5 px-2 w-[110px] border-l border-slate-600"
                  >
                    <p>Role</p>
                  </th>
                  {props.printHoursShownEnabled &&
                    <th
                      scope="col"
                      className="py-0.5 px-2 w-[80px] border-l border-slate-600"
                    >
                      <p>Hours</p>
                    </th>
                  }
                  {day_names.map((d) => (
                    <th
                      scope="col"
                      className="py-0.5 px-2 w-[140px] border-l border-slate-600"
                      key={d}
                    >
                      <p>{d}</p>
                    </th>
                  ))}
                </tr>
              </thead>
              {filteredData.map(([role, employees]) => (
                <tbody>
                  {employees
                    // .filter((p) => p.hours > 0)
                    .map((s) => (
                      <tr
                        key={s.employee_id}
                        className="bg-white border-b text-sm border-x border-slate-600"
                      >
                        <th
                          scope="row"
                          className="py-0.5 px-2 font-bold text-gray-900"
                        >
                          <p>{formatName(s.first_name + " " + s.last_name)}</p>
                        </th>
                        <th
                          scope="row"
                          className="py-0.5 px-2 font-bold text-gray-900 border-l border-slate-600"
                        >
                          <p>{s.role}</p>
                        </th>
                        {props.printHoursShownEnabled &&
                          <th
                            scope="row"
                            className="py-0.5 px-2 font-bold text-gray-900 border-l border-slate-600"
                          >
                            <p>{(s.hours - s.other_store_hours).toFixed(2)}</p>
                          </th>
                        }
                        {dow_nums.map((dow) => {
                          if (s[dow].length > 0) {
                            const startTime = s[dow][0].shift_start;
                            const endTime = s[dow][0].shift_end;

                            let backgroundColor = "white";
                            if (showColors) {
                              if (startTime <= 10) {
                                backgroundColor = "#fef08a";
                              } else if (startTime < 16 && endTime < 21) {
                                backgroundColor = "#fecaca";
                              } else {
                                backgroundColor = "#bfdbfe";
                              }
                            }

                            return (
                              <td
                                key={dow}
                                className="py-0.5 pl-2 border-l border-slate-600"
                                style={{ backgroundColor }}
                              >
                                <div>
                                  <p>
                                    {props.get_t_str(s[dow][0].shift_start) +
                                      "-" +
                                      props.get_t_str(s[dow][0].shift_end)}
                                  </p>
                                  <p>
                                    {s[dow][0].station
                                      ? s[dow][0].station.name
                                      : ""}
                                  </p>
                                </div>
                                {s[dow]?.length > 1 && (
                                  <div>
                                    <p>
                                      {props.get_t_str(
                                        s[dow]?.[1]?.shift_start
                                      ) +
                                        "-" +
                                        props.get_t_str(s[dow]?.[1]?.shift_end)}
                                    </p>
                                    <p>
                                      {s[dow]?.[1]?.station
                                        ? s[dow]?.[1]?.station.name
                                        : ""}
                                    </p>
                                  </div>
                                )}
                                {s[dow]?.length > 2 && (
                                  <div>
                                    <p>
                                      {props.get_t_str(
                                        s[dow]?.[2]?.shift_start
                                      ) +
                                        "-" +
                                        props.get_t_str(s[dow]?.[2]?.shift_end)}
                                    </p>
                                    <p>
                                      {s[dow]?.[2]?.station
                                        ? s[dow]?.[2]?.station.name
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={dow}
                                className="py-0.5 pl-2 border-l border-slate-600"
                              >
                                {showUnavailability && (
                                  <p className="text-slate-500">
                                    {getAvailabilityText(
                                      s[dow + "_availability"],
                                      s[dow + "_timeoff"]
                                    )}
                                  </p>
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                </tbody>
              ))}
              <tfoot>
                {totalOpenShiftHours > 0 && (
                  <tr className="bg-white border-b text-xxs border-x border-slate-600">
                    <th
                      colSpan="2"
                      className="py-1.5 px-2 font-bold text-xxs text-left"
                    >
                      Open Shifts
                    </th>
                    <th
                      scope="col"
                      className="py-1.5 px-2 w-[80px] font-bold text-gray-900 border-l border-slate-600"
                    >
                      <p>{totalOpenShiftHours.toFixed(2)}</p>
                    </th>
                    {daily_open_shifts?.map((day, i) => {
                      return (
                        <th
                          scope="row"
                          className="py-1.5 pl-2 font-bold text-gray-900 border-l border-slate-600 align-top"
                          key={i}
                        >
                          <div>
                            {day.map((shift, i) => (
                              <div>
                                <div className="flex items-center">
                                  {get_t_str(shift.shift_start)} -{" "}
                                  {get_t_str(shift.shift_end)}
                                </div>
                              </div>
                            ))}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                )}
                {props.printHoursShownEnabled &&
                  <tr className="bg-slate-100 border-x border-b border-slate-600 text-gray-900">
                    <th
                      colSpan="2"
                      className="py-1 px-2 font-bold text-xxs text-left"
                    >
                      Totals:
                    </th>
                    <th className="py-0.5 pl-2 font-bold text-xxs border-l border-slate-600">
                      {calculateTotalHours().toFixed(2)}
                    </th>
                    {totalHoursPerDay.map((hours, index) => (
                      <td
                        key={index}
                        className="py-1.5 pl-2 font-bold text-xxs border-l border-slate-600"
                      >
                        {hours.toFixed(2)}
                      </td>
                    ))}
                  </tr>
                }
              </tfoot>
            </table>
            {props.notes?.length > 0 && (
              <div className="pb-5">
                <div className="mt-3.5 flex flex-wrap gap-3">
                  {props.notes?.map((note, index) => (
                    <div
                      key={index}
                      className="px-4 py-3 border border-slate-600 rounded-xl w-[242px]"
                    >
                      <div className="w-full flex items-center justify-between text-slate-900 text-xxxs">
                        <p>
                          {formatDateReadableWithDayOfWeekNoYear(note.date)}
                        </p>
                        {note.start_time && (
                          <p>
                            {get_t_str(note.start_time)}{" "}
                            {note.end_time && `- ${get_t_str(note.end_time)}`}
                          </p>
                        )}
                      </div>
                      <p className="text-slate-900 text-xs mt-1.5">
                        {note.title}
                      </p>
                      {note.description && (
                        <p className="text-slate-500 text-xxs mt-1">
                          {note.description}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="w-full flex items-center justify-between pr-4 pt-8">
        <div className="flex items-center space-x-10">
          <h3>
            {day_names[0]} - {day_names[6]}
          </h3>
          <div className="w-[400px]">
            {/* <p className="text-sm text-slate-500">Filter by role</p> */}
            <div className="w-full">
              <FormControl sx={{ width: 400 }}>
                <Select
                  labelId="multiple-checkbox-label"
                  fullWidth
                  multiple
                  value={selectedRoles}
                  onChange={handleRoleChange}
                  renderValue={(selected) =>
                    selected.length === 0
                      ? "Filter by role"
                      : selected.join(", ")
                  }
                  displayEmpty
                  sx={{
                    borderRadius: "8px",
                    paddingY: "5px",
                    height: "38px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    <p>Filter by role</p>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      <Checkbox
                        checked={selectedRoles.indexOf(role) > -1}
                        sx={{
                          color: "#6d28d9",
                          "&.Mui-checked": {
                            color: "#6d28d9",
                          },
                          "&.MuiCheckbox-indeterminate": {
                            color: "#6d28d9",
                          },
                        }}
                      />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <div className="flex items-center">
              <Checkbox
                checked={showUnavailability}
                onChange={handleUnavailabilityToggle}
                sx={{
                  color: "#6d28d9",
                  "&.Mui-checked": {
                    color: "#6d28d9",
                  },
                  "&.MuiCheckbox-indeterminate": {
                    color: "#6d28d9",
                  },
                }}
              />
              <p className="text-xs -ml-1">Show Unavailability</p>
            </div>
            <div className="flex items-center">
              <Checkbox
                checked={showColors}
                onChange={handleColorsToggle}
                sx={{
                  color: "#6d28d9",
                  "&.Mui-checked": {
                    color: "#6d28d9",
                  },
                  "&.MuiCheckbox-indeterminate": {
                    color: "#6d28d9",
                  },
                }}
              />
              <p className="text-xs -ml-1">Show Colors</p>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-8">
          {showColors && (
            <div className="flex items-center space-x-8">
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-yellow-200"></div>
                <p className="text-sm">Morning</p>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-200"></div>
                <p className="text-sm">Afternoon</p>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-blue-200"></div>
                <p className="text-sm">Evening</p>
              </div>
            </div>
          )}
          <ReactToPrint
            trigger={() => (
              <div className="text-slate-500">
                <IconButton>
                  <PrintIcon />
                </IconButton>
              </div>
            )}
            content={() => componentRef}
          />
        </div>
      </div>
      <div className="overflow-x-auto relative">
        <div style={{ display: "none" }}>
          <ComponentToPrint ref={(el) => (componentRef = el)} />
        </div>
        <div>
          <table className="w-full text-sm text-left text-gray-900 mt-6 mb-5">
            <thead className="text-xs text-gray-900 uppercase bg-slate-100 border-x border-y border-slate-400">
              <tr>
                <th scope="col" className="py-1.5 px-4 w-[170px]">
                  <p>Employee</p>
                </th>
                <th
                  scope="col"
                  className="py-1.5 px-2.5 w-[110px] border-l border-slate-400"
                >
                  <p>Role</p>
                </th>
                {props.printHoursShownEnabled &&
                  <th
                    scope="col"
                    className="py-1.5 px-2.5 w-[80px] border-l border-slate-400"
                  >
                    <p>Hours</p>
                  </th>
                }
                {day_names.map((d) => (
                  <th
                    scope="col"
                    className="py-1.5 px-2.5 w-[140px] border-l border-slate-400"
                    key={d}
                  >
                    <p>{d}</p>
                  </th>
                ))}
              </tr>
            </thead>
            {filteredData.map(([role, employees]) => (
              <tbody>
                {employees
                  // .filter((p) => p.hours > 0)
                  .map((s, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b text-xs border-x border-slate-400"
                    >
                      <th
                        scope="row"
                        className="py-1.5 px-4 font-medium text-gray-900"
                      >
                        <p>{formatName(s.first_name + " " + s.last_name)}</p>
                      </th>
                      <th
                        scope="row"
                        className="py-1.5 pl-2.5 font-medium text-gray-900 border-l border-slate-400"
                      >
                        <p>{s.role}</p>
                      </th>
                      {props.printHoursShownEnabled &&
                        <th
                          scope="row"
                          className="py-1.5 pl-2.5 font-medium text-gray-900 border-l border-slate-400"
                        >
                          <p>{(s.hours - s.other_store_hours).toFixed(2)}</p>
                        </th>
                      }
                      {dow_nums.map((dow) => {
                        if (s[dow].length > 0) {
                          const startTime = s[dow][0].shift_start;
                          const endTime = s[dow][0].shift_end;

                          let backgroundColor = "white";
                          if (showColors) {
                            if (startTime <= 10) {
                              backgroundColor = "#fef08a";
                            } else if (startTime < 16 && endTime < 21) {
                              backgroundColor = "#fecaca";
                            } else {
                              backgroundColor = "#bfdbfe";
                            }
                          }

                          return (
                            <td
                              key={dow}
                              className="py-1.5 pl-2.5 border-l border-slate-400"
                              style={{ backgroundColor }}
                            >
                              <div>
                                <p>
                                  {props.get_t_str(s[dow][0].shift_start) +
                                    "-" +
                                    props.get_t_str(s[dow][0].shift_end)}
                                </p>
                                <p>
                                  {s[dow][0].station
                                    ? s[dow][0].station.name
                                    : ""}
                                </p>
                              </div>
                              {s[dow]?.length > 1 && (
                                <div>
                                  <p>
                                    {props.get_t_str(s[dow]?.[1]?.shift_start) +
                                      "-" +
                                      props.get_t_str(s[dow]?.[1]?.shift_end)}
                                  </p>
                                  <p>
                                    {s[dow]?.[1]?.station
                                      ? s[dow]?.[1]?.station.name
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {s[dow]?.length > 2 && (
                                <div>
                                  <p>
                                    {props.get_t_str(s[dow]?.[2]?.shift_start) +
                                      "-" +
                                      props.get_t_str(s[dow]?.[2]?.shift_end)}
                                  </p>
                                  <p>
                                    {s[dow]?.[2]?.station
                                      ? s[dow]?.[2]?.station.name
                                      : ""}
                                  </p>
                                </div>
                              )}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={dow}
                              className="py-1.5 pl-2.5 border-l border-slate-400"
                            >
                              {showUnavailability && (
                                <p className="text-slate-500">
                                  {getAvailabilityText(
                                    s[dow + "_availability"],
                                    s[dow + "_timeoff"]
                                  )}
                                </p>
                              )}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))}
              </tbody>
            ))}
            <tfoot>
              {totalOpenShiftHours > 0 && (
                <tr className="bg-white border-b text-xs border-x border-slate-400">
                  <th
                    colSpan="2"
                    className="py-1.5 px-4 font-bold text-xs text-left"
                  >
                    Open Shifts
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 px-2.5 w-[80px] font-medium text-gray-900 border-l border-slate-400"
                  >
                    <p>{totalOpenShiftHours.toFixed(2)}</p>
                  </th>
                  {daily_open_shifts?.map((day, i) => {
                    return (
                      <th
                        scope="row"
                        className="py-1.5 pl-2.5 font-medium text-gray-900 border-l border-slate-400 align-top"
                        key={i}
                      >
                        <div>
                          {day.map((shift, i) => (
                            <div>
                              <div className="flex items-center py-1">
                                {get_t_str(shift.shift_start)} -{" "}
                                {get_t_str(shift.shift_end)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              )}
              {props.printHoursShownEnabled &&
                <tr className="bg-slate-100 border-x border-b border-slate-400 text-gray-900">
                  <th
                    colSpan="2"
                    className="py-1.5 px-4 font-bold text-xs text-left"
                  >
                    Totals:
                  </th>
                  <th className="py-0.5 pl-2.5 font-bold text-xs border-l border-slate-400">
                    {calculateTotalHours().toFixed(2)}
                  </th>
                  {totalHoursPerDay.map((hours, index) => (
                    <td
                      key={index}
                      className="py-1.5 pl-2.5 font-bold text-xs border-l border-slate-400"
                    >
                      {hours.toFixed(2)}
                    </td>
                  ))}
                </tr>
              }
            </tfoot>
          </table>
          {props.notes?.length > 0 && (
            <div className="pb-5">
              <div className="mt-0 flex flex-wrap gap-3">
                {props.notes?.map((note, index) => (
                  <div
                    key={index}
                    className="px-4 py-3 border border-slate-400 rounded-xl w-[300px]"
                  >
                    <div className="w-full flex items-center justify-between text-slate-900 text-xxs">
                      <p>{formatDateReadableWithDayOfWeekNoYear(note.date)}</p>
                      {note.start_time && (
                        <p>
                          {get_t_str(note.start_time)}{" "}
                          {note.end_time && `- ${get_t_str(note.end_time)}`}
                        </p>
                      )}
                    </div>
                    <p className="text-slate-900 text-sm mt-1.5">
                      {note.title}
                    </p>
                    {note.description && (
                      <p className="text-slate-500 text-xs mt-1">
                        {note.description}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
