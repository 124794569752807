import React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import Pill from "../../shared/helpers/Pill";
import { add_pending_requests_info } from "../../utilities/helpers";
import PendingTableEmployeeRowShift from "./PendingTableEmployeeRowShift";
import PendingTableEmployeeRowTrade from "./PendingTableEmployeeRowTrade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function PendingTableEmployee(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const yourPickupRequests = props.shiftPickupResponses
    ? props.shiftPickupResponses
    : [];

  const yourPickupRequestsInfo = yourPickupRequests.map((v, i) =>
    add_pending_requests_info(v)
  );

  // console.log("yourPickupRequestsInfo", props.shiftPickupResponses);

  const renderRequestItems = () => {
    return yourPickupRequestsInfo.map((item, index) => {
      if (
        item.requestType === "tradeReceiver" ||
        item.requestType === "tradeSender"
      ) {
        return (
          <PendingTableEmployeeRowTrade
            key={index}
            {...item}
            rescind_trade_shift={props.rescind_trade_shift}
            respond_to_trade_request={props.respond_to_trade_request}
            archive_shift_change_request={props.archive_shift_change_request}
            myId={props.myId}
            myUserId={props.myUserId}
          />
        );
      } else {
        return (
          <PendingTableEmployeeRowShift
            key={index}
            {...item}
            rescind_drop_shift={props.rescind_drop_shift}
            rescind_pickup_shift={props.rescind_pickup_shift}
            archive_shift_change_request={props.archive_shift_change_request}
          />
        );
      }
    });
  };

  return (
    <div className="mt-12">
      <div className="flex items-center">
        <div className="flex-auto">
          <h1 className="text-3xl text-slate-900 px-6">Requests</h1>
        </div>
      </div>
      <div className="mt-12 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-[25%] px-3 py-3.5 text-left text-sm font-medium text-slate-500 pl-6"
                    >
                      Request Type
                    </th>
                    <th
                      scope="col"
                      className="w-[5%] px-3 py-3.5 text-left text-sm font-medium text-slate-500 pl-6"
                    >
                      Request Info
                    </th>
                    <th
                      scope="col"
                      className="w-[5%] px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                    ></th>
                    <th
                      scope="col"
                      className="w-[16%] px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                    ></th>
                    <th
                      scope="col"
                      className="w-[8%] px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                    ></th>
                    <th
                      scope="col"
                      className="w-[14%] relative py-3.5 pl-3 pr-6 text-sm font-medium text-slate-500"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200 bg-white">
                  {renderRequestItems()}
                </tbody>
              </table>
              <div className="border-b border-slate-200"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
