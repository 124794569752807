import React from "react";
import SettingsHeader from "../department_role_station_settings/SettingsHeader";
import HoursOfOperationTableRow from "./HoursOfOperationTableRow";

function HoursOfOperationDisplay(props) {
  // console.log("DATA", props.hoursOfOperation)

  return (
    <div>
      {/* <SettingsHeader title="Hours of Operations" /> */}
      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-[24%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                    >
                      Locations
                    </th>
                    <th
                      scope="col"
                      className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Standard Hours
                    </th>
                    <th
                      scope="col"
                      className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Special Hours / Overrides
                    </th>
                    <th
                      scope="col"
                      className="w-1/12 relative py-3.5 pl-3 pr-6"
                    >
                      {/* <span className="sr-only">Edit</span> */}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200 bg-white">
                  {props.hoursOfOperation?.map((location, i) => (
                    <HoursOfOperationTableRow
                      key={i}
                      i={i}
                      location={location}
                      update_hours_for_all_stores={
                        props.update_hours_for_all_stores
                      }
                      storeNames={props.storeNames}
                    />
                  ))}
                </tbody>
              </table>
              <div className="border-b border-slate-200"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoursOfOperationDisplay;
