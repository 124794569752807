import React from "react";
import PersonAvatar from "./PersonAvatar";

import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import PersonIcon from "@mui/icons-material/Person";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import Tooltip from "@mui/material/Tooltip";
import PersonCardModal from "./PersonCardModal";

import WarningIcon from "@mui/icons-material/Warning";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getCardColor, getCardColor2 } from "../../../../helpers/helpers";
import CircularProgress from "@mui/material/CircularProgress";

import { formatName } from "../../../../helpers/helpers";

function PersonCard(props) {
  // Shows modal when set to true
  const [open, setOpen] = React.useState(false);

  // Function for opening the modal
  const handleOpen = () => {
    setOpen(true);
    setIsDropdownOpen(false);
    setMainRoleId(get_main_role_id());
  };

  // Function for closing the modal
  const handleClose = () => setOpen(false);

  const [mainRoleId, setMainRoleId] = React.useState(get_main_role_id());

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isLoadingSchedule, setIsLoadingSchedule] = React.useState(false);

  function toggleIsDropdownOpen() {
    setIsDropdownOpen((prevState) => !prevState);
  }

  // Ref to the dropdown container
  const dropdownRef = React.useRef(null);

  const handleDocumentClick = (event) => {
    // Check if the click is outside the dropdown or on the MoreVertIcon
    if (
      isDropdownOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".more-vert-icon-class")
    ) {
      setIsDropdownOpen(false);
    }
  };

  // Attach click event listener to the document when the dropdown is open
  React.useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isDropdownOpen]);

  // Function that toggles locked
  function toggleLocked() {
    // console.log(props.id);
    props.lock_person(props.id);
  }

  // Function that gets main role id
  function get_main_role_id() {
    const main_role = props.p.available_role_info.filter(
      (obj) => obj.main_role_ind === 1
    );
    if (main_role.length > 0) {
      return main_role[0].scheduled_role;
    } else {
      return null;
    }
  }

  const handleMainRoleSelection = (event) => {
    setMainRoleId(event.target.value);
  };

  const avatarRoleId = get_main_role_id();

  const returnAvatar = () => {
    return (
      <PersonAvatar
        name={name}
        bgColor={getCardColor2(mainRoleId, props.role_names, false)}
      />
    );
  };

  // function formatName(inputName) {
  //   // Split the input name into parts
  //   const nameParts = inputName.split(" ");

  //   // Capitalize the first letter of each part and convert the rest to lowercase
  //   const formattedNameParts = nameParts.map(
  //     (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  //   );

  //   // Join the formatted parts back together
  //   const formattedName = formattedNameParts.join(" ");

  //   return formattedName;
  // }

  const name = props.p.first_name + " " + props.p.last_name;

  const formattedName = formatName(name);

  return (
    <div
      className={`w-full h-full ${
        props.highlightedEmployees?.has(props.id) ? "opacity-0" : "opacity-100"
      } duration-1000 ease-in-out`}
    >
      <div className="w-full h-full">
        <div className="flex w-full h-full max-w-sm text-slate-900 relative">
          <div className="flex flex-1 items-center gap-2">
            <div className="cursor-pointer" onClick={handleOpen}>
              {!props.minimizeMode && (
                <PersonAvatar
                  name={formattedName}
                  bgColor={getCardColor2(avatarRoleId, props.role_names, false)}
                  view={props.view}
                />
              )}
            </div>
            <div>
              <p
                className="text-xxs font-bold cursor-pointer"
                onClick={handleOpen}
              >
                {formattedName.substring(0, 18)}
              </p>

              <p
                className={`${
                  props.minimizeMode ? "text-xxxs" : "text-xxs"
                } cursor-pointer`}
                onClick={handleOpen}
              >
                {props.p.hours.toFixed(1)} hrs
              </p>
            </div>
          </div>
          <div className="relative">
            {!props.minimizeMode && (
              <div
                className={`more-vert-icon-class w-4 h-6 flex items-center justify-center hover:bg-slate-50 rounded cursor-pointer text-slate-500 ${
                  props.view !== "daily" ? "mt-1.5 -mr-0.5" : "-mt-0.5 -mr-2"
                }`}
                onClick={toggleIsDropdownOpen}
              >
                <MoreVertIcon style={{ fontSize: "20px" }} />
              </div>
            )}
            {isDropdownOpen && (
              <div
                className="absolute top-5 left-0 mt-2 w-[340px] rounded-lg shadow-xl bg-white border border-slate-200 z-[100] text-slate-500"
                ref={dropdownRef}
              >
                <div
                  className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                  onClick={handleOpen}
                >
                  <div className="text-slate-600">
                    <PersonIcon
                      style={{ fontSize: "18px", marginBottom: "2px" }}
                    />
                  </div>
                  <p className="text-sm">
                    Open {formatName(props.p.first_name)}'s profile
                  </p>
                </div>
                {!props.week_in_past && (
                  <div
                    className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                    onClick={toggleLocked}
                  >
                    <div className="text-slate-600">
                      {props.locked_person ? (
                        <LockOpenIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      ) : (
                        <LockIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      )}
                    </div>
                    <p className="text-sm">
                      {!props.week_in_past &&
                        (props.locked_person
                          ? `Unlock ${formatName(
                              props.p.first_name
                            )}'s schedule for week`
                          : `Lock ${formatName(
                              props.p.first_name
                            )}'s schedule for week`)}
                    </p>
                  </div>
                )}
                {!props.week_already_started && (
                  <div
                    className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
                    //onClick={toggleLocked}
                    onClick={() => {
                      setIsLoadingSchedule(true);
                      props.loadEmployeePreviousSchedule(props.p.id);
                    }}
                  >
                    <div className="text-slate-600">
                      {isLoadingSchedule ? (
                        <CircularProgress
                          size="1rem"
                          style={{ marginRight: "2px" }}
                        />
                      ) : (
                        <FolderCopyIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      )}
                    </div>
                    <p className="text-sm">
                      Copy {formatName(props.p.first_name)}'s schedule from last
                      week
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          {props.p.hours > props.p.overtime_hours_threshold && (
            <div
              className={`absolute ${
                props.view === "daily" ? "-bottom-2" : "-bottom-1"
              } ${props.locked_person ? "right-4" : "right-0"} text-gray-700`}
            >
              <Tooltip title="Overtime Hours">
                <WarningIcon style={{ fontSize: "14px", color: "#d32f2f" }} />
              </Tooltip>
            </div>
          )}
          <div
            className={`absolute ${
              props.view === "daily" ? "-bottom-2" : "-bottom-1"
            } right-0 cursor-pointer`}
            // onClick={toggleLocked}
          >
            {!props.week_in_past && props.locked_person && (
              <Tooltip
                title={`You have locked ${formatName(
                  props.p.first_name
                )}'s schedule for this week. This will prevent any changes to their schedule when the autoscheduler is run. Click to unlock.`}
              >
                <div
                  className="text-slate-500 hover:text-violet-700 cursor-pointer"
                  onClick={toggleLocked}
                >
                  <LockIcon style={{ fontSize: "14px" }} />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <PersonCardModal
          handleClose={handleClose}
          open={open}
          p={props.p}
          role_names={props.role_names}
          updatePersonInfo={props.updatePersonInfo}
          handleMainRoleSelection={handleMainRoleSelection}
          mainRoleId={mainRoleId}
          setMainRoleId={setMainRoleId}
          returnAvatar={returnAvatar}
          proficiencyStarsCount={props.proficiencyStarsCount}
          proficiencyFromLMSEnabled={props.proficiencyFromLMSEnabled}
        />
      </div>
    </div>
  );
}

export default PersonCard;
