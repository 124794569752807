import React from "react";
import TopBarViewLogs from "../home/top_bar_components/TopBarViewLogs";
import { formatDate } from "../requests/utilities/helpers";
import { formatDateReadableWithDayOfWeek } from "../requests/utilities/helpers";
import CategoryCard from "./CategoryCard";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import {
  API_get_logbook_category_entries,
  API_post_logbook_category_entry,
  API_delete_logbook_category_entry,
  API_get_stats,
  API_get_file_upload,
  API_delete_file_upload,
} from "../../../constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Lightbox } from "react-modal-image";

function ViewLogbookDisplay(props) {
  const [date, setDate] = React.useState(new Date());
  const [isLoading, setIsLoading] = React.useState(false);
  const [logbookCategoryEntriesData, setLogbookCategoryEntriesData] =
    React.useState(null);
  const [logbookStats, setLogbookStats] = React.useState(null);

  const [showLighbox, setShowLightbox] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const increment_day = async (date, direction) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + direction);
    setDate(newDate);
  };

  const fetchLogbookData = async (showLoading) => {
    setIsLoading(showLoading);
    const apiParamsStats = {
      specific_date: formatDate(date),
      store_id: props.homeStore,
    };
    const apiParamsEntries = {
      store_id: props.homeStore,
      date: formatDate(date),
    };

    try {
      const [statsRes, entriesRes] = await Promise.all([
        getRightworkAPI(API_get_stats, apiParamsStats),
        getRightworkAPI(API_get_logbook_category_entries, apiParamsEntries),
      ]);

      if (statsRes.status === 200) {
        const statsData = statsRes.data?.redux?.payload;
        setLogbookStats(statsData);
      }

      if (entriesRes.status === 200) {
        const entriesData = entriesRes.data?.redux?.payload?.columns;
        setLogbookCategoryEntriesData(entriesData);
      }
    } catch (error) {
      console.error("Promise failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchLogbookData(true);
  }, [date, props.homeStore]);

  // [GET] api/file_upload_info/get_file_upload
  // * file_upload_uuid
  const get_file_upload = async (file_upload_uuid) => {
    let api_params = {
      file_upload_uuid: file_upload_uuid,
    };
    try {
      const res = await getRightworkAPI(API_get_file_upload, api_params);
      if (res.status === 200) {
        setSelectedImage(res.data?.redux?.payload?.presigned_url);
        setShowLightbox(true);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  // [POST] api/file_upload_info/delete_file_upload
  // * file_upload_uuid
  const delete_file_upload = async (file_upload_uuid) => {
    const requestData = {
      file_upload_uuid: file_upload_uuid,
    };

    try {
      toast.loading("Deleting File...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_file_upload, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("File Deleted Successfully", { autoClose: 3000 });
        // fetchLogbookColumns(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        // console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error);
    }
  };

  const post_logbook_category_entry = async (
    logbook_category_uuid,
    value_text,
    value_integer,
    value_float,
    file_upload_uuids
  ) => {
    const requestData = {
      store_id: props.homeStore,
      logbook_category_uuid,
      value_text,
      value_integer,
      value_float,
      file_upload_uuids: JSON.stringify(file_upload_uuids),
      date: formatDate(date),
    };

    try {
      toast.loading("Saving Entry...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_logbook_category_entry,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Entry Saved Successfully", { autoClose: 3000 });
        fetchLogbookData(false);
      }
    } catch (error) {
      toast.dismiss();
      handlePostError(error);
    }
  };

  const delete_logbook_category_entry = async (logbook_category_entry_uuid) => {
    const requestData = {
      store_id: props.homeStore,
      logbook_category_entry_uuid,
    };

    try {
      toast.loading("Deleting Entry...", { autoClose: false });

      const res = await postRightworkAPI(
        API_delete_logbook_category_entry,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Entry Deleted Successfully", { autoClose: 3000 });
        fetchLogbookData(false);
      }
    } catch (error) {
      toast.dismiss();
      handlePostError(error);
    }
  };

  const handlePostError = (error) => {
    if (error.response?.data?.system?.message) {
      toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      // console.log("Error Info", error.response?.data?.system?.data);
    } else {
      toast.error("Request Failed", { autoClose: 3000 });
    }

    console.error("Error:", error);
  };

  // console.log("STATS", logbookStats)

  return (
    <>
      {showLighbox && (
        <div className="absolute w-screen h-screen z-40">
          <Lightbox
            small={selectedImage}
            medium={selectedImage}
            // large={selectedImage}
            hideDownload={true}
            hideZoom={true}
            onClose={() => setShowLightbox(false)}
          />
        </div>
      )}
      <div className="w-full min-h-screen flex flex-col">
        <div>
          <TopBarViewLogs
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
            date={date}
            increment_day={increment_day}
          />
        </div>
        {!isLoading ? (
          <div className="mt-12 px-12 pb-12">
            <h2 className="text-slate-900 text-2xl">Logbook</h2>
            <p className="text-slate-500 mt-2">
              {formatDateReadableWithDayOfWeek(formatDate(date))}
            </p>
            <div className="mt-4 w-full border border-slate-300 rounded-xl flex justify-between py-4 px-5">
              <div>
                <h4 className="text-slate-500">Total Sales</h4>
                <p className="text-slate-900 text-3xl">
                  {logbookStats?.actual_revenue
                    ? logbookStats?.actual_revenue?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "--"}
                </p>
              </div>
              {props.laborPercentEnabled ? (
                <div>
                  <h4 className="text-slate-500">Total Labor</h4>
                  <p className="text-slate-900 text-3xl">
                    {logbookStats?.labor_cost
                      ? logbookStats?.labor_cost.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "--"}
                  </p>
                </div>
              ) : (
                <div>
                  <h4 className="text-slate-500">Total Labor Hours</h4>
                  <p className="text-slate-900 text-3xl">
                    {logbookStats?.actual_labor_hours
                      ? logbookStats?.actual_labor_hours
                      : "--"}
                  </p>
                </div>
              )}
              {props.laborPercentEnabled ? (
                <div>
                  <h4 className="text-slate-500">Labor Percent</h4>
                  <p className="text-slate-900 text-3xl">
                    {logbookStats?.labor_cost
                      ? `${(
                          (logbookStats?.labor_cost /
                            logbookStats?.actual_revenue) *
                          100
                        ).toFixed(1)}%`
                      : "--"}
                  </p>
                </div>
              ) : (
                <div>
                  <h4 className="text-slate-500">SPLH</h4>
                  <p className="text-slate-900 text-3xl">
                    {logbookStats?.actual_labor_hours
                      ? (
                          logbookStats?.actual_revenue /
                          logbookStats?.actual_labor_hours
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "--"}
                  </p>
                </div>
              )}
              <div>
                <h4 className="text-slate-500">Weather</h4>
                <div className="flex items-center">
                  <img
                    src={logbookStats?.weather?.icon}
                    alt={logbookStats?.weather?.text}
                    width={40}
                  />
                  <p className="text-slate-900 text-3xl">
                    {logbookStats?.weather
                      ? `${logbookStats?.weather.maxtemp}°F`
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 w-full flex gap-x-6">
              <div className="w-1/2">
                {logbookCategoryEntriesData?.[0]?.categories?.length > 0 ? (
                  <div>
                    {logbookCategoryEntriesData?.[0]?.categories?.map(
                      (category, index) => (
                        <CategoryCard
                          key={index}
                          fileKey={index + "0"}
                          category={category}
                          post_logbook_category_entry={
                            post_logbook_category_entry
                          }
                          delete_logbook_category_entry={
                            delete_logbook_category_entry
                          }
                          homeCompany={props.homeCompany}
                          get_file_upload={get_file_upload}
                          delete_file_upload={delete_file_upload}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div className="w-full h-36 text-slate-500 flex flex-col items-center justify-center border border-slate-300 rounded-xl">
                    <div>
                      <InfoOutlinedIcon />
                    </div>
                    <p className="mt-1 w-[65%] text-center text-sm">
                      No categories configured for this column. Go to "Configure
                      Logbook" tab to add categories.
                    </p>
                  </div>
                )}
              </div>
              <div className="w-1/2">
                {logbookCategoryEntriesData?.[1]?.categories?.length > 0 ? (
                  <div>
                    {logbookCategoryEntriesData?.[1]?.categories?.map(
                      (category, index) => (
                        <CategoryCard
                          key={index}
                          fileKey={index + "1"}
                          category={category}
                          post_logbook_category_entry={
                            post_logbook_category_entry
                          }
                          delete_logbook_category_entry={
                            delete_logbook_category_entry
                          }
                          homeCompany={props.homeCompany}
                          get_file_upload={get_file_upload}
                          delete_file_upload={delete_file_upload}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div className="w-full h-36 text-slate-500 flex flex-col items-center justify-center border border-slate-300 rounded-xl">
                    <div>
                      <InfoOutlinedIcon />
                    </div>
                    <p className="mt-1 w-[65%] text-center text-sm">
                      No categories configured for this column. Go to "Configure
                      Logbook" tab to add categories.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex-1 relative">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <LoadSpinner />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ViewLogbookDisplay;
