import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ButtonFilled from "../requests/shared/helpers/ButtonFilled";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

function DepartmentSettingsDrawer(props) {
  const [state, setState] = React.useState(props.modalData);

  useEffect(() => {
    setState(props.modalData);
  }, [props.modalData]);

  function handleChange(evt) {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  function handleSubmit() {
    // console.log("SUBMITTING: ", state);
    let edit_type = "edit";
    if (!props.modalEdit) {
      edit_type = "create";
    }
    props.edit_department(state, edit_type);
  }

  useEffect(() => {
    if (!props.isSavingData) {
      props.onClose();
    }
  }, [props.isSavingData]);

  // console.log(state);

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader
            title={`${props.modalEdit ? "Edit" : "Add"} department`}
            onClose={props.onClose}
          />
          <div className="w-full px-6">
            <div className="mt-7">
              <p className="text-sm text-slate-500">Location</p>
              <div className="mt-1">
                <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                  <p>{props.store_name}</p>
                </div>
              </div>
            </div>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Name</p>
              <div className="mt-1">
                <TextField
                  placeholder="Front of house"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="role_type"
                  value={state.role_type}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <DrawerFooterContainerLarge>
            <div className="w-full">
              <LoadingButton
                fullWidth
                loading={props.isSavingData}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="contained"
                onClick={() => handleSubmit()}
                sx={{
                  textTransform: "none",
                  borderRadius: "50px",
                  paddingY: "14px",
                  backgroundColor: "#6d28d9",
                  "&:hover": {
                    backgroundColor: "#5b21b6",
                  },
                  fontSize: "16px",
                }}
              >
                Save
              </LoadingButton>
            </div>
            <div className="w-full mt-3">
              <ButtonOutlined text="Cancel" onClick={props.onClose} />
            </div>
          </DrawerFooterContainerLarge>
          <div className="h-[200px]"></div>
        </div>
      </div>
    </Drawer>
  );
}

export default DepartmentSettingsDrawer;
